import React, { useEffect, useState, useRef } from "react";
import { 
  ref, deleteObject 
} from 'firebase/storage';
import { storage } from '../../firebase';
import './ElementBar.css';
import frameIcon from '../../icons/frameIcon.png';
import stickerIcon from '../../icons/stickerIcon.png';
import textIcon from '../../icons/textIcon.png';
import photoIcon from '../../icons/photoIcon.png';
import deleteIcon2 from '../../icons/deleteIcon2.png';
import ImagePopup from './ImagePopup';

const ElementBar = (props) => {
  const { onClickOutside } = props;
  const [imageProgress, setImageProgress] = useState(0);
  const [showFrameOptions, setShowFrameOptions] = useState(false);

  // Initialize state to hold all the refs
  const [excludedRefs, setExcludedRefs] = useState([
    props.vendorBarRef,
    props.frameBarRef,
    props.imageFrameBarRef,
    props.frameBorderPopupRef,
    props.frameTypesPopupRef,
    props.draggableFrameRef,
    props.frameRef,
    props.imageRef,
    props.textPopupRef,
    props.resizeHandlerRef
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (imageProgress < 100) { // Prevent closing if image is still uploading
        // Check if the click is inside any of the excluded refs
        const isInsideExcluded = excludedRefs.some(refItem => {
          return refItem?.current && refItem.current.contains(event.target);
        });

        // Also check if the click is inside the ElementBar itself
        const isInsideElementBar = props.elementBarRef.current && props.elementBarRef.current.contains(event.target);

        if (!isInsideExcluded && !isInsideElementBar) {
          onClickOutside && onClickOutside();
          setShowFrameOptions(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside, imageProgress, excludedRefs]);

  const handleDeleteIconClick = () => {
    console.log("About");
    if (props.isImageFrame) {
      const imageURL = props.frameImg;
      let fileName = imageURL.slice(89, imageURL.lastIndexOf('?')).replace(/ /g, "%20");
      fileName = decodeURIComponent(fileName);
      console.log("Decoded fileName:", fileName);

      // Check if the imageURL contains '_1000x1000'
      if (imageURL.includes('_1000x1000')) {
        // Define references for both images
        const originalRef = ref(storage, `website images/${fileName.replace('_1000x1000', '')}`);
        const resizedRef = ref(storage, `website images/${fileName}`);

        // Delete original image
        deleteObject(originalRef).then(() => {
          console.log("Original image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting original image:", error);
        });

        // Delete resized image
        deleteObject(resizedRef).then(() => {
          console.log("Resized image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting resized image:", error);
        });
      } else {
        // If '_1000x1000' is not part of the URL, delete the current image and its resized version
        const deleteRef = ref(storage, `website images/${fileName}`);
        const deleteResizedRef = ref(storage, `website images/${fileName}_1000x1000`);

        deleteObject(deleteRef).then(() => {
          console.log("Image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting image:", error);
        });

        deleteObject(deleteResizedRef).then(() => {
          console.log("Resized image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting resized image:", error);
        });
      }
      props.setShowImagePopup(false);
    } else {
      props.removeTextPopup();
    }
    console.log("About to remove frame");
    props.removeFrame(props.frameID, props.storeID);
  };

  const handleFrameIconClick = () => {
    props.setShowImagePopup(false);
    props.setChangeToText(false)
    props.removeTextPopup();
    console.log(props.isFrameEmpty);
    if (props.isFrameEmpty) { 
      props.openFramePopup('newFrame'); // If the frame has no content, just open the normal Frame Popup
    } else {
      setShowFrameOptions(!showFrameOptions);  
    }
  };

  if(!props.show)
    return null;

  return (
    <div className="elementContainer" ref={props.elementBarRef}>
      <div className="elementBar" ref={props.vendorBarRef}> 
        <img className="eBFrameIcon" id="eBIcons" 
          src={frameIcon} 
          alt="insert frame" 
          onClick={handleFrameIconClick}
        />
        <img className="eBTextIcon" id="eBIcons" 
          src={textIcon} 
          alt="insert text" 
          onClick={() => {
            props.setShowImagePopup(false);
            setShowFrameOptions(false);  
            if (props.isImageFrame == true) { 
              console.log("Would the user like to change the frame")
              props.setChangeToText(!props.changeToText)
            } else {
              props.textIconClicked();
              props.closePopup()
            }
          }}
        />
        <img className="eBPhotoIcon" src={photoIcon} id="eBIcons"
          onClick={() => {
            props.setShowImagePopup(!props.showImagePopup);
            props.setChangeToText(false)
            setShowFrameOptions(false)
            props.imageIconClicked();
            props.removeTextPopup();
            // if (props.isTextFrame == true) { //remove text and make it an image frame }
          }}
        />
        <img className="eBStickerIcon" id="eBIcons" 
          src={stickerIcon} 
          alt="insert sticker" 
          onClick={() => {
            props.removeTextPopup();
            props.setShowImagePopup(false);
            props.openStickerPopup();
          }}
        />
        <img 
          className="eBDeleteIcon" id="eBIcons" 
          src={deleteIcon2} alt="" 
          onClick={handleDeleteIconClick}
        />
      </div>
      {showFrameOptions && (
        <div className="frameOptionsPopup">
          <div 
            className="frameOption" 
            onClick={() => {
              setShowFrameOptions(false);
              props.openFramePopup('shapeChange'); // Indicate shape change
            }}
          >
            change frame shape
          </div>
          <div 
            className="frameOption" 
            onClick={() => {
              setShowFrameOptions(false);
              props.openFramePopup('addedFrame'); // Normal frame insertion
            }}
          >
            insert new frame
          </div>
        </div>
      )}
      {props.changeToText && (
        <div className="changeToTextPopup"  ref={props.elementBarRef}>
          <div className="changeToTextPopupPrompt">
            Change to a text frame?
          </div>
          <div 
            className="changeToTextPopupYes" 
            onClick={() => {
              props.textIconClicked()
              props.closePopup()
              props.saveText()
            }}
          >
            yes
          </div>
          <div className="changeToTextPopupNo" onClick={() => {props.setChangeToText(false)}}>no</div>
        </div>
      )}
      {props.showImagePopup &&
      <div className="imagePopupContainer">
        <ImagePopup 
          frameID={props.frameID} 
          storeID={props.storeID} 
          addImageToFrame={props.addImageToFrame}
          closePopup={props.closePopup}
          closeImagePopup={() => props.setShowImagePopup(false)}
          setImageProgress={setImageProgress}
          isImageFrame={props.isImageFrame}
        />
      </div>}
    </div>
  );
};

export default ElementBar;
