// OpacityPopup.jsx

import React, { useEffect, useRef } from 'react';
import RoundedSlider from './RoundedSlider'; // Ensure this path is correct
import './OpacityPopup.css'; // Ensure this CSS file styles the popup appropriately

const OpacityPopup = ({ opacity, opacityInput, onSliderChange, onInputChange, closePopup, opacityPopupRef }) => {

  // Handler for slider change
  const handleSliderChange = (newOpacity) => {
    onSliderChange(newOpacity);
  };

  // Handler for input change
  const handleInputChange = (e) => {
    const newOpacity = e.target.value;
    onInputChange(newOpacity);
  };

  // Effect to handle clicks outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (opacityPopupRef.current && !opacityPopupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closePopup]); 

  return (
    <div className="opacityPopupContainer" ref={opacityPopupRef}>
      <div className="opacityPopupHeading">opacity</div>
      <div className="opacityPopupContent">
        <RoundedSlider  
          value={opacity}
          onChange={handleSliderChange}
          min={0}
          max={100}
        />
        <div className="opacityInputSection">
          <input 
            type="number" 
            className="opacityInput" 
            value={opacityInput}
            onChange={handleInputChange}
            min="0"
            max="100"
          />
          <span className="opacityPercentage">%</span>
        </div>
      </div>
    </div>
  );
};

export default OpacityPopup;
