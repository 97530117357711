import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Link, Outlet } from "react-router-dom";

import { onAuthStateChanged } from "firebase/auth";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot } 
from '@firebase/firestore'
import { auth, db, storage } from '../firebase'
import { motion, AnimatePresence, useAnimation, useMotionValue } from "framer-motion";

import './EthicalChecksPopup.css'
import ethicalSticker from '../images/ethicalSticker1.png'

const EthicalChecksPopup = ({ onClickOutside, ethicalChecks, ethicalChecksText }) => {
  const ethicalChecksPopupRef = useRef()

  // code below closes the framePopup when a user clicks outside the framePopup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ethicalChecksPopupRef.current && !ethicalChecksPopupRef.current.contains(event.target)) {
        onClickOutside?.();
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup function
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  console.log(ethicalChecks)

  return (
    <div className="ethicalChecksPopupContainer">
      <motion.div 
        initial={{ y: '100vh' }} // Start below the screen
        animate={{ y: 0 }} // End at its original position
        transition={{ stiffness: 100 }} 
        ref={ethicalChecksPopupRef} 
        className="ethicalChecksPopupDiv"
      >
        <div className="ethicalChecksPopupHeader" >ethical checks</div>
        <img src={ethicalSticker} className="ethicalStickerImg1" />
        <div className="ethicalChecksContent">
        <div className="ethicalChecksTagDiv">
        { ethicalChecks.map((ethicalTag, index) => (
          <div className="ethicalTagItem" key={index} >
            <span className="ethicalTagText">{ethicalTag}</span>
          </div>
        ))}
        </div>
        <div className="ethicalChecksDescription">{ethicalChecksText}</div>
        </div>
      </motion.div>
    </div>
  )
}

export default EthicalChecksPopup