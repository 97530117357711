import React, { useEffect, useState, useRef } from "react";
import { auth, db, storage } from '../../firebase'
import { collection, doc, updateDoc, setDoc, getDoc, 
query, getDocs, onSnapshot, deleteDoc, limit, orderBy, 
serverTimestamp, startAfter } from '@firebase/firestore'
import { ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, uploadBytes, deleteObject } 
  from 'firebase/storage'
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4} from "uuid";
import { motion, AnimatePresence } from 'framer-motion';

import Stickers from "./Stickers";
import StickerPopup from "./StickerPopup";
import './StickerPopup.css'
import './AssetPopup.css'
import backArrow from '../../icons/backArrow.png'
import plusIcon from '../../icons/plusIcon.png'
import infoIcon from '../../icons/infoIcon.png'
import loadIcon from '../../icons/scoutIcon.png'
import { setUserId } from "firebase/analytics";

const AssetPopup = (props) => {
  const assetPopupRef = useRef()
  const [showStickerSection, setShowStickerSection] = useState(false)
  const [url, setUrl] = useState([])
  const [data, setData] = useState([])
  const { onClickOutside } = props; 
  const stickerPreviewRef = useRef();
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [stickerLoading, setStickerLoading] = useState(true)
  const [imageUpload, setImageUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const handleArrowClick = () => {
    // Check if the ref is correctly attached to the div
    if (stickerPreviewRef.current) {
      // Define the scroll amount, adjust as needed
      const scrollAmount = 300;

      // Scroll horizontally
      stickerPreviewRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (assetPopupRef.current && !assetPopupRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

  useEffect(() => {
    fetchPreviewStickerData()
  }, [])

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => resolve(url);
      image.onerror = () => reject(url);
    });
  };

  const fetchPreviewStickerData = async () => {
    let stickerArr = [];
    try {
      const stickerPopupDocRef = query(
        collection(db, "sticker popup stickers"),
        orderBy("stickerDocID", "asc"),
        limit(9)
      );
      const querySnapshot = await getDocs(stickerPopupDocRef);
      const stickerInfo = [];
  
      const downloadPromises = querySnapshot.docs.map(async (doc) => {
        const stickerName = doc.data().stickerDocID;
        const url = await getDownloadURL(ref(storage, `stickers/${stickerName}`)).catch(() => {});
        if (url) {
          stickerArr.push(url);
          stickerInfo.push({ id: doc.id, ...doc.data() });
        }
      });
  
      Promise.all(downloadPromises).then(() => {
        const imagePromises = stickerArr.map(loadImage);
        Promise.all(imagePromises).then(() => {
          setData(stickerInfo);
          setUrl(stickerArr);
          setStickerLoading(false)
          console.log("Sticker loading has been set to false")
        });
      });
  
    } catch (err) {
      console.log(err);
    }
  };

  const uploadImage = (file) => {
    if (!file) {
      console.error("No file provided for upload");
      return;
    }
    setIsLoading(true);
    const imageName = file.name + uuidv4();
    const imageRef = ref(storage, `website images/${imageName}`);

    const uploadTask = uploadBytesResumable(imageRef, file);
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPercentage(progress);
      }, 
      (error) => {
        console.error("Upload Error:", error);
        setIsLoading(false)
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          props.addSticker(url, props.storeID, props.userID, props.stickerID, "uploadedImage")
          setImageUrl(url);
          setIsLoading(false);
        });
      }
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImagePreviewUrl(URL.createObjectURL(file))
      setImageUpload(file);
      uploadImage(file); // Start upload immediately
    }
  };

  return (
    <div className="assetPopupDiv" ref={assetPopupRef}>
      {!showStickerSection &&
        <div style={{width: '100%'}}>
          <div className="assetPopupDivHeader">
            <div className="assetPopupHeading">assets</div>
          </div>
          <div className="imageAssetUploadDiv">
            <div className="imageAssetDivHeading">upload an image</div>
            <div className="imageAssetPlusContainer">
            {imagePreviewUrl || imageUrl ? (
              <>
                <img src={imagePreviewUrl} alt="Uploaded" className="uploadedImagePreview" />
                {isLoading && <progress value={percentage} max="100" className="imageAssetUploadProgressBar"></progress>}
              </>
              ) : (
              <img 
                src={plusIcon} 
                alt="Upload" 
                onClick={() => fileInputRef.current.click()} 
                className="imageAssetPlusIcon"
              />
            )}
            </div>
            {!isLoading && imageUrl && (
              <button 
                className="imageAssetDoneButton" 
                onClick={() => {
                  onClickOutside(); // Assuming this closes the popup
                  setIsShown(false);
                  setImageUrl(null);
                  setPercentage(0);
                }}
              >
                done
              </button>
            )}


            {/* Hidden file input */}
            <input 
              type="file" 
              ref={fileInputRef} 
              style={{ display: 'none' }} 
              onChange={handleImageChange} 
            />
          </div>
          <div className="stickerAssetDiv">
            <div className="stickerAssetDivHeader">
              <div className="stickerAssetDivHeading">stickers</div>
              <div className="stickerAssetDivBtn"
                onClick={() => {setShowStickerSection(true)}}
              >
                show all
              </div>
            </div>
            {!stickerLoading &&
              <div className="stickerAssetPreviewDiv" ref={stickerPreviewRef}>
                {url.map((url, index) => (
                  <img className="stickerAssetPreview" src={url} key={index} 
                    onClick={() => {
                      props.addSticker(url, props.storeID, props.userID, props.stickerID, "sticker")
                      props.closePopup()
                    }}
                  />
                ))}
                <div 
                  className="stickerAssetPreviewArrow" 
                  onClick={handleArrowClick}
                >
                  <img src={backArrow} alt="" className="stickerAssetPreviewArrowIcon" />
                </div>
              </div>
            }

            {stickerLoading &&
              <motion.div 
                className="assetPopupLoadingDiv"
              >
                <img src={loadIcon} className="assetPopupLoadingIcon" alt="loading icon" />
              </motion.div>
            }
            
          </div>
        </div>
      }
      {showStickerSection &&
        <StickerPopup 
          closePopup={props.closePopup}
          closeStickerPopup={() => setShowStickerSection(false)}
          userID={props.userID}
          storeID={props.storeID}
          stickerID={props.stickerID}
          addSticker={props.addSticker}
        />
      }
    </div>
  )
}

export default AssetPopup