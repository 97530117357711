import React, { useRef } from 'react';
import plusIcon from '../../icons/plusIcon.png'; 
import './CategoryTags/CategoryTags.css';
import deleteIcon from '../../icons/deleteIcon.png';

const ImageTags = ({ tags = [], setTags }) => {
  const newTagRef = useRef();

  function addNewTag() {
    const value = newTagRef.current.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    newTagRef.current.value = '';
  }

  function removeTag(index) {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  }

  return (
    <div className="imageTagsDiv">
      <h1 className="activeTagsHeader">image tags</h1>
      <p className="activeTagsDescription">
        Used to describe your product in detail for our algorithm
      </p>
      <div className="activeTags">
        {tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>
              <img src={deleteIcon} alt="" className="deleteTagIcon" />
            </span>
          </div>
        ))}
      </div>
      <div className="imageAddNewTagDiv">
        <input
          type="text"
          placeholder="Add new tag"
          id="addNewTagText"
          ref={newTagRef}
        />
        <img
          onClick={addNewTag}
          src={plusIcon}
          alt="Add your tag"
          id="addNewTagIcon"
        />
      </div>
    </div>
  );
};

export default ImageTags;
