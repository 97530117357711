import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { auth, db, storage } from '../../firebase'
import { collection, doc, updateDoc, setDoc, arrayUnion,
getDoc, onSnapshot, deleteDoc, serverTimestamp } 
from '@firebase/firestore'

import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4} from "uuid";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import './EthicalChecks.css'

const EthicalChecks = (props) => {
  const ethicalChecksRef = useRef()
  const [tags, setTags] = useState([])
  const newTagRef = useRef()

  useEffect(() => {
    // Check if editing and ethicalTags prop is provided
    console.log(props.ethicalChecks)
    if (props.isEditing && props.ethicalChecks && props.ethicalChecks.length > 0) {
      console.log(props.ethicalChecks)
      setTags(props.ethicalChecks);
    }
  }, [props.isEditing, props.ethicalChecks]);
  
  useEffect(() => {
    if (props.isEditing && props.ethicalChecksText && ethicalChecksRef.current) {
      ethicalChecksRef.current.value = props.ethicalChecksText;
    }
  }, [props.isEditing, props.ethicalChecksText])

  const ethicalTags = [
    'second-hand',
    'recycled',
    'handmade',
    'pre-loved',
    'thrifted',
    'vintage',
    'locally made',
    'eco-friendly',
    'sustainable',
    'biodegradable',
    'cruelty-free',
    'ethically sourced',
    'non-toxic',
    'vegan',
    'organic',
    'carbon neutral',
    'upcycled',
    'refurbished',
    'energy efficient',
    'compostable packaging',
    'BPA-free',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function toggleTag(ethicalTag) {
    if (tags.includes(ethicalTag)) {
      removeTag(tags.indexOf(ethicalTag));
    } else {
      setTags([...tags, ethicalTag]);
      props.addEthicalChecks([...tags, ethicalTag]) 
    }
  }
  
  function removeTag(index) {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    props.addEthicalChecks(newTags); // Assuming you want to update props when a tag is removed
  }

  function isActiveTag(ethicalTag) {
    return tags.includes(ethicalTag);
  }

  const handleTextareaChange = (event) => {
    const newValue = event.target.value;
    props.addEthicalChecksText(newValue);
    console.log(newValue)
  };

  return (
    <div className="ethicalChecksContainer">
      <h1 className='ethicalChecksHeader'>ethical checks</h1>
      <div className="ethicalTagsDiv">
        { ethicalTags.map((ethicalTag, index) => (
          <div className={`ethicalTagItem${isActiveTag(ethicalTag) ? 'Active' : ''}`} 
          key={index} 
          onClick={() => toggleTag(ethicalTag)}>
            <span className="ethicalTagText">{ethicalTag}</span>
          </div>
        ))}
        
      </div>
      <textarea 
        placeholder='Is there anything else your product or brand does (that has not been mentioned above) which improves society overall ' 
        className='ethicalChecksText' 
        rows="6" 
        ref={ethicalChecksRef}
        onChange={handleTextareaChange}
      />
    </div>
  )
}

export default EthicalChecks