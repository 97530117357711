import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
  createUserWithEmailAndPassword, 
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth"

import { auth, signInWithGoogle, db, storage } from "../firebase"
import { addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot, deleteDoc, 
  limit, orderBy } 
from '@firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4} from "uuid";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import './CreateStore.css'
import './StorelessUser.css'
import FilmGrain from '../scout/FilmGrain'
import MarketGradient from "../market/MarketGradient";

import loadIcon from '../icons/scoutIcon.png';
import vendorIcon from '../icons/vendorIcon.png'
import deleteIcon from '../icons/deleteIcon.png'
import backArrow from '../icons/backArrow.png'
import eyeIcon from '../icons/eyeIcon.png'
import plusIcon from '../icons/plusIcon.png'

function getStoreID() {
  let title = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 7; i++) {
    title += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  title += new Date().getTime()
  return title
}

const CreateStore = () => {
  // no user or store associated 

  const createStoreBtnRef = useRef()
  const basicInfoDivRef = useRef()
  const businessInfoDivRef = useRef()
  const businessDescriptionRef = useRef()
  const industryRef = useRef()
  const businessLogoDivRef = useRef()
  const uploadDivRef = useRef()
  const cSAlreadyLoggedInRef = useRef()
  const navigate = useNavigate()

  const [storeID, setStoreID] = useState(getStoreID())
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [hasStore, setHasStore] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [storeName, setStoreName] = useState("")
  const [selectedIndustry, setSelectedIndustry] = useState("")
  const [description, setDescription] = useState("")
  const [data, setData] = useState({})
  const [percentage, setPercentage] = useState(null)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [firstSection, setFirstSection] = useState(true)
  const [secondSection, setSecondSection] = useState(false)
  const [storeNameExists, setStoreNameExists] = useState(true)
  const [storeNameExistsDiv, setStoreNameExistsDiv] = useState(false)
  const [thirdSection, setThirdSection] = useState(false)
  const [alreadyLoggedIn, createStoreAlreadyLoggedIn] = useState(false)

  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const savedDominantColors = ['rgb(197, 201, 198)', 'rgb(91, 106, 125)', 'rgb(138, 60, 36)', 'rgb(210, 136, 55)', 'rgb(114, 126, 132)']

  const [file, setFile] = useState("")
  const [progressCompleted, setProgressCompleted] = useState(false)

  useEffect(() => {
    document.title = 'Create Store | taizte™';
  }, []);

  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     setFirstSection(false)
  //     setThirdSection(false)
  //     createStoreAlreadyLoggedIn(true)
  //   } else {}
  // })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          const dbRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(dbRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.store) {
              // User has a store
              setHasStore(true);
            } else {
              // User does not have a store
              setHasStore(false);
            }
          } else {
            // User document does not exist
            setHasStore(false);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        // No user is logged in
        setHasStore(false);
      }
      setIsLoading(false);
    });
  
    return () => unsubscribe();
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  
  useEffect(() => {
    const checkIfStoreNameExists = async () => {
      const storeQuery = query(collection(db, "stores"), where("store", "==", storeName));
      let storeNameExists = false;
      try {
        const snapshot = await getDocs(storeQuery);
        if (snapshot.size > 0) {
          storeNameExists = true;
          setStoreNameExistsDiv(true)
        } else {setStoreNameExistsDiv(false)}
      } catch (error) {
        console.error(error);
      }
      setStoreNameExists(storeNameExists);
    };
  
    if (storeName) {
      checkIfStoreNameExists();
    }
  }, [storeName]);

  const handleCreateAccount = () => {
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log(user.uid, user.email, name, storeName, description, storeID, selectedIndustry);

      const dbRef = doc(db, 'users', user.uid)
      
      const resolution = "_1000x1000"
      function insertResolution(str,indexPos,str2){
        return str.substring(0, indexPos) + str2 + str.substring(indexPos);
      };
    
      let productImg = data.img
      const resizedImage = (insertResolution(productImg,productImg.lastIndexOf('?'),resolution));
      
      const bundlingRuleData = {    
        "Aramex Standard (35 x 45cm)": 1,
        "Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)": 1,
        "Locker-to-Locker Pudo Small (60 x 41 x 8cm)": 1,
        "Locker-to-Locker Pudo Medium (60 x 41 x 19cm)": 1,
        "Locker-to-Locker Pudo Large (60 x 41 x 41cm)": 1,
        "Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)": 1,
        "Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)": 1,
        "Door-to-Locker Pudo Small (60 x 41 x 8cm)": 1,
        "Door-to-Locker Pudo Medium (60 x 41 x 19cm)": 1,
        "Door-to-Locker Pudo Large (60 x 41 x 41cm)": 1,
        "Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)": 1,
        "Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)": 1,
        "Locker-to-Door Pudo Small (60 x 41 x 8cm)": 1,
        "Locker-to-Door Pudo Medium (60 x 41 x 19cm)": 1,
        "Locker-to-Door Pudo Large (60 x 41 x 41cm)": 1,
        "Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)": 1,
        "Paxi Standard (45 x 37cm)": 1,
        "Paxi Large (64 x 51cm)": 1
      }

      let userInfo = {
        email: user.email,
        user: name,
        userID: user.uid,
        store: storeName,
        store_description: description,
        store_logo: resizedImage,
        storeID: storeID,
        industry: selectedIndustry,
        likes: 0,
        bundlingRule: bundlingRuleData
      }

      try {
        setDoc(dbRef, userInfo)
      } catch(e){
        console.log(e)
      }

      updateProfile(auth.currentUser, {
        displayName: name,
      }).then(() => {}).catch((error) => {console.log(error)});

      console.log(user)
      console.log("The user has been added to the database")

      // delete the non-resized logo image that has been uploaded
      let imageURL = (data.img)
      let fileName = imageURL.slice(85, imageURL.lastIndexOf('?')).replace(/ /g, "%20")
      fileName = decodeURIComponent(fileName)

      const deleteRef = ref(storage, `store logo/${fileName}`)
      
      deleteObject(deleteRef).then(() => {
        console.log("Non-resized logo successfully deleted")
      }).catch((error) => {
        console.log(error)
      });

      // create store document within database
      const storeDbRef = doc(db, 'stores', storeID)

      try {
        setDoc(storeDbRef, userInfo)
      } catch(e){
        console.log(e)
      }

      // alert("You have successfully created your store")
      navigate('/vendor')
    })
    .catch((error) => {
      setError(true)
    });
  }

  function showBusinessInfo() {
    setFirstSection(false)
    setSecondSection(true)
  }

  function backToShowBasicInfo() {
    setFirstSection(true)
    setSecondSection(false)
    // show previously inputed data
  }

  function showBusinessLogo() {
    setSecondSection(false)
    setThirdSection(true)
  }

  function backToShowBusiness() {
    setSecondSection(true)
    setThirdSection(false)
  }
  // update doc with user id
  // store: storeName,

  // retrieve store name for add product

  useEffect(() => {
    const name = file.name + uuidv4()
    const storageRef = ref(storage, `store logo/${name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    const uploadFile = () => {
      uploadDivRef.current.style.display = ""
      uploadTask.on(
        'state_changed', 
        (snapshot) => {
          const progress = 
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          setPercentage(progress)
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        }, 
        (error) => {
          console.log(error)
        }, 
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({...prev, img:downloadURL}))
          });
        }
      );
    }
    file && uploadFile() 
  }, [file])

  useEffect(() => {
    if (percentage === 100) {
      setPercentage(null);
      setShowProgressBar(false);
      setProgressCompleted(true)
    } else if (percentage !== null) {
      setShowProgressBar(true)
    }
  }, [percentage])


  const handleDelete = () => {
    console.log(data.img)
    let imageURL = (data.img)

    let fileName = imageURL.slice(85, imageURL.lastIndexOf('?')).replace(/ /g, "%20")
    fileName = decodeURIComponent(fileName)

    const deleteRef = ref(storage, `store logo/${fileName + "_1000x1000"}`)
    const deleteRef2 = ref(storage, `store logo/${fileName}`)
    console.log(deleteRef)

    deleteObject(deleteRef) 
    deleteObject(deleteRef2)
    .then(() => {
      uploadDivRef.current.style.display = "none"
      console.log("Image successfully deleted")
    }).catch((error) => {
      console.log(error)
    });
  }


  return (
    <div className="createStoreDiv">
      <canvas className="storelessUserGradientCanvas">
        <MarketGradient page={"market"} dominantColors={savedDominantColors} isWideScreen={isWideScreen}/>
      </canvas>
      <motion.div
        key="filmGrain"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <FilmGrain />
      </motion.div>
      <div className="createStoreHeader">
        <Link to="/vendor">
          <div className="createStoreBack">
            <img src={backArrow} className="createStoreBackIcon"/>
          </div>
        </Link>
      </div>
      {isLoading && (
        <div className="storelessUserLoadingDiv">
          <img src={loadIcon} className="storelessUserLoadingIcon" alt="loading icon" />
        </div>
      )}
      {!isLoading && user && !hasStore && (
        // User is logged in but doesn't have a store: Prompt to create a store
        <div className="cSNoStore">
          <div>You already have an account, click below to create a store</div>
          <button 
            className="cSNoStoreBtn" 
            onClick={() => setSecondSection(true)}
          >
            create store
          </button>
        </div>
      )}
      {!isLoading && !user && (
      <div className="createStoreFirstSection">
        <div className="basicInfoDiv" ref={basicInfoDivRef}>
          <div className="basicInfoHeader">tell us who you are</div>
          <input type="text" value={name} placeholder=' name & surname' id='nameSurname' onChange={(e) => setName(e.target.value)} required/>
          <input type="email" value={email} placeholder=' email' id='email' onChange={(e) => setEmail(e.target.value)} required/>
          <div className="passwordText">please set a password</div>
          <div className="passwordDiv">
            <input value={password} type={showPassword ? "text" : "password"} placeholder=' password' id='password' 
            onChange={e=>setPassword(e.target.value)} required/>
            <img className="showPasswordIcon" src={eyeIcon} alt="show password" onClick={togglePassword}/>
          </div>
        </div>
      <div className="firstSectionButtonDiv">
        <button className="nextBtn1" id="nextBtn1" onClick={showBusinessInfo}>next</button>
      </div>
      </div>
      )}
      {!isLoading && user && !hasStore && secondSection && (
      <div className="createStoreSecondSection">
        <div className="businessInfoDiv" ref={businessInfoDivRef}>
          <div className="businessInfoHeader">tell us about your business</div>
          <input value={storeName} type="text" placeholder=' business name' id='businessName' 
          onChange={e=> {setStoreName(e.target.value)}} required/>
          {storeNameExistsDiv?
          <div className="storeNameExistsDiv">Business name already exists, please pick another name.</div>:null
          }
          <select name=" industry" id="industries" ref={industryRef} value={selectedIndustry}
          onChange={(e) => setSelectedIndustry(e.target.value)} required>
            <option value="">industry</option>
            <option value="Antiques & Collectables">Antiques & Collectables</option>
            <option value="Art">Art</option>
            <option value="Audio & Media">Audio & Media</option>
            <option value="Automotive & DIY">Automotive & DIY</option>
            <option value="Beauty">Beauty</option>
            <option value="Books">Books</option>
            <option value="Cellphones & Wearables">Cellphones & Wearables</option>
            <option value="Crafts & Handmade Goods">Crafts & Handmade Goods</option>
            <option value="Electronics">Electronics</option>
            <option value="Fashion">Fashion</option>
            <option value="Gaming">Gaming</option>
            <option value="Garden, Pool & Patio">Garden, Pool & Patio</option>
            <option value="Health, Fitness & Personal Care">Health, Fitness & Personal Care</option>
            <option value="Hobbies & Toys">Hobbies & Toys</option>
            <option value="Holistic & Esoteric">Holistic & Esoteric</option>
            <option value="Home & Decor">Home & Decor</option>
            <option value="Movies & Television">Movies & Television</option>
            <option value="Music & Instruments">Music & Instruments</option>
            <option value="Office & Stationary">Office & Stationary</option>
            <option value="Pets">Pets</option>
            <option value="Physical Sport & Training">Physical Sport & Training</option>
          </select>
          <textarea placeholder=' business description' id='businessDescription' rows="6" ref={businessDescriptionRef} value={description} onChange={(e) => setDescription(e.target.value)} required/>
        </div>
        <div className="secondSectionButtonDiv">
          <button className="backBtnCS" id="backBtnCS" onClick={backToShowBasicInfo} >back</button>
          <button className="nextBtn2" id="nextBtn2" onClick={showBusinessLogo} disabled={storeNameExists}>next</button>
        </div>
      </div>
      )}
      {!isLoading && user && !hasStore && thirdSection && (
        <div className="createStoreThirdSection">
          <div className="businessLogoDiv" ref={businessLogoDivRef}>
            <label htmlFor="inputField" className="chooseImageButton"> 
              choose business logo
              <img src={plusIcon} alt="upload image icon" className="createStorePlusIcon"/>
            </label>
            <input type="file" id="inputField" onChange={(e) => setFile(e.target.files[0])} style={{ display: "none"}} required/>
            {file?
            <div id='uploadDiv' ref={uploadDivRef}>
              <img src={file ? URL.createObjectURL(file) : null}
                id="uploadedImage"
                alt="uploaded"
              />
              <div id="delete" onClick={handleDelete}>
                <img src={deleteIcon} alt="delete uploaded" id="deleteIcon" />
              </div>
              {showProgressBar && (
                <progress className="createStoreProgressBar" value={percentage} max="100"/>
              )}
            </div> :
            <div className='createStoreNoProductImageDiv'>no image has been selected yet</div>
            }
          </div>
          <div className="thirdSectionButtonDiv">
            <button className="backBtnCS2" id="backBtnCS2" onClick={backToShowBusiness} >back</button>
            <button className="completeStoreCreationBtn" onClick={handleCreateAccount} ref={createStoreBtnRef} disabled={!progressCompleted}>create store.</button>
          </div>
        </div>
      )}
      {!isLoading && user && hasStore && (
        // User has a store: Inform them and provide a navigation option
        <div className="cSAlreadyLoggedIn">
          <div>You already have an account and store</div>
          <Link to="/vendor">
            <button className="returnToVendor">
              <img className="returnToVendorIcon" src={vendorIcon} alt="vendor icon" />
              go back to vendor page
            </button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default CreateStore