import React, { useState, useEffect } from 'react';
import { doc, setDoc, updateDoc, collection, runTransaction } from '@firebase/firestore';
import { db } from '../../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import blankImage from '../../images/blankImage.png';
import dropdownIcon from '../../icons/popupIcon.png';
import ParcelUploadComponent from './ParcelUploadComponent';
import './PendingProduct.css'; // reuse same css or create a separate one for bundles

const PendingProductBundle = ({ bundleProducts, handleProductClick, adminOrderData, isSent = false }) => {
  // Assume all products in the bundle share the same buyer info/order
  // Find order and buyer info using the first product
  const firstProduct = bundleProducts[0];

  const order = adminOrderData.find((order) =>
    order.products.some((p) => p.productID === firstProduct.productID)
  );

  let mergedProducts = bundleProducts.map((product) => {
    if (order) {
      const orderProduct = order.products.find(p => p.productID === product.productID);
      return {
        ...product,
        buyerInfo: order.buyerInfo,
        selected_shippingName: orderProduct.selected_shippingName,
        selected_shippingPrice: orderProduct.selected_shippingPrice,
        selected_subgroup: orderProduct.selected_subgroup,
      };
    }
    return product;
  });

  // Use the first product's info as representative for shipping and buyer info display
  const representativeProduct = mergedProducts[0];

  const [isBuyerInfoVisible, setIsBuyerInfoVisible] = useState(false);
  const [trackingNum, setTrackingNum] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      uploadedImages.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [uploadedImages]);

  const toggleBuyerInfo = () => {
    setIsBuyerInfoVisible(!isBuyerInfoVisible);
  };

  const handleTrackingNumChange = (e) => {
    setTrackingNum(e.target.value);
  };

  const handleSubmit = async () => {
    if (!trackingNum.trim()) {
      setError('Please enter the waybill/tracking number.');
      return;
    }

    if (uploadedImages.length === 0) {
      setError('Please upload at least one image of the parcel.');
      return;
    }

    setError('');
    setIsSubmitting(true);

    try {
      await runTransaction(db, async (transaction) => {
        // Notify the buyer once for the entire bundle
        const buyerNotificationRef = doc(collection(db, 'notifications'));
        transaction.set(buyerNotificationRef, {
          notificationType: 'shippingInfo',
          message: `Your purchase of the bundled items (${mergedProducts.map(mp => mp.productName).join(', ')}) has been shipped. Tracking Number: ${trackingNum}.`,
          messageDate: new Date().toLocaleString(),
          messageDateNumeric: new Date().getTime(),
          messageID: buyerNotificationRef.id,
          readStatus: 'unread',
          storeName: representativeProduct.storeName,
          productName: "Bundle",
          storeID: representativeProduct.storeID,
          userID: representativeProduct.buyerInfo?.userID,
          trackingNum: trackingNum,
          images: uploadedImages,
        });

        // Update each product in the bundle as sent
        for (let prod of mergedProducts) {
          const productRef = doc(db, "products", prod.productID);
          transaction.update(productRef, {
            productSent: true,
            trackingNum: trackingNum,
          });

          // Also write to soldProducts if needed
          const soldProductRef = doc(db, 'soldProducts', prod.productID);
          transaction.set(soldProductRef, {
            ...prod,
            trackingNum: trackingNum,
            trackingImages: uploadedImages,
            sentDate: new Date(),
          });
        }
      });

      setSuccessMessage('Shipping information sent to the buyer successfully for the entire bundle.');
      setTrackingNum('');
      setUploadedImages([]);
      setIsBuyerInfoVisible(false);
    } catch (err) {
      console.error('Error sending shipping info:', err);
      setError('Failed to send shipping information. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bundleContainer" style={{ border: '2px solid #ddd', padding: '10px', margin: '10px 0', borderRadius: '8px' }}>
      {/* Display all products in the bundle */}
      <div className="bundleSummaryDiv">
        <div className="bundleProductsRow">
          {mergedProducts.map(prod => (
            <div className="bundleProductItem" key={prod.productID} style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
              <img
                src={prod.imageThumbnails?.[0] || blankImage}
                className="storesProductImg"
                onClick={() => handleProductClick(prod)}
                alt={prod.productName || 'Untitled'}
                style={{ width: '50px', height: '50px', borderRadius: '4px', marginRight: '8px' }}
              />
              <div>
                <p className="storesProductName" style={{ fontSize: '14px' }}>{prod.productName}</p>
                <p className="storesProductPrice" style={{ fontSize: '14px' }}>R{parseFloat(prod.listed_price).toFixed(2)}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Only show dropdown on the first product or the bundle as a whole */}
        {!isSent && (
          <img
            src={dropdownIcon}
            alt="Toggle Buyer Info"
            className="ordersDropdownIcon"
            onClick={toggleBuyerInfo}
            style={{ cursor: 'pointer', marginTop: '10px' }}
          />
        )}
      </div>

      <AnimatePresence>
        {isBuyerInfoVisible && (
          <motion.div
            className="productBuyerInfo"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{ marginTop: '10px' }}
          >
            <div className="shippingInfoContainer">
              <div className="shippingInfoContainerHeader">Shipping Information (Bundle):</div>
              {representativeProduct.selected_shippingName === 'paxi' && (
                <div className="shippingInfoField">
                  <strong>Paxi Store Code:</strong> {representativeProduct.buyerInfo?.paxiStoreCode || 'N/A'}
                </div>
              )}

              {representativeProduct.selected_shippingName === 'pudo' && (
                <div className="shippingInfoField">
                  <strong>Pudo Locker Location:</strong> {representativeProduct.buyerInfo?.pudoLockerAddress || 'N/A'}
                </div>
              )}

              <div className="shippingInfoField">
                <strong>Courier Method:</strong> {representativeProduct.courierMethod || 'N/A'}
              </div>
              <div className="shippingInfoField">
                <strong>Shipping Price:</strong> {representativeProduct.selected_shippingPrice ? `R${parseFloat(representativeProduct.selected_shippingPrice).toFixed(2)}` : 'N/A'}
              </div>
              <div className="shippingInfoField">
                <strong>Shipping Name:</strong> {representativeProduct.selected_shippingName || 'N/A'}
              </div>
              <div className="shippingInfoField">
                <strong>Shipping Subgroup:</strong> {representativeProduct.selected_subgroup || 'N/A'}
              </div>
            </div>

            <div className="trackingNumContainer" style={{ marginTop: '10px' }}>
              <input
                type="text"
                placeholder="Waybill/Tracking Number"
                value={trackingNum}
                onChange={handleTrackingNumChange}
                className="trackingNumInput"
              />
            </div>

            <div className="parcelImageUpload" style={{ marginTop: '10px' }}>
              <ParcelUploadComponent 
                setUploadedImages={setUploadedImages}
                productID={representativeProduct.productID} 
              />
            </div>

            <div className="submitButtonContainer" style={{ marginTop: '10px' }}>
              <button
                className="sendInfoButton"
                onClick={handleSubmit}
                disabled={isSubmitting || !trackingNum.trim() || uploadedImages.length === 0}
              >
                {isSubmitting ? 'Sending...' : 'Send Info to Buyer'}
              </button>
            </div>

            {error && <div className="errorMessage">{error}</div>}
            {successMessage && <div className="successMessage">{successMessage}</div>}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PendingProductBundle;
