import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import styles from './frameStyles.json'
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Rnd } from 'react-rnd';

import { motion, useMotionValue } from 'framer-motion';
import { Resizable } from 'react-resizable';
import { 
  ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, deleteObject } 
from 'firebase/storage'
import { auth, db, storage } from '../../firebase'
import { 
  collection, doc, updateDoc, setDoc, 
  getDoc, onSnapshot, deleteDoc } 
from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4} from "uuid";
import FontFaceObserver from 'fontfaceobserver';

import plusIcon from '../../icons/plusIcon.png'
import checkIcon from '../../icons/checkIcon.png'
import optionsIcon from '../../icons/optionsIcon.png'
import ElementBar from "./ElementBar"
import TextPopup from './TextPopup'
import WebsiteBackgroundPopup from "./WebsiteBackgroundPopup";

import './Frames.css'
import './DraggableFrames.css'
import './Fonts.css'

const DraggableFrames = (props) => {
  const imageRef = useRef()
  const frameRef = useRef()
  const textareaElementBarIconRef = useRef()
  const textPopupRef = useRef();
  const framePlusIconRef = useRef()
  const frameTextAreaRef = useRef()
  const resizeHandlerRef = useRef();
  const textColourPopupRef = useRef();
  const elementBarRef = useRef();

  let [showInfo1, setShowInfo1] = useState(false)
  const [frameImg, setFrameImg] = useState()
  const [zIndex, setZIndex] = useState(props.zIndex)
  const [isImageFrame, setIsImageFrame] = useState(false)
  const [isTextArea, setIsTextArea] = useState(false)
  const [showTextAreaOptions, setShowTextAreaOptions] = useState(false)
  const [text, setText] = useState('')
  const [selectedTextAreaFont, setSelectedTextAreaFont] = useState('Poppins')
  const [fontFullName, setFontFullName] = useState('Poppins')
  const [textAlign, setTextAlign] = useState('left')
  const [selectedFontSize, setSelectedFontSize] = useState(16)
  const [selectedFontColor, setSelectedFontColor] = useState('#000000')
  const [whitePlusIcon, setWhitePlusIcon] = useState(false)
  let [showTextPopup, setShowTextPopup] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [frameDesign, setFrameDesign] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [frameRefStyle, setFrameRefStyle] = useState(props.currentFrame.frame_styling != null ? props.currentFrame.frame_styling : null)
  const [frameHeight, setFrameHeight] = useState(0); 
  const [frameWidth, setFrameWidth] = useState(0); 
  const [isFrameActive, setIsFrameActive] = useState(false);
  const [showImageDonePopup, setShowImageDonePopup] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0);
  const [fetchedFrameData, setFetchedFrameData] = useState();
  const [isFrameRefMounted, setIsFrameRefMounted] = useState(false)
  const [isFontLoaded, setIsFontLoaded] = useState(false)
  const [isResizing, setIsResizing] = useState(false);
  const [changeToText, setChangeToText] = useState(false)
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showTextColorPopup, setShowTextColorPopup] = useState(false)
  const [previewColor, setPreviewColor] = useState(null)
  const [showCommonColors, setShowCommonColors] = useState(false); // New state for collapsible div
  const [colorPickerOpen, setColorPickerOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState(null);

  const x = useMotionValue(props.initialX || 0);
  const y = useMotionValue(props.initialY || 0);  

  useEffect(() => {
    if (props.websiteView) return

    if (props.initialX !== undefined && props.initialX !== x.get()) {
      x.set(props.initialX);
    }
    if (props.initialY !== undefined && props.initialY !== y.get()) {
      y.set(props.initialY);
    }
  }, [props.initialX, props.initialY, x, y, props.websiteView]);
  

  //line below prevents unwanted things from being dragged
  const preventDrag = (event) => {
    event.preventDefault();
  };

  const containerRef = useRef(null);
  const [constraints, setConstraints] = useState({ left: 0, right: 0 });

  useEffect(() => {
    const updateConstraints = () => {
      const containerWidth = containerRef.current?.offsetWidth || 0;
      const screenWidth = window.innerWidth;
      setConstraints({
        left: -props.x + 5, // 5px from the left edge
        right: screenWidth - containerWidth - props.x - 5 // 5px from the right edge
      });
    };

    window.addEventListener('resize', updateConstraints);
    updateConstraints(); // Initial setting

    return () => {
      window.removeEventListener('resize', updateConstraints);
    };
  }, [props.x]);

  const handleMouseDown = (event) => {
    // If it's a resize handle, stop the event from propagating to the frame
    if (event.target.classList.contains('react-resizable-handle')) {
      event.stopPropagation();
      setIsResizing(true)
    }
  };

  // Effect to check if frameRef is mounted
  useEffect(() => {
    setIsFrameRefMounted(frameRef.current != null);
  }, [frameRef.current]);

  useEffect(() => {
    if (props.selectedObjectID) {
      console.log("Here is the frameID and selectedObjectID: ", props.frameID, props.selectedObjectID)
      setIsClicked(true)
      if (props.frameID !== props.selectedObjectID) {
        console.log("There is no match")
        setIsClicked(false); // Assuming this removes the selected styles
        setIsFrameActive(false);
        setShowTextPopup(false)
        setShowTextAreaOptions(false)
        setShowImageDonePopup(false)
        setZIndex(props.zIndex); // Reset zIndex to its original value
      }
    }
  }, [props.selectedObjectID, props.frameID, props.zIndex]);

  useEffect(() => {
    if (isFrameRefMounted) {
      if (frameRef.current != null) {
        const style = styles[props.frameStyle]
        const width = frameRef.current.offsetWidth; // Get the actual width
        
        const currentFrame = props.frames.find(frame => frame.frameID === props.frameID);
        const currentFrameStyling = currentFrame.frame_styling
        
        if (!currentFrameStyling || !currentFrameStyling.width) {
          const width = window.innerWidth * 0.95; // Update width to 95% of window width
          setFrameWidth(width)
        } else {
          console.log("Line 127")
          console.log("Width provided by props:", currentFrameStyling.width);
          console.log(parseFloat(currentFrameStyling.width))
          setFrameWidth(parseFloat(currentFrameStyling.width));
        }
        const aspectRatio = style.aspectRatio;

        if (aspectRatio) {
          const [numerator, denominator] = aspectRatio.split('/').map(Number);
          if (denominator) {
            const switchedRatio = denominator / numerator; // Switch the ratio from 2/3 to 3/2
            const height = width * switchedRatio; 
            setFrameHeight(height); 
          } else {
            console.error("Invalid aspect ratio format");
          }
        } else {
          console.log("There is an associated height")

          if (frameRefStyle.height != null) {
            setFrameHeight(frameRefStyle.height)
          } else {
          const initialHeight = parseInt(style.height, 10)
          console.log(initialHeight)
          setFrameHeight(initialHeight)
          }
        }
        
      }
    }
  }, [isFrameRefMounted])
  
  const forceRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1); // Increment the key to trigger a re-render
  };

  const isDarkBackground = (color) => {
    if (!color) return false;
  
    // Remove '#' if present
    if (color.startsWith('#')) {
      color = color.slice(1);
    }
  
    let r, g, b;
  
    if (color.length === 3) {
      r = parseInt(color[0] + color[0], 16);
      g = parseInt(color[1] + color[1], 16);
      b = parseInt(color[2] + color[2], 16);
    } else if (color.length === 6) {
      r = parseInt(color.slice(0, 2), 16);
      g = parseInt(color.slice(2, 4), 16);
      b = parseInt(color.slice(4, 6), 16);
    } else {
      // Unsupported color format
      return false;
    }
  
    // Calculate brightness
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
    return brightness < 128; // Returns true if dark
  };
  

  useEffect(() => {
    if (props.frames && props.frameID) {
      console.log(props.frames, props.frameID)
      const matchingFrame = props.frames.find(frame => frame.frameID === props.frameID);
      if (matchingFrame == undefined) {
        return
      }
      console.log(matchingFrame)
      setFetchedFrameData(matchingFrame)
      setTextAreaStyling(matchingFrame)
    }
  }, [props.frameID, props.frames, props.selectedObject, refreshKey])
  
  const setTextAreaStyling = ((data) => {
    console.log(data)
    const frameData = data
    const frameFont = frameData.font;
    const frameFontSize = frameData.font_size;
    const frameFontFullName = frameData.font_fullname;
    const frameFontColor = frameData.font_color;
    const fetchedFrameDesign = frameData.frame_design;
    const frameStyles = frameData.frame_styling
    const frameText = frameData.text

    if (frameStyles) {
      console.log(frameStyles)
      setFrameRefStyle(frameStyles);
    } else {
      console.log("No frame_styling set")    
    }
    console.log(frameData.frame_styling)
    const frameHeightValue = frameData.frame_styling?.height
    
    if (frameHeightValue) {
      setFrameHeight(parseInt(frameHeightValue, 10));
    }
  
    // Only proceed if there is text to set
    if (fetchedFrameDesign != null || (frameData.text && frameData.text.trim() !== '')) {
      setIsTextArea(true);
      setFrameDesign(fetchedFrameDesign);
      setSelectedTextAreaFont(frameFont);
      setFontFullName(frameFontFullName);
      setSelectedFontColor(frameFontColor);
      setSelectedFontSize(frameFontSize);
      const fontObserver = new FontFaceObserver(frameFont);

      // Start a timer for font loading
      const fontLoadTimer = setTimeout(() => {
        console.log('Taking some time to fetch the font...');
      }, 3000); // 3 seconds threshold

      fontObserver.load(null, 10000)
        .then(() => {
          clearTimeout(fontLoadTimer); // Clear timer if font loads within threshold
          console.log(frameTextAreaRef)
          // Check if frameTextAreaRef.current is defined
          if (frameTextAreaRef.current) {
            frameTextAreaRef.current.style.fontFamily = `${frameFont}`;
            frameTextAreaRef.current.style.fontSize = `${frameFontSize}px`;
            frameTextAreaRef.current.style.color = `${frameFontColor}`;
            frameTextAreaRef.current.style.textAlign = frameStyles?.textAlign; // Also check for frameStyles
            setTextAlign(frameStyles?.textAlign)
            console.log(frameData)
            setText(frameData.text); // Set text here after font is loaded
          }

          setIsLoading(false); // Font is loaded and applied
          props.onFramesLoaded()
        }).catch(() => {
          console.error('Font loading failed for:', frameFont);
          clearTimeout(fontLoadTimer); // Clear timer even if font fails to load
          setIsLoading(false); // Proceed with fallback font or error handling
          // Optionally set text here if you want it to appear even if font fails
          props.onFramesLoaded()
        });
    } else {
      setIsLoading(false)
      props.onFramesLoaded()
    }
  })

  useEffect(() => {
    updateFrameBorder();
  }, [isFrameActive, isClicked, isHovered, text, frameImg, frameRefStyle]);  

  useEffect(() => {
    console.log('Current frame style:', props.frameStyle);
    console.log('Available styles:', styles);
    if (props.frameStyle && styles[props.frameStyle]) {
      const style = styles[props.frameStyle];
      console.log('Applied style:', style);
      if (style && style.height) {
        const initialHeight = parseInt(style.height, 10)
        console.log(initialHeight)
        setFrameHeight(initialHeight);
      }
    }
  }, [props.frameStyle]);
  
  const updateHeight = (newHeight) => {
    console.log("Resize height: ", newHeight)
    setFrameHeight(newHeight);
  
    // Find the current frame
    const currentFrame = props.frames.find(frame => frame.frameID === props.frameID);
  
    // If the current frame is found
    if (currentFrame) {
      // Prepare the updated frame styling object with the new height
      const updatedFrameStyling = { 
        ...currentFrame.frame_styling, 
        height: `${parseFloat(newHeight)}px`
      };
  
      // Update frame styling in the state
      setFrameRefStyle(updatedFrameStyling);
  
      // Update the current frame with new styling and text
      const updatedFrame = {
        ...currentFrame,
        frame_styling: updatedFrameStyling,
        text: text // Assuming 'text' is the current state of the text in the textarea
      };
  
      // Update the frames array with the updated current frame
      const updatedData = props.frames.map(frame => 
        frame.frameID === props.frameID ? updatedFrame : frame
      );
  
      // Update frames in the parent component
      props.updateFrames(updatedData, updatedFrameStyling);
    }
  };

  const updateWidth = (newWidth) => {
    console.log(newWidth)
    setFrameWidth(newWidth);
  
    // Find the current frame
    const currentFrame = props.frames.find(frame => frame.frameID === props.frameID);
    console.log("Current frame: ", currentFrame)
    // If the current frame is found
    if (currentFrame) {
      // Prepare the updated frame styling object with the new width
      const updatedFrameStyling = { 
        ...currentFrame.frame_styling, 
        width: `${parseFloat(newWidth)}px`
      };
  
      // Update frame styling in the state
      setFrameRefStyle(updatedFrameStyling);
  
      // Update the current frame with new styling
      const updatedFrame = {
        ...currentFrame,
        frame_styling: updatedFrameStyling
      };
      
      // Update the frames array with the updated current frame
      const updatedData = props.frames.map(frame => 
        frame.frameID === props.frameID ? updatedFrame : frame
      );

      console.log(updatedFrame, updatedData, updatedFrameStyling)
  
      // Update frames in the parent component
      props.updateFrames(updatedData, updatedFrameStyling);
    }
  };  

  const updateFrameStyling = (changes) => {
    // Update local state
    setFrameRefStyle((prevStyle) => ({
      ...prevStyle,
      ...changes,
    }));
  
    // Update Firebase
    const currentFrame = props.frames.find(frame => frame.frameID === props.frameID);
    if (currentFrame) {
      const updatedFrameStyling = { 
        ...currentFrame.frame_styling, 
        ...changes
      };
  
      const updatedFrame = {
        ...currentFrame,
        frame_styling: updatedFrameStyling,
      };
  
      const updatedData = props.frames.map(frame => 
        frame.frameID === props.frameID ? updatedFrame : frame
      );
  
      // Update frames in the parent component
      props.updateFrames(updatedData, updatedFrameStyling);
  
      // Update Firestore
      const dbRef = doc(db, "frames", props.frameID);
      updateDoc(dbRef, {
        frame_styling: updatedFrameStyling,
      }).catch(error => {
        console.error("Error updating frame styling in Firebase:", error);
      });
    }
  };
  
  
  const handleResize = (event, { size, handle }) => {
    setIsResizing(true);
  
    if (handle === 'e') {
      // Resizing from the East: Only update width
      updateWidth(size.width);
    } else if (handle === 's') {
      // Resizing from the South: Only update height
      updateHeight(size.height);
    } else if (handle === 'w') {
      // Resizing from the West: Update width and shift x position
      const deltaWidth = frameWidth - size.width;
      setFrameWidth(size.width);
      const newX = x.get() + deltaWidth;
      x.set(newX);
      updateFrameStyling({ width: size.width, x: newX });
    } else if (handle === 'n') {
      // Resizing from the North: Update height and shift y position
      const deltaHeight = frameHeight - size.height;
      setFrameHeight(size.height);
      const newY = y.get() + deltaHeight;
      y.set(newY);
      updateFrameStyling({ height: size.height, y: newY });
    }
  };
  
  
  
  // Function to reset resizing state when resizing ends
  const onResizeStop = () => {
    setIsResizing(false);
  };
  
  const [frameDragging, setFrameDragging] = useState(false)

  const handleDragStart = () => {
    setFrameDragging(true)
  }

  const handleDragEnd = () => {
    // setIsDragging(false);
    setFrameDragging(false)
    updatePosition();
    if (showTextPopup) {
      positionPopup(); // Reposition the popup after drag
    }
  };

  const updatePosition = async () => {
    if (props.websiteView) return;

    console.log("Position updated: ", x.get(), y.get());
    const storeRef = doc(db, 'stores', props.storeID);
  
    try {
      const docSnap = await getDoc(storeRef);
      if (docSnap.exists()) {
        const frameData = docSnap.data().frames;
        const currentFrameID = props.frameID;
        const currentFrame = frameData.find(frame => frame.frameID === currentFrameID);
  
        if (!currentFrame) {
          console.error("Current frame not found in store frames.");
          return;
        }
  
        const updatedFrame = {
          ...currentFrame,
          frame_styling: {
            ...currentFrame.frame_styling,
            x: x.get(),
            y: y.get(),
            height: `${parseFloat(frameHeight)}px`,
            width: `${parseFloat(frameWidth)}px`,
          },
        };
  
        const updatedData = props.frames.map(frame => 
          frame.frameID === props.frameID ? updatedFrame : frame
        );
  
        await updateDoc(storeRef, {
          frames: updatedData,
        });
  
        const dbRef = doc(db, "frames", props.frameID);
        await updateDoc(dbRef, {
          frame_styling: updatedFrame.frame_styling,
        });
  
        props.updateFrames(updatedData, updatedFrame); // Ensure parent state updates immediately
      } else {
        console.error("Store document does not exist.");
      }
    } catch (error) {
      console.error("Error updating position in DB:", error);
    }
  
    console.log("Frame " + props.frameID + " position updated to " + x.get() + ", " + y.get());
  };
  

  // Handlers for mouse events
  const handleMouseEnter = () => {
    if (!props.websiteView) {
      setIsHovered(true);
    }
  };
  
  const handleMouseLeave = () => {
    if (!props.websiteView) {
      setIsHovered(false);
    }
  };
  
  const handleClick = (frameID, zIndex, frameType) => {
    if (props.websiteView) return;

    setIsClicked(true);
    props.setDraggableFrameSelected(true)
    console.log("The draggable frame has been clicked")
    setIsFrameActive(true)
    updateFrameBorder()

    setZIndex(1000)
 
    if (isImageFrame) {
      console.log("Hiding the frame bar")
      props.setShowFrameBar(false)
      props.setShowImageFrameBar(true)
    }

    if (frameType === 'imageFrame') {
      setShowImageDonePopup(true)
      props.handleFrameSelected(frameID, zIndex, null, "imageFrame", getFrameStyle());
    }
  
    if (frameRef.current) {
      const computedStyles = window.getComputedStyle(frameRef.current);
      let frameStyle = {};
  
      // Convert the CSSStyleDeclaration object to a plain object
      for (let key of computedStyles) {
        frameStyle[key] = computedStyles.getPropertyValue(key);
      }
  
      // Pass the frame styling to the handleFrameSelected function
      props.handleFrameSelected(frameID, zIndex, frameStyle, "textFrame", getFrameStyle());
    }
  };

  const updateFrameBorder = () => {
    if (frameRef.current) {
      let borderColor = 'rgba(0, 0, 0, 0.8)';
      if (isDarkBackground(props.websiteColor)) {
        borderColor = frameRefStyle?.backgroundColor === '#FFFFFF' ? 'orangered' : 'rgb(255, 255, 255)';
      }
  
      if (isFrameActive || isClicked) {
        frameRef.current.style.border = `dashed 3px ${borderColor}`;
      } else {
        if (frameRefStyle && frameRefStyle.border) {
          frameRef.current.style.border = frameRefStyle.border;
        } else {
          frameRef.current.style.border = 'none';
        }
      }
    }
  };
  
  
  const isFrameEmpty = () => {
    // Find the current frame's styling from the frames array
    const currentFrameStyling = props.frames.find(frame => frame.frameID === props.frameID)?.frame_styling;
  
    // Check if text is empty
    const isTextEmpty = !text || text === null || text === '';
  
    // Check if the frame is an image frame
    const hasImage = isImageFrame;
  
    // Check if frame has border or background color
    const hasBorderOrBackground = currentFrameStyling && 
      (currentFrameStyling.border || currentFrameStyling.backgroundColor);
  
    // Frame is considered empty if it has no text, no image, and no border/background color
    return isTextEmpty && !hasImage && !hasBorderOrBackground;
  };
  
  // Function to get dynamic style for the frame
  const getDynamicFrameStyle = () => {
    // Assuming frameRefStyle has a nested frame_styling object and possibly a zIndex property
    const frameStyling = frameRefStyle || {};
    const zIndex = props.zIndex || frameRefStyle?.zIndex; // Use props.zIndex, fallback to frameRefStyle.zIndex

    // Start with the base styles from frame_styling and apply zIndex
    const baseStyle = {
      ...frameStyling,
      zIndex: zIndex,
    };

    // console.log(baseStyle)

    // Apply additional styles based on conditions, e.g., dashed border if frame is empty
    if (isFrameEmpty()) {
      return {
        ...baseStyle,
        border: '2px dashed white',
      };
    }

    // Return the base style if the frame is not empty
    return baseStyle;
};


  const getFrameStyle = () => {
    const baseStyle = {
      ...getDynamicFrameStyle(),      
      zIndex: props.zIndex,
      width: `${parseFloat(frameWidth)}px`,
      height: `${parseFloat(frameHeight)}px`,
    };

    if (!props.websiteView && (isHovered || isClicked)) {
      return {
        ...baseStyle,
        ...liftedStyle(frameRefStyle)
      };
    }
  
    return baseStyle;
  };
  
  const onTextPopupClick = () => {
    // Set isClicked to true
    setIsClicked(true);
  }

  useEffect(() => {
    if (props.currentFrame.frame_styling) {
      console.log("The props.currentFrame.frame_styling: ", props.currentFrame.frame_styling)
      setFrameRefStyle(props.currentFrame.frame_styling);
  
      // Apply styling to frameRef and/or other elements
      if (frameRef.current) {
        Object.keys(props.currentFrame.frame_styling).forEach(styleKey => {
          frameRef.current.style[styleKey] = props.currentFrame.frame_styling[styleKey];
        });
      }
  
      // Apply text styling if necessary
      if (frameTextAreaRef.current && props.frameStyling && props.frameStyling.textAlign) {
        setTextAlign(props.frameStyling.textAlign)
        frameTextAreaRef.current.style.textAlign = props.frameStyling.textAlign;
      } else {
        console.log("There is no text align")
      }
  
      console.log("Frame styling has been updated, ", props.currentFrame.frame_styling);
    }
  }, [props.currentFrame.frame_styling]);

  function useOutsideClick(refs, outsideClickCallback, websiteView) {
    useEffect(() => {
      console.log(websiteView)
      if (websiteView) return;
  
      const handleClickOutside = (event) => {
        // If the click is inside any of the refs, do nothing
        for (let ref of refs) {
          if (ref.current && ref.current.contains(event.target)) {
            return;
          }
        }
        // Click is outside all refs, execute the callback
        outsideClickCallback();
        props.setDraggableFrameSelected(false)
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [websiteView]); // Only depends on websiteView
  }
  
  const frameOutsideClick = () => {
    setIsClicked(false);
    setIsFrameActive(false);
    updateFrameBorder();
    setShowTextPopup(false);
    if (typeof props.closeFrameBar === 'function') {
      props.closeFrameBar(false);
    }
    setShowTextAreaOptions(false);
    setZIndex(props.zIndex);    
    console.log("frame is no longer selected");
  };
  

  const imageOutsideClick = () => {
    if (props.websiteView) return
    setIsClicked(false); // Assuming this removes the imageLiftedStyle
    setIsFrameActive(false)
    if (typeof props.closeFrameBar === 'function') {
      props.closeFrameBar(false);
    }
    props.setShowImageFrameBar(false)
    setZIndex(props.zIndex)
    console.log("image frame is no longer selected")
  };

  // Use the custom hook
  useOutsideClick(
    [
      frameRef, 
      imageRef, 
      textPopupRef, 
      props.textPopupRef,
      textColourPopupRef,
      props.vendorBarRef,
      props.frameDynamicsRef,
      props.frameBarRef, 
      props.imageFrameBarRef, 
      props.frameBorderPopupRef, 
      resizeHandlerRef, 
      props.draggableFrameRef,
      props.frameTypesPopupRef,
      props.doneBtnDivRef,
      props.elementBarRef,
    ].filter(ref => ref != null),
    () => {
      frameOutsideClick();
      imageOutsideClick()
      props.setShowZIndexPopup(false);
      setShowTextColorPopup(false);
      props.showLightenSticker(false);
      // Include any additional state resets or callbacks here
    },
    props.websiteView
  );

  useEffect(() => {
    if (props.liftedStyle == true) {
      // Sticker is selected and thus the liftedStyle should be removed 
      // - to prevent frames from overlaying it
      setIsClicked(false)
      setIsHovered(false)
    } 
  })

  const textIconClicked = () => {
    setShowTextPopup(true)
    props.showLightenSticker(true)
    setIsTextArea(true)
    setShowTextAreaOptions(true)
  }

  const removeTextPopup = () => {
    setShowTextPopup(false)
    props.showLightenSticker(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchTextFrame(), fetchFrameImage()]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false); // Optionally set loading to false even on error
      }
    };
  
    fetchData();
  }, []); 
  

  const fetchFrameImage = async() => {
    const dbRef = doc(db, 'frames', props.frameID);
    const docSnap = await getDoc(dbRef);
    if (docSnap.exists()) {
      const frameData = docSnap.data();
      const frameImageLink = frameData.frame_image;
      const frameHeightValue = frameData.frame_styling?.height?.replace('px', '');
  
      if (frameImageLink) {
        setIsImageFrame(true);
        setFrameImg(frameImageLink);
      } else {
        setIsImageFrame(false);
      }
  
      if (frameHeightValue) {
        setFrameHeight(parseInt(frameHeightValue, 10));
      }
    }
  };
  

  const fetchTextFrame = async () => {
    setIsLoading(true);
  
    const dbRef = doc(db, 'frames', props.frameID);
    const docSnap = await getDoc(dbRef);
    if (docSnap.exists()) {
      const frameData = docSnap.data();
      setFetchedFrameData(frameData)
      const frameFont = frameData.font;
      const frameFontSize = frameData.font_size;
      const frameFontFullName = frameData.font_fullname;
      const frameFontColor = frameData.font_color;
      const fetchedFrameDesign = frameData.frame_design;
      const currentFrameStyle = frameData.frame_styling
      console.log(currentFrameStyle)

      if (currentFrameStyle) {
        console.log("The frame styles have been set: ", currentFrameStyle)
        setFrameRefStyle(currentFrameStyle);

        if (currentFrameStyle.height) {
          setFrameHeight(parseFloat(currentFrameStyle.height));
        }
        if (currentFrameStyle.width) {
          console.log(currentFrameStyle)
          setFrameWidth(parseFloat(currentFrameStyle.width));
        }

        if (currentFrameStyle.x) {
          x.set(currentFrameStyle.x)
          y.set(currentFrameStyle.y)
        }
      } else {
        // Handle the case where frame_styling is not available
        // For example, set a default style or take other appropriate actions
      }

      const frameHeightValue = frameData.frame_styling?.height?.replace('px', '');
      if (frameHeightValue) {
        setFrameHeight(parseInt(frameHeightValue, 10));
      }
  
      // Only proceed if there is text to set
      if (fetchedFrameDesign != null || frameData.text != null) {
        setIsTextArea(true);
        setFrameDesign(fetchedFrameDesign);
        setSelectedTextAreaFont(frameFont);
        setFontFullName(frameFontFullName);
        setSelectedFontColor(frameFontColor);
        setSelectedFontSize(frameFontSize);

        const fontObserver = new FontFaceObserver(frameFont);

        // Start a timer for font loading
        const fontLoadTimer = setTimeout(() => {
          console.log('Taking some time to fetch the font...');
        }, 3000); // 3 seconds threshold

        fontObserver.load(null, 15000)
          .then(() => {
            clearTimeout(fontLoadTimer); // Clear timer if font loads within threshold

            // Check if frameTextAreaRef.current is defined
            if (frameTextAreaRef.current) {
              frameTextAreaRef.current.style.fontFamily = `${frameFont}`;
              frameTextAreaRef.current.style.fontSize = `${frameFontSize}px`;
              frameTextAreaRef.current.style.color = `${frameFontColor}`;
              frameTextAreaRef.current.style.textAlign = currentFrameStyle?.textAlign; // Also check for frameStyles
              setTextAlign(currentFrameStyle?.textAlign)
              setText(frameData.text); // Set text here after font is loaded
            }

            setIsFontLoaded(true)
            props.onFramesLoaded()
          }).catch(() => {
            console.error('Font loading failed for:', frameFont);
            clearTimeout(fontLoadTimer); // Clear timer even if font fails to load
            setIsFontLoaded(false)
            props.onFramesLoaded()
          });
      }
      
    } else {
      setIsTextArea(false);
      setIsLoading(false);
    }
  };
  
  const setFontSize = useCallback(async(fontSize) => {
    console.log(fontFullName)
    frameTextAreaRef.current.style.fontSize = `${fontSize}px`
    frameTextAreaRef.current.style.fontFamily = `${fontFullName}`;
    setSelectedFontSize(fontSize)
    setFetchedFrameData(prevData => {
      return {
          ...prevData,
          font_size: fontSize,
          font_fullname: fontFullName
      };
    });

  })

  const selectedFont = useCallback(async(fontName) => {
    console.log(fontName)

    if (fontName === 'Poppins') {
      frameTextAreaRef.current.style.fontFamily = 'Poppins'
      setFontFullName('Poppins')
      setSelectedTextAreaFont('Poppins')
    } else {
      const fontsFolderRef = ref(storage, `fonts/${fontName}`)
      getDownloadURL(fontsFolderRef)
      .then(function(url) {
        const strippedFontName = fontName.split('.')[0]
        console.log(strippedFontName)
        console.log(url)
        frameTextAreaRef.current.style.fontFamily = `${strippedFontName}`
        setSelectedTextAreaFont(strippedFontName)
        setFontFullName(fontName)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  })

  const getSelectedFontColor = useCallback(async(color) => {
    setSelectedFontColor(color)
    frameTextAreaRef.current.style.color = `${color}`
  })

  const addImageToFrame = useCallback(async(url, storeID, frameID) => {
    if (props.websiteView) return;

    setIsImageFrame(true)
    const resolution = "_1000x1000"
    function insertResolution(str,indexPos,str2){
      return str.substring(0, indexPos) + str2 + str.substring(indexPos);
    };
          
    let productImg = url
    const resizedImage = (insertResolution(productImg,productImg.lastIndexOf('?'),resolution));

    setFrameImg(url)

    const frameRef = doc(db, 'frames', frameID)
    try {
      updateDoc(frameRef, {
        frame_image: resizedImage,
        non_resized_frame_image: url,
      })
      console.log("image added to frame")
    } catch(err) {console.log(err)}

    const storeRef = doc(db, 'stores', storeID)

    getDoc(storeRef).then(docSnap => {
      if (docSnap.exists()) {
        const frames = docSnap.data().frames
        const currentFrameID = frameID

        if (frames) {
          const frameIndex = frames.findIndex(frame => frame.frameID === currentFrameID)

          const updatedFrame = {
            ...frames[frameIndex],
            frame_image: resizedImage,
            non_resized_frame_image: url,
          }

          const updatedFrames = frames.map((frame, index) => {
            if (index === frameIndex) {
              return updatedFrame;
            }
            return frame;
          })

          console.log(updatedFrames)

          updateDoc(storeRef, {
            frames: updatedFrames,
          });
        } else {console.log("no frames")}
      }
    })

  }, [frameImg, props.websiteView])

  const updateFrameStylingInFirebase = async () => {
    if (props.websiteView) return;
    setIsClicked(false)
    setIsFrameActive(false)
    updateFrameBorder()
    setChangeToText(false)
    setShowImagePopup(false)
    console.log(`Updating frame styling in Firebase for frame at index ${props.index}, height: ${frameHeight}`)
    
    if (isImageFrame) {
      setShowImageDonePopup(false)
      props.setShowImageFrameBar(false)
      props.setFrameSelected(false)
    }

    props.showLightenSticker(false)
    setIsClicked(false)
    setIsFrameActive(false)
    const frameRef = doc(db, 'frames', props.frameID);
    const storeRef = doc(db, 'stores', props.storeID);
  
    try {
      // Update frame document
      const frameDoc = await getDoc(frameRef);
      if (frameDoc.exists()) {
        const frameData = frameDoc.data();
        const updatedFrameStyling = {
          ...frameData.frame_styling,
          height: `${parseFloat(frameHeight)}px`
        };
        setFrameRefStyle(updatedFrameStyling)
        await updateDoc(frameRef, {
          frame_styling: updatedFrameStyling,
        });
        console.log("Frame styling updated in Firebase");
      }
  
      // Update store document
      const storeDoc = await getDoc(storeRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames;
  
        const frameIndex = framesArray.findIndex(frame => frame.frameID === props.frameID);
        if (frameIndex !== -1) {
          framesArray[frameIndex] = {
            ...framesArray[frameIndex],
            frame_styling: {
              ...framesArray[frameIndex].frame_styling,
              height: `${parseFloat(frameHeight)}px`
            },
          };
  
          await updateDoc(storeRef, {
            frames: framesArray,
          });
          props.setDraggableFrameSelected(false)
          console.log("Store's frame styling updated in Firebase");
        }
      }
    } catch (error) {
      console.error("Error updating frame styling in Firebase:", error);
    }
  };
  
  const changeImageToTextFrame = () => {
    props.handleDeleteFunctionality(isImageFrame, frameImg, props.frameID, props.storeID )
    console.log("image deleted")
    setFrameImg('')
    setIsImageFrame(false)
    setIsTextArea(true)
    props.setShowImageFrameBar(false)
    props.setShowFrameBar(true)
    setShowImageDonePopup(false)
    setShowTextAreaOptions(true)
    setShowTextPopup(true)
  }

  const saveText = () => {
    if (props.websiteView) return;

    console.log("Updated stuff ", props, frameHeight);
    
    if (isImageFrame) {
      changeImageToTextFrame()
    }

    // Prepare the updated frame details
    let frameColorValue;
    if (frameDesign === 'color') {
      frameColorValue = props.selectedFrameColor ? props.selectedFrameColor : '#FFFFFF';
    } else {
      frameColorValue = null;
    }
  
    const updatedFrame = {
      text: text || '',
      font: selectedTextAreaFont || 'Poppins',
      font_size: selectedFontSize || 16,
      font_fullname: fontFullName || 'Poppins',
      font_color: selectedFontColor || '#000000',
      frame_color: frameColorValue,
      frame_design: frameDesign || 'none',
      frame_styling: frameRefStyle,
      non_resized_frame_image: '',
      frame_image: ''
    };
  
    // Update the frame in the local component state and props immediately
    const currentFrameID = props.frameID;
    const updatedFrames = props.frames.map(frame =>
      frame.frameID === currentFrameID ? { ...frame, ...updatedFrame } : frame
    );
  
    // Update the parent component's state immediately
    props.updateFrames(updatedFrames, updatedFrame);
  
    // Update the Firestore database in the background
    const dbRef = doc(db, 'frames', props.frameID);
    updateDoc(dbRef, updatedFrame).catch(error => {
      console.error("Error updating document: ", error);
    });
  
    const storeRef = doc(db, 'stores', props.storeID);
    getDoc(storeRef).then(docSnap => {
      if (docSnap.exists()) {
        const frames = docSnap.data().frames;
        const frameIndex = frames.findIndex(frame => frame.frameID === currentFrameID);
        frames[frameIndex] = { ...frames[frameIndex], ...updatedFrame };
  
        updateDoc(storeRef, { frames }).catch(error => {
          console.error("Error updating frames in store: ", error);
        });
  
        console.log("The text has been saved and the frames updated: ", frames);
      } else {
        console.log("No frames found in store document.");
      }
    }).catch(error => {
      console.error("Error getting store document: ", error);
    });
  
    // Apply inline styles if necessary
    if (frameRef.current && frameRefStyle) {
      Object.keys(frameRefStyle).forEach(key => {
        frameRef.current.style[key] = frameRefStyle[key];
      });
    }
  
    // Close the text area options and popup
    setShowTextAreaOptions(false);
    setShowTextPopup(false);
    props.showLightenSticker(false);
  };

  const saveTextAndFrameHeight = () => {
    saveText(); // Your existing function to save text
    updateFrameStylingInFirebase(); // New function to update frame height
    setZIndex(props.zIndex)
    updateFrameBorder()
  };

  const imageIconClicked = () => {
    setIsTextArea(false)
  }

  const checkAndAdjustFrameHeight = () => {
    if (frameTextAreaRef.current) {
      const textArea = frameTextAreaRef.current;
      const requiredHeight = textArea.scrollHeight;
      console.log("Height values: ", requiredHeight, frameHeight)
      if (requiredHeight > frameHeight) {
        console.log("Automatically adjusting height")
        updateHeight(requiredHeight);
      }
    } 
  };

  const liftedStyle = (frameStyling) => {
    let borderStyle = 'none';
  
    // console.log(frameStyling)
    if (frameStyling) {
      // Override border style based on frameStyling
      borderStyle = frameStyling.border ? frameStyling.border : borderStyle;
  
      // If backgroundColor is present but border is not, set border to 'none'
      if (frameStyling.backgroundColor && !frameStyling.border) {
        borderStyle = 'none';
      }
    }
  
    // Return the styling object with the determined border style
    return {
      ...frameStyling,
      position: 'relative',
      zIndex: 1001,
      backgroundColor: frameDesign === 'none' ? 'rgba(0, 0, 0, 0.2)' : ((frameDesign != 'color') ? 'transparent' : frameRefStyle.backgroundColor),
      filter: frameDesign !== 'none' ? 'brightness(0.8)' : 'none',
      backdropFilter: 'blur(12px)',
      border: borderStyle, // Apply the determined border style
    };;
  };

  const [handleTheme, setHandleTheme] = useState('');

  useEffect(() => {
    // Determine if the background is dark
    const darkBackground = isDarkBackground(props.websiteColor);
    // Set the handle theme state based on the background
    setHandleTheme(darkBackground ? 'dark-background-handle' : 'light-background-handle');
  
  }, [props.websiteColor])
  
  // console.log(handleTheme)

  useEffect(() => {
    // Define a function to add the theme class to handles
    const addHandleThemes = () => {
      const handles = document.querySelectorAll('.react-resizable-handle');
      handles.forEach(handle => {
        // Add the appropriate theme class
        handle.classList.add(handleTheme);

        // Remove the other theme class to ensure no conflicts
        handle.classList.remove(handleTheme === 'dark-background-handle' ? 'light-background-handle' : 'dark-background-handle');
      });
    };

    // Use a MutationObserver to watch for changes and add classes
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.addedNodes.length) {
          addHandleThemes();
        }
      });
    });

    // Start observing the document body for added nodes
    observer.observe(document.body, { childList: true, subtree: true });

    // Add themes initially in case the component is already mounted
    addHandleThemes();

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, [handleTheme]);

  const positionPopup = () => {
    if (!frameRef.current || !textPopupRef.current) {
      return; // Exit if refs are not ready
    }
  
    const popupHeight = textPopupRef.current.offsetHeight;
    const frameRect = frameRef.current.getBoundingClientRect();
    const frameTop = frameRect.top;
    const frameHeight = frameRect.height;
  
    let topPosition;
    if (frameTop > 140) {
      // Position above the frame
      topPosition = frameTop - popupHeight - 10;
    } else {
      // Position below the frame
      topPosition = frameTop + frameHeight + 10;
    }
  
    const newTop = showTextColorPopup ? 75 : topPosition;
  
    setPopupPosition({
      top: newTop,
      left: 0, // Adjusted to match the existing left offset
      margin: '0 10px',
      width: 'calc(100% - 20px)', // 10px padding on both sides
      position: 'fixed',
      zIndex: 1001,
    });
  };

  useEffect(() => {
    if (showTextPopup) {
      const timeoutId = setTimeout(() => {
        positionPopup();
      }, 0); // Delay the execution to the next event loop tick
  
      return () => clearTimeout(timeoutId);
    }
  }, [showTextPopup, showTextColorPopup]);
  
  
  useLayoutEffect(() => {
    if (showTextPopup) {
      positionPopup();
    }
  
    const handleResizeOrScroll = () => {
      if (showTextPopup) {
        positionPopup();
      }
    };
  
    window.addEventListener('resize', handleResizeOrScroll);
    window.addEventListener('scroll', handleResizeOrScroll);
  
    return () => {
      window.removeEventListener('resize', handleResizeOrScroll);
      window.removeEventListener('scroll', handleResizeOrScroll);
    };
  }, [showTextPopup, showTextColorPopup]);

  const [popupSize, setPopupSize] = useState({ width: '300px', height: 'auto' })
  let textColorPopupStyle = {}

  const handlePopupSizeChange = useCallback((width, height) => {
    setPopupSize({ width, height });
  }, [])

  const handleSelectedColor = useCallback((color) => {
    if (color && color.hex) {
      setPreviewColor(color.hex); // Update previewColor for real-time preview
    } else if (typeof color === 'string') {
      setPreviewColor(color); // Directly set previewColor if a string is provided
    }
  }, []);

  const handleConfirmColor = useCallback(async () => {
    if (previewColor) { // Only proceed if a new color has been selected
      try {
        setSelectedFontColor(previewColor)
        getSelectedFontColor(previewColor)
      } catch (error) {
        console.error('Error updating text:', error);
      }
    }
    setPreviewColor(null); // Reset previewColor regardless
    setShowTextColorPopup(false); // Close the color picker popup
  }, [previewColor, props.storeID]);
  
  const handleCancelColor = useCallback(() => {
    setPreviewColor(null); // Revert to original color
    setShowTextColorPopup(false); // Close the popup
  }, []);
  

  return ( 
    <>
    {showTextPopup && !frameDragging && (
      <div 
        className="draggableTextPopupContainer" 
        ref={textPopupRef}
        style={{
          ...popupPosition,
          transition: 'top 0.3s ease-in-out',
        }}  
      >
        <div className="draggableTextPopupContainerSection1">
          <TextPopup
            parentComponent={'draggableFrames'}
            data={props.data}
            frameID={props.frameID} 
            storeID={props.storeID}
            selectedFont={selectedFont}
            selectedFontSize={selectedFontSize}
            selectedFontColor={selectedFontColor}
            getSelectedFontColor={getSelectedFontColor}
            selectedTextAreaFont={selectedTextAreaFont}
            fontFullName={fontFullName}
            setFontFullName={setFontFullName}
            setFontSize={setFontSize}
            closePopup={props.closePopup}
            closeTextPopup={() => {
              props.showLightenSticker(false)
              setShowTextPopup(false)
            }}
            frameDesign={frameDesign}
            setShowTextColorPopup={setShowTextColorPopup}
            showTextColorPopup={showTextColorPopup}
            onPopupClick={onTextPopupClick}
            textPopupRef={props.textPopupRef}
          />
          {showTextAreaOptions && !props.websiteView &&
            <div className="draggableTextareaOptionsContainer" ref={props.doneBtnDivRef} >
              <div className="draggableTextareaOptions">
                <div 
                  className="textareaDoneBtn" 
                  onClick={() => { 
                    console.log("Done button clicked")
                    props.setShowZIndexPopup(false)
                    if (typeof props.closeFrameBar === 'function') {
                      props.closeFrameBar(false);
                    }
                    saveTextAndFrameHeight(); 
                    setIsFrameActive(false)
                    forceRefresh(); 
                    setShowInfo1(false)
                    setChangeToText(false)
                    setShowImagePopup(false)
                  }}
                >
                  done
                  <img src={checkIcon} 
                    onDragStart={preventDrag}
                    className="textareaCheckIcon" 
                    alt="finished with text"
                  />
                </div>
                <img src={optionsIcon} 
                  ref={textareaElementBarIconRef}
                  onDragStart={preventDrag}
                  className="textareaElementBarIcon"
                  onClick={() => {setShowInfo1(!showInfo1)}}
                />
              </div> 
            </div>
          }
        </div>
        {showTextColorPopup &&
          <div 
            className="textColourPopupContainer"
            ref={textColourPopupRef}
            style={{
              ...textColorPopupStyle,
              height: popupSize.height,
              marginTop: '10px',
            }}
          >
            <WebsiteBackgroundPopup 
              parentComponent={'draggableFrames'}
              useCase={'textColorChange'}
              storeID={props.storeID}
              closePopup={() => {setShowTextColorPopup(false)}}
              show={showTextColorPopup} 
              onClickOutside={() => {setShowTextColorPopup(false)}} 
              onSelectColor={handleSelectedColor}
              objectColor={previewColor || selectedFontColor}
              data={props.data}
              pickerRef={props.pickerRef}
              onDone={handleConfirmColor} 
              onCancel={handleCancelColor}
              onSizeChange={handlePopupSizeChange}
              showCommonColors={showCommonColors}
              setShowCommonColors={setShowCommonColors}
              setColorPickerOpen={setColorPickerOpen}
            />
          </div>
        }
      </div> 
    )}
    <motion.div 
      className="draggableFrameBoundaryDiv"
      drag={!props.websiteView && !isResizing}
      dragMomentum={false}
      onDragStart={!props.websiteView ? handleDragStart : undefined}
      onDragEnd={!props.websiteView ? handleDragEnd : undefined}      
      style={{
        position: 'absolute',
        x: x,
        y: y,
        height: 'fit-content',
        width: 'fit-content',
        zIndex: isClicked ? 1000 : props.zIndex,
        pointerEvents: 'auto'
      }}
      ref={props.draggableFrameRef}
    >
      {!isLoading && (
        <motion.div 
          className="frameBoundaryContainer" 
          key={refreshKey}
        >
          <Resizable
            height={frameHeight}
            width={frameWidth}
            onResize={props.websiteView ? undefined : handleResize}
            onResizeStop={props.websiteView ? undefined : onResizeStop}
            onMouseDown={props.websiteView ? undefined : handleMouseDown}
            handleClasses={{
              n: `resize-handle resize-handle-n ${handleTheme}`,
              s: `resize-handle resize-handle-s ${handleTheme}`,
              e: `resize-handle resize-handle-e ${handleTheme}`,
              w: `resize-handle resize-handle-w ${handleTheme}`,
            }}
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              position: 'relative', // Ensure Resizable is positioned correctly
            }}
            resizeHandles={
              !props.websiteView && (isClicked || isFrameActive) 
                ? ['s', 'e', 'n', 'w'] 
                : []
            }
          >
            {isImageFrame ? (
              <div>
                <img 
                  className={`image${props.frameStyle}`} 
                  src={frameImg}
                  ref={imageRef} 
                  style={getFrameStyle()}          
                  onClick={() => {
                    if (!props.websiteView) {
                      handleClick(props.frameID, props.zIndex, 'imageFrame');
                      props.handleDeleteFunctionality(isImageFrame, frameImg, props.frameID, props.storeID);
                    }
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  draggable="false"
                  onDragStart={preventDrag}
                /> 
              </div>
            ) : (
              <div 
                className={props.frameStyle}
                style={getFrameStyle()}      
                ref={frameRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  if (!props.websiteView) {
                    handleClick(props.frameID, props.zIndex, 'textFrame');
                  }
                }}
                draggable="false"
              >
                {!isTextArea ? (
                  <img 
                    id={whitePlusIcon ? 'whiteFramePlusIcon' : 'framePlusIcon'}
                    src={plusIcon} 
                    alt="insert an element into this frame" 
                    onClick={() => { setShowInfo1(true); }}
                    ref={framePlusIconRef}
                    onDragStart={preventDrag}
                  />
                ) : (
                  <textarea 
                    className="frameTextArea"
                    ref={frameTextAreaRef} 
                    value={text}
                    readOnly={props.websiteView}
                    spellCheck={isClicked}
                    style={{
                      cursor: props.websiteView ? 'default' : 'text'
                    }}
                    onChange={(e) => {
                      setText(e.target.value);
                      checkAndAdjustFrameHeight(); // Adjust height on text change
                    }}
                    onClick={() => {
                      if (!props.websiteView) {
                        setShowTextPopup(true);
                        props.showLightenSticker(true);
                        setShowTextAreaOptions(true);
                      }
                    }}
                  />
                )}
              </div>
            )}
          </Resizable>
          {!props.websiteView && (
            <ElementBar
              show={showInfo1} 
              onClickOutside={() => { setShowInfo1(false); }}
              vendorBarRef={props.vendorBarRef}
              rameBarRef={props.frameBarRef}
              imageFrameBarRef={props.imageFrameBarRef}
              frameBorderPopupRef={props.frameBorderPopupRef}
              frameTypesPopupRef={props.frameTypesPopupRef}
              draggableFrameRef={props.draggableFrameRef}
              frameRef={frameRef}
              imageRef={imageRef}
              textPopupRef={textPopupRef}
              resizeHandlerRef={resizeHandlerRef}
              frameID={props.frameID}
              storeID={props.storeID}
              openFramePopup={props.openFramePopup}
              openStickerPopup={props.openStickerPopup}
              addImageToFrame={addImageToFrame}
              isImageFrame={isImageFrame}
              frameImg={frameImg}
              removeFrame={props.removeFrame}
              removeTextPopup={removeTextPopup}
              closePopup={() => { setShowInfo1(false); }}
              textIconClicked={textIconClicked}
              imageIconClicked={imageIconClicked}
              isFrameEmpty={isFrameEmpty()}
              changeToText={changeToText}
              setChangeToText={setChangeToText}
              elementBarRef={props.elementBarRef}
              showImagePopup={showImagePopup}
              setShowImagePopup={setShowImagePopup}
              saveText={saveText}
            />
          )}
        </motion.div>
      )}

      {showImageDonePopup && 
        <div className="imageFrameOptionsContainer" ref={props.doneBtnDivRef} >
          <div className="imageFrameOptions">
            <div className="imageFrameDoneBtn" 
            onClick={updateFrameStylingInFirebase}
            >
              done
              <img src={checkIcon} 
                className="imageFrameCheckIcon" 
                alt="finished with text"
              />
            </div>
            <img src={optionsIcon} 
              className="imageFrameElementBarIcon"
              onClick={() => {setShowInfo1(true)}}
            />
          </div> 
        </div>
      }
    </motion.div>
    </>
  )
}

export default DraggableFrames