import React from 'react';
import './StepIndicator.css';

const StepIndicator = ({ currentStep }) => {
  const steps = [
    'Basic Information',
    'Business Context',
    'Aesthetic Preferences',
    'Review',
  ];

  return (
    <div className="step-indicator">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${currentStep === index ? 'currentlySelected' : ''} ${
            currentStep > index ? 'completed' : ''
          }`}
        >
          <div className="step-number">{index + 1}</div>
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;
