import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from 'framer-motion';
import './DeleteConfirmationPopup.css'

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  const deleteConfirmationRef = useRef();
  let pressTimer;

  useEffect(() => {
    const handleMouseDown = () => {
      // Start the timer
      pressTimer = setTimeout(() => {
        // This will set some flag or state if needed
      }, 500); // 500ms for a long press
    };

    const handleMouseUp = () => {
      // Clear the timer
      clearTimeout(pressTimer);
    };

    const handleClickOutside = (event) => {
      if (deleteConfirmationRef.current && !deleteConfirmationRef.current.contains(event.target)) {
        if (pressTimer) {
          // Only call onCancel if this was not a long press
          onCancel();
        }
      }
    };

    // Add event listeners
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      // Clean up the event listeners
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onCancel]);
  
  return (
    <div className="popupOverlay">
      <motion.div 
        className="popupContent" 
        ref={deleteConfirmationRef}
        initial={{ y: '100vh' }} // Start below the screen
        animate={{ y: 0 }} // End at its natural position
        transition={{ 
          duration: 0.5, 
          ease: "easeInOut" 
        }}
      >        
        <p>Are you sure you want to remove this product from favourites?</p>
        <div className="deleteConfirmationBtnDiv">
          <button className="deleteConfirmationNoBtn" onClick={onCancel}>Go Back</button>
          <button className="deleteConfirmationYesBtn"onClick={onConfirm}>Yes, Delete</button>
        </div>
      </motion.div>
    </div>
  );
};

export default DeleteConfirmationPopup
