// ImageGrain.js

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';

const ImageGrain = () => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    // Initialize Three.js Scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      container.offsetWidth / container.offsetHeight || 1,
      0.1,
      1000
    );
    camera.position.z = 1;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    renderer.setClearColor(0x000000, 0); // Transparent background
    container.appendChild(renderer.domElement);

    // Initialize EffectComposer
    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));

    // Define the Film Grain Shader
    const ImageGrainShader = {
      uniforms: {
        'tDiffuse': { value: null },
        'grainIntensity': { value: 0.4 },
        'grainSize': { value: 0.25 },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float grainIntensity;
        uniform float grainSize;
        varying vec2 vUv;

        float random(vec2 co){
          return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
        }

        void main() {
          vec3 color = texture2D(tDiffuse, vUv).rgb;
          float grain = random(vUv * grainSize) * grainIntensity;
          gl_FragColor = vec4(color + vec3(grain), 1.0);
        }
      `,
    };

    // Create and add the ShaderPass
    const ImageGrainPass = new ShaderPass(ImageGrainShader);
    composer.addPass(ImageGrainPass);

    // Render once
    composer.render();

    // Handle container resize
    const resizeObserver = new ResizeObserver(() => {
      if (container.offsetWidth === 0 || container.offsetHeight === 0) {
        return;
      }
      camera.aspect = container.offsetWidth / container.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      composer.setSize(container.offsetWidth, container.offsetHeight);
      composer.render();
    });

    resizeObserver.observe(container);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
      if (renderer.domElement && container.contains(renderer.domElement)) {
        container.removeChild(renderer.domElement);
      }
      renderer.dispose();
      composer.dispose();
    };
  }, []); // Empty dependency array, runs once on mount

  return (
    <div
      className="scoutImageGrain"
      ref={containerRef}
      style={{
        position: 'absolute',
        width: `100%`,
        height: '100%',
        zIndex: 2,
        top: 0,
        left: 0,
        opacity: '0.15',
        pointerEvents: 'none',
        borderRadius: '20px'
      }}
    ></div>
  );
};

export default ImageGrain;
