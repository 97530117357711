import React, { useRef, useState, useCallback, useEffect } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import debounce from 'lodash/debounce';
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  arrayUnion, getDoc, getDocs, query, where, 
  onSnapshot, serverTimestamp, deleteDoc, runTransaction } 
from '@firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, storage } from '../firebase'
import { Outlet, Link, useLocation } from "react-router-dom";
import { Reorder, motion, AnimatePresence } from 'framer-motion';

import './Vendor.css'
import './VendorCreateStorePopup.css'

import websitePageColourIcon from '../icons/websitePageColourIcon.png'
import plusIcon from '../icons/plusIcon.png'
import walletIcon from '../icons/walletIcon.png'
import dashboardIcon from '../icons/dashboardIcon.png'
import shareIcon from '../icons/shareIcon.png'
import frameIcon from '../icons/frameIcon.png'
import stickerIcon from '../icons/stickerIcon.png'
import deleteIcon from '../icons/deleteIcon2.png'
import loadIcon from '../icons/scoutIcon.png'

import frameStyles from './website creation/frameStyles.json'
import Login from '../login/Login'
import Frames from './website creation/Frames'; 
import DraggableFrames from './website creation/DraggableFrames';
import { FramePopup } from './website creation/FramePopup';
import AssetPopup from './website creation/AssetPopup';
import ImageFrameBar from './website creation/ImageFrameBar';
import Stickers from './website creation/Stickers';
import WebsiteBackgroundPopup from './website creation/WebsiteBackgroundPopup';
import FrameBar from './website creation/FrameBar';
import StickerBar from './website creation/StickerBar';
import FrameDynamics from './website creation/FrameDynamics';
import VendorPopup from './VendorPopup'

export function getStickerID() {
  let title = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 7; i++) {
    title += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  title += new Date().getTime()
  return title
}

const Vendor = (props) => {
  const vendorBarRef = useRef()
  const websiteRef = useRef()
  const vendorOpeningPopupBackgroundRef = useRef()
  const vendorOpeningPopupDivRef = useRef()
  const createStorePopupDivRef = useRef()
  const frameBarRef = useRef() 
  const imageFrameBarRef = useRef() 
  const frameBorderPopupRef = useRef()
  const frameTypesPopupRef = useRef()
  const draggableFrameRef = useRef()
  const frameDynamicsRef = useRef()
  const opacityPopupRef = useRef()
  const doneBtnDivRef = useRef()
  const elementBarRef = useRef();
  const pickerRef = useRef()
  const textPopupRef = useRef();
  
  const [stickers, setStickers] = useState([]);
  const [frames, setFrames] = useState([])
  const [draggingFramePosition, setDraggingFramePosition] = useState(null)

  const [isFramesDataLoaded, setIsFramesDataLoaded] = useState(false)
  const [draggableFrames, setDraggableFrames] = useState([]);
  const [fixedFrames, setFixedFrames] = useState([])
  const [userID, setUserID] = useState()
  const [storeID, setStoreID] = useState()
  const [storeData, setStoreData] = useState({})
  const [stickerID, setStickerID] = useState(getStickerID())
  const [showFrameSelector, setShowFrameSelector] = useState(false)
  const [showInfo2, setShowInfo2] = useState(false)
  const [showInfo3, setShowInfo3] = useState(false)
  const [showBackgroundSelector, setShowBackgroundSelector] = useState(false)
  const [stickerDeleting, setStickerDeleting] = useState(false)
  const [showVendorPopup, setShowVendorPopup] = useState(false)
  const [showCreateStorePopup, setShowCreateStorePopup] = useState(false)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [showTimeoutLogin, setShowTimeoutLogin] = useState(false)
  const [deleteTimer, setDeleteTimer] = useState(null)
  const [websiteColor, setWebsiteColor] = useState("#FFFFFF"); // Confirmed color
  const [previewColor, setPreviewColor] = useState(null); // Preview color
  let [animation, setAnimation] = useState(-80)
  const [lightenSticker, showLightenSticker] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingStickers, setIsLoadingStickers] = useState(true);
  const [isLoadingFrames, setIsLoadingFrames] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(true);
  const [showZIndexPopup, setShowZIndexPopup] = useState(false)
  const [selectedFrameStyle, setSelectedFrameStyle] = useState('none')
  const [showFrameBar, setShowFrameBar] = useState(false)
  const [frameDesign, setFrameDesign] = useState()
  const [newFrameStyling, setNewFrameStyling] = useState()
  const [newStickerStyling, setNewStickerStyling] = useState()
  const [stickerSelected, setStickerSelected] = useState(false)
  const [frameSelected, setFrameSelected] = useState(false)
  const [selectedObjectID, setSelectedObjectID] = useState()
  const [selectedObject, setSelectedObject] = useState()
  const [showImageFrameBar, setShowImageFrameBar] = useState(false)
  const [draggableFrameSelected, setDraggableFrameSelected] = useState(false)
  const [isResizing, setIsResizing] = useState(false);

  const [frameWidth, setFrameWidth] = useState()
  const [frameEvent, setFrameEvent] = useState('newFrame')

  const [marginTopInput, setMarginTopInput] = useState('');
  const [marginBottomInput, setMarginBottomInput] = useState('')

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [stretchFrame, setStretchFrame] = useState(false) 

  useEffect(() => {
    document.title = 'Vendor | taizte™';
  }, []);

  const preventDrag = (event) => {
    event.preventDefault();
  };

  const updateDraggingFramePosition = useCallback((position) => {
    setDraggingFramePosition(position);
  }, [])


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 400) {
        if (props.navBarState === true) {
          setAnimation(-60);
        } else {
          setAnimation(0);
        }
      } else {
        setAnimation(0);
      }
    };
  
    window.addEventListener('resize', handleResize);
  
    // Call it initially to set the correct animation state based on the initial window size
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.navBarState]);
  
  
  // NEW: Authentication State Listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setShowLoginPopup(false);
        setShowVendorPopup(false);
        setShowTimeoutLogin(false); // Reset timeout flag
        setUserID(user.uid);
    
        await getDoc(doc(db, 'users', user.uid)).then(async (docSnap) => {
          if (docSnap.exists()) {
            const storeID = docSnap.data().storeID;
            setStoreID(storeID);
    
            if (storeID == null || !docSnap.data().hasOwnProperty('storeID')) {
              setShowVendorPopup(false);
              setShowCreateStorePopup(true);
            } else {
              // code to save and get background colour
              const getStoreData = async () => {
                await getDoc(doc(db, 'stores', storeID)).then((docSnap) => {
                  if (docSnap.exists()) {
                    setStoreData(docSnap.data());
    
                    if (docSnap.data() && docSnap.data().website_background) {
                      setWebsiteColor(docSnap.data().website_background);
                    } else {
                      setWebsiteColor('#FFFFFF');
                    }
                    setIsLoading(false);
                  } else {
                    console.log('There is no store');
                  }
                });
              };
              getStoreData();
            }
          } else {
            console.log('There is no user');
          }
        });
        
        // Start inactivity tracking
        startInactivityTimer();
      } else {
        setIsLoggedIn(false);
        if (showTimeoutLogin) {
          // User was logged out due to inactivity
          setShowTimeoutLogin(true);
          setShowVendorPopup(false);
        } else {
          // User is unauthenticated for other reasons
          setShowTimeoutLogin(false);
          setShowVendorPopup(true);
        }
  
        // Stop inactivity tracking
        stopInactivityTimer();
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [showTimeoutLogin])

  // NEW: Inactivity Timer Logic
  let inactivityTimer = useRef(null);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User has been logged out due to inactivity.");
        setShowTimeoutLogin(true); // Flag to indicate logout due to timeout
        setShowVendorPopup(false); // Ensure initial login prompt is hidden
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(handleLogout, 600000); // 10 minutes
  };

  const handleActivity = () => {
    resetInactivityTimer();
  };

  const startInactivityTimer = () => {
    resetInactivityTimer();
    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });
  };

  const stopInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    events.forEach((event) => {
      window.removeEventListener(event, handleActivity);
    });
  };

  // Ensure to reset the inactivity timer when the user logs in again
  useEffect(() => {
    if (isLoggedIn) {
      setShowTimeoutLogin(false); // Hide session expiry overlay upon successful login
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!isLoadingStickers && !isLoadingFrames && !isLoadingText) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000); // Adjust the delay as needed
    }
  }, [isLoadingStickers, isLoadingFrames, isLoadingText]);  

  useEffect(() => {
    if (storeID) {
      fetchFrameArray(storeID)
      fetchStickerArray(storeID)
    }
  }, [storeID])

  useEffect(() => {
    if (isFramesDataLoaded) {
      console.log("All frames:", frames);

      const draggable = frames.filter(frame => frame.isDragging === true);
      const fixed = frames.filter(frame => frame.isDragging === false);

      console.log("Draggable frames:", draggable);
      console.log("Fixed frames:", fixed);

      setDraggableFrames(draggable);
      setFixedFrames(fixed);
    }
  }, [frames, isFramesDataLoaded])

  const fetchFrameArray = async(storeID) => {
    getDoc(doc(db, 'stores', storeID)).then(docSnap => {
      const storeInfo = docSnap.data()
      if (!storeInfo.frames) {
        console.log("user has no frames")
        setFrames([]); // Ensure frames is an empty array
      } else {
        const frameData = docSnap.data().frames
        console.log(frameData)
        setFrames(frameData)
      }
      setIsFramesDataLoaded(true) // Now set regardless of frames existing
    })
    setIsLoadingFrames(false)
  }
  
  

  const fetchStickerArray = async(storeID) => {
    getDoc(doc(db, 'stores', storeID)).then(docSnap => {
      const storeInfo = docSnap.data()
      if (!storeInfo.stickers) {
        console.log("user has no stickers")
      } else {
      const stickerData = docSnap.data().stickers
      console.log(stickerData)
      const initializedStickers = stickerData.map(sticker => ({ ...sticker, isVisible: true }));
      // isVisible here relates to sticker deleting
      setStickers(initializedStickers)
      }
    })
  }

  useEffect(() => {
    if (selectedObject && selectedObject.frame_styling) {
      const { marginTop, marginBottom } = selectedObject.frame_styling;
      setMarginTopInput(marginTop !== undefined ? marginTop.toString() : '');
      setMarginBottomInput(marginBottom !== undefined ? marginBottom.toString() : '');
    } else {
      setMarginTopInput('');
      setMarginBottomInput('');
    }
  }, [selectedObjectID]);

  const handleMarginTopChange = (e) => {
    const value = e.target.value;
    setMarginTopInput(value);
  
    const marginTopValue = value.trim() === '' ? '' : value;
    debouncedUpdateMarginTop(marginTopValue);
  };
  
  const handleMarginBottomChange = (e) => {
    const value = e.target.value;
    setMarginBottomInput(value);
  
    const marginBottomValue = value.trim() === '' ? '' : value;
    debouncedUpdateMarginBottom(marginBottomValue);
  };

  const handleConfirmColor = useCallback(async (newColor) => {
    if (newColor) { 
      console.log("Selected Color:", newColor);
      console.log("Store ID:", storeID);
      try {
        const storeRef = doc(db, 'stores', storeID);
        const storeSnap = await getDoc(storeRef);
        if (storeSnap.exists()) {
          setWebsiteColor(newColor); // Set confirmed color
          console.log("Updating Firestore with color:", newColor);
          await updateDoc(storeRef, { website_background: newColor });
          console.log("Firestore update successful");
        } else {
          console.error("Store document does not exist.");
        }
      } catch (error) {
        console.error('Error updating website background:', error);
      }
    }
    setPreviewColor(null); // Reset previewColor regardless
    setShowBackgroundSelector(false); // Close the color picker popup
  }, [storeID]);

  
  const handleCancelColor = useCallback(() => {
    setPreviewColor(null); // Revert to original color
    setShowBackgroundSelector(false); // Close the popup
  }, []);

  const handleSelectedColor = useCallback((color) => {
    if (color && color.hex) {
      setPreviewColor(color.hex); // Update previewColor for real-time preview
      console.log('woof')
    } else if (typeof color === 'string') {
      setPreviewColor(color); // Directly set previewColor if a string is provided
      console.log('woof')
    }
  }, []);
  
  const addSticker = useCallback((url, storeID, userID, stickerID, stickerType) => {
    console.log(stickerID)

    const newStickerID = getStickerID()
    console.log(newStickerID)
    const dbRef = doc(db, 'stickers', newStickerID)
    const stickerInfo = {
      sticker: url,
      stickerID: newStickerID,
      user: userID,
      store: storeID,
      x: -130,
      y: 70,
      height: 250,
      width: 250,
      zIndex: 1,
      stickerType: stickerType,
    }   

    setDoc(dbRef, stickerInfo)
    console.log("Sticker " + newStickerID + "added to database")   

    updateDoc(doc(db, 'stores', storeID), {
      stickers: arrayUnion(stickerInfo),
    })
    console.log("sticker added to store document")
    setStickers(prev => [...prev, { ...stickerInfo, isVisible: true }])

  }, [stickers])

  const [draggedStickerID, setDraggedStickerID] = useState()

  const draggedStickerInfo = useCallback(async(stickerID) => {
    setDraggedStickerID(stickerID)
  })

  const deleteSticker = async (stickerIDFromChild) => {
    if (stickerIDFromChild) {
      console.log(stickerIDFromChild)
      const storeRef = doc(db, 'stores', storeID);
  
      try {
        // Delete the sticker document
        setStickers(prevStickers => prevStickers.map(sticker => 
          sticker.stickerID === stickerIDFromChild ? { ...sticker, isVisible: false } : sticker
        ));
        setStickerSelected(false)
        await deleteDoc(doc(db, "stickers", stickerIDFromChild));
  
        // Fetch the current state of the store
        const docSnap = await getDoc(storeRef);
        if (docSnap.exists()) {
          const stickers = docSnap.data().stickers;
          const updatedStickers = stickers
          .filter(sticker => sticker.stickerID !== stickerIDFromChild)
          .map(sticker => ({ ...sticker, isVisible: true })); // Preserve isVisible
          
          console.log(updatedStickers)
          setStickers(updatedStickers);

          if (stickers.stickerType === "uploadedImage") {
            let imageURL = stickers.sticker;
            let fileName = imageURL.slice(89, imageURL.lastIndexOf('?')).replace(/ /g, "%20");
            fileName = decodeURIComponent(fileName);
          
            let deleteRef, deleteRef2;

            deleteRef = ref(storage, `website images/${fileName + "_1000x1000"}`);
            deleteRef2 = ref(storage, `website images/${fileName}`);
            console.log("Image sticker has been deleted")
          }

          await updateDoc(storeRef, { stickers: updatedStickers });
          console.log("Sticker deleted successfully");
          setStickerDeleting(false)
          setDraggedStickerID(null);
        } else {
          console.log("Store document not found");
        }
      } catch (error) {
        console.error("Error deleting sticker: ", error);
        showSticker(stickerIDFromChild)
      }
    }
  };

  const showSticker = (stickerID) => {
    setStickers(prevStickers => prevStickers.map(sticker => 
        sticker.stickerID === stickerID ? { ...sticker, isVisible: true } : sticker
    ));
  };
  
  const addDeleteStickerPopup = () => {
    setStickerDeleting(true)
  }

  const removeDeleteStickerPopup = () => {
    setStickerDeleting(false)
  }

  const updateFrames = useCallback(async(updatedData, updatedSelectedObjectData) => {
    console.log("Frames have been updated: ", updatedData)
    console.log(updatedSelectedObjectData)
    setSelectedObject(updatedSelectedObjectData)
    setNewFrameStyling(updatedData)
    setFrames(updatedData)
  })

  const debouncedUpdateMarginTop = useCallback(
    debounce(async (marginTopValue) => {
      if (!selectedObject) return;
  
      const updatedMarginTop = marginTopValue !== '' ? parseInt(marginTopValue, 10) : 0;
  
      const updatedFrameStyling = {
        ...selectedObject.frame_styling,
        marginTop: updatedMarginTop,
      };
  
      const updatedObjectData = { ...selectedObject, frame_styling: updatedFrameStyling };
      const updatedData = frames.map(obj =>
        obj.frameID === selectedObjectID ? updatedObjectData : obj
      );
  
      updateFrames(updatedData, updatedObjectData);
      setSelectedObject(updatedObjectData);
  
      // Update Firestore for frames
      const framesRef = doc(db, 'frames', selectedObjectID);
      await updateDoc(framesRef, { frame_styling: updatedFrameStyling });
  
      // Update the frames array in the stores document
      const storesRef = doc(db, 'stores', storeID);
      const storeDoc = await getDoc(storesRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames || [];
        const frameIndex = framesArray.findIndex(frame => frame.frameID === selectedObjectID);
  
        if (frameIndex !== -1) {
          framesArray[frameIndex].frame_styling = updatedFrameStyling;
          await updateDoc(storesRef, { frames: framesArray });
        }
      }
    }, 300),
    [selectedObject, selectedObjectID, storeID, frames, updateFrames]
  );
  
  const debouncedUpdateMarginBottom = useCallback(
    debounce(async (marginBottomValue) => {
      if (!selectedObject) return;
  
      const updatedMarginBottom = marginBottomValue !== '' ? parseInt(marginBottomValue, 10) : 0;
  
      const updatedFrameStyling = {
        ...selectedObject.frame_styling,
        marginBottom: updatedMarginBottom,
      };
  
      const updatedObjectData = { ...selectedObject, frame_styling: updatedFrameStyling };
      const updatedData = frames.map(obj =>
        obj.frameID === selectedObjectID ? updatedObjectData : obj
      );
  
      updateFrames(updatedData, updatedObjectData);
      setSelectedObject(updatedObjectData);
  
      // Update Firestore for frames
      const framesRef = doc(db, 'frames', selectedObjectID);
      await updateDoc(framesRef, { frame_styling: updatedFrameStyling });
  
      // Update the frames array in the stores document
      const storesRef = doc(db, 'stores', storeID);
      const storeDoc = await getDoc(storesRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames || [];
        const frameIndex = framesArray.findIndex(frame => frame.frameID === selectedObjectID);
  
        if (frameIndex !== -1) {
          framesArray[frameIndex].frame_styling = updatedFrameStyling;
          await updateDoc(storesRef, { frames: framesArray });
        }
      }
    }, 300),
    [selectedObject, selectedObjectID, storeID, frames, updateFrames]
  );
  
  const addFrame = useCallback(
    async (frameIdentifier, frameID, userID, storeID, frameDragState) => {
      setShowFrameSelector(false);
      console.log(`Frame Event: ${frameEvent}`);
      console.log(`Frame Identifier: ${frameIdentifier}`);
      console.log(`Frame ID: ${frameID}`);
      
      // Retrieve predefined styles from frameStyles.json
      const predefinedFrameStyle = frameStyles[frameIdentifier];
      console.log(`Predefined Frame Style for ${frameIdentifier}:`, predefinedFrameStyle);
      
      if (!predefinedFrameStyle) {
        console.error(`No predefined styles found for ${frameIdentifier}`);
        return;
      }
  
      // Calculate zIndex and frame styling
      const combinedItems = [...frames, ...stickers];
      const zIndexes = combinedItems.map(item => item.zIndex || 0);
      const maxZIndex = Math.max(...zIndexes);
      const newZIndex = maxZIndex + 1;
      
      const newFrameStyling = frameDragState 
        ? { x: 0, y: 75, width: window.innerWidth * 0.95, zIndex: newZIndex } 
        : {};
  
      // Calculate height based on aspect ratio
      let calculatedHeight;
      if (predefinedFrameStyle.aspectRatio) {
        const [numerator, denominator] = predefinedFrameStyle.aspectRatio.split('/').map(Number);
        if (denominator !== 0) {
          const aspectRatioValue = denominator / numerator;
          calculatedHeight = frameDragState 
            ? window.innerWidth * 0.95 * aspectRatioValue 
            : 0; // Adjust as needed
          newFrameStyling.height = `${calculatedHeight}px`;
        } else {
          console.error("Invalid aspect ratio format");
        }
      }
  
      // Construct frameInfo
      const frameInfo = {
        frame_style: frameIdentifier,
        frameID: frameID,
        user: userID,
        store: storeID,
        zIndex: newZIndex,
        isDragging: frameDragState,
        frame_styling: newFrameStyling,
      };
  
      console.log('Constructed frameInfo:', frameInfo);
  
      // Optimistically update local state
      setFrames(prevFrames => [...prevFrames, frameInfo]);
  
      // Proceed with Firebase transaction
      const storeRef = doc(db, 'stores', storeID);
      const frameRef = doc(db, 'frames', frameInfo.frameID);
  
      try {
        await runTransaction(db, async (transaction) => {
          const storeDoc = await transaction.get(storeRef);
          if (!storeDoc.exists()) {
            throw new Error(`Store with ID ${storeID} does not exist.`);
          }
  
          transaction.set(frameRef, frameInfo);
          transaction.update(storeRef, {
            frames: arrayUnion(frameInfo),
          });
        });
  
        console.log(`Frame ${frameID} successfully added to Firestore.`);
      } catch (error) {
        console.error(`Error in addFrame (${frameEvent}):`, error);
        // Revert optimistic update on failure
        setFrames(prevFrames => prevFrames.filter(frame => frame.frameID !== frameID));
        // Optionally, notify the user about the failure
        props.setAlertMessage('Error adding the new frame');
        props.setAlertType('error')
      }
  
      // Close the popup
      setShowFrameSelector(false)
    },
    [frameEvent, frames, stickers, frameStyles, props]
  );
  
  
  const removeFrame = useCallback(async(frameID, storeID) => {
    console.log("About to remove frame")
    deleteDoc(doc(db, "frames", frameID))
    const storeRef = doc(db, 'stores', storeID)

    getDoc(storeRef).then(docSnap => {
      if (docSnap.exists()) {
        const frames = docSnap.data().frames
        const currentFrameID = frameID

        if (frames) {
          const updatedFrames = frames.filter(frame => frame.frameID !== currentFrameID);
          console.log(updatedFrames)
          updateDoc(storeRef, {
            frames: updatedFrames,
          });
          setFrames(updatedFrames)
          // refresh code for frames
        } else {console.log("no frames")}
      }
    })
    // delete images from database
    setShowImageFrameBar(false)
    setShowFrameBar(false)
    setFrameSelected(false)
    console.log("frame deleted")
  })

  const [deleteInfo, setDeleteInfo] = useState({
    isImageFrame: '',
    frameImg: ''
  })

  const handleDeleteFunctionality = useCallback((isImage, frameImgValue, currFrameID, currStoreID) => {
    setDeleteInfo((prevState) => ({
      isImageFrame: isImage,
      frameImg: frameImgValue,
      frameID: currFrameID,
      storeID: currStoreID
    }))
  })

  const removeFrameImage = () => {
    console.log("Delete icon clicked")
    if (deleteInfo.isImageFrame) {
      const imageURL = deleteInfo.frameImg;
      console.log(imageURL)
      let fileName = imageURL.slice(89, imageURL.lastIndexOf('?')).replace(/ /g, "%20");
      fileName = decodeURIComponent(fileName);
      console.log("Decoded fileName:", fileName);

      // Check if the imageURL contains '_1000x1000'
      if (imageURL.includes('_1000x1000')) {
        // Define references for both images
        const originalRef = ref(storage, `website images/${fileName.replace('_1000x1000', '')}`);
        const resizedRef = ref(storage, `website images/${fileName}`);

        // Delete original image
        deleteObject(originalRef).then(() => {
          console.log("Original image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting original image:", error);
        });

        // Delete resized image
        deleteObject(resizedRef).then(() => {
          console.log("Resized image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting resized image:", error);
        });
      } else {
        // If '_1000x1000' is not part of the URL, delete the current image and its resized version
        const deleteRef = ref(storage, `website images/${fileName}`);
        const deleteResizedRef = ref(storage, `website images/${fileName}_1000x1000`);

        deleteObject(deleteRef).then(() => {
          console.log("Image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting image:", error);
        });

        deleteObject(deleteResizedRef).then(() => {
          console.log("Resized image successfully deleted");
        }).catch((error) => {
          console.log("Error deleting resized image:", error);
        });
      }
      // setShowImagePopup(false)
    } else {
      // props.removeTextPopup()
    }
    console.log("About to remove frame")
    console.log(deleteInfo)
    removeFrame(deleteInfo.frameID, deleteInfo.storeID)
    setFrameSelected(false)
    setShowFrameBar(false)
  }

  const openFramePopup = useCallback((eventType) => {
    setFrameEvent(eventType)
    console.log("Event type: ", eventType)
    setShowFrameSelector(true)
  }, [])

  function openStickerPopup() {
    setShowInfo2(true)
  }

  const [liftedStyle, setLiftedStyle] = useState(false)
  
  const noLiftedStyle = useCallback((state) => {
    setLiftedStyle(state)
  })

  // add introduction, how to block for new users

  const handleFramesLoaded = () => {
    setIsLoadingText(false);
  };
  
  const handleStickerUpdate = () => {
    setIsLoadingStickers(false)
  };
  
  const updateStickerData = useCallback((stickerData, selectedStickerID) => {
    if (selectedStickerID) {
      setStickers(stickerData)
      const selectedSticker = stickerData.find(sticker => sticker.stickerID === selectedStickerID)
      console.log(selectedSticker)
      setSelectedObject(selectedSticker)
    }
  })

  const handleStickerSelected = useCallback((stickerID, xPosition, yPosition, height, width, zIndex) => {
    console.log(height, width)
    setStickerSelected(true);
    setSelectedObjectID(stickerID);
    console.log(stickers)
  
    // Directly use passed height and width here
    const selectedObjectData = { 
      ...stickers.find(sticker => sticker.stickerID === stickerID),
      height: height, // Update height
      width: width    // Update width
    };
    
    console.log(selectedObjectData);
    setSelectedObject(selectedObjectData);
    console.log("sticker has been selected");
  }, [stickers]);

  const [selectedFrameStyling, setSelectedFrameStyling] = useState()
  //add height and width when doing frame resize
  const handleFrameSelected = useCallback((frameID, zIndex, selectedFrameStyle, frameTypeString) => {
    console.log(frameID, zIndex, selectedFrameStyle)
    setSelectedFrameStyling(selectedFrameStyle)
    setFrameSelected(true);
    
    setSelectedObjectID(frameID);
  
    // Find the selected frame based on frameID
    const selectedFrame = frames.find(frame => frame.frameID === frameID);
    
    if (selectedFrame) {
      // Update the selectedObject state
      setSelectedObject(selectedFrame);

      if (frameTypeString === "textFrame") {
        setShowFrameBar(true)
        console.log("showing frame bar")
      } else if (frameTypeString === "imageFrame") {
        setShowImageFrameBar(true)
      }
    }

    console.log("frame has been selected", selectedFrame)
  }, [frames]);

  const handleStickerUnselected = () => {
    setStickerSelected(false)
    setStickerDeleting(false)
  }
  
  // remember to account for frames too
  const updateStickerSize = async (stickerID, newHeight, newWidth) => {
    // Update local state
    setStickers(prevStickers => prevStickers.map(sticker => 
      sticker.stickerID === stickerID ? { ...sticker, height: newHeight, width: newWidth } : sticker
    ));

    // Prepare updated sticker data for Firebase
    const updatedStickerData = {
      height: newHeight,
      width: newWidth
    };

    // Update sticker data in Firebase
    const stickerDocRef = doc(db, "stickers", stickerID);
    await updateDoc(stickerDocRef, updatedStickerData);

    // If the sticker is also part of a store's stickers array, update there as well
    if (storeID) {
      const storeRef = doc(db, 'stores', storeID);
      const storeDoc = await getDoc(storeRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const updatedStickers = storeData.stickers.map(sticker => 
          sticker.stickerID === stickerID ? { ...sticker, ...updatedStickerData } : sticker
        );

        await updateDoc(storeRef, { stickers: updatedStickers });
      }
    }
  };

  const handleHeightChange = (e) => {
    const newHeight = parseInt(e.target.value) || 0;
    setSelectedObject(prev => ({ ...prev, height: newHeight }));
    updateStickerSize(selectedObjectID, newHeight, selectedObject.width);
  };
  
  const closeFrameBar = () => {
    setFrameSelected(false)
    setShowFrameBar(false)
  }

  const moveFrame = useCallback((dragIndex, hoverIndex) => {
    const draggedFrame = frames[dragIndex];
    const updatedFrames = [...frames];
    updatedFrames.splice(dragIndex, 1);
    updatedFrames.splice(hoverIndex, 0, draggedFrame);
    setFrames(updatedFrames);

    // Update Firebase with the new order
    updateDoc(doc(db, 'stores', storeID), {
      frames: updatedFrames
    }).catch(error => console.error("Error updating frames in Firebase: ", error));
  }, [frames, storeID])

  useEffect(() => {
    if (draggingFramePosition !== null) {
      setFrames((prevFrames) => {
        return prevFrames.map((frame, index) => {
          const frameElement = document.getElementById(frame.frameID);
          if (frameElement) {
            const rect = frameElement.getBoundingClientRect();
            if (draggingFramePosition > rect.top && draggingFramePosition < rect.bottom) {
              return { ...frame, style: { marginTop: '60px' } };  // Adjust this value as needed
            }
          }
          return { ...frame, style: {} };
        });
      });
    }
  }, [draggingFramePosition])

  const updateStickers = useCallback(async(updatedData, updatedSelectedObjectData) => {
    console.log(updatedData, updatedSelectedObjectData)
    setSelectedObject(updatedSelectedObjectData)
    setStickers(updatedData)
    setNewStickerStyling(updatedData)
  })

  const loadingVariants = {
    exit: { 
      y: '-100px',
      opacity: 0,
      scale: 0.5,
      transition: { duration: 0.5 }
    }
  };
  
  const contentVariants = {
    hidden: { 
      y: 30,
      opacity: 0, 
      scale: 0.9 
    },
    visible: { 
      y: 0, // Move to final position
      opacity: 1, 
      scale: 1,
      transition: { duration: 1, delay: 0.5 }
    }
  };

  const handleReorder = useCallback(async (newOrder) => {
    setFrames(newOrder);
    
    try {
      await updateDoc(doc(db, 'stores', storeID), {
        frames: newOrder
      });
      console.log("Frames order updated in Firebase: ", newOrder);
    } catch (error) {
      console.error("Error updating frames in Firebase: ", error);
    }
  }, [storeID])

  const handleFrameResize = useCallback((frameId, newHeight) => {
    setFrames(prevFrames => 
      prevFrames.map(frame => 
        frame.frameID === frameId 
          ? { ...frame, frame_styling: { ...frame.frame_styling, height: `${newHeight}px` } }
          : frame
      )
    );
  }, [])

  const copyToClipboard = () => {
    const baseUrl = 'https://taizte.com/';
    const amendedLink = encodeURIComponent(storeData.store); // Ensure the store name is URL-safe
    const url = `${baseUrl}${amendedLink}`;

    navigator.clipboard.writeText(url)
      .then(() => {
        props.setAlertMessage("Link copied to clipboard!"); // Success message
        props.setAlertType('success');
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        props.setAlertMessage("Failed to copy the link, try again."); // Error message
        props.setAlertType('error');
      });
  }
  
  return (
    <div className='websiteBody' ref={websiteRef}>
      <style>{`body { background: ${previewColor || websiteColor}; }`}</style>
      {showVendorPopup &&
        <VendorPopup 
        showVendorPopup={showVendorPopup}
        setShowLoginPopup={setShowLoginPopup}
        setShowInfo3={setShowInfo3}
        vendorOpeningPopupBackgroundRef={vendorOpeningPopupBackgroundRef}
        vendorOpeningPopupDivRef={vendorOpeningPopupDivRef}
        navBarState={props.navBarState}
        />
      }
      {showTimeoutLogin &&
        <motion.div
          className="vendorTimeoutLogin"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0, duration: 0.5 }}
        >
          <div className="vendorTimeoutLoginDiv">
            <p className="vendorTimeoutLoginTextDiv">
              Session expired due to inactivity. 
              Please <strong>login</strong> again to continue.
            </p>
            <Login show={showTimeoutLogin} />
          </div>
        </motion.div>
      }
      {showCreateStorePopup &&
      <div className="vendorOpeningPopupBackground" ref={vendorOpeningPopupBackgroundRef}>
        <div className="createStorePopupDiv" ref={createStorePopupDivRef}>
          <p className="createStorePopupText">please create a store</p>
          <Link to="/create-store"><button className="createStoreBtn2">create store</button></Link>
        </div>         
      </div>
      }
      {showLoginPopup &&
        <div className="vendorLoginDiv">
          <Login show={showInfo3} 
          onClickOutside={() => {
            setShowLoginPopup(false)
            setShowInfo3(false)
          }}/>
        </div>
      }
      <AnimatePresence>
        {isLoading &&
          <motion.div 
            className="vendorLoadingDiv"
            variants={loadingVariants}
            exit="exit"
            initial={{ opacity: 1, scale: 1, y: 0 }}
          >
            <img 
              src={loadIcon} 
              onDragStart={preventDrag}
              className="vendorLoadingIcon" 
              alt="loading icon" 
            />
          </motion.div>
        }
      </AnimatePresence>
      {!stickerDeleting && !stickerSelected && !frameSelected && !showFrameBar && !showImageFrameBar &&
        <div className='vendorBar'>
          <Link to="/addproduct" className="addProductBtn">
            <p className='addProductBtnText'>add product</p>
            <img src={plusIcon} id='addProduct' 
              alt="add product page"
              title="add product"
              className='addProductBtnIcon'
              onDragStart={preventDrag}
            />
          </Link>
          <div className="vendorBarIconContainer">
            <img src={websitePageColourIcon} id='changePageColour'
              onClick={() => { setShowBackgroundSelector(true) }}
              onDragStart={preventDrag}
              alt="change the colour of your website" 
              title="change website colour"
            />
            <Link to="/wallet">
              <img src={walletIcon} id='wallet'
                alt="wallet page"
                title="wallet"
                onDragStart={preventDrag}
              />
            </Link>
            <Link to="/dashboard">
              <img src={dashboardIcon} id='dashboard' 
                alt="store dashboard"
                title="store dashboard"
                onDragStart={preventDrag}
              />
            </Link>
            <img src={shareIcon} 
              id='share' 
              alt="share" 
              title="share website link"
              onDragStart={preventDrag}  
              onClick={copyToClipboard}
            />
          </div>
        </div>
      }
      {stickerDeleting && stickerSelected &&
        <div 
          className="deleteStickerDiv"
          onMouseEnter={() => setDeleteTimer(setTimeout(() => deleteSticker(draggedStickerID), 500))}
          onMouseLeave={() => clearTimeout(deleteTimer)}
        >
          <img src={deleteIcon} onDragStart={preventDrag} className="vendorStickerDeleteIcon" />
        </div>
      }
      {stickerSelected && !stickerDeleting &&
        <StickerBar 
          data={stickers}
          vendorBarRef={vendorBarRef}
          selectedObject={selectedObject}
          selectedObjectID={selectedObjectID}
          handleHeightChange={handleHeightChange}
          deleteSticker={deleteSticker}
          setShowZIndexPopup={setShowZIndexPopup}
          showZIndexPopup={showZIndexPopup}
          storeID={storeID}
          storeData={storeData}
          updateStickers={updateStickers}
        />
      }
      {(showFrameBar) && (
        <div ref={frameBarRef}>
          {console.log("Rendering FrameBar")}
          <FrameBar 
            data={frames}
            storeData={storeData}
            selectedFrame={selectedObject}
            selectedObjectID={selectedObjectID}
            setShowFrameBar={setShowFrameBar}
            closeFrameBar={closeFrameBar}
            setShowZIndexPopup={setShowZIndexPopup}
            showZIndexPopup={showZIndexPopup}
            setSelectedFrameStyle={setSelectedFrameStyle}
            storeID={storeID}
            setFrames={setFrames}
            setFrameDesign={setFrameDesign}
            vendorBarRef={vendorBarRef}
            updateFrames={updateFrames}
            selectedFrameStyling={selectedFrameStyling}
            frameBarRef={frameBarRef}
            frameBorderPopupRef={frameBorderPopupRef}
            frameTypesPopupRef={frameTypesPopupRef}
            opacityPopupRef={opacityPopupRef}
            doneBtnDivRef={doneBtnDivRef}
            pickerRef={pickerRef}
          />
        </div>
      )}
      {showImageFrameBar &&
        <div ref={imageFrameBarRef}>
          <ImageFrameBar
            data={frames}
            storeID={storeID}
            storeData={storeData}
            selectedFrame={selectedObject}
            selectedObjectID={selectedObjectID}
            setShowImageFrameBar={setShowImageFrameBar}
            showImageFrameBar={showImageFrameBar}
            vendorBarRef={vendorBarRef}
            updateFrames={updateFrames}
            setShowZIndexPopup={setShowZIndexPopup}
            showZIndexPopup={showZIndexPopup}
            removeFrameImage={removeFrameImage}
            opacityPopupRef={opacityPopupRef}
            selectedFrameStyling={selectedFrameStyling}
            frameBarRef={frameBarRef}
            frameBorderPopupRef={frameBorderPopupRef}
            frameTypesPopupRef={frameTypesPopupRef}
            pickerRef={pickerRef}
            doneBtnDivRef={doneBtnDivRef}
          />
        </div>
      }
      {showZIndexPopup &&
        <FrameDynamics
          frameDynamicsRef={frameDynamicsRef}
          draggableFrameSelected={draggableFrameSelected}
          stretchFrame={stretchFrame}
          setStretchFrame={setStretchFrame}
          selectedObjectID={selectedObjectID}
          setFrames={setFrames}
          setStickers={setStickers}
          setShowZIndexPopup={setShowZIndexPopup}
          marginTopInput={marginTopInput}
          handleMarginTopChange={handleMarginTopChange}
          marginBottomInput={marginBottomInput}
          handleMarginBottomChange={handleMarginBottomChange}
          frames={frames}
          stickers={stickers}
          storeID={storeID}
          frameSelected={frameSelected}
          stickerSelected={stickerSelected}
          currentFrameStretch={selectedObject.stretchFrame}
        />
      }

      <DndProvider backend={HTML5Backend}>
        <div className="framesWrapper" 
          style={{ 
            position: 'relative',
            width: '100%',
            height: '100%' 
          }}
        >

          <Reorder.Group
            axis="y" 
            values={frames}
            onReorder={handleReorder}
            className="usersFramesDiv"
            initial="hidden"
            animate="visible"
            variants={contentVariants}
            layout={!isResizing}
            transition={{ stiffness: 500, damping: 30 }}
            style={{
              pointerEvents: 'none',
              // zIndex: frameSelected ? 1000 : ''
            }}
          >
            <div className="framesContainer" 
              style={{ 
                position: 'absolute',
                width: '100vw', 
                height: '100vh',
                // pointerEvents: 'none',
                top: 0
              }}
            >
              {draggableFrames.map((frame, index) => {
                // Find the corresponding frame styling from newFrameStyling using frameID
                const frameStylingData = newFrameStyling?.find(f => f.frameID === frame.frameID)?.frame_styling;
                
                return (
                  <DraggableFrames 
                    key={`${frame.frameID}-${index}`}
                    index={index}
                    currentFrame={frame}
                    frameStyle={frame.frame_style}
                    initialX={frame.frame_styling.x}
                    initialY={frame.frame_styling.y}
                    zIndex={frame.zIndex}
                    frameID={frame.frameID}
                    storeID={storeID}
                    openFramePopup={openFramePopup}
                    openStickerPopup={openStickerPopup}
                    websiteColor={websiteColor}
                    removeFrame={removeFrame}
                    frames={frames}
                    frameStyling={frameStylingData} // Pass the specific frame styling
                    data={storeData}
                    showLightenSticker={showLightenSticker}
                    noLiftedStyle={noLiftedStyle}
                    liftedStyle={liftedStyle}
                    setLiftedStyle={setLiftedStyle}
                    onFramesLoaded={handleFramesLoaded}
                    handleFrameSelected={handleFrameSelected}
                    closeFrameBar={closeFrameBar}
                    setFrameSelected={setFrameSelected}
                    vendorBarRef={vendorBarRef}
                    frameDynamicsRef={frameDynamicsRef}
                    selectedFrameStyle={selectedFrameStyle}
                    draggableFrameSelected={draggableFrameSelected}
                    setDraggableFrameSelected={setDraggableFrameSelected}
                    updateFrames={updateFrames}
                    selectedObject={selectedObject}
                    moveFrame={moveFrame}
                    setShowImageFrameBar={setShowImageFrameBar}
                    setShowZIndexPopup={setShowZIndexPopup}
                    showZIndexPopup={showZIndexPopup}
                    setShowFrameBar={setShowFrameBar}
                    selectedObjectID={selectedObjectID}
                    isDragging={true}
                    frameBarRef={frameBarRef}
                    imageFrameBarRef={imageFrameBarRef}
                    handleDeleteFunctionality={handleDeleteFunctionality}
                    frameBorderPopupRef={frameBorderPopupRef}
                    draggableFrameRef={draggableFrameRef}
                    frameTypesPopupRef={frameTypesPopupRef}
                    opacityPopupRef={opacityPopupRef}
                    doneBtnDivRef={doneBtnDivRef}
                    elementBarRef={elementBarRef}
                    textPopupRef={textPopupRef}
                    pickerRef={pickerRef}
                  />
                  
                );
              })}
            </div>
            {isFramesDataLoaded && fixedFrames.length === 0 && (
              <div className="plusIconContainer">
                <img 
                  src={plusIcon} 
                  onClick={() => {openFramePopup('newFrame')}} 
                  alt="Add Frame" 
                  className="plusIcon" 
                  style={{ cursor: 'pointer', marginTop: '10px' }}
                  onDragStart={preventDrag}
                />
              </div>
            )}     
            {fixedFrames.map((frame, index) => {
              // Find the corresponding frame styling from newFrameStyling using frameID
              const frameStylingData = newFrameStyling?.find(f => f.frameID === frame.frameID)?.frame_styling;
          
              return (
                <Frames 
                  key={`${frame.frameID}-${index}`}
                  index={index}
                  currentFrame={frame}
                  moveFrame={moveFrame}
                  updateDraggingFramePosition={updateDraggingFramePosition}
                  frameStyle={frame.frame_style}
                  zIndex={frame.zIndex}
                  frameID={frame.frameID}
                  storeID={storeID}
                  openFramePopup={openFramePopup}
                  openStickerPopup={openStickerPopup}
                  websiteColor={websiteColor}
                  removeFrame={removeFrame}
                  frames={frames}
                  frameStyling={frameStylingData} // Pass the specific frame styling
                  draggableFrameSelected={draggableFrameSelected}
                  setDraggableFrameSelected={setDraggableFrameSelected}
                  data={storeData}
                  showLightenSticker={showLightenSticker}
                  noLiftedStyle={noLiftedStyle}
                  liftedStyle={liftedStyle}
                  setLiftedStyle={setLiftedStyle}
                  onFramesLoaded={handleFramesLoaded}
                  handleFrameSelected={handleFrameSelected}
                  closeFrameBar={closeFrameBar}
                  vendorBarRef={vendorBarRef}
                  frameDynamicsRef={frameDynamicsRef}
                  selectedFrameStyle={selectedFrameStyle}
                  updateFrames={updateFrames}
                  selectedObject={selectedObject}
                  setShowImageFrameBar={setShowImageFrameBar}
                  setShowZIndexPopup={setShowZIndexPopup}
                  showZIndexPopup={showZIndexPopup}
                  setShowFrameBar={setShowFrameBar}
                  selectedObjectID={selectedObjectID}
                  isDragging={false}
                  frameBarRef={frameBarRef}
                  imageFrameBarRef={imageFrameBarRef}
                  frameBorderPopupRef={frameBorderPopupRef}
                  handleDeleteFunctionality={handleDeleteFunctionality}
                  setIsResizing={setIsResizing}
                  onResize={(newHeight) => handleFrameResize(frame.frameID, newHeight)}
                  frameTypesPopupRef={frameTypesPopupRef}
                  setFrameWidth={setFrameWidth}
                  draggableFrameRef={draggableFrameRef}
                  opacityPopupRef={opacityPopupRef}
                  doneBtnDivRef={doneBtnDivRef}
                  elementBarRef={elementBarRef}
                  textPopupRef={textPopupRef}
                  pickerRef={pickerRef}
                  stretchFrame={frame.stretchFrame}
                  frameSelected={frameSelected}
                  setFrameSelected={setFrameSelected}
                />
              );
            })}
            {stickers.map((sticker, index) => {
              if (!sticker.isVisible) {
                return null;
              }

              const stickerStylingData = newStickerStyling?.find(obj => obj.stickerID === sticker.stickerID)?.sticker_styling;
              console.log(sticker)
              return (
                <motion.div
                  key={sticker.stickerID}
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  style={{
                    position: 'absolute',
                  }}
                >
                  <Stickers
                    data={sticker}
                    storeID={storeID}
                    stickerID={sticker.stickerID}
                    image={sticker.sticker}
                    xPosition={sticker.x}
                    yPosition={sticker.y}
                    zIndex={sticker.zIndex}
                    height={sticker.height}
                    width={sticker.width}
                    addDeleteStickerPopup={addDeleteStickerPopup}
                    removeDeleteStickerPopup={removeDeleteStickerPopup}
                    draggedStickerInfo={draggedStickerInfo}
                    isVisible={sticker.isVisible}
                    showLightenSticker={showLightenSticker}
                    lightenSticker={lightenSticker}
                    noLiftedStyle={noLiftedStyle}
                    onStickerUpdate={handleStickerUpdate}
                    handleStickerSelected={handleStickerSelected}
                    handleStickerUnselected={handleStickerUnselected}
                    vendorBarRef={vendorBarRef}
                    updateStickerData={updateStickerData}
                    stickerStylingData={stickerStylingData}
                  />
                </motion.div>
              )
            })}
          </Reorder.Group>
        </div>
      </DndProvider>

      <motion.div 
        animate={{ y: animation }}
        onClick={() => {setShowFrameSelector(true)}} 
        className="frameSelector">
          <img src={frameIcon} onDragStart={preventDrag} alt="add a new frame" id='frameIcon'/>
      </motion.div>
      <motion.div 
        animate={{ y: animation }}
        onClick={() => {setShowInfo2(true)}}
        className="stickerSelector">
          <img src={stickerIcon} onDragStart={preventDrag} alt="add a new sticker" id='stickerIcon'/>
      </motion.div>

      <FramePopup 
        show={showFrameSelector} 
        onClickOutside={() => { 
          setShowFrameSelector(false);
          setFrameEvent('newFrame'); // Reset frameEvent after closing
        }}
        userID={userID}
        storeID={storeID}
        addFrame={addFrame}
        frameEvent={frameEvent}
        selectedObjectID={selectedObjectID}
      />
      {showInfo2 &&
        <AssetPopup
          onClickOutside={() => {setShowInfo2(false)}}
          closePopup={() => {setShowInfo2(false)}}
          userID={userID}
          storeID={storeID}
          stickerID={stickerID}
          addSticker={addSticker}
        />
      }
      <WebsiteBackgroundPopup 
        useCase={'websiteColorChange'}
        storeID={storeID}
        closePopup={() => {setShowBackgroundSelector(false)}}
        show={showBackgroundSelector} 
        onClickOutside={() => {setShowBackgroundSelector(false)}} 
        onSelectColor={handleSelectedColor}
        objectColor={previewColor || websiteColor}
        data={storeData}
        pickerRef={pickerRef}
        onDone={handleConfirmColor} 
        onCancel={handleCancelColor}
        setColorPickerOpen={null}
      />
      <Outlet />
    </div>
  )
}
 
export default Vendor