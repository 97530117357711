// src/components/VendorFormContent.jsx

import React, { useContext } from 'react';
import { VendorFormContext } from '../context/VendorFormContext.jsx';
import StepIndicator from './StepIndicator.jsx';

const VendorFormContent = ({ step, next, prev, renderStep, country }) => {
  const { formData } = useContext(VendorFormContext);

  return (
    <>
      <StepIndicator currentStep={step} />
      {country && country !== 'South Africa' && (
        <div className="vendorFormCountryUploadNote">
          Note that product <strong>purchasing is not available in {country} yet, </strong> 
          but feel free to test the app.
        </div>
      )}
      <div className="vendorFormContainer">
        {renderStep()}
      </div>
    </>
  );
};

export default VendorFormContent;
