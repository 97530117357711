import React, { useState, useEffect, useRef, useCallback } from "react";
import { InView } from "react-intersection-observer";
import { addDoc, collection, doc, updateDoc, setDoc, getDoc, getDocs, query, where, onSnapshot, deleteDoc, limit, orderBy, startAfter, startAt } from '@firebase/firestore';
import { auth, db, storage } from '../firebase';
import { onAuthStateChanged } from "firebase/auth";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import './Scout.css';
import ImageGallery from './ImageGallery';
import ScoutCard from './ScoutCard';
import EthicalChecksPopup from "./EthicalChecksPopup";
import ScoutGradient from './ScoutGradient';
import VintageHaze from './VintageHaze';
import paperTexture from '../images/paperTexture.jpg';
import searchIcon from '../icons/searchIcon.png';
import loadIcon from '../icons/scoutIcon.png';
import backArrowIcon from '../icons/backArrow.png';
import verticalArrowIcon from '../icons/verticalArrowIcon.png';
import Login from '../login/Login';
import FilmGrain from './FilmGrain';
import DustEffect from './DustEffect';
import SearchComponent from '../market/SearchComponent'


// ResizeObserver setup
const resizeObserverErrDiv = document.createElement('div');
const observer = new ResizeObserver(() => {});
observer.observe(resizeObserverErrDiv);

const Scout = (props) => {  
  // State declarations
  const [data, setData] = useState([]);
  const [inputTerm, setInputTerm] = useState('');
  const [hasSearchText, setHasSearchText] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [productData, setProductData] = useState([]);
  const [noStores, setNoStores] = useState(false);
  const [activeCategory, setActiveCategory] = useState("All");
  const [noProducts, setNoProducts] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [productNotFound, setProductNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [showLogin, setShowLogin] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [visibleCardIndex, setVisibleCardIndex] = useState(null);
  const [cardRefs, setCardRefs] = useState(Array(data.length).fill(null));
  const [initialCardsFetched, setInitialCardsFetched] = useState(false);
  const scoutDivRef = useRef();
  const scoutBodyRef = useRef();
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [noMoreProducts, setNoMoreProducts] = useState(false);
  const noMoreProductsMessageControl = useAnimation();
  const holdTextDivMessageControl = useAnimation();
  const productInCartAnimation = useAnimation();
  const [showCartMessage, setShowCartMessage] = useState(false);
  const navBarState = props.navBarState;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userID, setUserID] = useState();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [colorArray, setColorArray] = useState([]);
  const cardsContainerRef = useRef(null);
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [showEthicalChecksPopup, setShowEthicalChecksPopup] = useState(false);
  const [showGradient, setShowGradient] = useState(false);
  const [showEffects, setShowEffects] = useState(false);
  const [gradientLoaded, setGradientLoaded] = useState(false);
  const [firstCardLoaded, setFirstCardLoaded] = useState(false);
  const [animationsActive, setAnimationsActive] = useState(true);
  const [showTextDivMessage, setShowTextDivMessage] = useState(false);
  const [infoDivExpanded, setInfoDivExpanded] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState('#FFFFFF');  
  const [websiteBackgroundColor, setWebsiteBackgroundColor] = useState({});

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryThumbnails, setGalleryThumbnails] = useState([]);

  const openGallery = (images, thumbnails) => {
    setGalleryImages(images);
    setGalleryThumbnails(thumbnails);
    setIsGalleryOpen(true);
  };
  
  const closeGallery = () => setIsGalleryOpen(false);

  useEffect(() => {
    document.title = 'Scout | taizte™';
  }, []);

  // Effect: Retrieve website color on mount
  useEffect(() => {
    const storedColor = sessionStorage.getItem('websiteColor');
    if (storedColor) {
      document.body.style.backgroundColor = storedColor;
      sessionStorage.removeItem('websiteColor');
    }
  }, []);

  // Effect: Reset background color on unmount
  useEffect(() => {
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  // Effect: Fetch all store backgrounds when data changes
  useEffect(() => {
    const fetchAllStoreBackgrounds = async () => {
      const allStoreIDs = [...new Set(data.map((item) => item.storeID))];
      const backgroundPromises = allStoreIDs.map(async (id) => {
        try {
          const docSnap = await getDoc(doc(db, "stores", id));
          if (docSnap.exists()) {
            const storeInfo = docSnap.data();
            return { id, color: storeInfo.website_background || "#FFFFFF" };
          }
          return { id, color: "#FFFFFF" };
        } catch (error) {
          console.error(`Error fetching store ${id}:`, error);
          return { id, color: "#FFFFFF" };
        }
      });

      try {
        const backgrounds = await Promise.all(backgroundPromises);
        console.log("Fetched backgrounds:", backgrounds);

        const backgroundMap = {};
        backgrounds.forEach((bg) => {
          backgroundMap[bg.id] = bg.color;
        });

        setWebsiteBackgroundColor(backgroundMap);
      } catch (error) {
        console.error("Error fetching all store backgrounds:", error);
      }
    };

    if (data.length > 0) {
      fetchAllStoreBackgrounds();
    }
  }, [data]);

  // Effect: Update background color based on visible card
  useEffect(() => {
    const fetchCurrentBackground = () => {
      if (
        visibleCardIndex !== null &&
        data[visibleCardIndex] &&
        websiteBackgroundColor
      ) {
        const currentStoreID = data[visibleCardIndex].storeID;
        const newBackgroundColor =
          websiteBackgroundColor[currentStoreID] || "#FFFFFF";

        setIsFading(true);

        const animationDuration = 500; // in milliseconds

        const timeoutId = setTimeout(() => {
          setCurrentBackgroundColor(newBackgroundColor);
          setIsFading(false);
        }, animationDuration);

        return () => clearTimeout(timeoutId);
      }
    };

    if (data.length > 0) {
      fetchCurrentBackground();
    }
  }, [visibleCardIndex, websiteBackgroundColor, data]);

  // Arrow click handlers
  const handleArrowUpClick = () => {
    const container = cardsContainerRef.current;
    if (container && visibleCardIndex > 0) {
      const cardHeight = container.firstChild.offsetHeight;
      container.scrollBy({ top: -cardHeight, behavior: 'smooth' });
    }
  };
  
  const handleArrowDownClick = () => {
    const container = cardsContainerRef.current;
    if (container && visibleCardIndex < data.length - 1) {
      const cardHeight = container.firstChild.offsetHeight;
      container.scrollBy({ top: cardHeight, behavior: 'smooth' });
    }
  };
  
  // Effect: Prevent wheel event propagation
  useEffect(() => {
    const handleWheel = (e) => {
      e.stopPropagation();
    };
  
    const cardsContainerElement = cardsContainerRef.current;
    
    if (cardsContainerElement) {
      cardsContainerElement.addEventListener('wheel', handleWheel);
    }
    
    return () => {
      if (cardsContainerElement) {
        cardsContainerElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  // Debounce utility function
  function debounce(func, wait) {
    let timeout;
    const debounced = (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
    debounced.cancel = () => clearTimeout(timeout);
    return debounced;
  }

  // Authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setShowLogin(false);
        setUserID(user.uid);
        setIsLoggedIn(true);
        setAnimationsActive(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Pause animations when login popup is shown
  useEffect(() => {
    if (showLogin) {
      setAnimationsActive(false);
    }
  }, [showLogin]);

  // Shuffle utility function
  function randomArrayShuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  // Handle dominant color extraction (preserved from original code)
  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  // Initial data fetching (firestore) when no search is active
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const q = query(
          collection(db, "products"), 
          where("status", "==", "published"), 
          where("productSold", "!=", true),
          orderBy("productSold"), // Required ordering for inequality filter
          orderBy("date_added", "desc"),
          limit(20)
        );
        const querySnapshot = await getDocs(q);
        const list = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setData(list);
        setProductData(list); // Ensure 'productData' holds the initial data
        setHasMore(querySnapshot.size === 20);
        setIsLoading(false);
        setShowGradient(true);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };

    if (!searchTerm) {
      fetchInitialData();
    }
  }, [searchTerm]);

  // Load more cards (pagination)
  const loadMoreCards = async () => {
    if (noMoreProducts) {
      return;
    }

    setLoadingMore(true);
    let list = [];
    try {
      let q;
      if (searchTerm) {
        // Since we've removed Firestore search, consider removing or adjusting this
        q = query(
          collection(db, "products"),
          where("title", ">=", searchTerm),
          where("title", "<=", searchTerm + "\uf8ff"),
          where("productSold", "!=", true),
          orderBy("productSold"),
          orderBy("title"),
          startAfter(lastVisibleDoc),
          limit(20)
        );
      } else {
        q = query(
          collection(db, "products"),
          where("status", "!=", "editing"),
          orderBy("date_added", "desc"),
          startAfter(lastVisibleDoc),
          limit(4)
        );
      }
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size < (searchTerm ? 20 : 4)) {
        setNoMoreProducts(true);
        console.log("There are no more products to load");
      }
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      if (searchTerm) {
        setSearchResults([...searchResults, ...list]);
        setData([...data, ...list]);
      } else {
        randomArrayShuffle(list);
        setData([...data, ...list]);
      }
      console.log("More products have been loaded");
      if (!querySnapshot.empty) {
        setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingMore(false);
  };

  const [showNoMoreProducts, setShowNoMoreProducts] = useState(false);

  // Handle card visibility
  const onCardInView = (index) => {
    setVisibleCardIndex(index);
    console.log("Visible card index:", index);
    if (initialCardsFetched && index === data.length - 2 && !loadingMore) {
      loadMoreCards();
    }
  
    if (!auth.currentUser && (index === 3 || index === data.length - 1)) {
      setShowLogin(true);
    }
  
    if (noMoreProducts && index === data.length - 1) {
      setShowNoMoreProducts(true);
    }
  };

  // Effect to hide "No More Products" when login is shown
  useEffect(() => {
    if (showLogin) {
      setShowNoMoreProducts(false);
    }
  }, [showLogin]);

  // Handle already in cart message
  const alreadyInCart = useCallback((alreadyInCartMessage) => {
    setShowCartMessage(alreadyInCartMessage);
    console.log('alreadyInCart called with', alreadyInCartMessage);
    if (alreadyInCartMessage === true) {
      setShowCartMessage(true);
  
      productInCartAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 },
      });
  
      setTimeout(() => {
        productInCartAnimation.start({
          y: -30,
          opacity: 0,
          transition: { duration: 0.5 },
        });
      }, 4000);
    } else {
      setShowCartMessage(false);
    }
  }, [productInCartAnimation]);

  // Handle ethical checks popup
  const [ethicalChecks, setEthicalChecks] = useState();
  const [ethicalChecksText, setEthicalChecksText] = useState('');

  const openEthicalChecksPopup = useCallback((eCheck, eCheckText) => {
    console.log(eCheck, eCheckText);
    setEthicalChecks(eCheck);
    setEthicalChecksText(eCheckText);
    setShowEthicalChecksPopup(true);
  }, []);

  // Effects for gradients and animations
  useEffect(() => {
    if (showGradient) {
      setTimeout(() => {
        setShowEffects(true);
      }, 500);
    }
  }, [showGradient]);

  useEffect(() => {
    if (showGradient && gradientLoaded) {
      setTimeout(() => {
        setFirstCardLoaded(true);
      }, 500); // Add delay before animating cards
    }
  }, [showGradient, gradientLoaded]);

  const handleGradientLoaded = () => {
    setGradientLoaded(true);
  };

  // Preload images
  useEffect(() => {
    const preloadImages = () => {
        const nextCards = data.slice(visibleCardIndex + 1, visibleCardIndex + 3);
        nextCards.forEach(item => {
            const img = new Image();
            img.src = item.images[0]; // Assuming item.images is an array
        });
    };
    if (data.length && visibleCardIndex !== null) {
        preloadImages();
    }
  }, [data, visibleCardIndex]);

  // Effects for showing and hiding messages
  useEffect(() => {
    if (showTextDivMessage) {
      const timer = setTimeout(() => {
        setShowTextDivMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }

    if (showNoMoreProducts) {
      const timer = setTimeout(() => {
        setShowNoMoreProducts(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showTextDivMessage, setShowTextDivMessage, showNoMoreProducts, setShowNoMoreProducts]);

  return (
    <div 
      className="scoutBody" 
      style={{ height: '100vh' }} 
      ref={scoutBodyRef}
    >
      <AnimatePresence>
        {showEffects && animationsActive && !props.transitionStarted && (
          <motion.div
            key="vintageHaze"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <VintageHaze dominantColors={colorArray[visibleCardIndex]} />
          </motion.div>
        )}
      </AnimatePresence>

      {/* AnimatePresence for paperTexture */}
      <AnimatePresence>
        {!props.transitionStarted && (
          <motion.img
            key="paperTexture"
            className="paperTexture"
            src={paperTexture}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          />
        )}
      </AnimatePresence>

      {/* ScoutGradient */}
      <ScoutGradient 
        page={"scout"} 
        dominantColors={colorArray[visibleCardIndex]} 
        isWideScreen={isWideScreen} 
        visible={showGradient} 
        onLoad={handleGradientLoaded} 
        animationsActive={animationsActive}  
        transitionStarted={props.transitionStarted}
        backgroundColor={currentBackgroundColor} 
        isFading={isFading}
      />

      {/* AnimatePresence for FilmGrain */}
      <AnimatePresence>
        {showEffects && animationsActive && !props.transitionStarted && (
          <motion.div
            key="filmGrain"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <FilmGrain />
          </motion.div>
        )}
      </AnimatePresence>

      {/* AnimatePresence for DustEffect */}
      <AnimatePresence>
        {showEffects && animationsActive && !props.transitionStarted && (
          <motion.div
            key="dustEffect"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <DustEffect dustEffectWidth={800} dustEffectHeight={600} />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Scout Header with Search Icon */}
      <motion.div transition={{ duration: 2.5 }} className='scoutHeader' style={{ height: windowHeight }}>
        <div className='searchContainer'>
          <div>
            <div className='searchBar' />
          </div>
          <img 
            src={searchIcon} 
            alt="search icon" 
            id="searchIcon" 
            onClick={() => setIsSearchVisible(true)} // Toggle search visibility
            style={{ cursor: 'pointer' }} // Indicate it's clickable
          />
        </div>

        {/* No More Products Popup */}
        <AnimatePresence>
          {showNoMoreProducts &&
            <motion.div
              className="noMoreProductsPopup"
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -30, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              Sorry there are no more products
            </motion.div>}
        </AnimatePresence>

        {/* Text Div Message */}
        <AnimatePresence>
          {showTextDivMessage &&
            <motion.div
              className="textDivMessage"
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -30, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {infoDivExpanded && 'Longpress the description box to collapse it.'}
              {!infoDivExpanded && 'Longpress the description box to expand it.'}

            </motion.div>}
        </AnimatePresence>
        <AnimatePresence>
          {isGalleryOpen && (
            <ImageGallery 
              image={galleryImages} 
              imageThumbnail={galleryThumbnails}
              closeGallery={closeGallery}
            />
          )}
        </AnimatePresence>
        {/* Cards Container */}
        <motion.div className='scoutDiv' transition={{ duration: 0.1 }} ref={scoutDivRef} style={{ height: windowHeight }}>
          <motion.div className="cardsContainer" style={{ height: windowHeight }} ref={cardsContainerRef}
            initial={{ y: 100, opacity: 0 }}
            animate={firstCardLoaded ? { y: 0, opacity: 1 } : {}}
            transition={{ duration: 1.5, ease: "easeInOut" }} // Ease and duration for smooth transition
          >
            {isLoading ? (
              <div className="scoutLoadingDiv">
                <img src={loadIcon} className="scoutLoadingIcon" alt="loading icon" />
              </div>
            ) : (
              data.map((item, index) => (
                <InView
                  as="div"
                  key={item.id} // Use unique key based on item ID
                  threshold={0.7}
                  rootMargin="200px"
                  onChange={(inView) => {
                    if (inView) {
                      onCardInView(index);
                    }
                  }}
                >
                  <motion.div 
                    className="scoutCardWrapper" 
                    style={{ height: windowHeight }}
                    animate={{ translateY: isWideScreen ? "0px" : (props.navBarState ? "-10px" : "0px") }}
                  >
                    <ScoutCard
                      page={'scout'}
                      showLogin={() => { setShowLogin(true) }}
                      isLoggedIn={isLoggedIn}
                      productID={item.productID}
                      data={item}
                      data_length={data.length}
                      image={item.images}
                      imageThumbnail={item.imageThumbnails}
                      onDominantColor={handleDominantColor}
                      listed_price={item.listed_price}
                      title={item.productName}
                      store={item.store}
                      storeID={item.storeID}
                      description={item.description}
                      category={item.category}
                      index={index}
                      navBarState={props.navBarState}
                      userID={userID}
                      alreadyInCart={alreadyInCart}
                      bundling={item.bundling}
                      isWideScreen={isWideScreen}
                      openEthicalChecksPopup={openEthicalChecksPopup}
                      showTextDivMessage={showTextDivMessage}
                      setShowTextDivMessage={setShowTextDivMessage}
                      infoDivExpanded={infoDivExpanded}
                      setInfoDivExpanded={setInfoDivExpanded}
                      dominantColors={colorArray[visibleCardIndex]} 
                      visible={showGradient} 
                      onLoad={handleGradientLoaded} 
                      animationsActive={animationsActive} 
                      setTransitionStarted={props.setTransitionStarted}
                      websiteBackground={websiteBackgroundColor[item.storeID] || '#FFFFFF'}
                      openGallery={openGallery}
                      setAlertMessage={props.setAlertMessage}
                      setAlertType={props.setAlertType}
                      country={props.country}
                    />
                  </motion.div>
                </InView>
              ))
            )}
          </motion.div>
        </motion.div>
      </motion.div>

      {/* Login Popup */}
      {showLogin &&
        <motion.div
          className="scoutLogin"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <div className="scoutLoginDiv">
            <p className="scoutLoginTextDiv">To <strong>discover more</strong>, please login or create an account.</p>
            <Login show={showLogin} />
          </div>
        </motion.div>
      }

      {/* Wide Screen Arrows */}
      {isWideScreen &&
        <div className="wideScreenArrowContainer">
          <div className="wideScreenArrowDiv">
            <img src={verticalArrowIcon} className={`scoutUpArrowIcon ${visibleCardIndex > 0 ? 'arrow-up-active' : ''}`}
              style={{
                opacity: visibleCardIndex > 0 ? 1 : 0.5,
                cursor: visibleCardIndex > 0 ? 'pointer' : 'default'
              }}
              onClick={handleArrowUpClick} />

            <img src={verticalArrowIcon} className={`scoutDownArrowIcon ${visibleCardIndex < data.length - 1 ? 'arrow-down-active' : ''}`}
              style={{
                opacity: visibleCardIndex < data.length - 1 ? 1 : 0.5,
                cursor: visibleCardIndex < data.length - 1 ? 'pointer' : 'default'
              }}
              onClick={handleArrowDownClick} />
          </div>
        </div>
      }

      {/* Cart Message */}
      {showCartMessage &&
        <motion.div
          className="alreadyInCartDiv"
          initial={{ y: -30, opacity: 0 }}
          animate={productInCartAnimation}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="alreadyInCartDivText">
            The product is already in the cart, to review it go to the cart page
          </div>
        </motion.div>
      }

      {/* Ethical Checks Popup */}
      {showEthicalChecksPopup &&
        <EthicalChecksPopup
          onClickOutside={() => { setShowEthicalChecksPopup(false) }}
          ethicalChecks={ethicalChecks}
          ethicalChecksText={ethicalChecksText}
        />
      }

      {/* Search Overlay */}
      {isSearchVisible && (
        <div className="scoutSearchOverlayContainer">
          <div className="scoutSearchOverlay">
            {/* Back Arrow Button */}
            <button 
              onClick={() => {
                setSearchTerm('');
                setHasSearchText(false);
                setActiveCategory("All");
                setNoProducts(false);
                setProductData(initialData); // Reset to initial data if needed
                setIsSearchVisible(false);
              }} 
              className="scoutBackArrowButton"
            >
              <img 
                src={backArrowIcon} 
                alt="Back" 
                style={{ 
                  width: '24px', 
                  height: '24px' 
                }} 
              />
            </button>

            {/* Search Component */}
            <SearchComponent
              parentComponent={'scout'}
              clearSearch={() => {
                setInputTerm('');
                setHasSearchText(false);
                setActiveCategory("All");
                setNoProducts(false);
                setProductNotFound(false);
                setProductData(initialData); // Reset to initial data
                setSearchTerm('');
                setIsSearchVisible(false);
              }}
              hasSearchText={hasSearchText}
              setProductData={(hits) => {
                setProductData(hits);
                setData(hits); // Ensure 'data' is synchronized with Algolia's results
              }}
              setNoStores={setNoStores}
              setActiveCategory={setActiveCategory}
              setNoProducts={setNoProducts}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setHasSearchText={setHasSearchText}
              setInputTerm={setInputTerm}
              inputTerm={inputTerm}
              setHasMore={setHasMore}
              setProductNotFound={setProductNotFound}
              setIsLoading={setIsLoading}
            />
          </div>
        </div>
      )}
    </div>
  )
};

export default Scout;
