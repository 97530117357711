import { useContext, useState, useEffect, useCallback } from "react";
import { Link, Outlet } from "react-router-dom";

import { onAuthStateChanged } from "firebase/auth";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot } 
from '@firebase/firestore'
import { auth, db, storage } from '../firebase'
import { motion, AnimatePresence, LayoutGroup, useAnimation } from "framer-motion";

import deleteIcon from '../icons/deleteIcon2.png'
import shippingIcon from '../icons/shippingIcon.png'
import './CartProduct.css'

const CartProduct = (props) => {
  const [userID, setUserID] = useState()
  const selectedShippingPrice = (props.selectedShippingPrice)
  const hasNoShipping = !props.selectedShippingName

  onAuthStateChanged(auth, (user) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  })

  useEffect(() => {
    if (props.selectedShippingName === null || !props.selectedShippingName) {
      console.log("The product ", props.productID," has no shipping yet")
    } else {
      console.log("The product ", props.productID," has shipping of: ", props.selectedShippingName)
    }
  }, [props.selectedShippingName, props.productID])

  const deleteProduct = useCallback(async (selectedShippingPrice) => {
    console.log("Deleting")
    console.log(selectedShippingPrice)

      // if there is a value for the shipping
      const cartRef = doc(db, 'cart', userID);
  
      try {
        // Fetch the current cart data
        const cartSnap = await getDoc(cartRef);
        if (cartSnap.exists()) {
          const cartProducts = cartSnap.data().cart;
  
          // Check if the cart will be empty after deletion
          if (cartProducts.length === 1 && cartProducts[0].productID === props.data.productID) {
            // Update cart_total and cartShippingTotal to 0 in Firebase
            await updateDoc(cartRef, {
              cart: arrayRemove(cartProducts[0]),
              cart_total: 0,
              cartShippingTotal: 0
            });
            console.log("Last product removed, cart is now empty.");
          } else {
            // Find the exact product object in the cart
            const productToRemove = cartProducts.find(product => product.productID === props.data.productID);
            if (productToRemove) {
              // Remove the product and keep other cart data as is
              await updateDoc(cartRef, {
                cart: arrayRemove(productToRemove)
              });
              console.log("Product removed from cart");
            } else {
              console.log("Product not found in cart, nothing to remove");
            }
          }
  
          // Re-fetch the updated cart data
          const updatedCartSnap = await getDoc(cartRef);
          if (updatedCartSnap.exists()) {
            const updatedCartProducts = updatedCartSnap.data().cart;
  
            const reformattedCart = updatedCartProducts.reduce((groups, item) => {
              const store = (groups[item.store] || []);
              store.push(item);
              groups[item.store] = store;
              return groups;
            }, {});
  
            console.log(reformattedCart);
            // Pass the updated cart data to props.deletedProduct
            props.deletedProduct(props.productID, selectedShippingPrice, reformattedCart);
            
          } else {
            console.log("Failed to fetch updated cart data");
          }
        } else {
          console.log("No cart document found");
        }
      } catch (err) {
        console.log(err);
      }
      window.location.reload();  //I made a change here - Nikhil
  }, [props.data, props.productID, props.deletedProduct, userID]);
  
  return (
    <div className="cartStoreProductObject">
      <div className="cartStoreProduct" >
        <div className="cartProductPrice">R{props.productPrice}</div>
        <LayoutGroup id="uniqueId">
          <motion.img 
            className="cartStoreProductImage" 
            src={props.productImage} 
            onClick={() => {
              props.expandCardFunction(
                props.productID, props.productImage, props.productPrice, props.productName,
                props.store, props.productDescription, props.productCategory, props.data, 
                props.bundling, props.cart
              )
              console.log("product clicked")
            }}
            layoutId={`productImage-${props.productID}`}
          />
        </LayoutGroup>
        <img className="cartStoreProductDelete" src={deleteIcon} 
        onClick={() => deleteProduct(props.selectedShippingPrice)}

        />
        {hasNoShipping && 
        <div className="shippingNeededButton">
          <img src={shippingIcon} className="shippingNeededIcon" onClick={() => {props.setShowShipping(true)}}></img>
        </div>
        }
      </div>
      
    </div>    
  )
}

export default CartProduct