import React, { useEffect, useState, useRef } from "react";
import { ref, getDownloadURL, getStorage, listAll } from 'firebase/storage'
import { auth, db, storage } from '../../firebase'
import { collection, doc, updateDoc, setDoc, getDoc, onSnapshot, deleteDoc } from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";

import './FramePopup.css'
import infoIcon from '../../icons/infoIcon.png'

export function getFrameID() {
  let title = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 7; i++) {
    title += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  title += new Date().getTime()
  return title
}

export function FramePopup(props) {
  const [isDragging, setIsDragging] = useState(false)
  const framePopupRef = useRef(null);
  const frameInfoRef = useRef()
  const { onClickOutside } = props;
  const [frameID, setFrameID] = useState(getFrameID())

  // code below closes the framePopup when a user clicks outside the framePopup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (framePopupRef.current && !framePopupRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

  const handleFrameOptionClick = async (frameIdentifier) => {
    let frameID = getFrameID();

    if (props.frameEvent === 'shapeChange') {
      // Use the existing frameID for shapeChange
      frameID = props.selectedFrameID;
    }

    await props.addFrame(frameIdentifier, frameID, props.userID, props.storeID, isDragging);
    props.onClickOutside(); // Close the popup
  }

  if(!props.show)
    return null;


  return (
    <div className="framePopup" ref={framePopupRef}>
      <h1 className="frameSelectorHeader">frame selector.</h1>
      <img src={infoIcon} className="framesInfoIcon"
        onClick={() => {
          frameInfoRef.current.style.visibility = "visible"
        }}
      />
      <div className="frameTypeOptionsDiv">
        <div className={`fixedFrameBtn ${!isDragging ? 'Selected' : ''}`} onClick={() => setIsDragging(false)}>
          fixed
        </div>
        <div className={`floatingFrameBtn ${isDragging ? 'Selected' : ''}`} onClick={() => setIsDragging(true)}>
          floating
        </div>      
      </div>
      <div className="frameOptions">
        <div 
          className="frameOption1" 
          onClick={() => handleFrameOptionClick("frame1")}
        />
        <div 
          className="frameOption2" 
          onClick={() => handleFrameOptionClick("frame2")}
        />
        <div 
          className="frameOption3" 
          onClick={() => handleFrameOptionClick("frame3")}
        />
        <div 
          className="frameOption4" 
          onClick={() => handleFrameOptionClick("frame4")}
        />
        <div 
          className="frameOption5" 
          onClick={() => handleFrameOptionClick("frame5")}
        />
        <div 
          className="frameOption6" 
          onClick={() => handleFrameOptionClick("frame6")}
        />
        <div 
          className="frameOption7" 
          onClick={() => handleFrameOptionClick("frame7")}
        />
        <div 
          className="frameOption8" 
          onClick={() => handleFrameOptionClick("frame8")}
        />
        <div 
          className="frameOption9" 
          onClick={() => handleFrameOptionClick("frame9")}
        />
        <div 
          className="frameOption10" 
          onClick={() => handleFrameOptionClick("frame10")}
        />
        <div 
          className="frameOption11" 
          onClick={() => handleFrameOptionClick("frame11")}
        />
        <div 
          className="frameOption12" 
          onClick={() => handleFrameOptionClick("frame12")}
        />
        <div 
          className="frameOption13" 
          onClick={() => handleFrameOptionClick("frame13")}
        />
        <div 
          className="frameOption14" 
          onClick={() => handleFrameOptionClick("frame14")}
        />
        <div 
          className="frameOption15" 
          onClick={() => handleFrameOptionClick("frame15")}
        />
        <div 
          className="frameOption16" 
          onClick={() => handleFrameOptionClick("frame16")}
        />
        <div 
          className="frameOption17" 
          onClick={() => handleFrameOptionClick("frame17")}
        />
        <div 
          className="frameOption18" 
          onClick={() => handleFrameOptionClick("frame18")}
        />
        <div 
          className="frameOption19" 
          onClick={() => handleFrameOptionClick("frame19")}
        />
        <div 
          className="frameOption20" 
          onClick={() => handleFrameOptionClick("frame20")}
        />
        <div 
          className="frameOption21" 
          onClick={() => handleFrameOptionClick("frame21")}
        />
        <div 
          className="frameOption22" 
          onClick={() => handleFrameOptionClick("frame22")}
        />
        <div 
          className="frameOption23" 
          onClick={() => handleFrameOptionClick("frame23")}
        />
        <div 
          className="frameOption24" 
          onClick={() => handleFrameOptionClick("frame24")}
        />
        <div 
          className="frameOption25" 
          onClick={() => handleFrameOptionClick("frame25")}
        />
        <div 
          className="frameOption26" 
          onClick={() => handleFrameOptionClick("frame26")}
        />
        <div 
          className="frameOption27" 
          onClick={() => handleFrameOptionClick("frame27")}
        />
        <div 
          className="frameOption28" 
          onClick={() => handleFrameOptionClick("frame28")}
        />
        <div 
          className="frameOption29" 
          onClick={() => handleFrameOptionClick("frame29")}
        />
        <div 
          className="frameOption30" 
          onClick={() => handleFrameOptionClick("frame30")}
        />
      </div>
      <div className="frameInfo" ref={frameInfoRef}>
        <p className="frameInfoText">frames are used to give your content shape</p>
        <div className="frameExample"></div>
        <p className="frameInfoText">simply click on a frame to select it</p>
        <button className='closeFrameInfoBtn'
          onClick={() => {
            frameInfoRef.current.style.visibility = "hidden"
          }}>
          got it.
        </button>
      </div>
    </div>
  )
}