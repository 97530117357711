import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { getStorage } from 'firebase/storage'
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, addDoc, collection, doc, setDoc } from '@firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAmNSIAOrYYevYJ23Z6-wXnL0w7PgLcdA0",
  authDomain: "boot-strapt.firebaseapp.com",
  databaseURL: "https://boot-strapt-default-rtdb.firebaseio.com",
  projectId: "boot-strapt",
  storageBucket: "boot-strapt.appspot.com",
  messagingSenderId: "189068137058",
  appId: "1:189068137058:web:e1c71904dc670d3241773b",
  measurementId: "G-46DZZCCLXF"
};

const app = initializeApp(firebaseConfig)
const auth = getAuth()
const storage = getStorage(app)
const functions = getFunctions(app)
const db = getFirestore(app)
const analytics = getAnalytics(app)

export { auth, storage, functions, db, analytics }
