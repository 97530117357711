import React, { useEffect, useState, useRef } from "react";
import { ref, getDownloadURL, getStorage, listAll, 
uploadBytesResumable, uploadBytes, deleteObject } 
from 'firebase/storage'
import { auth, db, storage } from '../../firebase'
import { collection, doc, updateDoc, setDoc, getDoc, onSnapshot, deleteDoc } from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4} from "uuid";

import './ImagePopup.css'
import loadIcon from '../../icons/scoutIcon.png'
import plusIcon from '../../icons/plusIcon.png'

const ImagePopup = (props) => {
  const [imageUpload, setImageUpload] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null); // For preview
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [percentage, setPercentage] = useState(0)

  const uploadImage = (file) => {
    if (!file) {
      console.error("No file provided for upload");
      return;
    }
    setIsLoading(true);
    const imageName = file.name + uuidv4();
    const imageRef = ref(storage, `website images/${imageName}`);

    const uploadTask = uploadBytesResumable(imageRef, file);
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPercentage(progress);
        props.setImageProgress(progress)
      }, 
      (error) => {
        console.error("Upload Error:", error);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageUrl(url);
          props.addImageToFrame(url, props.storeID, props.frameID);
          setIsShown(true);
          setIsLoading(false);
        });
      }
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageUpload(file);
      setImagePreviewUrl(URL.createObjectURL(file)); // Create a preview URL
      uploadImage(file); // Optional: start upload immediately
    }
  };

  return (
  <div className="imagePopupDiv">
    <label htmlFor="inputField" className="imagePopupChooseImageButton">
      {props.isImageFrame ? 'change current image' : 'choose an image'}
      <img src={plusIcon} alt="upload image icon" className="imagePopupPlusIcon"/>
    </label>
    <input type="file" id="inputField" onChange={handleImageChange}/>

    {imageUpload === null ?
      <div className="imagePopupNoImage">no image has been selected yet</div>:
      <div></div>
    }

    
    {imagePreviewUrl && 
      <div>
        <div className="imagePreviewContainer">
          <img src={imagePreviewUrl} className="imagePreview" alt="Preview"/>
          {isLoading && (
            <progress className="imageFrameProgressBar" value={percentage} max="100"></progress>
          )}
        </div>
      </div>
    }

    {!isLoading && isShown &&
      <button className="closeImagePopupBtn" 
        onClick={() => {
          props.closeImagePopup()
          props.closePopup()
          setImagePreviewUrl(null); // Optionally clear the preview
          setImageUpload(null);
          setIsShown(false)
        }}
      >
        done
      </button>
    }
  </div>
  )
}

export default ImagePopup