import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef, useCallback } from "react";

import { addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot, deleteDoc, 
  limit, orderBy, startAfter, startAt } 
from '@firebase/firestore'
import { auth, db, storage } from '../firebase'
import { onAuthStateChanged } from "firebase/auth";
import { motion, AnimatePresence, LayoutGroup, useAnimation } from "framer-motion";

import '../scout/ScoutCard.css'
import './MarketProductCard.css'

import shareIcon from '../icons/shareIcon.png'
import deleteIcon from '../icons/deleteIcon.png'
import deleteIcon2 from '../icons/deleteIcon2.png'
import ScoutCard from '../scout/ScoutCard'
import LongPress from '../components/LongPress';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';
import backArrow from '../icons/returnArrowIcon.png'

const MarketProductCard = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userID, setUserID] = useState()
  const [isShown, setIsShown] = useState(false)
  const [colorArray, setColorArray] = useState([])
  const productInCartAnimation = useAnimation()
  const [showCartMessage, setShowCartMessage] = useState(false)
  const [imageGrowthAnimation, setImageGrowthAnimation] = useState()

  const location = useLocation();
  const [isFavouritePage, setIsFavouritePage] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [imageScale, setImageScale] = useState(1)
  const imageRef = useRef(null);

  const longPressTimerRef = useRef(null);
  const productPopupRef = useRef(null)
  const [isPressing, setIsPressing] = useState(false);
  const [pressStartTime, setPressStartTime] = useState(null);
  const [isLongPress, setIsLongPress] = useState(false);
  const deleteIconRef = useRef(null)
  const hoveringDeleteIconRef = useRef(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [infoDivExpanded, setInfoDivExpanded] = useState(false)
  const [showTextDivMessage, setShowTextDivMessage] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      element.addEventListener('mousemove', handleMouseMove);
      return () => {
        element.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize()
    
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  const debounce = (func, delay) => {
    let debounceTimer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    // Delay the attachment of the event listener to ensure the element is present
    const timer = setTimeout(() => {
      const element = containerRef.current 
      if (element) {
        element.addEventListener('mousemove', handleMouseMove);
      } else {
        console.error('Element not found for attaching mousemove event');
      }
    }, 0);
  
    return () => {
      clearTimeout(timer);
      const element = document.querySelector('.your-container-class');
      if (element) {
        element.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);
  
  const handleMouseMove = (event) => {
    const isHovering = deleteIconRef.current && deleteIconRef.current.contains(event.target);
    hoveringDeleteIconRef.current = isHovering;
  };
  
  useEffect(() => {
    if (location.pathname.includes('favourites')) { // Adjust this condition based on your routing
      setIsFavouritePage(true);
    }
  }, [location]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      setIsPressing(true);
      setPressStartTime(Date.now());
      console.log('MouseDown Event Triggered')
      const timer = setTimeout(() => handleLongPress(event), 500); // Adjust this time for long press duration

      const handleMouseLeave = () => {
        clearTimeout(timer);
        setIsLongPress(false);
      };

      event.target.addEventListener('mouseleave', handleMouseLeave);
      event.target.addEventListener('mouseup', handleMouseUp);

      return () => {
        event.target.removeEventListener('mouseleave', handleMouseLeave);
        event.target.removeEventListener('mouseup', handleMouseUp);
        clearTimeout(timer);
      };
    };

    const imgElement = imageRef.current;
    
    if (imageRef.current) {
      imgElement.addEventListener('mousedown', handleMouseDown);
      // ... rest of the useEffect ...
    } else {
      console.log('imageRef is not attached to an element');
    }

    return () => {
      imgElement.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      element.addEventListener('mouseup', handleMouseUp);
    } else {
      console.log('containerRef element not found for attaching mouseup event');
    }
  
    return () => {
      if (element) {
        element.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, []);

  const handleMouseUp = (event) => {
    console.log('MouseUp Event Triggered', 'hoveringDeleteIconRef:', hoveringDeleteIconRef.current, 'isPressing:', isPressing);
    if (hoveringDeleteIconRef.current) {
      console.log('Triggering Delete Confirmation');
      setShowDeleteConfirmation(true);
    }
    // Reset the states as needed
    setShowPopup(false);
    setImageScale(1);
    setIsLongPress(false);
    setIsPressing(false)
  };
  

  const handleLongPress = (event) => {
    console.log('Long Press Detected')
    setIsLongPress(true);
    setShowPopup(true);
    setImageScale(0.95);
  };

  useEffect(() => {
    const checkForLongPress = () => {
      if (isPressing && Date.now() - pressStartTime > 500) {
        setIsLongPress(true);
      }
    };

    let intervalId;
    if (isPressing) {
      intervalId = setInterval(checkForLongPress, 100);
    }

    return () => clearInterval(intervalId);
  }, [isPressing, pressStartTime]);

  const confirmDelete = () => {
    handleDelete(); // Perform the delete action
    setShowDeleteConfirmation(false); // Close the popup after confirming
  };
  
  const cancelDelete = () => {
    setShowDeleteConfirmation(false); // Close the popup without deleting
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (productPopupRef.current && !productPopupRef.current.contains(event.target)) {
        setShowPopup(false);
        setImageScale(1)
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showPopup]); 



  const handleClick = () => {
    if (!isLongPress) {
      setIsShown(true);
      setImageGrowthAnimation(true);
    }
  };

  const handleDelete = () => {
    console.log('Delete action triggered');
    const dbRef = doc(db, 'favourites', userID)
    props.onDeleteProduct(props.productID)
    getDoc(doc(db, 'products', props.productID)).then(productSnap => {
      if (productSnap.exists()) {
        const data = productSnap.data()
        getDoc(dbRef).then(docSnap => {
          if (docSnap.exists()) {
            try {
              // Retrieve the current value of the liked_products array
              const likedProducts = docSnap.data().liked_products;
              const newLikedProducts = likedProducts.filter(product => product.productID !== data.productID);
 
              // Update the liked_products array in the favourites document with the new array
              updateDoc(dbRef, {
                liked_products: newLikedProducts,
              })
            } catch(err) {console.log(err)}
            console.log("product unchecked and removed from favourites document")
          }
        })
      }
    })
    console.log('Product removed from favourites');
  };

  const closeExpandedProductCard = () => {
    setIsShown(false)
  }

  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  const alreadyInCart = useCallback((alreadyInCartMessage) => {
    setShowCartMessage(alreadyInCartMessage);
    console.log('alreadyInCart called with', alreadyInCartMessage)
    if (alreadyInCartMessage == true) {
      setShowCartMessage(true);
  
      // Start the animation
      productInCartAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 },
      });
  
      // Reset the animation after 4 seconds
      setTimeout(() => {
        productInCartAnimation.start({
          y: -30,
          opacity: 0,
          transition: { duration: 0.5 },
        });
      }, 4000);
    } else {
      setShowCartMessage(false);
    }
  }, [productInCartAnimation]);

  useEffect(() => {
    if (showTextDivMessage) {
      const timer = setTimeout(() => {
        setShowTextDivMessage(false);
      }, 3000); // 500ms for fade-in + 1000ms delay before fade-out

      return () => clearTimeout(timer);
    }
  }, [showTextDivMessage, setShowTextDivMessage])

  return (
    <div className="marketProductDiv" ref={containerRef}>
      <div className="marketPrice">R{props.productPrice}</div>
      <LayoutGroup id="uniqueId">
        <div className="marketProductContainer">
          <motion.img 
            ref={imageRef}
            src={props.productImage[0]} 
            className="marketProductImage" 
            id={props.productID} 
            onClick={handleClick}
            animate={{ scale: imageScale }}
            layoutId={`productImage-${props.productID}`}
          />
          {showPopup && (
          <div className="marketProductPopupDiv" ref={productPopupRef}>
            <img className="popupDivShareIcon" src={shareIcon} alt="Share" />
            {isFavouritePage && (
              <img 
                ref={deleteIconRef}
                className="popupDivDeleteIcon"  
                src={deleteIcon2} 
                alt="Remove Product from Favourites"
              />
            )}
          </div>
          )}
        </div>
      </LayoutGroup>
      <AnimatePresence>
        {showTextDivMessage &&
          <motion.div
            className="marketTextDivMessage"
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {infoDivExpanded && 'Longpress the description box to collapse it.'}
            {!infoDivExpanded && 'Longpress the description box to expand it.'}
          </motion.div>
        }
      </AnimatePresence>
      {isShown && ( 
        <div className="marketCardPopupDiv">
          <div className="marketCardPopupContent">
            <div className="marketBackArrow" onClick={closeExpandedProductCard}>
              <div className="marketCardBlurredBackground">
                <img src={backArrow} alt="Close" id="marketBackArrowIcon" />
              </div>
            </div>              
            <ScoutCard 
              page={'market'}
              showLogin={props.showLogin}
              imageGrowthAnimation={imageGrowthAnimation}
              isLoggedIn={isLoggedIn}
              productID={props.productID}
              data={props.data}
              image={props.productImage} 
              imageThumbnail={props.data.imageThumbnails}
              onDominantColor={handleDominantColor}
              listed_price={props.productPrice}
              title={props.productName}
              store={props.store}
              storeID={props.storeID}
              description={props.productDescription}
              category={props.productCategory}
              navBarState={props.navBarState}
              userID={userID}
              bundling={props.bundling}
              alreadyInCart={alreadyInCart}
              isWideScreen={isWideScreen}
              openEthicalChecksPopup={props.openEthicalChecksPopup}
              showTextDivMessage={showTextDivMessage}
              setInfoDivExpanded={setInfoDivExpanded}
              setShowTextDivMessage={setShowTextDivMessage}
              setTransitionStarted={props.setTransitionStarted}
            />
          </div>
        </div>
      )}
      {showCartMessage && 
        <motion.div
          className="alreadyInCartDiv"
          initial={{ y: -30, opacity: 0 }}
          animate={productInCartAnimation}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="alreadyInCartDivText">
            The product is already in the cart, to review it go to the cart page
          </div>
        </motion.div>
      }
      {showDeleteConfirmation && (
        <DeleteConfirmationPopup onConfirm={confirmDelete} onCancel={cancelDelete} />
      )}
    </div>
  )
}

export default MarketProductCard