import React, { useRef, useState, useEffect, useCallback } from "react";
import { motion, useMotionValue } from 'framer-motion'
import { Resizable } from 'react-resizable';
import { throttle } from 'lodash'
import _debounce from 'lodash.debounce';
import './Stickers.css'

import { auth, db, storage } from '../../firebase'
import { onAuthStateChanged } from "firebase/auth";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, query, where, onSnapshot } 
from '@firebase/firestore'
import { ref, getDownloadURL, getStorage, listAll } from 'firebase/storage'

function useWindowSize() {
  const isClient = typeof window === 'object';
  
  const [windowSize, setWindowSize] = useState({
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = _debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 250);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const Stickers = (props) => {
  const size = useWindowSize()
  const [isStickerSelected, setIsStickerSelected] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false)
  const stickerHeight = useMotionValue(250);
  const stickerWidth = useMotionValue(250);
  const stickerRef = useRef()
  const stickerDivRef = useRef()
  const xCord = useMotionValue(props.xPosition)
  const yCord = useMotionValue(props.yPosition)
  const initialSize = useRef({ width: 0, height: 0 });
  const initialMousePosition = useRef({ x: 0, y: 0 });
  const [zIndex, setZIndex] = useState(props.zIndex)
  const [stickerRefStyle, setStickerRefStyle] = useState()
  const tempWidth = useRef(stickerWidth.get());
  const tempHeight = useRef(stickerHeight.get())
  const [isLoading, setIsLoading] = useState(true)
  const [borderRadius, setBorderRadius] = useState('0')

  useEffect(() => {
    retrieveUserStickers()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    setZIndex(props.zIndex);
  }, [props.zIndex]);

  useEffect(() => {
    xCord.set(props.xPosition);
    yCord.set(props.yPosition);
  }, [props.xPosition, props.yPosition, xCord, yCord]);
  
  useEffect(() => {
    stickerHeight.set(props.height);
    stickerWidth.set(props.width);
    if (stickerDivRef.current) {
      stickerDivRef.current.style.width = `${props.width}px`;
      stickerDivRef.current.style.height = `${props.height}px`;
    }
  }, [props.height, props.width, stickerHeight, stickerWidth])

  useEffect(() => {
    if (stickerDivRef.current) {
      stickerDivRef.current.style.width = `${stickerWidth.get()}px`;
      stickerDivRef.current.style.height = `${stickerHeight.get()}px`;
    }
  }, [stickerWidth, stickerHeight]);

  useEffect(() => {
    if (props.stickerStylingData) {
      console.log("The props.stickerStylingData: ", props.stickerStylingData)
      
      setStickerRefStyle(props.stickerStylingData);

      if (props.stickerStylingData.borderTopLeftRadius) {
        const stickerStyling = props.stickerStylingData
        const borderRadiusValues = `${stickerStyling.borderTopLeftRadius} ${stickerStyling.borderTopRightRadius} ${stickerStyling.borderBottomRightRadius} ${stickerStyling.borderBottomLeftRadius}`
        setBorderRadius(borderRadiusValues)
      }
  
      // Apply styling to frameRef and/or other elements
      if (stickerDivRef.current) {
        Object.keys(props.stickerStylingData).forEach(styleKey => {
          stickerDivRef.current.style[styleKey] = props.stickerStylingData[styleKey];
        });
      }
  
      console.log("Sticker styling has been updated, ", props.stickerStylingData);
    }
  }, [props.stickerStylingData]);
  
  const retrieveUserStickers = async() => {
    try {
      await getDoc(doc(db, "stickers", props.stickerID)).then(docSnap => {
        if (docSnap.exists()) {
          const stickerData = docSnap.data();
          console.log(stickerData)
          if (stickerData.sticker_styling) {
            setStickerRefStyle(stickerData.sticker_styling)
            const stickerStyling = stickerData.sticker_styling
            if (stickerStyling.borderTopLeftRadius) {
              const borderRadiusValues = `${stickerStyling.borderTopLeftRadius} ${stickerStyling.borderTopRightRadius} ${stickerStyling.borderBottomRightRadius} ${stickerStyling.borderBottomLeftRadius}`
              setBorderRadius(borderRadiusValues)
            }
          }
          console.log(docSnap.data().zIndex)
          // setZIndex(docSnap.data().zIndex) 
          xCord.set(props.xPosition);
          yCord.set(props.yPosition);

          // Set initial size from Firebase data, or default to 250x250
          const initialWidth = stickerData.width || 250;
          const initialHeight = stickerData.height || 250;
          console.log(initialWidth)

          if (stickerDivRef.current) {
            stickerDivRef.current.style.width = `${initialWidth}px`;
            stickerDivRef.current.style.height = `${initialHeight}px`;
          }

          stickerWidth.set(initialWidth);
          stickerHeight.set(initialHeight);

          // Also update tempWidth and tempHeight refs
          tempWidth.current = initialWidth;
          tempHeight.current = initialHeight;

          console.log(initialHeight, initialWidth)
          
          console.log("Sticker " + props.stickerID + " current position is " + props.xPosition + ", " + props.yPosition)
          if (!props.websiteView) {            
            props.onStickerUpdate()
          }
        } else {
          console.log("The user has no stickers")
        }
      })
    } catch (error) {
      console.error('Error retrieving stickers:', error);
    }
  }

  const updatePosition = async() => {
    if (props.websiteView) return; // Prevent updates

    props.removeDeleteStickerPopup()
    const storeRef = doc(db, 'stores', props.storeID)

    await getDoc(storeRef).then(docSnap => {
      if (docSnap.exists()) {
        const stickers = docSnap.data().stickers
        const currentStickerID = props.stickerID
        const stickerIndex = stickers.findIndex(sticker => sticker.stickerID === currentStickerID)
        
        const updatedSticker = {
          ...stickers[stickerIndex],
          x: xCord.get(),
          y: yCord.get(),
          height: stickerHeight.get(),
          width: stickerWidth.get(),
        }

        const updatedStickers = stickers.map((sticker, index) => {
          if (index === stickerIndex) {
            return updatedSticker;
          }
          return sticker;
        })

        console.log(updatedStickers)

        updateDoc(storeRef, {
          stickers: updatedStickers,
        });

        props.updateStickerData(updatedStickers)
        console.log("data saved to stores document")
      }
    })

    const dbRef = doc(db, "stickers", props.stickerID)
    await getDoc(dbRef).then((docSnap) => {
      if (docSnap.exists()) {
        try {
          updateDoc(dbRef, {
            x: xCord.get(),
            y: yCord.get(),
            height: stickerHeight.get(),
            width: stickerWidth.get(),
          })
          console.log("Sticker " + props.stickerID + " position updated to " + xCord.get() + ", " + yCord.get())
        } catch(err) {console.log(err)}
        
      } else {console.log("The sticker does not exist")}
    })
  }

  const handleResizeMoveNW = (event) => {
    if (isResizing) {
      const dx = event.clientX - initialMousePosition.current.x;
      const dy = event.clientY - initialMousePosition.current.y;
      stickerWidth.set(initialSize.current.width - dx);
      stickerHeight.set(initialSize.current.height - dy);
    }
  }
  
  const handleResizeMoveNE = (event) => {
    if (isResizing) {
      const dx = event.clientX - initialMousePosition.current.x;
      const dy = event.clientY - initialMousePosition.current.y;
      stickerWidth.set(initialSize.current.width + dx);
      stickerHeight.set(initialSize.current.height - dy);
    }
  }
  
  const handleResizeMoveSW = (event) => {
    if (isResizing) {
      const dx = event.clientX - initialMousePosition.current.x;
      const dy = event.clientY - initialMousePosition.current.y;
      stickerWidth.set(initialSize.current.width - dx);
      stickerHeight.set(initialSize.current.height + dy);
    }
  }
  
  const handleResizeMoveSE = (event) => {
    if (isResizing) {
      const dx = event.clientX - initialMousePosition.current.x;
      const dy = event.clientY - initialMousePosition.current.y;
      stickerWidth.set(initialSize.current.width + dx);
      stickerHeight.set(initialSize.current.height + dy);
    }
  }
  
  const [resizeDirection, setResizeDirection] = useState('');

  const handleMouseDown = (event) => {
    if (event.target.classList.contains('react-resizable-handle')) {
      // determine the current direction
      if (event.target.classList.contains('react-resizable-handle-nw')) {
        setResizeDirection('nw');
      } else if (event.target.classList.contains('react-resizable-handle-ne')) {
        setResizeDirection('ne');
      } else if (event.target.classList.contains('react-resizable-handle-sw')) {
        setResizeDirection('sw');
      } else {
        setResizeDirection('se');
      }

      if (props.websiteView) return; // Prevent resizing in websiteView

      setIsResizing(true);
      event.stopPropagation();
    
      // Save the initial size and mouse position.
      initialSize.current.width = stickerWidth.get();
      initialSize.current.height = stickerHeight.get();
      initialMousePosition.current.x = event.clientX;
      initialMousePosition.current.y = event.clientY;
  
      if (event.target.classList.contains('react-resizable-handle-nw')) {
        document.addEventListener('mousemove', handleResizeMoveNW);
      } else if (event.target.classList.contains('react-resizable-handle-ne')) {
        document.addEventListener('mousemove', handleResizeMoveNE);
      } else if (event.target.classList.contains('react-resizable-handle-sw')) {
        document.addEventListener('mousemove', handleResizeMoveSW);
      } else if (event.target.classList.contains('react-resizable-handle-se')) {
        document.addEventListener('mousemove', handleResizeMoveSE);
      }
        
      document.addEventListener('mouseup', handleResizeEnd);
    }
  }
  
  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleResizeMove);
      document.addEventListener('mouseup', handleResizeEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleResizeMove);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, resizeDirection])

  const handleResizeMove = useCallback(throttle((event) => {
    if (!isResizing) return;
  
    const dx = event.clientX - initialMousePosition.current.x;
    const dy = event.clientY - initialMousePosition.current.y;
  
    switch (resizeDirection) {
      case 'nw':
        tempWidth.current = initialSize.current.width - dx;
        tempHeight.current = initialSize.current.height - dy;
        break;
      case 'ne':
        tempWidth.current = initialSize.current.width + dx;
        tempHeight.current = initialSize.current.height - dy;
        break;
      case 'sw':
        tempWidth.current = initialSize.current.width - dx;
        tempHeight.current = initialSize.current.height + dy;
        break;
      case 'se':
        tempWidth.current = initialSize.current.width + dx;
        tempHeight.current = initialSize.current.height + dy;
        break;
      default:
        return;
    }
  
    // Directly update the sticker's size via DOM for visual feedback
    if (stickerDivRef.current) {
      stickerDivRef.current.style.width = `${tempWidth.current}px`;
      stickerDivRef.current.style.height = `${tempHeight.current}px`;
    }
  }, 100), [isResizing, resizeDirection])

  useEffect(() => {
    const resizeMoveHandler = (event) => handleResizeMove(event);
  
    if (isResizing) {
      document.addEventListener('mousemove', resizeMoveHandler);
      document.addEventListener('mouseup', handleResizeEnd);
    }
  
    return () => {
      document.removeEventListener('mousemove', resizeMoveHandler);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, handleResizeMove])
  
  const onDrag = useCallback(throttle((event) => {
    if (props.websiteView) return; // Prevent drag-related actions in websiteView
    
    // Dragging logic here
    props.draggedStickerInfo(props.stickerID);
    if (yCord.get() <= 100) props.addDeleteStickerPopup();
    if (yCord.get() >= 100) props.removeDeleteStickerPopup();
  }, 500), [props.websiteView])

  const handleResizeEnd = () => {
    if (isResizing) {
      setIsResizing(false);
  
      stickerWidth.set(tempWidth.current);
      stickerHeight.set(tempHeight.current);
      updatePosition(); // Your logic to update the position
  
      // Cleanup
      document.removeEventListener('mousemove', handleResizeMove);
      document.removeEventListener('mouseup', handleResizeEnd)
    }
  };

  useEffect(() => {
    // listen for click events on the document
    if (props.websiteView) {
      return
    }

    const handleClickOutside = (event) => {
      if (stickerRef.current && !stickerRef.current.contains(event.target) &&
          (!props.vendorBarRef?.current || !props.vendorBarRef.current.contains(event.target))) {
        // If the click is outside both stickerRef and vendorBarRef (if it exists)
        setIsStickerSelected(false);
        props.handleStickerUnselected(); 
        setZIndex(props.zIndex)
      }
    }    
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [stickerRef, props.vendorBarRef, props.websiteView, props.zIndex]);
 
  const handleStickerClick = (event) => {
    if (props.websiteView) return; // Prevent selection
    props.handleStickerSelected(props.stickerID, xCord, yCord, stickerHeight.get(), stickerWidth.get(), props.zIndex)
    if (event.target.className.includes('react-resizable-handle')) {
      // If the user clicked on a resize handle, don't select the sticker
      return;
    }

    event.stopPropagation();
    setIsStickerSelected(true);
    setIsResizing(false);
    setZIndex(1000)
  }

  const handleDragStart = () => {
    if (props.websiteView) return; // Prevent dragging in websiteView
    setIsDragging(true);
  };
  
  const handleDragEnd = () => {
    if (props.websiteView) return; // Prevent dragging in websiteView
    setIsDragging(false);
    updatePosition(); // Update position at the end of drag
  };

  const handleResizeStart = () => {
    if (props.websiteView) return; // Prevent resizing in websiteView
    setIsResizing(true);
  }

  const handleResizeStop = async (event, data) => {
    if (props.websiteView) return; // Prevent resizing in websiteView
    
    const { width, height } = data.size;
    stickerWidth.set(width);
    stickerHeight.set(height);
    setIsResizing(false);
  
    // Updating individual sticker document
    const dbRef = doc(db, "stickers", props.stickerID);
    const newStickerData = {
      x: xCord.get(),
      y: yCord.get(),
      height: height,
      width: width,
    };
  
    await updateDoc(dbRef, newStickerData);
  
    // Updating store document
    const storeRef = doc(db, 'stores', props.storeID);
    await getDoc(storeRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        const storeData = docSnap.data();
        let updatedStickers = storeData.stickers.map(sticker => {
          if (sticker.stickerID === props.stickerID) {
            return {...sticker, ...newStickerData};
          }
          return sticker;
        });
  
        await updateDoc(storeRef, {
          stickers: updatedStickers
        });
    
        props.updateStickerData(updatedStickers)
      }
    });
  }
  
  console.log(props.lightenSticker)
  return (
    <motion.div 
      className={`stickerDiv ${isStickerSelected ? 'chosen' : ''}`}
      ref={stickerDivRef}
      key={JSON.stringify(size)}
      type="spring"
      style={{ 
        ...stickerRefStyle,
        x: xCord, 
        y: yCord, 
        zIndex: zIndex, 
        position: "absolute",
        width: stickerWidth.get(),
        height: stickerHeight.get(),
      }}
      drag={!props.websiteView && isStickerSelected && !isResizing}
      dragPropagation={true}
      dragMomentum={false}
      dragElastic={0.15}
      transition={{ duration: 0.5 }}
      onDragStart={!props.websiteView ? handleDragStart : null}
      onDragEnd={!props.websiteView ? handleDragEnd : null}
      onDrag={!props.websiteView ? onDrag : null}
    >
      {!isLoading &&
      <Resizable
        width={stickerWidth.get()}
        height={stickerHeight.get()}
        onResizeStart={!props.websiteView ? handleResizeStart : null}
        onResizeStop={!props.websiteView ? handleResizeStop : null}
        resizeHandles={!props.websiteView && isStickerSelected ? ['sw', 'se', 'nw', 'ne'] : []}
        className={`resizable ${!props.websiteView && isStickerSelected ? '' : 'hide-handles'}`}
        onMouseDown={!props.websiteView ? handleMouseDown : null}
      >
        <div 
          className="sticker-container"
          onClick={handleStickerClick}
          style={{
            width: '100%', 
            height: '100%',
            overflow: 'hidden',
            borderRadius: borderRadius
          }}
        >
          <img 
            className="sticker" 
            id={props.stickerID}
            src={props.image} 
            alt="sticker" 
            ref={stickerRef} 
            style={{
              width: '100%', 
              height: '100%', 
              opacity: props.lightenSticker ? '70%' : '100%',
              filter: props.lightenSticker ? 'blur(10px)' : 'none',
              borderRadius: 'inherit',
              pointerEvents: props.websiteView ? 'none' : 'auto' // Disable pointer events when in websiteView
            }}
          />
        </div>
      </Resizable>
      }
    </motion.div>
 )
}

export default Stickers
