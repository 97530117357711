import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './FullScreenPopup.css';
import QRCode from './images/QRCode.jpeg';
import FilmGrain from './scout/FilmGrain'
import MarketGradient from './market/MarketGradient';

const FullScreenPopup = ({ navBarOpen }) => {
  const [isMobile, setIsMobile] = useState(null);

  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [savedDominantColors, setSavedDominantColors] = useState([
    'rgb(197, 201, 198)',
    'rgb(91, 106, 125)',
    'rgb(138, 60, 36)',
    'rgb(210, 136, 55)',
    'rgb(114, 126, 132)'
  ]);

  // Detect screen width to check if it is a mobile-sized screen
  useEffect(() => {
    const handleResize = () => {
      // Set isMobile to true if the screen width is less than or equal to 768px
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Add event listener to update on window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile === null) {
    return null; // Or a loading spinner if preferred
  }
  

  return (
    <>
      <AnimatePresence>
        {/* Render the popup if app is NOT on a mobile phone */}
        {!isMobile && (
          <motion.div 
            className="fullScreenOverlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <canvas className="fullScreenPopupGradientCanvas">
              <MarketGradient 
                page={"market"} 
                dominantColors={savedDominantColors} 
                isWideScreen={isWideScreen}
              />
            </canvas>
            <motion.div
              key="filmGrain"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <FilmGrain />
            </motion.div>
            <motion.div
              className="fullScreenPopup"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <p className="fullScreenPopupText"><strong>taizte</strong> is better experienced <strong>on your phone</strong></p>
              <img src={QRCode} className="QRCode" alt="QR Code" />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FullScreenPopup;
