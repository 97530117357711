import React from 'react';
import { Link } from "react-router-dom";
import plusIcon from '../../icons/plusIcon.png';
import editIcon from '../../icons/editIcon.png';
import deleteIcon2 from '../../icons/deleteIcon2.png';
import blankImage from '../../images/blankImage.png';
import './Dashboard.css'
import './StoreUploadedProducts.css'

const StoreUploadedProducts = ({
  storeName,
  publishedProducts,
  editingProducts,
  showPublishedProducts,
  showEditingProducts,
  setShowPublishedProducts,
  setShowEditingProducts,
  handleProductClick,
  handleEditProduct,
  deleteProduct,
  longPressEvent
}) => {
  return (
    <div className="storeUploadedProducts">
      <Link to="/addproduct">
        <img className="uploadNewProduct" src={plusIcon} alt="Upload New Product" />
      </Link>
      <div className="storeUploadedProductsHeader">products</div>
      <div className="productOptionsButtonsDiv">
        <div
          className={`productsPublishedBtn${showPublishedProducts ? 'Active' : ''}`}
          onClick={() => {
            setShowPublishedProducts(true);
            setShowEditingProducts(false);
          }}
        >
          published
        </div>
        <div
          className={`productsEditingBtn${showEditingProducts ? 'Active' : ''}`}
          onClick={() => {
            setShowEditingProducts(true);
            setShowPublishedProducts(false);
          }}
        >
          editing
        </div>
      </div>

      {/* Conditional Rendering for Published Products */}
      {showPublishedProducts && publishedProducts.length === 0 && (
        <div className="dashboardNoProducts">No products uploaded yet</div>
      )}
      {showPublishedProducts && publishedProducts.length > 0 && (
        publishedProducts.map((publishedProduct) => (
          <div className="storesProducts" {...longPressEvent} key={publishedProduct.productID}>
            <div className="storesProductsSection1">
              <img
                src={publishedProduct.imageThumbnails?.[0] || blankImage}
                className="storesProductImg"
                onClick={() => handleProductClick(publishedProduct)}
                alt={publishedProduct.productName}
              />
              <div className="storesProductInfo">
                <div className="storesProductName">{publishedProduct.productName}</div>
                <div className="storesProductPrice">R{publishedProduct.listed_price}</div>
              </div>
            </div>
            <div className="storesProductsSection2">
              <div className="storesProductQuantity">{publishedProduct.quantity}</div>
              <img
                className="editProductIcon"
                src={editIcon}
                onClick={() => handleEditProduct(publishedProduct)}
                alt="edit product"
              />
              <img
                src={deleteIcon2}
                className="deleteProductIcon"
                onClick={() => deleteProduct(publishedProduct, publishedProduct.productName, storeName)}
                alt="delete product"
              />
            </div>
          </div>
        ))
      )}

      {/* Conditional Rendering for Editing Products */}
      {showEditingProducts && editingProducts.length === 0 && (
        <div className="dashboardNoProducts">No products are currently being edited</div>
      )}
      {showEditingProducts && editingProducts.length > 0 && (
        editingProducts.map((product) => (
          <div className="storesProducts" {...longPressEvent} key={product.productID}>
            <div className="editStoresProductsSection1">
              <img
                src={product.imageThumbnails?.[0] || blankImage}
                className="storesProductImg"
                onClick={() => handleProductClick(product)}
                alt={product.productName || 'Untitled'}
              />
              <div className="storesProductInfo">
                <div className="storesProductName">{product.productName || 'No name set'}</div>
                <div className="storesProductPrice">R{product.listed_price || 0}</div>
              </div>
            </div>
            <div className="storesProductsSection2">
              <div className="storesProductQuantity">{product.quantity || 0}</div>
              <img
                className="editProductIcon"
                src={editIcon}
                onClick={() => handleEditProduct(product)}
                alt="edit product"
              />
              <img
                src={deleteIcon2}
                className="deleteProductIcon"
                onClick={() => deleteProduct(product, product.productName || 'Untitled', storeName)}
                alt="delete product"
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default StoreUploadedProducts;
