import React, { useEffect, useState, useRef, useCallback } from "react";
import { 
  ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, uploadBytes, deleteObject } 
from 'firebase/storage'
import { auth, db, storage } from '../../firebase'
import { 
  collection, doc, updateDoc, setDoc, getDoc, 
  onSnapshot, deleteDoc } 
from '@firebase/firestore'

import './ColourSquares.css'
import './TextPopup.css'
import './Fonts.css'

const TextPopup = (props) => {
  const [selectedFont, setSelectedFont] = useState('Poppins')
  const [optionText, setOptionText] = useState('')
  const selectRef = useRef()

  useEffect(() => {
    console.log('TextPopup mounted with ref:', props.textPopupRef.current);
    const popupWidth = props.textPopupRef.current.offsetWidth;
    console.log(popupWidth);


    // Call the callback function to pass popupWidth to the parent
    if (props.onPopupWidthChange) {
      console.log("running")
      props.onPopupWidthChange(popupWidth);
    }

    return () => {
      console.log('TextPopup unmounted');
    };
  }, [props.onPopupWidthChange])

  useEffect(() => {
    if (props.fontFullName) {
    const fontValue = props.fontFullName
    // Use the getOptionText function to get the text of 
    // the option with the value of fontValue
    const optionText = getOptionText(fontValue);
    setOptionText(optionText)
    } else {
      setOptionText('Poppins')
    }
  });

  const getOptionText = (value) => {
    if (selectRef.current) {
      const optionElement = selectRef.current.querySelector(`option[value="${value}"]`);
      // Check if the option element exists
      if (optionElement) {
        // Return the text of the option element
        return optionElement.text;
      } else {
        // Return an empty string if the option element does not exist
        return '';
      }
    } else {
      // Return an empty string if the select element has not been rendered
      return '';
    }
  
  }

  // useEffect(() => {
  //   if(selectRef.current) {
  //     const options = selectRef.current.options;
      
  //     for (let i = 0; i < options.length; i++) {
  //       const optionRect = options[i].getBoundingClientRect();
  //       // console.log(optionRect)
  //       const strippedFontName = (options[i].value.split('.')[0])
        
  //         options[i].current.style.fontFamily = `${strippedFontName}`
  //         // console.log(options[3].current.style.fontFamily)
        
  //     }
  //   }
  // }, [selectRef]);

  const handlePopupClick = (e) => {
    e.stopPropagation(); // Prevent event from propagating to parent elements

    // Call the passed callback function
    if (props.onPopupClick) {
      props.onPopupClick(e);
    }
  }
  
  return (
    <div className={`textPopupFullSection${(props.parentComponent === 'draggableFrames') ? 'DraggableFrames' : ''}`} onClick={handlePopupClick} ref={props.textPopupRef}>
      <div>
      <div className="textPopupDiv">
        <div className="textPopupBottomSection">
          <div 
            className="fontColourSelector" 
            onClick={(e) => {
              e.stopPropagation()
              props.setShowTextColorPopup(!props.showTextColorPopup)
            }}
          >
            <div 
              className="currentTextColourOption" 
              alt="choose font colour" 
              style={{ backgroundColor: props.selectedFontColor}}
            />
          </div>
          <select  
            className="fontSelector" 
            name="fonts" 
            ref={selectRef}
            onChange={(e) => {
              setSelectedFont(e.target.value)
              props.setFontFullName(e.target.value)
              props.selectedFont(e.target.value)
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <option>{optionText}</option>
            <option value="Poppins">Poppins</option>
            <option value="abigateDesgo.ttf" style={{ fontFamily: 'abigateDesgo' }}>Abigate Desgo</option>
            <option value="abingtonBoldItalic.ttf" style={{ fontFamily: 'abingtonBoldItalic' }}>Abington</option>
            <option value="aeternus_nano_thin_static.otf" style={{ fontFamily: 'aeternus_nano_thin_static' }}>Aeternus Nano</option>
            <option value="ActuallyGrotesk-Regular.otf" style={{ fontFamily: 'ActuallyGrotesk-Regular' }}>Actually Grotesk</option>
            <option value="Ageya.ttf" style={{ fontFamily: 'Ageya' }}>Ageya</option>
            <option value="AgfiustorFree.ttf" style={{ fontFamily: 'AgfiustorFree' }}>Agfiustor</option>
            <option value="Aion-Bold.otf" style={{ fontFamily: 'Aion-Bold' }}>Aion-Bold</option>
            <option value="Aoudax-Display.otf" style={{ fontFamily: 'Aoudax-Display' }}>Aoudax</option>
            <option value="ApriAsh_Extended.otf" style={{ fontFamily: 'ApriAsh_Extended' }}>ApriAsh Extended</option>
            <option value="Avalancheno.ttf" style={{ fontFamily: 'Avalancheno' }}>Avalancheno</option>
            <option value="Bagias.ttf" style={{ fontFamily: 'Bagias' }}>Bagias</option>
            <option value="BarnebokBold.ttf" style={{ fontFamily: 'BarnebokBold' }}>Barnebok</option>
            <option value="Baymanat.ttf" style={{ fontFamily: 'Baymanat' }}>Baymanat</option>
            <option value="Behila.ttf" style={{ fontFamily: 'Behila' }}>Behila</option>
            <option value="Berzulis-GAILA-Regular.ttf" style={{ fontFamily: 'Berzulis-GAILA-Regular' }}>Berzulis GAILA</option>
            <option value="bilboinc-webfont.ttf" style={{ fontFamily: 'bilboinc-webfont' }}>Bilboinc</option>
            <option value="BipHop.otf" style={{ fontFamily: 'BipHop' }}>BipHop</option>
            <option value="Black_M.otf" style={{ fontFamily: 'Black_M' }}>Black M</option>
            <option value="Blocus.otf" style={{ fontFamily: 'Blocus' }}>Blocus</option>
            <option value="blup-Regular_final.ttf" style={{ fontFamily: 'blup-Regular_final' }}>Blup</option>
            <option value="Boeticher-Roman.otf" style={{ fontFamily: 'Boeticher-Roman' }}>Boeticher</option>
            <option value="Bogam.ttf" style={{ fontFamily: 'Bogam' }}>Bogam</option>
            <option value="Bolgarus.otf" style={{ fontFamily: 'Bolgarus' }}>Bolgarus</option>
            <option value="Bolinger.ttf" style={{ fontFamily: 'Bolinger' }}>Bolinger</option>
            <option value="Boxer.otf" style={{ fontFamily: 'Boxer' }}>Boxer</option>
            <option value="Broom.otf" style={{ fontFamily: 'Broom' }}>Broom</option>
            <option value="celattinfont.ttf" style={{ fontFamily: 'celattinfont' }}>Celattin</option>
            <option value="Chaumont.otf" style={{ fontFamily: 'Chaumont' }}>Chaumont</option>
            <option value="Circle.ttf" style={{ fontFamily: 'Circle' }}>Circle</option>
            <option value="CitationRegular.ttf" style={{ fontFamily: 'CitationRegular' }}>Citation</option>
            <option value="Collision-Regular.otf" style={{ fontFamily: 'Collision-Regular' }}>Collision</option>
            <option value="Compute.otf" style={{ fontFamily: 'Compute' }}>Compute</option>
            <option value="Coqnegre.otf" style={{ fontFamily: 'Coqnegre' }}>Coqnegre</option>
            <option value="CuteChars.otf" style={{ fontFamily: 'CuteChars' }}>CuteChars</option>
            <option value="DAMN.ttf" style={{ fontFamily: 'DAMN' }}>DAMN</option>
            <option value="Danzantetypeface-Regular.ttf" style={{ fontFamily: 'Danzantetypeface-Regular' }}>Danzante</option>
            <option value="davidcarson-Regular.otf" style={{ fontFamily: 'davidcarson-Regular' }}>David Carson</option>
            <option value="Derojela.ttf" style={{ fontFamily: 'Derojela' }}>Derojela</option>
            <option value="Distrikt.otf" style={{ fontFamily: 'Distrikt' }}>Distrikt</option>
            <option value="DonorType-Regular.otf" style={{ fontFamily: 'DonorType-Regular' }}>DonorType</option>
            <option value="DRUZHOK.otf" style={{ fontFamily: 'DRUZHOK' }}>DRUZHOK</option>
            <option value="DTRandomDisplay-Regular.otf" style={{ fontFamily: 'DTRandomDisplay-Regular' }}>DT Random Display</option>
            <option value="Elektron.otf" style={{ fontFamily: 'Elektron' }}>Elektron</option>
            <option value="EngageRegular.ttf" style={{ fontFamily: 'EngageRegular' }}>Engage</option>
            <option value="eunoia.ttf" style={{ fontFamily: 'eunoia' }}>Eunoia</option>
            <option value="Excelsiorama-Regular.ttf" style={{ fontFamily: 'Excelsiorama-Regular' }}>Excelsiorama</option>
            <option value="Experitypo5-MOxv.ttf" style={{ fontFamily: 'Experitypo5-MOxv' }}>Experitypo</option>
            <option value="fabuliste.otf" style={{ fontFamily: 'fabuliste' }}>Fabuliste</option>
            <option value="Fantome-Regular.otf" style={{ fontFamily: 'Fantome-Regular' }}>Fantome</option>
            <option value="Fashionable.ttf" style={{ fontFamily: 'Fashionable' }}>Fashionable</option>
            <option value="Firenze.otf" style={{ fontFamily: 'Firenze' }}>Firenze</option>
            <option value="Five.otf" style={{ fontFamily: 'Five' }}>Five</option>
            <option value="FleuronMixed.otf" style={{ fontFamily: 'FleuronMixed' }}>Fleuron-Mixed</option>
            <option value="FramesPartOne-Bold.otf" style={{ fontFamily: 'FramesPartOne-Bold' }}>Frames</option>
            <option value="free_jazz.otf" style={{ fontFamily: 'free_jazz' }}>Jazz</option>
            <option value="Galgo.ttf" style={{ fontFamily: 'Galgo' }}>Galgo</option>
            <option value="GAWKER-Regular.otf" style={{ fontFamily: 'GAWKER-Regular' }}>GAWKER</option>
            <option value="Geek.otf" style={{ fontFamily: 'Geek' }}>Geek</option>
            <option value="GENTODREAM.ttf" style={{ fontFamily: 'GENTODREAM' }}>GENTODREAM</option>
            <option value="Geodesic-v1.otf" style={{ fontFamily: 'Geodesic-v1' }}>Geodesic</option>
            <option value="Giga.otf" style={{ fontFamily: 'Giga' }}>Giga</option>
            <option value="Git-Sans.ttf" style={{ fontFamily: 'Git-Sans' }}>Git-Sans</option>
            <option value="Goat.ttf" style={{ fontFamily: 'Goat' }}>Goat</option>
            <option value="GoldenMetaforRegular.ttf" style={{ fontFamily: 'GoldenMetaforRegular' }}>Golden Metafor</option>
            <option value="Gorki.ttf" style={{ fontFamily: 'Gorki' }}>Gorki</option>
            <option value="Gourmandise.ttf" style={{ fontFamily: 'Gourmandise' }}>Gourmandise</option>
            <option value="Grey_Charles.otf" style={{ fontFamily: 'Grey_Charles' }}>Grey Charles</option>
            <option value="Grotesk.otf" style={{ fontFamily: 'Grotesk' }}>Grotesk</option>
            <option value="GTEAPAC.ttf" style={{ fontFamily: 'GTEAPAC' }}>GTEAPAC</option>
            <option value="Gulax-Regular.otf" style={{ fontFamily: 'Gulax-Regular' }}>Gulax</option>
            <option value="H2ODisplay.otf" style={{ fontFamily: 'H2ODisplay' }}>H2O</option>
            <option value="Hallucinda-Variable.ttf" style={{ fontFamily: 'Hallucinda-Variable' }}>Hallucinda</option>
            <option value="HCGleam-Reg.otf" style={{ fontFamily: 'HCGleam-Reg' }}>HCGleam</option>
            <option value="Hemingway-Regular.otf" style={{ fontFamily: 'Hemingway-Regular' }}>Hemingway</option>
            <option value="Hemis.otf" style={{ fontFamily: 'Hemis' }}>Hemis</option>
            <option value="Histeria.ttf" style={{ fontFamily: 'Histeria' }}>Histeria</option>
            <option value="hngl.otf" style={{ fontFamily: 'hngl' }}>Hngl</option>
            <option value="Ikapito.otf" style={{ fontFamily: 'Ikapito' }}>Ikapito</option>
            <option value="Ink.otf" style={{ fontFamily: 'Ink' }}>Ink</option>
            <option value="Inky.otf" style={{ fontFamily: 'Inky' }}>Inky</option>
            <option value="Intervals_Variable.ttf" style={{ fontFamily: 'Intervals_Variable' }}>Intervals</option>
            <option value="Jasper.otf" style={{ fontFamily: 'Jasper' }}>Jasper</option>
            <option value="Jax.otf" style={{ fontFamily: 'Jax' }}>Jax</option>
            <option value="Jester-00-FOOL.otf" style={{ fontFamily: 'Jester-00-FOOL' }}>Jester-FOOL</option>
            <option value="Jester-08-STRENGTH.otf" style={{ fontFamily: 'Jester-08-STRENGTH' }}>Jester-STRENGTH</option>
            <option value="Jimmy.otf" style={{ fontFamily: 'Jimmy' }}>Jimmy</option>
            <option value="Jolene.otf" style={{ fontFamily: 'Jolene' }}>Jolene</option>
            <option value="kaerukaeru-Regular.otf" style={{ fontFamily: 'kaerukaeru-Regular' }}>Kaerukaeru</option>
            <option value="kalmunt.ttf" style={{ fontFamily: 'kalmunt' }}>Kalmunt</option>
            <option value="kandinsky.ttf" style={{ fontFamily: 'kandinsky' }}>Kandinsky</option>
            <option value="Kegisiko.ttf" style={{ fontFamily: 'Kegisiko' }}>Kegisiko</option>
            <option value="KIK-Regular.ttf" style={{ fontFamily: 'KIK-Regular' }}>KIK</option>
            <option value="KUPOLE-Regular.ttf" style={{ fontFamily: 'KUPOLE-Regular' }}>KUPOLE</option>
            <option value="KyivTypeSans-Heavy.otf" style={{ fontFamily: 'KyivTypeSans-Heavy' }}>Kyiv Heavy</option>
            <option value="KyivTypeSans-Thin.otf" style={{ fontFamily: 'KyivTypeSans-Thin' }}>Kyiv Thin</option>
            <option value="KyivTypeTitling-VarGX.ttf" style={{ fontFamily: 'KyivTypeTitling-VarGX' }}>Kyiv Title</option>
            <option value="KYLTYRE-TECHNICAL.otf" style={{ fontFamily: 'KYLTYRE-TECHNICAL' }}>KYLTYRE-TECHNICAL</option>
            <option value="Lment2.otf" style={{ fontFamily: 'Lment2' }}>Lment2</option>
            <option value="Lobular-Regular.otf" style={{ fontFamily: 'Lobular-Regular' }}>Lobular</option>
            <option value="lorean.otf" style={{ fontFamily: 'lorean' }}>Lorean</option>
            <option value="MangoGrotesque-Bold.ttf" style={{ fontFamily: 'MangoGrotesque-Bold' }}>Mango Grotesque Bold</option>
            <option value="MangoGrotesque-Light.ttf" style={{ fontFamily: 'MangoGrotesque-Light' }}>Mango Grotesque Light</option>
            <option value="MangoGrotesque-Regular.ttf" style={{ fontFamily: 'MangoGrotesque-Regular' }}>Mango Grotesque Regular</option>
            <option value="Martin_Fek_Thirtysixdays.otf" style={{ fontFamily: 'Martin_Fek_Thirtysixdays' }}>Martin Fek - 36 days</option>
            <option value="Mash-Ultrabold.otf" style={{ fontFamily: 'Mash-Ultrabold' }}>Mash Ultrabold</option>
            <option value="Mash-VariableVF.ttf" style={{ fontFamily: 'Mash-VariableVF' }}>Mash Regular</option>
            <option value="Megare.otf" style={{ fontFamily: 'Megare' }}>Megare</option>
            <option value="MegastaSignateriaSignature.ttf" style={{ fontFamily: 'MegastaSignateriaSignature' }}>Megasta Signateria</option>
            <option value="Metamorphosis.otf" style={{ fontFamily: 'Metamorphosis' }}>Metamorphosis</option>
            <option value="Metropolis.otf" style={{ fontFamily: 'Metropolis' }}>Metropolis</option>
            <option value="MGGeometricColor-NoColor.otf" style={{ fontFamily: 'MGGeometricColor-NoColor' }}>MG-Geometric</option>
            <option value="MGZebra.otf" style={{ fontFamily: 'MGZebra' }}>MG-Zebra</option>
            <option value="MinosBold.otf" style={{ fontFamily: 'MinosBold' }}>Minos Bold</option>
            <option value="MinosRegular.otf" style={{ fontFamily: 'MinosRegular' }}>Minos Regular</option>
            <option value="MoaiFreebie-Regular2400.otf" style={{ fontFamily: 'MoaiFreebie-Regular2400' }}>Moai</option>
            <option value="MolluscaScript.ttf" style={{ fontFamily: 'MolluscaScript' }}>Mollusca</option>
            <option value="MonkegRegular.ttf" style={{ fontFamily: 'MonkegRegular' }}>Monkeg</option>
            <option value="Montchauve.otf" style={{ fontFamily: 'Montchauve' }}>Montchauve</option>
            <option value="MudClub-FranklintheGoth.otf" style={{ fontFamily: 'MudClub-FranklintheGoth' }}>Mud Club-Franklin the Goth</option>
            <option value="Munsteria.ttf" style={{ fontFamily: 'Munsteria' }}>Munsteria</option>
            <option value="mx-salamander.ttf" style={{ fontFamily: 'mx-salamander' }}>MX-Salamander</option>
            <option value="Neoptera-Regular.ttf" style={{ fontFamily: 'Neoptera-Regular' }}>Neoptera</option>
            <option value="Normant.otf" style={{ fontFamily: 'Normant' }}>Normant</option>
            <option value="nuitBlanche.otf" style={{ fontFamily: 'nuitBlanche' }}>Nuit Blanche</option>
            <option value="NuMonoFree.otf" style={{ fontFamily: 'NuMonoFree' }}>NuMono</option>
            <option value="NW.otf" style={{ fontFamily: 'NW' }}>NW</option>
            <option value="Oceanus_Variable.ttf" style={{ fontFamily: 'Oceanus_Variable' }}>Oceanus</option>
            <option value="outward-block.ttf" style={{ fontFamily: 'outward-block' }}>Outward-Block</option>
            <option value="Pierrafeu.otf" style={{ fontFamily: 'Pierrafeu' }}>Pierrafeu</option>
            <option value="Pilowlava-Regular.otf" style={{ fontFamily: 'Pilowlava-Regular' }}>Pilowlava</option>
            <option value="PsychicFortunes.ttf" style={{ fontFamily: 'PsychicFortunes' }}>Psychic Fortunes Regular</option>
            <option value="Qegor.ttf" style={{ fontFamily: 'Qegor' }}>Qegor</option>
            <option value="Quarantine.otf" style={{ fontFamily: 'Quarantine' }}>Quarantine</option>
            <option value="Radikal.otf" style={{ fontFamily: 'Radikal' }}>Radikal</option>
            <option value="Ramka-Bold.otf" style={{ fontFamily: 'Ramka-Bold' }}>Ramka Bold</option>
            <option value="Ramka.otf" style={{ fontFamily: 'Ramka' }}>Ramka Regular</option>
            <option value="RemboyRegular.ttf" style={{ fontFamily: 'RemboyRegular' }}>Remboy</option>
            <option value="ROLLERALIKA-DemiBold.ttf" style={{ fontFamily: 'ROLLERALIKA-DemiBold' }}>ROLLERALIKA</option>
            <option value="Rotunda.otf" style={{ fontFamily: 'Rotunda' }}>Rotunda</option>
            <option value="Rubik-SeaCamouflage-Regular.ttf" style={{ fontFamily: 'Rubik-SeaCamouflage-Regular' }}>Rubik-SeaCamouflage</option>
            <option value="Semicir.otf" style={{ fontFamily: 'Semicir' }}>Semicir</option>
            <option value="Slang.ttf" style={{ fontFamily: 'Slang' }}>Slang</option>
            <option value="Soulcraft.ttf" style={{ fontFamily: 'Soulcraft' }}>Soulcraft</option>
            <option value="SpaceCowgirl-Bold.otf" style={{ fontFamily: 'SpaceCowgirl-Bold' }}>Space Cowgirl</option>
            <option value="SpicyCaps.otf" style={{ fontFamily: 'SpicyCaps' }}>SpicyCaps</option>
            <option value="Spirt-O_font_beta.ttf" style={{ fontFamily: 'Spirt-O_font_beta' }}>Spirt-O</option>
            <option value="StarBlackInline.ttf" style={{ fontFamily: 'StarBlackInline' }}>Star Black Inline</option>
            <option value="StraratEleganteFont.ttf" style={{ fontFamily: 'StraratEleganteFont' }}>Strarat Elegante</option>
            <option value="StreetSignSans.otf" style={{ fontFamily: 'StreetSignSans' }}>Streetsign</option>
            <option value="Suspicion_Variable.ttf" style={{ fontFamily: 'Suspicion_Variable' }}>Suspicion</option>
            <option value="terminal-grotesque.ttf" style={{ fontFamily: 'terminal-grotesque' }}>Terminal-Grotesque</option>
            <option value="therow.otf" style={{ fontFamily: 'therow' }}>Therow</option>
            <option value="Thunder-BoldHC.ttf" style={{ fontFamily: 'Thunder-BoldHC' }}>Thunder</option>
            <option value="Turismo.otf" style={{ fontFamily: 'Turismo' }}>Turismo</option>
            <option value="Typefesse_Claire-Obscure.otf" style={{ fontFamily: 'Typefesse_Claire-Obscure' }}>Typefesse-Claire</option>
            <option value="Typefesse_Pleine.otf" style={{ fontFamily: 'Typefesse_Pleine' }}>Typefesse-Pleine</option>
            <option value="ultraprint.otf" style={{ fontFamily: 'ultraprint' }}>Ultraprint</option>
            <option value="vasilek.otf" style={{ fontFamily: 'vasilek' }}>Vasilek</option>
            <option value="VTF_Mixo.otf" style={{ fontFamily: 'VTF_Mixo' }}>VTF Mixo</option>
            <option value="wakile.ttf" style={{ fontFamily: 'wakile' }}>Wakile</option>
            <option value="WARNING.otf" style={{ fontFamily: 'WARNING' }}>WARNING</option>
            <option value="WataFreeFont.otf" style={{ fontFamily: 'WataFreeFont' }}>WATA</option>
            <option value="WavefontBetaV001-Regular.otf" style={{ fontFamily: 'WavefontBetaV001-Regular' }}>Wave</option>
            <option value="Yunke-Regular.otf" style={{ fontFamily: 'Yunke-Regular' }}>Yunke</option>
            <option value="ZALTYS-Regular.ttf" style={{ fontFamily: 'ZALTYS-Regular' }}>ZALTYS</option>
            <option value="Zimra-Regular.otf" style={{ fontFamily: 'Zimra-Regular' }}>Zimra</option>
            <option value="Zorz.otf" style={{ fontFamily: 'Zorz' }}>Zorz</option>
            <option value="ZtshagoExtrabold.otf" style={{ fontFamily: 'ZtshagoExtrabold' }}>Ztshago</option>
          </select>
          <input className="fontSizeSelector" type="text" 
          value={props.selectedFontSize}
          onChange={(e) => {
            props.setFontSize(e.target.value)
          }}
          />
        </div>
      </div>
      </div>
    </div>
  )
}

export default TextPopup

