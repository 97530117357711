import React, { useState, useCallback, useEffect } from 'react';
import { doc, updateDoc, getDoc } from '@firebase/firestore';
import { db } from '../../firebase';
import debounce from 'lodash/debounce'; 

import './ImageFrameBar.css';
import './FrameBar.css';
import './RoundedSlider.css'; 

import borderIcon from '../../icons/borderIcon.png';
import noBorderIcon from '../../icons/noBorderIcon.png';
import lineBorderIcon from '../../icons/lineBorderIcon.png';
import dashedBorderIcon from '../../icons/dashedBorderIcon.png';
import dottedBorderIcon from '../../icons/dottedBorderIcon.png';
import dropdownIcon from '../../icons/popupIcon.png';
import deleteIcon2 from '../../icons/deleteIcon2.png';
import opacityIcon from '../../icons/opacityIcon.png';

import WebsiteBackgroundPopup from "./WebsiteBackgroundPopup"; // Updated import
import ImageFrameBorderRadius from './FrameBorderRadius';
import FrameBorderWidth from './FrameBorderWidth'; 
import OpacityPopup from './OpacityPopup'; 

const ImageFrameBar = (props) => {
  const storeID = props.storeID;
  const [showFrameBorderPopup, setShowFrameBorderPopup] = useState(false);
  const [selectedObjectData, setSelectedObjectData] = useState(null);
  const [borderManualUpdate, setBorderManualUpdate] = useState(false);
  const [borderStyle, setBorderStyle] = useState('solid'); // Set default to 'solid'
  const [selectedBorderColor, setSelectedBorderColor] = useState('black'); // Initialize border color
  const [previewBorderColor, setPreviewBorderColor] = useState(null);
  const [showBorderColourPopup, setShowBorderColourPopup] = useState(false);
  const [showCommonColors, setShowCommonColors] = useState(false); // If needed
  const [colorPickerOpen, setColorPickerOpen] = useState(false); // If needed

  // New states for opacity control
  const [showOpacityPopup, setShowOpacityPopup] = useState(false);
  const [opacity, setOpacity] = useState(100); // Numeric value for UI
  const [opacityInput, setOpacityInput] = useState('100'); // String value for input

  // Fetch the selected object's data
  useEffect(() => {
    const matchedObject = props.data.find(obj => obj.frameID === props.selectedObjectID);
    if (matchedObject) {
      setSelectedObjectData(matchedObject);
      // Initialize opacity
      const storedOpacity = matchedObject.frame_styling?.opacity;
      let initialOpacity = 100; // Default value
      if (typeof storedOpacity === 'string' && storedOpacity.endsWith('%')) {
        const parsed = parseInt(storedOpacity.slice(0, -1), 10);
        initialOpacity = isNaN(parsed) ? 100 : parsed;
      } else if (typeof storedOpacity === 'number') {
        initialOpacity = storedOpacity;
      }
      setOpacity(initialOpacity);
      setOpacityInput(initialOpacity.toString());

      // Initialize border style and color
      if (matchedObject.frame_styling?.border) {
        const borderStyles = ['none', 'solid', 'dashed', 'dotted'];
        const borderParts = matchedObject.frame_styling.border.split(' ');
        const style = borderParts.find(part => borderStyles.includes(part)) || 'solid';
        setBorderStyle(style);
        const colorPart = borderParts[2];
        if (colorPart) {
          setSelectedBorderColor(colorPart);
        }
      } else {
        setBorderStyle('solid');
      }
    }
  }, [props.data, props.selectedObjectID]);

  // Handle border style changes
  const handleBorderStyle = async (borderStyleValue) => {
    setBorderStyle(borderStyleValue);
    setBorderManualUpdate(true);

    // Extract current borderWidth from selectedObjectData.frame_styling.border
    let borderWidth = '3'; // Default value
    if (selectedObjectData?.frame_styling?.border) {
      const borderParts = selectedObjectData.frame_styling.border.split(' ');
      const widthPart = borderParts[0];
      const widthValue = parseInt(widthPart, 10);
      if (!isNaN(widthValue)) {
        borderWidth = widthValue.toString();
      }
    }

    const newBorderStyle = borderStyleValue === 'none' 
      ? 'none' 
      : `${borderWidth}px ${borderStyleValue} ${selectedBorderColor || 'black'}`;

    if (selectedObjectData) {
      const updatedFrameStyling = {
        ...selectedObjectData.frame_styling,
        border: newBorderStyle,
      };

      const updatedObjectData = {
        ...selectedObjectData,
        frame_styling: updatedFrameStyling,
      };

      const updatedData = props.data.map(obj =>
        obj.frameID === props.selectedObjectID ? updatedObjectData : obj
      );

      setSelectedObjectData(updatedObjectData);
      props.updateFrames(updatedData, updatedObjectData);

      // Firestore updates
      const framesRef = doc(db, 'frames', props.selectedObjectID);
      await updateDoc(framesRef, {
        frame_styling: updatedFrameStyling,
      });

      // Update the frames array in the stores document
      const storesRef = doc(db, 'stores', storeID);
      const storeDoc = await getDoc(storesRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames || [];
        const frameIndex = framesArray.findIndex(frame => frame.frameID === props.selectedObjectID);
        if (frameIndex !== -1) {
          framesArray[frameIndex] = updatedObjectData;
          await updateDoc(storesRef, {
            frames: framesArray,
          });
        }
      }
    }
  };

    // Handle border color updates
    const handleBorderSelectedColor = useCallback(async (color) => {
      if (color) {
        setSelectedBorderColor(color);
  
        // Extract current borderWidth and borderStyle from selectedObjectData.frame_styling.border
        let borderWidth = '3'; // Default value
        let borderStyleValue = borderStyle || 'solid';
        if (selectedObjectData?.frame_styling?.border) {
          const borderParts = selectedObjectData.frame_styling.border.split(' ');
          const widthPart = borderParts[0];
          const widthValue = parseInt(widthPart, 10);
          if (!isNaN(widthValue)) {
            borderWidth = widthValue.toString();
          }
          if (borderParts[1]) {
            borderStyleValue = borderParts[1];
          }
        }
  
        const newBorder = borderStyleValue !== 'none' 
          ? `${borderWidth}px ${borderStyleValue} ${color}` 
          : 'none';
  
        const updatedFrameStyling = {
          ...selectedObjectData.frame_styling,
          border: newBorder
        };
  
        const updatedObjectWithColor = {
          ...selectedObjectData,
          frame_styling: updatedFrameStyling
        };
  
        setSelectedObjectData(updatedObjectWithColor);
  
        const updatedData = props.data.map(obj => 
          obj.frameID === props.selectedObjectID ? updatedObjectWithColor : obj
        );
  
        props.updateFrames(updatedData, updatedObjectWithColor);
  
        // Firestore updates
        const framesRef = doc(db, 'frames', props.selectedObjectID);
        try {
          await updateDoc(framesRef, {
            frame_styling: updatedFrameStyling,
          });
        } catch (error) {
          console.error("Error updating border color in Firebase:", error);
        }
  
        // Update the stores document
        const storesRef = doc(db, 'stores', storeID);
        await updateDoc(storesRef, {
          frames: updatedData,
        });
      }
    }, [selectedObjectData, props.data, props.selectedObjectID, props.updateFrames, storeID, borderStyle]);  

  // Handle border color selection and preview
  const handleSelectedBorderColor = useCallback((color) => {
    if (color && color.hex) {
      setPreviewBorderColor(color.hex); // Update previewed color for real-time preview
      handleBorderSelectedColor(color.hex);
    } else if (typeof color === 'string') {
      setPreviewBorderColor(color); // Directly set previewed color if a string is provided
      handleBorderSelectedColor(color);
    }
  }, [handleBorderSelectedColor]);

  // Confirm the selected border color
  const handleConfirmBorderColor = useCallback(async () => {
    if (previewBorderColor) { // Only proceed if a new color has been selected
      try {
        setSelectedBorderColor(previewBorderColor);
        handleBorderSelectedColor(previewBorderColor);
      } catch (error) {
        console.error('Error updating border color:', error);
      }
    }
    setPreviewBorderColor(null); // Reset previewed color regardless
    setShowBorderColourPopup(false); // Close the color picker popup
  }, [previewBorderColor, handleBorderSelectedColor]);

  // Cancel the border color selection
  const handleCancelBorderColor = useCallback(() => {
    setPreviewBorderColor(null); // Revert to original color
    setShowBorderColourPopup(false); // Close the popup
  }, []);

  // Debounced opacity update (unchanged)
  const debouncedUpdateOpacity = useCallback(
    debounce(async (updatedOpacity) => {
      if (!selectedObjectData) return;

      try {
        // Update Firestore for frames with opacity as a percentage string
        const framesRef = doc(db, 'frames', props.selectedObjectID);
        await updateDoc(framesRef, { 
          frame_styling: { 
            ...selectedObjectData.frame_styling, 
            opacity: `${updatedOpacity}%` 
          } 
        });

        // Update the frames array in the stores document
        const storesRef = doc(db, 'stores', storeID);
        const storeDocSnap = await getDoc(storesRef);
        if (storeDocSnap.exists()) {
          const storeData = storeDocSnap.data();
          const framesArray = storeData.frames || [];
          const frameIndex = framesArray.findIndex(frame => frame.frameID === props.selectedObjectID);
          if (frameIndex !== -1) {
            framesArray[frameIndex].frame_styling.opacity = `${updatedOpacity}%`;
            await updateDoc(storesRef, { frames: framesArray });
          }
        }
      } catch (error) {
        console.error("Failed to update opacity in Firestore:", error);
      }
    }, 300), // 300ms debounce delay
    [selectedObjectData, props.selectedObjectID, storeID]
  );

  // Handler for opacity slider change
  const handleOpacitySliderChange = (newOpacity) => {
    if (isNaN(newOpacity)) return;

    setOpacity(newOpacity);
    setOpacityInput(newOpacity.toString());

    // Update frame styling
    const updatedFrameStyling = {
      ...selectedObjectData.frame_styling,
      opacity: `${newOpacity}%`, // Save as percentage string
    };

    const updatedObjectData = { ...selectedObjectData, frame_styling: updatedFrameStyling };
    const updatedData = props.data.map(obj =>
      obj.frameID === props.selectedObjectID ? updatedObjectData : obj
    );
    setSelectedObjectData(updatedObjectData);
    props.updateFrames(updatedData, updatedObjectData);

    // Invoke debounced Firestore update
    debouncedUpdateOpacity(newOpacity);
  };

  // Handler for opacity input change
  const handleOpacityInputChange = (newOpacity) => {
    if (newOpacity === '') {
      setOpacityInput('');
      return;
    }

    const numericValue = parseInt(newOpacity, 10);
    if (isNaN(numericValue)) {
      return;
    }

    if (numericValue < 0 || numericValue > 100) {
      return;
    }

    setOpacity(numericValue);
    setOpacityInput(numericValue.toString());

    // Update frame styling
    const updatedFrameStyling = {
      ...selectedObjectData.frame_styling,
      opacity: `${numericValue}%`, // Save as percentage string
    };

    const updatedObjectData = { ...selectedObjectData, frame_styling: updatedFrameStyling };
    const updatedData = props.data.map(obj =>
      obj.frameID === props.selectedObjectID ? updatedObjectData : obj
    );
    setSelectedObjectData(updatedObjectData);
    props.updateFrames(updatedData, updatedObjectData);

    // Invoke debounced Firestore update
    debouncedUpdateOpacity(numericValue);
  };

  // Define the handler outside the JSX
const handleDropdownClick = useCallback((e) => { 
  e.preventDefault();
  e.stopPropagation(); 
  setShowBorderColourPopup(prev => {
    console.log('Toggling showBorderColourPopup from', prev, 'to', !prev);
    return !prev;
  }); 
}, []);


  return (
    <div>
      <div className="imageFrameBar">
        <div className="imageFrameBarSection1">
          <div 
            className="frameZIndexBtn" 
            onClick={() => {
              console.log('frameDynamics clicked')
              props.setShowZIndexPopup(!props.showZIndexPopup)
            }}
          >
            frame dynamics
          </div>
          <img 
            className="imageFrameBorderBtn" 
            src={borderIcon} 
            alt="Border Icon"
            onClick={() => {
              setShowFrameBorderPopup(!showFrameBorderPopup);
              setShowOpacityPopup(false); // Close opacity popup if open
            }}
          />
          <img 
            className="imageFrameOpacityBtn"
            src={opacityIcon}
            alt="Opacity Icon"
            onClick={() => {
              setShowOpacityPopup(!showOpacityPopup);
              setShowFrameBorderPopup(false); // Close border popup if open
            }}
          />
        </div>
        
        <img 
          className="imageFrameDeleteIcon"
          src={deleteIcon2} alt="Delete Icon" 
          onClick={props.removeFrameImage}
        />
      </div>
      
      {/* Border Popup */}
      {showFrameBorderPopup &&
        <div className="imageFrameBorderPopupDiv" ref={props.vendorBarRef}>
          <div className="frameBorderStyleDiv">
            <div className="frameBorderStyleText">border style</div>
            <div className="frameBorderStyleIconDiv">
              <img 
                src={noBorderIcon} 
                className={`noBorderBtn${borderStyle === 'none' ? 'Active' : ''}`}
                alt="No Border"
                onClick={() => {handleBorderStyle('none')}}
              />
              <img 
                src={lineBorderIcon} 
                className={`lineBorderBtn${borderStyle === 'solid' ? 'Active' : ''}`} 
                alt="Solid Border"
                onClick={() => {handleBorderStyle('solid')}}
              />
              <img 
                src={dashedBorderIcon} 
                className={`dashedBorderBtn${borderStyle === 'dashed' ? 'Active' : ''}`}
                alt="Dashed Border"
                onClick={() => {handleBorderStyle('dashed')}}
              />
              <img 
                src={dottedBorderIcon} 
                className={`dottedBorderBtn${borderStyle === 'dotted' ? 'Active' : ''}`}
                alt="Dotted Border"
                onClick={() => {handleBorderStyle('dotted')}}
              />
            </div>
          </div>
          {/* Replace the border width div with the FrameBorderWidth component */}
          <FrameBorderWidth
            selectedObjectData={selectedObjectData}
            updateFrames={props.updateFrames}
            data={props.data}
            selectedObjectID={props.selectedObjectID}
            storeID={storeID}
            borderStyle={borderStyle}
            selectedBorderColor={selectedBorderColor}
            setSelectedObjectData={setSelectedObjectData}
          />
          <ImageFrameBorderRadius
            selectedObjectData={selectedObjectData}
            updateFrames={props.updateFrames}
            data={props.data}
            selectedObjectID={props.selectedObjectID}
            storeID={storeID}
          />
          <div className="frameBorderColor">
            <div className="frameBorderColorText">
              border colour
              <img 
                src={dropdownIcon} 
                className="borderColourSectionDropdownIcon" 
                alt="Dropdown Icon"
                onClick={handleDropdownClick}                
              />
            </div>
            {showBorderColourPopup &&
              <WebsiteBackgroundPopup 
                parentComponent={'imageFrameBar'}
                useCase={'borderColorChange'}
                storeID={props.storeID}
                closePopup={() => { setShowBorderColourPopup(false); }}
                show={showBorderColourPopup} 
                onClickOutside={null} 
                onSelectColor={handleSelectedBorderColor}
                objectColor={previewBorderColor || selectedBorderColor}
                data={props.storeData}
                pickerRef={props.pickerRef}
                onDone={handleConfirmBorderColor} 
                onCancel={handleCancelBorderColor}
                onSizeChange={null}
                showCommonColors={showCommonColors}
                setShowCommonColors={setShowCommonColors}
                setColorPickerOpen={setColorPickerOpen}
              />
            }
          </div>
        </div>
      }

      {/* Opacity Popup */}
      {showOpacityPopup &&
        <OpacityPopup
          opacity={opacity}
          opacityInput={opacityInput}
          onSliderChange={handleOpacitySliderChange}
          onInputChange={handleOpacityInputChange}
          closePopup={() => setShowOpacityPopup(false)}
          opacityPopupRef={props.opacityPopupRef}
        />
      }
    </div>
  );
};

export default ImageFrameBar;
