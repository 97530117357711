// src/components/VendorForm.jsx

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import VendorFormApp from './VendorFormApp.jsx';

const VendorForm = (props) => {
  return (
    <Routes>
      <Route 
        path="/*" 
        element={
          <VendorFormApp 
            storeID={props.storeID}
            setStoreID={props.setStoreID} 
            userID={props.userID}
            setUserID={props.setUserID}
            setAlertMessage={props.setAlertMessage}
            setAlertType={props.setAlertType}
            country={props.country}
          />
        } 
      />
    </Routes>
  );
};

export default VendorForm;
