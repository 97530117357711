import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';

const FilmGrain = () => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    if (!container || container.offsetWidth === 0 || container.offsetHeight === 0) {
      return;
    }

    function onWindowResize() {
      camera.aspect = container.offsetWidth / container.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      composer.setSize(container.offsetWidth, container.offsetHeight);
    }
  
    window.addEventListener('resize', onWindowResize);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    container.appendChild(renderer.domElement);

    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));

    const filmGrainShader = {
      uniforms: {
        'tDiffuse': { value: null },
        'time': { value: 0 },
        'grainSize': { value: 1 }, // Adjustable grain size
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float time;
        uniform float grainSize;
        varying vec2 vUv;
    
        float random(vec2 co){
          return fract(sin(dot(co.xy, vec2(12.9898,78.233) + time)) * 43758.5453);
        }
    
        void main() {
          vec3 color = texture2D(tDiffuse, vUv).rgb;
          // Adding a random offset to the grain pattern
          vec2 noiseCoord = vUv + vec2(random(vUv), random(vUv + vec2(1.0)));
          float grain = random(noiseCoord) * grainSize;
          gl_FragColor = vec4(color + grain, 1.0);
        }
      `,
    };

    const filmGrainPass = new ShaderPass(filmGrainShader);
    composer.addPass(filmGrainPass);

    let animationFrameId;
    function animate() {
      animationFrameId = requestAnimationFrame(animate);
      filmGrainPass.uniforms.time.value = (performance.now() / 1000) % 10000;
      composer.render();
    }

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div className="scoutFilmGrain" ref={containerRef} 
  style={{ position: 'absolute', width: '100vw', height: '105vh', zIndex: 1, top: 0, left: 0, opacity: '0.08', backdropFilter: 'blur(10px)' }}></div>;
};

export default FilmGrain;
