import React, { useState, useEffect } from 'react';
import { doc, setDoc, updateDoc, collection, runTransaction } from '@firebase/firestore';
import { db } from '../../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import blankImage from '../../images/blankImage.png';
import dropdownIcon from '../../icons/popupIcon.png';
import ParcelUploadComponent from './ParcelUploadComponent';
import './PendingProduct.css';

const PendingProduct = ({ product, handleProductClick, adminOrderData }) => {
  // If product doesn't already have buyerInfo merged in:
  const order = adminOrderData.find((order) =>
    order.products.some((p) => p.productID === product.productID)
  );

  let mergedProduct = product;
  if (order) {
    const orderProduct = order.products.find(p => p.productID === product.productID);
    mergedProduct = {
      ...product,
      buyerInfo: order.buyerInfo,
      selected_shippingName: orderProduct.selected_shippingName,
      selected_shippingPrice: orderProduct.selected_shippingPrice,
      selected_subgroup: orderProduct.selected_subgroup,
    };
  }

  const [isBuyerInfoVisible, setIsBuyerInfoVisible] = useState(false);
  const [trackingNum, setTrackingNum] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      uploadedImages.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [uploadedImages]);

  const toggleBuyerInfo = () => {
    setIsBuyerInfoVisible(!isBuyerInfoVisible);
  };

  const handleTrackingNumChange = (e) => {
    setTrackingNum(e.target.value);
  };

  const handleSubmit = async () => {
    if (!trackingNum.trim()) {
      setError('Please enter the waybill/tracking number.');
      return;
    }

    if (uploadedImages.length === 0) {
      setError('Please upload at least one image of the parcel.');
      return;
    }

    setError('');
    setIsSubmitting(true);

    try {
      await runTransaction(db, async (transaction) => {
        const buyerNotificationRef = doc(collection(db, 'notifications'));
        transaction.set(buyerNotificationRef, {
          notificationType: 'shippingInfo',
          message: `Your purchase of '${mergedProduct.productName}' has been shipped. Tracking Number: ${trackingNum}.`,
          messageDate: new Date().toLocaleString(),
          messageDateNumeric: new Date().getTime(),
          messageID: buyerNotificationRef.id,
          readStatus: 'unread',
          storeName: mergedProduct.storeName,
          productName: mergedProduct.productName,
          storeID: mergedProduct.storeID,
          userID: mergedProduct.buyerInfo?.userID,
          trackingNum: trackingNum,
          images: uploadedImages,
        });

        const productRef = doc(db, "products", mergedProduct.productID);
        transaction.update(productRef, {
          productSent: true,
          trackingNum: trackingNum,
        });

        const soldProductRef = doc(db, 'soldProducts', mergedProduct.productID);
        transaction.set(soldProductRef, {
          ...mergedProduct,
          trackingNum: trackingNum,
          trackingImages: uploadedImages,
          sentDate: new Date(),
        });
      });

      setSuccessMessage('Shipping information sent to the buyer successfully.');
      setTrackingNum('');
      setUploadedImages([]);
      setIsBuyerInfoVisible(false);
    } catch (err) {
      console.error('Error sending shipping info:', err);
      setError('Failed to send shipping information. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="storeOrdersProduct">
      <div className="storeProductSummaryDiv">
        <div className="storesProductsSection1">
          <img
            src={mergedProduct.imageThumbnails?.[0] || blankImage}
            className="storesProductImg"
            onClick={() => handleProductClick(mergedProduct)}
            alt={mergedProduct.productName || 'Untitled'}
          />
          <div className="storesProductInfo">
            <p className="storesProductName">{mergedProduct.productName}</p>
            <p className="storesProductPrice">R{parseFloat(mergedProduct.listed_price).toFixed(2)}</p>
          </div>
        </div>
        <img
          src={dropdownIcon}
          alt="Toggle Buyer Info"
          className="ordersDropdownIcon"
          onClick={toggleBuyerInfo}
        />
      </div>
      <AnimatePresence>
        {isBuyerInfoVisible && (
          <motion.div
            className="productBuyerInfo"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {/* Display Buyer Info */}
            {/* <div className="buyerInfoFields">
              <div className="buyerInfoField">
                <strong>First Name:</strong> {mergedProduct.buyerInfo.firstName}
              </div>
              <div className="buyerInfoField">
                <strong>Surname:</strong> {mergedProduct.buyerInfo.surname}
              </div>
              <div className="buyerInfoField">
                <strong>Cellphone:</strong> {mergedProduct.buyerInfo.cellphone}
              </div>
              <div className="buyerInfoField">
                <strong>Email:</strong> {mergedProduct.buyerInfo.email}
              </div>
              <div className="buyerInfoField">
                <strong>Address:</strong> {mergedProduct.buyerInfo.address}
              </div>
              
            </div> */}
            <div className="shippingInfoContainer">
              <div className="shippingInfoContainerHeader">Shipping Information:</div>
              {mergedProduct.selected_shippingName === 'paxi' && (
                <div className="shippingInfoField">
                  <strong>Paxi Store Code:</strong> {mergedProduct.buyerInfo?.paxiStoreCode || 'N/A'}
                </div>
              )}

              {mergedProduct.selected_shippingName === 'pudo' && (
                <div className="shippingInfoField">
                  <strong>Pudo Locker Location:</strong> {mergedProduct.buyerInfo?.pudoLockerAddress || 'N/A'}
                </div>
              )}

              <div className="shippingInfoField">
                <strong>Courier Method:</strong> {mergedProduct.courierMethod || 'N/A'}
              </div>
              <div className="shippingInfoField">
                <strong>Shipping Price:</strong> {mergedProduct.selected_shippingPrice ? `R${parseFloat(mergedProduct.selected_shippingPrice).toFixed(2)}` : 'N/A'}
              </div>
              <div className="shippingInfoField">
                <strong>Shipping Name:</strong> {mergedProduct.selected_shippingName || 'N/A'}
              </div>
              <div className="shippingInfoField">
                <strong>Shipping Subgroup:</strong> {mergedProduct.selected_subgroup || 'N/A'}
              </div>
            </div>

            <div className="trackingNumContainer">
              <input
                type="text"
                placeholder="Waybill/Tracking Number"
                value={trackingNum}
                onChange={handleTrackingNumChange}
                className="trackingNumInput"
              />
            </div>

            <div className="parcelImageUpload">
              <ParcelUploadComponent 
                setUploadedImages={setUploadedImages}
                productID={mergedProduct.productID} 
              />
            </div>

            <div className="submitButtonContainer">
              <button
                className="sendInfoButton"
                onClick={handleSubmit}
                disabled={isSubmitting || !trackingNum.trim() || uploadedImages.length === 0}
              >
                {isSubmitting ? 'Sending...' : 'Send Info to Buyer'}
              </button>
            </div>

            {error && <div className="errorMessage">{error}</div>}
            {successMessage && <div className="successMessage">{successMessage}</div>}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PendingProduct;
