// LongPress.js
import { useRef, useState, useEffect } from 'react';

const LongPress = (onLongPress, onClick, options = {}) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = (event) => {
    // Check if the event target or its parent has data-interactive="true"
    if (event.target.closest('[data-interactive="true"]')) {
      return; // Do not initiate long press
    }

    if (options.shouldPreventDefault && event.target) {
      event.target.addEventListener('touchend', preventDefault, {
        passive: false,
      });
      target.current = event.target;
    }
    timeout.current = setTimeout(() => {
      onLongPress(event);
      setLongPressTriggered(true);
    }, options.delay);
  };

  const clear = (event, shouldTriggerClick = true) => {
    timeout.current && clearTimeout(timeout.current);
    shouldTriggerClick && !longPressTriggered && onClick(event);
    setLongPressTriggered(false);
    if (options.shouldPreventDefault && target.current) {
      target.current.removeEventListener('touchend', preventDefault);
    }
  };
  
  const isTouchEvent = (event) => {
    return 'touches' in event;
  };
  
  const preventDefault = (event) => {
    if (!isTouchEvent(event)) return;
  
    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  };
  
  useEffect(() => {
    return () => {
      clear(null, false);
    };
  }, []);
  
  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};
  
export default LongPress;
