import { useContext, useState, useEffect, useLocation, useRef } from "react";
import { Link, Outlet } from "react-router-dom";

import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot } 
from '@firebase/firestore'
import { auth, db, storage } from '../firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import dropdownIcon from '../icons/popupIcon.png'
import './NotificationPopup.css'

const NotificationPopup = (props) => {
  const { notifications, unreadNotifications, readNotifications, markNotificationAsRead } = props;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Function to mark all unread notifications as read
    const markAllAsRead = () => {
      unreadNotifications.forEach(notification => {
        markNotificationAsRead(notification.id);
      });
    };

    // Set a timer to mark notifications as read after 2 seconds
    const timer = setTimeout(markAllAsRead, 5000);

    // Add an event listener to mark notifications as read when the popup is closed
    window.addEventListener('beforeunload', markAllAsRead);

    // Clean up the timer and event listener
    return () => {
      clearTimeout(timer);
      window.removeEventListener('beforeunload', markAllAsRead);
    };
  }, [unreadNotifications, markNotificationAsRead]);

  if (!notifications || notifications.length === 0) {
    return (
      <div className="notificationPopupContainer">
        <div className="emptyUnreadyNotificationsMessage">No notifications</div>  
      </div>
    )
  }

  const convertToReadableDate = (numericDate) => {
    const milliseconds = numericDate.seconds * 1000;
    const date = new Date(milliseconds);
    const formattedDate = `${date.getDate()} ${date.toLocaleString('en-GB', { month: 'long' })} ${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    return formattedDate;
  }

  return (
    <div className="notificationPopupContainer">
      <div className="unreadNotificationsGroup">
        <div className="unreadNotificationsHeading">new notifications</div>
        <div className="notificationsList">
          {unreadNotifications.length > 0 ? (
            unreadNotifications.map(notification => (
              <div key={notification.id} className="notificationItem">
                {notification.message}
                <div className="notificationTime">{convertToReadableDate(notification.messageDateNumeric)}</div>
              </div>
            ))
          ) : (
            <div className="emptyUnreadyNotificationsMessage">There are currently no new notifications</div>
          )}
        </div>
      </div>

      <div className="readNotificationsGroup">
        <div className="notificationsDropdownContainer">
          <div className="readNotificationsHeading">read notifications</div>
          <div onClick={() => setIsActive(!isActive)}>
            {isActive ? 
              <img className="favouritesDropdownIcon2" src={dropdownIcon}  alt="dropdown icon" /> 
              : <img className="favouritesDropdownIcon" src={dropdownIcon}  alt="dropdown icon" />
            }
          </div>
        </div>
        {isActive &&
        <div className="notificationsList">
          {readNotifications.map(notification => (
            <div key={notification.id} className="notificationItem">
              {/* Display read notification details */}
              {notification.message}
              <div className="notificationTime">{convertToReadableDate(notification.messageDateNumeric)}</div>
            </div>
          ))}
        </div>
        }
      </div>
    </div>
  )
}

export default NotificationPopup