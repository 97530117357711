import { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { Outlet, Link } from "react-router-dom";
import { ref, getDownloadURL, listAll } from 'firebase/storage'

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot 
} 
from '@firebase/firestore'
import { auth, db, storage } from '../firebase'
import './Home.css'

import logoIcon from '../icons/logoIcon.png'
import loadIcon from '../icons/scoutIcon.png'
import heartIcon from '../icons/heartIcon.png'
import accountIcon from '../icons/accountIcon.png'
import scoutIcon from '../icons/scoutIcon.png'
import vendorIcon from '../icons/vendorIcon.png'
import notificationIcon from "../icons/notificationIcon.png"
import NotificationPopup from "./NotificationPopup";

import Login from '../login/Login'
import { ProblemStatement } from './popups/ProblemStatement';
import { NameMeaning } from './popups/NameMeaningPopup'
import { DefinitionsPopup } from './popups/DefinitionsPopup';

const Home = (props) => {
  const loginPopupRef = useRef()
  const [isLoading, setIsLoading] = useState(true)
  const [showInfo1, setShowInfo1] = useState(false)
  const [showHomeIcons, setShowHomeIcons] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [notifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [popupClosed, setPopupClosed] = useState(false)
  
  // **Add a state variable to track the current user**
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // **Set up the authentication state listener inside useEffect**
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setShowHomeIcons(true);
        setCurrentUser(user);
      } else {
        setShowHomeIcons(false);
        setShowInfo1(true);
        setCurrentUser(null);
        setShowNotifications(false); // **Ensure notifications are hidden when no user is logged in**
      }
    });

    // **Clean up the listener on unmount**
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    document.title = 'Home | taizte™';
  }, []);

  const handleNavigation = () => {
    window.location.href = '/favourites';
  };

  useEffect(() => {
    if (!props.userID) return; // Make sure userID is not undefined
    getNotifications();
    console.log(notifications)
  }, [props.userID]);

  const getNotifications = async () => {
    try {
      const q = query(
        collection(db, "notifications"), 
        where("userID", "==", props.userID)
      );
      const querySnapshot = await getDocs(q);
      let fetchedNotifications = [];
  
      querySnapshot.forEach((doc) => {
        let notification = doc.data();
        notification.id = doc.id; // Add document ID to the notification object
        fetchedNotifications.push(notification);
      });
  
      // Sort notifications by time
      fetchedNotifications.sort((a, b) => b.messageDateNumeric - a.messageDateNumeric);
  
      // Update notifications state
      setNotifications(fetchedNotifications);
  
      // Filter and set read and unread notifications
      setReadNotifications(fetchedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(fetchedNotifications.filter(n => n.readStatus === 'unread'));
    
      // **Set showNotifications based on the presence of notifications**
      setShowNotifications(fetchedNotifications.length > 0);
      console.log(fetchedNotifications)
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };  

  const markNotificationAsRead = async (notificationId) => {
    try {
      // Update the readStatus in Firebase
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, {
        readStatus: 'read'
      });
  
      // Update local state
      // Create a new array with the updated readStatus
      const updatedNotifications = notifications.map(notification => {
        if (notification.id === notificationId) {
          return { ...notification, readStatus: 'read' };
        }
        return notification;
      });
  
      // Update the notifications state
      setNotifications(updatedNotifications);
  
      // Update read and unread notifications states
      setReadNotifications(updatedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(updatedNotifications.filter(n => n.readStatus === 'unread'));
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  // **Memoize purchaseConfirmation notifications for efficient rendering**
  const purchaseNotifications = useMemo(() => 
    notifications.filter(n => n.notificationType === 'purchaseConfirmation'), 
    [notifications]
  );

  const togglePopup = () => {
    setShowNotifications(!showNotifications);
    setPopupClosed(!popupClosed); // Set this to true when the popup is closed
  }  

  return (
    <div className='homeBody' style={{ backgroundColor: '#EBB89C' }}>
      <div className='homeHeader'>
        <div className="iconContainer">
          <img src={logoIcon} alt="bootstrapt logo" id='logo'/>
          <div className="homeSpace"/>

          {showHomeIcons &&
            <div className='loggedInHomeIcons'>
              <img 
                src={heartIcon} 
                onClick={handleNavigation}
                alt="favourites page" 
                id='favourites'
              /> 
              <img 
                onClick={togglePopup}
                src={notificationIcon} 
                alt="notifications" 
                id='homeNotifications'
              />

              <img 
                onClick={() => {setShowInfo1(true)}} 
                src={accountIcon} 
                alt="login" 
                id='login'
              />
            </div>
          }
        </div>
      </div>
      
      {/* **Purchase Notifications Section** */}
      {purchaseNotifications.length > 0 && (
        <div className="purchaseNotifications">
          <h2>Purchase Confirmations</h2>
          {purchaseNotifications.map(notification => (
            <div key={notification.id} className="notificationItem">
              {/* In future have an icon relevant to types of notifications, like purchase confirmations, low stock, etc */}
              <span>{notification.message}</span>
            </div>
          ))}
        </div>
      )}
      
      {/* **Conditionally render NotificationPopup only if user is logged in** */}
      {currentUser && showNotifications && 
        <NotificationPopup 
          userID={props.userID}
          markNotificationAsRead={markNotificationAsRead}
          notifications={notifications}
          unreadNotifications={unreadNotifications}
          readNotifications={readNotifications}
        />
      }
      
      <div ref={loginPopupRef} className="loginPopup">
        <Login 
          show={showInfo1} 
          onClickOutside={currentUser ? () => setShowInfo1(false) : undefined}
        />      
      </div>
      <Outlet />
    </div>
  );

};

export default Home;
