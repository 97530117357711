import React, { useRef, useState, useCallback, useEffect } from 'react'

import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  arrayUnion, getDoc, getDocs, query, where, 
  onSnapshot, serverTimestamp, deleteDoc } 
from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from '../../firebase'
import { Outlet, Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion"

import './StickerBar.css'
import './ImageFrameBar.css'
import './FrameBar.css'

import deleteIcon from '../../icons/deleteIcon2.png'
import borderIcon from '../../icons/borderIcon.png'
import noBorderIcon from '../../icons/noBorderIcon.png'
import lineBorderIcon from '../../icons/lineBorderIcon.png'
import dashedBorderIcon from '../../icons/dashedBorderIcon.png'
import dottedBorderIcon from '../../icons/dottedBorderIcon.png'
import borderRadiusIcon from '../../icons/borderRadiusIcon.png'
import dropdownIcon from '../../icons/popupIcon.png'
import topLeftCornerIcon from '../../icons/topLeftCornerIcon.png'
import topRightCornerIcon from '../../icons/topRightCornerIcon.png'
import bottomLeftCornerIcon from '../../icons/bottomLeftCornerIcon.png'
import bottomRightCornerIcon from '../../icons/bottomRightCornerIcon.png'
import TextColourPopup from './TextColourPopup';

const StickerBar = (props) => {
  const storeID = props.storeID
  const [showFrameBorderPopup, setShowFrameBorderPopup] = useState(false)
  const [showStickerDimensionPopup, setShowStickerDimensionPopup] = useState(false)

  const [borderWidth, setBorderWidth] = useState('3')
  const [selectedObjectData, setSelectedObjectData] = useState(null);
  const [borderManualUpdate, setBorderManualUpdate] = useState(false);
  const [borderStyle, setBorderStyle] = useState('');
  const [isManualBorderRadiusChange, setIsManualBorderRadiusChange] = useState(false);
  const [showCornerDropdownContent, setShowCornerDropdownContent] = useState('')
  const [showBorderColourPopup, setShowBorderColourPopup] = useState(false)
  const [topLeftRadius, setTopLeftRadius] = useState('');
  const [topRightRadius, setTopRightRadius] = useState('');
  const [bottomLeftRadius, setBottomLeftRadius] = useState('');
  const [bottomRightRadius, setBottomRightRadius] = useState('');
  const [unifiedBorderRadius, setUnifiedBorderRadius] = useState('0');

  useEffect(() => {
    console.log(props)
    setSelectedObjectData(props.selectedObject)    
  }, [props.data, props.selectedObject])

  useEffect(() => {
    if (selectedObjectData) {
      const defaultBorderRadiusValue = '0'; // Default border radius
    
      if (selectedObjectData && selectedObjectData.sticker_styling) {
        const styling = selectedObjectData.sticker_styling;
    
        // Extracting the border width
        if (styling.border) {
          const borderWidth = styling.border.match(/(\d+)px/)?.[1] || '0';
          setBorderWidth(borderWidth);
        }
    
        // Extract corner radii from styling or set default if not available
        setTopLeftRadius(styling.borderTopLeftRadius?.slice(0, -2) || defaultBorderRadiusValue);
        setTopRightRadius(styling.borderTopRightRadius?.slice(0, -2) || defaultBorderRadiusValue);
        setBottomLeftRadius(styling.borderBottomLeftRadius?.slice(0, -2) || defaultBorderRadiusValue);
        setBottomRightRadius(styling.borderBottomRightRadius?.slice(0, -2) || defaultBorderRadiusValue);
    
        // Check for unified border radius
        if (styling.borderTopLeftRadius === styling.borderTopRightRadius &&
          styling.borderTopRightRadius === styling.borderBottomLeftRadius &&
          styling.borderBottomLeftRadius === styling.borderBottomRightRadius) {
          setUnifiedBorderRadius(styling.borderTopLeftRadius?.slice(0, -2));
        } else {
          setUnifiedBorderRadius('mixed');
        }
      } else {
        // Set default border radius when sticker_styling is not available
        setTopLeftRadius(defaultBorderRadiusValue);
        setTopRightRadius(defaultBorderRadiusValue);
        setBottomLeftRadius(defaultBorderRadiusValue);
        setBottomRightRadius(defaultBorderRadiusValue);
        setUnifiedBorderRadius(defaultBorderRadiusValue);
        console.log("default border radius set to: ", defaultBorderRadiusValue);
      }
    }
  }, [selectedObjectData]);
  
  useEffect(() => {
    if (isManualBorderRadiusChange) {
      if (topLeftRadius === topRightRadius &&
        topRightRadius === bottomLeftRadius &&
        bottomLeftRadius === bottomRightRadius) {
        // All corners have the same radius
        console.log("There is a unified border")
        setUnifiedBorderRadius(topLeftRadius);
      } else {
        // Corners have different radii
        setUnifiedBorderRadius('mixed');
      }
    }
  }, [topLeftRadius, topRightRadius, bottomLeftRadius, bottomRightRadius]);
  
  useEffect(() => {
    if (borderManualUpdate) {
      // Reset the manual update flag
      setBorderManualUpdate(false);
      return;
    }

    if (selectedObjectData?.sticker_styling?.border) {
      const borderStyles = ['solid', 'dashed', 'dotted', 'none'];
      const borderParts = selectedObjectData.sticker_styling.border.split(' ');
  
      // Search for a known border style in the border string
      const style = borderParts.find(part => borderStyles.includes(part)) || 'none';
      setBorderStyle(style);
    }
  }, [selectedObjectData, borderManualUpdate]);

  const handleBorderWidthChange = async (newWidth) => {
    console.log("New Width: ", newWidth);

    // Parsing the input to ensure it's a valid number
    const widthValue = parseInt(newWidth, 10);

    // Handling invalid input (e.g., empty string, non-numeric input)
    if (isNaN(widthValue)) {
      setBorderWidth('');
      return;
    }

    // Adding 'px' unit to the width
    const newWidthWithUnit = `${widthValue}px`;
    console.log("New Width with Unit: ", newWidthWithUnit);

    console.log(borderStyle)
    // Determining the new border style based on the width and existing border style
    const newBorderStyle = widthValue > 0 && borderStyle !== 'none'
                            ? `${newWidthWithUnit} ${borderStyle}`
                            : 'none';

    // Update border width in the state
    setBorderWidth(newWidthWithUnit);

    // Proceed only if there's a selected object
    if (selectedObjectData) {
      const stickerContainerStyling = {
        ...selectedObjectData.sticker_styling,
        border: newBorderStyle,
      };

      // Update selectedObjectData with new styling
      const updatedObjectData = { ...selectedObjectData, sticker_styling: stickerContainerStyling };
      setSelectedObjectData(updatedObjectData);

      // Update props.data array
      const updatedData = props.data.map(obj =>
        obj.stickerID === props.selectedObjectID ? updatedObjectData : obj
      );

      props.updateStickers(updatedData, updatedObjectData)

      // Firestore updates
      const stickersRef = doc(db, 'stickers', props.selectedObjectID);
      await updateDoc(stickersRef, { sticker_styling: stickerContainerStyling });

      // Update stores document
      const storesRef = doc(db, 'stores', storeID);
      updateDoc(storesRef, { stickers: updatedData })
    }
  };

  const handleBorderRadiusChange = async (newRadius, corner) => {
    let newTopLeftRadius = topLeftRadius;
    let newTopRightRadius = topRightRadius;
    let newBottomLeftRadius = bottomLeftRadius;
    let newBottomRightRadius = bottomRightRadius;
  
    // Convert newRadius to a number if it's not an empty string
    const radiusValue = newRadius === '' ? '' : parseInt(newRadius, 10);
  
    switch (corner) {
      case 'topLeft':
        newTopLeftRadius = radiusValue;
        break;
      case 'topRight':
        newTopRightRadius = radiusValue;
        break;
      case 'bottomLeft':
        newBottomLeftRadius = radiusValue;
        break;
      case 'bottomRight':
        newBottomRightRadius = radiusValue;
        break;
      default:
        newTopLeftRadius = newTopRightRadius = newBottomLeftRadius = newBottomRightRadius = radiusValue;
        break;
    }
  
    // Update the state for each corner
    setTopLeftRadius(newTopLeftRadius);
    setTopRightRadius(newTopRightRadius);
    setBottomLeftRadius(newBottomLeftRadius);
    setBottomRightRadius(newBottomRightRadius);
  
    // Determine if all corners have the same radius for the unified radius
    const unifiedRadius = (newTopLeftRadius === newTopRightRadius && 
                           newTopRightRadius === newBottomLeftRadius && 
                           newBottomLeftRadius === newBottomRightRadius) ? 
                           (newRadius === '' ? '' : newRadius) : 'mixed';
  
    setUnifiedBorderRadius(unifiedRadius);
  
    // Update selectedObjectData and Firestore if necessary
    if (selectedObjectData) {
      const updatedStickerStyling = {
        ...selectedObjectData.sticker_styling,
        borderTopLeftRadius: newTopLeftRadius !== '' ? `${newTopLeftRadius}px` : '',
        borderTopRightRadius: newTopRightRadius !== '' ? `${newTopRightRadius}px` : '',
        borderBottomLeftRadius: newBottomLeftRadius !== '' ? `${newBottomLeftRadius}px` : '',
        borderBottomRightRadius: newBottomRightRadius !== '' ? `${newBottomRightRadius}px` : ''
      };
  
      const updatedObjectData = { ...selectedObjectData, sticker_styling: updatedStickerStyling };
      setSelectedObjectData(updatedObjectData);
  
      // Update props.data array for parent component
      const updatedData = props.data.map(obj => obj.stickerID === props.selectedObjectID ? updatedObjectData : obj);
      console.log(updatedData, updatedObjectData)
      props.updateStickers(updatedData, updatedObjectData)

      // Firestore updates for frames
      const stickersRef = doc(db, 'stickers', props.selectedObjectID);
      await updateDoc(stickersRef, { sticker_styling: updatedStickerStyling });
  
      // Update the stickers array in the stores document
      const storesRef = doc(db, 'stores', storeID);
      updateDoc(storesRef, { stickers: updatedData })

    }
    setIsManualBorderRadiusChange(true)
  };

  const handleBorderStyle = async (borderStyleValue) => {
    console.log(borderStyleValue);

    // Building the new border style string
    const newBorderStyle = borderWidth !== '0' && borderWidth.trim() !== '' 
        ? `${borderWidth}px ${borderStyleValue}` 
        : 'none';

    console.log(newBorderStyle)

    // Update the local state for borderStyle
    setBorderStyle(borderStyleValue);

    // Indicate a manual update has occurred
    setBorderManualUpdate(true);

    const updatedObjectWithNewStyle = props.data.find(obj => obj.stickerID === props.selectedObjectID);
    
    if (updatedObjectWithNewStyle) {
        updatedObjectWithNewStyle.sticker_styling = {
            ...updatedObjectWithNewStyle.sticker_styling,
            border: newBorderStyle,
        };
    } else {
        console.log('Selected object not found');
        return; // Exit the function if the object is not found
    }

    // Update the data array in the local state
    const updatedData = props.data.map(obj => 
      obj.stickerID === props.selectedObjectID ? updatedObjectWithNewStyle : obj
    );

    console.log(updatedData, updatedObjectWithNewStyle);
    props.updateStickers(updatedData, updatedObjectWithNewStyle)
    // Set the updated object data in the state
    setSelectedObjectData(updatedObjectWithNewStyle)

    // Update Firestore for the current sticker
    const stickerRef = doc(db, 'stickers', props.selectedObjectID);
    await updateDoc(stickerRef, {
      sticker_styling: updatedObjectWithNewStyle.sticker_styling,
    });

    // Update the sticker array in the stores document
    const storesRef = doc(db, 'stores', storeID);
    await updateDoc(storesRef, {
      stickers: updatedData,
    });
  };

  const handleBorderSelectedColor = useCallback(async (color) => {
    if (color) {
      console.log(color);
  
      // Find and update the selected sticker object
      const updatedSticker = props.data.find(obj => obj.stickerID === props.selectedObjectID);
      if (updatedSticker) {
        // Construct the new border string
        const newBorder = borderWidth && borderStyle 
                          ? `${borderWidth}px ${borderStyle} ${color}` 
                          : 'none';
  
        // Update sticker_styling with the new border
        const updatedStickerStyling = {
          ...updatedSticker.sticker_styling,
          border: newBorder
        };
  
        // Create a new updated object with the updated sticker_styling
        const updatedStickerWithColor = {
          ...updatedSticker,
          sticker_styling: updatedStickerStyling
        };
  
        // Update the selectedObjectData state
        setSelectedObjectData(updatedStickerWithColor);
  
        // Update the props.data array
        const updatedData = props.data.map(obj => 
          obj.stickerID === props.selectedObjectID ? updatedStickerWithColor : obj
        );
  
        // Call the updateStickers function with the updated data
        props.updateStickers(updatedData, updatedStickerWithColor)
  
        // Firestore updates for stickers
        const stickersRef = doc(db, 'stickers', props.selectedObjectID);
        try {
          await updateDoc(stickersRef, {
            sticker_styling: updatedStickerStyling,
          });
          console.log("Firebase updated successfully");
        } catch (error) {
          console.error("Error updating color in Firebase:", error);
        }
  
        // Update the stores document
        const storesRef = doc(db, 'stores', storeID);
        await updateDoc(storesRef, {
          stickers: updatedData,
        });
      }
    }
  }, [props.data, props.selectedObjectID, props.updateStickers, borderWidth, borderStyle, storeID]);
  
  return (
    <div>
      <div className="stickerBar" ref={props.vendorBarRef}>
        <img 
          className="imageFrameBorderBtn" 
          src={borderIcon} 
          onClick={() => {
            setShowFrameBorderPopup(!showFrameBorderPopup)
          }}>
        </img>
        <div className="stickerZIndexBtn" onClick={() => props.setShowZIndexPopup(!(props.showZIndexPopup))}>
          position
        </div>

        <div className="stickerDimensions" onClick={() => {setShowStickerDimensionPopup(!showStickerDimensionPopup)}}>
          dimensions
        </div>

        <div className="stickerDeleteBtn" onClick={() => {props.deleteSticker(props.selectedObjectID)}}>
          <img src={deleteIcon} className="vendorStickerDeleteIcon" />
        </div>

      </div>
      {showStickerDimensionPopup &&
        <div className="stickerDimensionPopup">
          <div className="stickerHeight">
            height: 
            <input 
              type="number" 
              min="0" 
              value={props.selectedObject?.height || 0}
              onChange={props.handleHeightChange}
              id='stickerDimension' step="any"/>
            px
          </div>
          <div className="stickerWidth">
            width:
            <input 
              type="number" 
              min="0" 
              value={props.selectedObject?.width || 0}
              onChange={props.handleWidthChange}
              id='stickerDimension' step="any"/>
            px
          </div>
        </div>
      }
      {showFrameBorderPopup &&
        <div className="imageFrameBorderPopupDiv" ref={props.vendorBarRef}>
          <div className="frameBorderStyleDiv">
            <div className="frameBorderStyleText">border style</div>
            <div className="frameBorderStyleIconDiv">
              <img 
                src={noBorderIcon} 
                className={`noBorderBtn${borderStyle === 'none' ? 'active' : ''}`}
                onClick={() => {handleBorderStyle('none')}}
              />
              <img 
                src={lineBorderIcon} 
                className={`lineBorderBtn${borderStyle === 'solid' ? 'active' : ''}`} 
                onClick={() => {handleBorderStyle('solid')}}
              />
              <img 
                src={dashedBorderIcon} 
                className={`dashedBorderBtn${borderStyle === 'dashed' ? 'active' : ''}`}
                onClick={() => {handleBorderStyle('dashed')}}
              />
              <img 
                src={dottedBorderIcon} 
                className={`dottedBorderBtn${borderStyle === 'dotted' ? 'active' : ''}`}
                onClick={() => {handleBorderStyle('dotted')}}
              />
            </div>
          </div>
          <div className="frameBorderWidth">
            <div className="frameBorderWidthText">border width</div>
            <div className="frameBorderWidthInputSection">
              <input 
                type="text" 
                className="frameBorderWidthInput"
                value={borderWidth}
                onChange={(e) => {
                  setBorderWidth(e.target.value);
                  handleBorderWidthChange(e.target.value);
                }}
              />
              <div className="frameBorderWidthPixelText">px</div>
            </div>
          </div>
          <div className="imageFrameBorderRadius">
            <div className="imageFrameBorderRadiusFirstSection">
              <div className="imageFrameBorderRadiusText">border radius</div>
              <div className="imageFrameBorderRadiusInputSection">
                <input 
                  type="text" 
                  className="imageFrameBorderRadiusInput" 
                  value={unifiedBorderRadius}
                  style={{ width: unifiedBorderRadius == 'mixed' ? '60px' : '30px'}}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setUnifiedBorderRadius(newValue); // Directly update unifiedBorderRadius
                    handleBorderRadiusChange(newValue); // Handle other updates
                  }}
                />
                <div className="imageFrameBorderRadiusPixelText">px</div>
              </div>
            </div>
            <div className="imageFrameBorderRadiusCornerDropdown">
              <div className="imageFrameCornerSectionContainer">
                <div className="imageFrameCornerSection1">
                  <img src={borderRadiusIcon} className="imageFrameCornerIcon" />
                  <div className="imageFrameCornerSectionText">set individual corner radii</div>
                </div>
                <img 
                  src={dropdownIcon} 
                  className="imageFrameCornerSectionDropdownIcon" 
                  onClick={() => {setShowCornerDropdownContent(!showCornerDropdownContent)}}
                />
              </div>
              {showCornerDropdownContent &&
                <div className="imageFrameCornerDropdownContainer">
                  <div className="imageFrameCornerDropdownTopSection">
                    <div className="topLeftCornerDiv">
                      <img src={topLeftCornerIcon} alt="" className="topLeftCornerIcon" />
                      <input 
                        type="text" 
                        className="topLeftCornerInput" 
                        value={topLeftRadius}
                        onChange={(e) => {
                          setTopLeftRadius(e.target.value);
                          handleBorderRadiusChange(e.target.value, 'topLeft');
                        }}
                      />
                    </div>
                    <div className="topRightCornerDiv">
                      <img src={topRightCornerIcon} alt="" className="topRightCornerIcon" />
                      <input 
                        type="text" 
                        className="topRightCornerInput" 
                        value={topRightRadius}
                        onChange={(e) => {
                          setTopRightRadius(e.target.value);
                          handleBorderRadiusChange(e.target.value, 'topRight');
                        }}
                      />
                    </div>
                  </div>
                  <div className="imageFrameCornerDropdownBottomSection">
                    <div className="bottomLeftCornerDiv">
                      <img src={bottomLeftCornerIcon} alt="" className="bottomLeftCornerIcon" />
                      <input 
                        type="text" 
                        className="bottomLeftCornerInput" 
                        value={bottomLeftRadius}
                        onChange={(e) => {
                          setBottomLeftRadius(e.target.value);
                          handleBorderRadiusChange(e.target.value, 'bottomLeft');
                        }}
                      />
                    </div>
                    <div className="bottomRightCornerDiv">
                      <img src={bottomRightCornerIcon} alt="" className="bottomRightCornerIcon" />
                      <input 
                        type="text" 
                        className="bottomRightCornerInput" 
                        value={bottomRightRadius}
                        onChange={(e) => {
                          setBottomRightRadius(e.target.value);
                          handleBorderRadiusChange(e.target.value, 'bottomRight');
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="frameBorderColor">
            <div className="frameBorderColorText" >
              border colour
              <img 
                src={dropdownIcon} 
                className="borderColourSectionDropdownIcon" 
                onClick={() => {setShowBorderColourPopup(!showBorderColourPopup)}}
              />
            </div>
            {showBorderColourPopup &&
              <div className="frameBorderColour">
                <TextColourPopup
                data={props.storeData}
                closePopup={() => {setShowBorderColourPopup(false)}}
                onSelectColor={handleBorderSelectedColor}
                />
              </div> 
            }
          </div>
        </div>
      }
    </div>
  )
}

export default StickerBar