import React, { useRef, useState, useEffect } from 'react';
import { doc, updateDoc } from '@firebase/firestore';
import { db } from '../../firebase';
import ColorThief from 'colorthief';
import ColorPicker from './ColorPicker'; // Import the ColorPicker component

import checkIcon from '../../icons/checkIcon.png'
import dropdownIcon from '../../icons/popupIcon.png';
import './WebsiteBackgroundPopup.css';
import './ColourSquares.css';
import './ColorPicker.css'; // Import the CSS for ColorPicker

const rgbStringToHex = (rgbString) => {
  const result = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/.exec(rgbString);
  if (!result) {
    return '#FFFFFF'; // Default to white if parsing fails
  }
  const [, r, g, b] = result.map(Number);
  return rgbToHex(r, g, b);
};

const hexToRgb = (hex) => {
  // Remove '#' if present
  hex = hex.replace('#', '');
  // Parse r, g, b
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};

const rgbToHex = (r, g, b) => {
  return (
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};

const getTransitionalColor = (color1, color2) => {
  // Convert color1 and color2 to RGB arrays
  let rgb1, rgb2;

  // Determine if color is in hex or rgb format
  if (color1.startsWith('rgb')) {
    rgb1 = hexToRgb(rgbStringToHex(color1));
  } else {
    rgb1 = hexToRgb(color1);
  }

  if (color2.startsWith('rgb')) {
    rgb2 = hexToRgb(rgbStringToHex(color2));
  } else {
    rgb2 = hexToRgb(color2);
  }

  // Calculate the average of each RGB component
  const r = Math.round((rgb1[0] + rgb2[0]) / 2);
  const g = Math.round((rgb1[1] + rgb2[1]) / 2);
  const b = Math.round((rgb1[2] + rgb2[2]) / 2);

  // Convert back to hex
  return rgbToHex(r, g, b);
};

// Utility functions can be moved to a separate file if used across multiple components
const contrastRatio = (color1, color2) => {
  const luminance = (rgb) => {
    const a = rgb.map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  const lum1 = luminance(color1);
  const lum2 = luminance(color2);
  const [light, dark] = lum1 > lum2 ? [lum1, lum2] : [lum2, lum1];
  return (light + 0.05) / (dark + 0.05);
};

const rgbToHsl = (r, g, b) => {
  r /= 255; g /= 255; b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default: h = 0;
    }
    h /= 6;
  }
  return [h, s, l];
};

const hslToRgb = (h, s, l) => {
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

const WebsiteBackgroundPopup = (props) => {
  const { 
    parentComponent,
    useCase, // New prop to determine the use case
    storeID, 
    closePopup, 
    show, 
    onClickOutside, 
    onSelectColor, 
    objectColor, // Renamed to be more generic
    data, 
    pickerRef, 
    onDone, 
    onCancel, 
    onSizeChange, 
    showCommonColors,
    setShowCommonColors,
    setColorPickerOpen
  } = props;

  const commonColorsDivRef = useRef(null);
  const colorSelectorPopupRef = useRef(null);

  const [dominantColor, setDominantColor] = useState(null);
  const [lightColor, setLightColor] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [customColor, setCustomColor] = useState(objectColor || '#FFFFFF'); // Initialize to objectColor or default
  const [initialColor, setInitialColor] = useState(objectColor || '#FFFFFF'); // Initial color when picker is opened
  const [isPickerOpen, setIsPickerOpen] = useState(false); // Track if picker is open
  const [transitionalColor, setTransitionalColor] = useState('#FFFFFF')
  const [displayPicker, setDisplayPicker] = useState(false);

  // Define your colors array
  const colors = [
    "#FFFFFF","#FF8A8A", "#FFD280", "#FDE890", "#C8E163", "#B2FFFF", "#AFAFED", "#D7A1F9", "#FFD1DC", "#FFE5B4",
    "#E8E8E8", "#E84B4F", "#E8A14D", "#FFFF00", "#77DD77", "#00FFFF", "#8A8AE5", "#C576F6", "#F39AA5", "#DA9E73",        
    "#D9D9D9", "#E32227", "#FFA500", "#FFD700", "#28FF00", "#89CFF0", "#6464DC", "#8B52FE", "#FE65C4", "#E8A14D",         
    "#A6A6A6", "#FF0000", "#F28500", "#F7C331", "#00D100", "#00D1D1", "#3F3FD4", "#A020F0", "#EF3A5D", "#923806",          
    "#545454", "#BF181D", "#D18700", "#D1D100", "#0EC75A", "#53B0AE", "#2A2ABB", "#880ED4", "#B00058", "#A07671",         
    "#2E2E2E", "#961316", "#A36A00", "#A3A300", "#00A86B", "#00A3A3", "#212196", "#6C0BA9", "#820041", "#523936",        
    "#000000", "#6D0E10", "#7B3F00", "#757500", "#048037", "#007575", "#191970", "#51087E", "#55002A", "#362624" 
  ];

  const isColorDark = (color) => {
    const rgb = hexToRgb(normalizeColor(color));
    const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
    return luminance < 0.5; // Threshold can be adjusted
  }

  const normalizeColor = (color) => {
    if (!color) return null;
    if (color.startsWith('rgb')) {
      return rgbStringToHex(color).toLowerCase();
    } else if (color.startsWith('#')) {
      return color.toLowerCase();
    }
    // Add handling for other formats if necessary
    return color.toLowerCase();
  };
  

  // Handle clicks outside the popup to close it
  useEffect(() => {
    if (parentComponent !== 'imageFrameBar' || parentComponent !== 'frameBar') {
    const handleClickOutside = (event) => {
      // If pickerRef is defined and the click is inside picker, do nothing
      if (pickerRef.current && pickerRef.current.contains(event.target)) {
        return;
      }
      // If click is outside the popup, trigger onClickOutside
      if (colorSelectorPopupRef.current && !colorSelectorPopupRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside, false);
    document.addEventListener('touchstart', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('touchstart', handleClickOutside, true);
    };
  }
  }, [onClickOutside, pickerRef]);

  // Handle image loading and dominant color extraction
  useEffect(() => {
    const img = new Image();
    const colorThief = new ColorThief();

    img.crossOrigin = "Anonymous"; // Handle CORS issues
    img.src = data.store_logo;

    img.onload = () => {
      if (img.complete && img.naturalHeight !== 0) {
        try {
          const colorPalette = colorThief.getPalette(img, 10);
          const colorPaletteRGBStrings = colorPalette.map(color => `rgb(${color[0]}, ${color[1]}, ${color[2]})`);

          // Find the lightest color with sufficient contrast
          let lightestColor = colorPalette[0];
          let maxLightness = rgbToHsl(...colorPalette[0])[2];

          for (let i = 1; i < colorPalette.length; i++) {
            const currentLightness = rgbToHsl(...colorPalette[i])[2];
            if (currentLightness > maxLightness) {
              maxLightness = currentLightness;
              lightestColor = colorPalette[i];
            }
          }

          // Ensure the lightest color has a contrast ratio >= 4.5 with black
          let black = [0, 0, 0];
          while (contrastRatio(lightestColor, black) < 4.5) {
            let hsl = rgbToHsl(...lightestColor);
            hsl[2] += 0.05; // Increase lightness by 5%
            if (hsl[2] > 1) hsl[2] = 1; // Cap at 100%
            lightestColor = hslToRgb(...hsl);
            if (hsl[2] === 1) break; // Prevent infinite loop
          }

          setLightColor(`rgb(${lightestColor[0]}, ${lightestColor[1]}, ${lightestColor[2]})`);
          setDominantColor(colorPaletteRGBStrings);
        } catch (err) {
          console.error('Error getting color palette', err);
        }
      } else {
        console.log('Image not fully loaded');
      }
    };
  }, [data.store_logo]);

  // Synchronize customColor with objectColor when picker is not open
  useEffect(() => {
    if (!isPickerOpen) {
      setCustomColor(objectColor || '#FFFFFF');
      setInitialColor(objectColor || '#FFFFFF');
    }
  }, [objectColor, isPickerOpen]);

  useEffect(() => {
    if (lightColor && objectColor) {
      const transitional = getTransitionalColor(lightColor, objectColor);
      setTransitionalColor(transitional);
    }
  }, [lightColor, objectColor])

  useEffect(() => {
    if (colorSelectorPopupRef.current && onSizeChange && (parentComponent !== 'frameBar' || parentComponent !== 'imageFrameBar')) {
      const measure = () => {
        const rect = colorSelectorPopupRef.current.getBoundingClientRect();
        if (displayPicker) {
          onSizeChange(rect.width, (rect.height + (showCommonColors ? 0 : 320)));
        } else {
          onSizeChange(rect.width, rect.height);
        }
        
      };

      // Measure after a slight delay to ensure rendering
      // Alternatively, you can use requestAnimationFrame
      const timer = setTimeout(measure, 0);

      // Cleanup
      return () => clearTimeout(timer);
    }
  }, [displayPicker, onSizeChange, show, lightColor, objectColor, showDropdown, showCommonColors])
  
  const handleColourSelected = (color) => {
    if (color && color.hex) {
      onSelectColor(color.hex); // Pass the hex value to the parent
    } else if (typeof color === 'string') {
      onSelectColor(color); // Directly set objectColor if a string is provided
    }
  }

  let popupStyle;

  if (parentComponent === 'frameBar' || parentComponent === 'imageFrameBar') {
    popupStyle = {
      width: 'calc(100% - 20px)',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: displayPicker && '100%',
      // Do not set the background property
    };
  } else if (useCase === 'textColorChange') {
    popupStyle = {
      top: '',
      width: '100%',
      padding: '10px',
      height: displayPicker && '100%',
    };
  } else {
    popupStyle = {
      background: 'rgba(236, 236, 236, 0.5)',
      top: '75px',
      width: 'calc(100% - 20px)',
      padding: '10px 10px 20px 10px',
    };
  }

  // Determine the className based on parentComponent
  const popupClassName = (parentComponent === 'frameBar' || parentComponent === 'imageFrameBar') ? 'frameBorderPopup' : 'backgroundSelectorPopup';

  if (!show) return null;

  return (
    <div 
      className={popupClassName}
      ref={colorSelectorPopupRef}
      style={popupStyle} // Apply conditional positioning
    >
      {/* Header can be dynamic based on useCase */}
      {((useCase !== 'textColorChange') && (parentComponent !== 'frameBar' || parentComponent !== 'imageFrameBar') && (
        <h1 className="backgroundSelectorHeader">
          {(useCase === 'textColorChange') && 'text'}
          {(useCase === 'websiteColorChange') && 'background'}
        </h1>
        ))
      }

      {/* Add Choose Specific Colour */}
      <ColorPicker
        useCase={useCase}
        color={objectColor}
        onChangeComplete={onSelectColor}
        objectColor={objectColor}
        pickerRef={pickerRef}
        onDone={onDone}
        onCancel={onCancel}
        displayPicker={displayPicker}
        setDisplayPicker={setDisplayPicker}
        setColorPickerOpen={setColorPickerOpen}
      />

      {/* Suggested Colors Section */}
      <div className="brandColorsDiv" style={{ backgroundColor: lightColor }}>
        <div className="brandColorSectionHeader">
          <div className="bCSection1">
            <img src={data.store_logo} className="websiteLogoSquare" alt="Store Logo" />
            <h1 className="brandColorsDivHeader">logo suggested colours</h1>
          </div>

          {/* Toggle Dropdown for Suggested Colors */}
          <img
            className={`brandColorDropdownIcon${showDropdown ? 'Active' : ''}`}
            onClick={() => setShowDropdown(!showDropdown)}
            src={dropdownIcon}
            alt="dropdown icon"
          />
        </div>
        <div className="brandColorSection"
          style={{ margin: showDropdown ? '0px 5px 5px 5px' : '0px' }}
        >
          {showDropdown && dominantColor && dominantColor.map((color, index) => (
            <div
              className="brandColorSquare"
              key={index}
              style={{ backgroundColor: color }}
              onClick={() => {
                handleColourSelected(color)
                onDone(color)
              }}
            />
          ))}
        </div>
      </div>

      {/* Collapsible Common Colors Section for textColorChange */}
      {useCase === 'textColorChange' && (
        <div 
          className="commonColorsDiv" 
          style={{ backgroundColor: transitionalColor }}
          ref={commonColorsDivRef}
        >
          <div 
            className="commonColorsSection"
            style={{ 
              display: showCommonColors ? 'grid' : 'none',
              gridTemplateColumns: 'repeat(10, 1fr)', // 10 columns of equal width
              gap: '10px', // Spacing between squares
              width: '100%',
              padding: '8px',
              margin: '5px',
            }}
          >
            {colors.map((color, index) => {
              const isSelected = normalizeColor(objectColor) === normalizeColor(color);
              const isDark = isColorDark(color)
              
              return (
                <div
                  key={index}
                  className={`colour colour${index + 1} colourSquare${isSelected ? ' selected' : ''}${isSelected && isDark ? ' dark' : ''}`}
                  id="colourSquare"
                  onClick={() => {
                    handleColourSelected(color)
                    onDone(color)
                  }}
                  style={{ 
                    backgroundColor: color,
                    border: normalizeColor(objectColor) === normalizeColor(color) ? '2px inset #000' : 'none' 
                  }}
                />
              )
            })}
          </div>
          <div className="commonColorsHeader">
            <h1 className="commonColorsDivHeader">common colours</h1>
            <img
              className={`commonColorsDropdownIcon${showCommonColors ? 'Active' : ''}`}
              onClick={() => {
                setShowCommonColors(!showCommonColors)
              }}
              src={dropdownIcon}
              alt="dropdown icon"
            />
          </div>
        </div>
      )}

      {showCommonColors && (
        <div className='expandedTextColourDoneDiv'>
          <div className='expandedTextColourDoneBtn'
            onClick={() => {
              setShowCommonColors(false)
              closePopup()
            }}
          >
            done
            <img src={checkIcon} 
              className="expandedTextColourDoneCheckIcon" 
              alt="finished with text"
            />
          </div>
        </div>
      )}

      {/* Predefined Color Options for Background Selection */}
      {useCase !== 'textColorChange' && (
        <div 
          className="backgroundOptions"
          style={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(10, 1fr)', // 10 columns of equal width
            gap: '10px', // Spacing between squares
            width: '100%',
            padding: '0 10px',
            margin: '5px',
            boxSizing: 'border-box'
          }}
        >
          {colors.map((color, index) => {
            const isSelected = normalizeColor(objectColor) === normalizeColor(color);
            const isDark = isColorDark(color)

            return (
            <div
              key={index}
              className={`colour colour${index + 1} colourSquare${isSelected ? ' selected' : ''}${isSelected && isDark ? ' dark' : ''}`}
              id="colourSquare"
              onClick={() => {
                handleColourSelected(color)
                onDone(color)
              }}
              style={{
                backgroundColor: color,
                cursor: 'pointer',
                borderRadius: '8px',
                boxSizing: 'border-box',
                position: 'relative', 
              }} 
            />
            )
          })}
        </div>
      )}
    </div>
  );
};

export default WebsiteBackgroundPopup;
