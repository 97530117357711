import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { auth, db, storage } from '../../firebase'
import { collection, doc, updateDoc, setDoc, arrayUnion,
getDoc, onSnapshot, deleteDoc, serverTimestamp }
from '@firebase/firestore'

import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4} from "uuid";
import { motion, AnimatePresence } from 'framer-motion';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import './AddProduct.css'
import ShippingOptions from './ShippingOptions';
import MarketGradient from '../../market/MarketGradient';
import FilmGrain from '../../scout/FilmGrain';
import UploadComponent from './UploadComponent';
import EthicalChecks from './EthicalChecks';

import CategoriesDiv from './CategoriesDiv';
import AntiquesCollectablesTags from './CategoryTags/AntiquesCollectablesTags';
import ArtTags from './CategoryTags/ArtTags';
import AudioMediaTags from './CategoryTags/AudioMediaTags';
import AutomotiveDIYTags from './CategoryTags/AutomotiveDIYTags';
import BeautyTags from './CategoryTags/BeautyTags';
import BooksTags from './CategoryTags/BooksTags';
import CellphonesWearablesTags from './CategoryTags/CellphonesWearablesTags';
import CraftsHandmadeGoodsTags from './CategoryTags/CraftsHandmadeGoodsTags';
import ElectronicsTags from './CategoryTags/ElectronicsTags';
import FashionTags from './CategoryTags/FashionTags';
import GamingTags from './CategoryTags/GamingTags';
import GardenPoolPatioTags from './CategoryTags/GardenPoolPatioTags';
import HealthFitnessPersonalCareTags from './CategoryTags/HealthFitnessPersonalCareTags'
import HobbiesToysTags from './CategoryTags/HobbiesToysTags';
import HolisticEsotericTags from './CategoryTags/HolisticEsotericTags';
import HomeDecorTags from './CategoryTags/HomeDecorTags';
import MoviesTelevisionTags from './CategoryTags/MoviesTelevisionTags';
import MusicInstrumentsTags from './CategoryTags/MusicInstrumentsTags';
import OfficeStationaryTags from './CategoryTags/OfficeStationaryTags';
import PetsTags from './CategoryTags/PetsTags';
import PhysicalSportTrainingTags from './CategoryTags/PhysicalSportTrainingTags'

import backArrow from '../../icons/backArrow.png'
import infoIcon from '../../icons/infoIcon.png'

function getProductID() {
  let title = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'


  for (let i = 0; i < 7; i++) {
    title += characters.charAt(Math.floor(Math.random() * characters.length))
  }


  title += new Date().getTime()
  return title
}


const AddProduct = (props) => {
  const navigate = useNavigate()

  const feesPopupRef = useRef()
  const feesPopupBackgroundRef = useRef()
  const quantityRef = useRef()
  const priceRef = useRef()
  const titleRef = useRef()
  const categoryRef = useRef()
  const descriptionRef = useRef()

  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("");
  const [listedPrice, setListedPrice] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [originalStatus, setOriginalStatus] = useState(null)
  const [description, setDescription] = useState("");

  const [showUploadDiv, setShowUploadDiv] = useState(false)
  const [progressCompleted, setProgressCompleted] = useState(false)
  const [file, setFile] = useState("");
  const [uploadTask, setUploadTask] = useState(null);
  const [commission, setCommission] = useState(0)
  const [data, setData] = useState({})
  const [percentage, setPercentage] = useState(null)
  const [showProgressBar, setShowProgressBar] = useState(false)

  const [tags, setTags] = useState([])
  const [submit, setSubmit] = useState(false)
  const [productID, setProductID] = useState(getProductID())
  const [userID, setUserID] = useState(props.user ? props.user.uid : null)
  const [storeID, setStoreID] = useState()

  const [uploadsInProgress, setUploadsInProgress] = useState(0);
  const location = useLocation()
  const initialPathRef = useRef(location.pathname);
  const isEditing = location.state?.isEditing
  const [imageUrl, setImageUrl] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [fetchedImageUrl, setFetchedImageUrl] = useState(null);
  const [secondaryImageUrls, setSecondaryImageUrls] = useState([]);
  const [fetchedImageThumbnail, setFetchedImageThumbnail] = useState(null)
  const [imagesChanged, setImagesChanged] = useState(false)

  const [completedUploads, setCompletedUploads] = useState(0);
  const [uploadImages, setUploadImages] = useState([])

  const [isFormValid, setIsFormValid] = useState(false);
  const [ethicalChecks, setEthicalChecks] = useState([])
  const [ethicalChecksText, setEthicalChecksText] = useState('')
  const [showSaveDraft, setShowSaveDraft] = useState(false);

  const [isImageRequired, setIsImageRequired] = useState(true);

  useEffect(() => {
    setIsImageRequired(!(uploadImages.length > 0 || imageUrl));
  }, [uploadImages, imageUrl]);


  // const [sellerDeliveryPreferance, setSellerDeliveryPreferance] = useState('') 
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [savedDominantColors, setSavedDominantColors] = useState([
    'rgb(197, 201, 198)',
    'rgb(91, 106, 125)',
    'rgb(215, 87, 89)',
    'rgb(234, 86, 1)',
    'rgb(114, 126, 132)'
  ]);

  const[showGotIt, setShowGotIt] = useState(false)

  const [shippingOptions, setShippingOptions] = useState([])
  const [showBundlingInfo, setShowBundlingInfo] = useState(false)
  const [bundlingRule, setBundlingRule] = useState("")

  const [priceError, setPriceError] = useState('');
  const [listedPriceError, setListedPriceError] = useState('');

  useEffect(() => {
    if (isEditing) {
      document.title = 'Edit Product | taizte™';
    } else {
      document.title = 'Add Product | taizte™';
    }
  }, []);

  const handleFieldChange = () => {
    setShowSaveDraft(true);
  };

  // Fetch product data if editing
  useEffect(() => {
    if (isEditing) {
      const editingProductID = location.state?.productID;
      const editingStoreID = location.state?.storeID;
      setProductID(editingProductID);
      setStoreID(editingStoreID);
  
      const productRef = doc(db, 'products', editingProductID);
      getDoc(productRef).then((docSnap) => {
        if (docSnap.exists()) {
          const firebaseData = docSnap.data();
  
          // Set state variables
          setQuantity(firebaseData.quantity || "1");
          setPrice(firebaseData.seller_price || "");
          setListedPrice(firebaseData.listed_price || "");
          setTitle(firebaseData.productName || "");
          setOriginalStatus(firebaseData.status || '')
          setCategory(firebaseData.category || "");
          setDescription(firebaseData.description || "");
          setTags(firebaseData.tags || []);
          setEthicalChecks(firebaseData.ethicalChecks || []);
          setEthicalChecksText(firebaseData.ethicalChecksText || "");
          setShippingOptions(firebaseData.shipping || []); // Ensure shipping options are set
          setBundlingRule(firebaseData.bundling || ""); // Ensure bundling state is set

          // incorporate when multiple options available
          // setSellerDeliveryPreferance(firebaseData.sellerDeliveryPreferance || "");
          
          // Handle fees_on_product
          if (firebaseData.fees_on_product !== undefined && firebaseData.fees_on_product !== null && firebaseData.fees_on_product !== "") {
            setCommission(firebaseData.fees_on_product);
          } else if (firebaseData.listed_price && firebaseData.seller_price) {
            const calculatedCommission = firebaseData.listed_price - firebaseData.seller_price;
            setCommission(calculatedCommission);
            
            // Optionally update Firestore with the calculated commission
            updateDoc(productRef, {
              fees_on_product: calculatedCommission
            }).then(() => {
              console.log("fees_on_product was missing and has been calculated and updated.");
            }).catch(error => {
              console.error("Error updating fees_on_product:", error);
            });
          } else {
            setCommission(0);
          }

          // Handle images
          const imagesFromFirebase = [];
          if (firebaseData.images && firebaseData.images.length > 0) {
            firebaseData.images.forEach((image, index) => {
              imagesFromFirebase.push({
                file: null,
                progress: 100,
                preview: image,
                completed: true,
                fromFirebase: true
              });
  
              if (firebaseData.imageThumbnails && index < firebaseData.imageThumbnails.length) {
                setImageUrl(firebaseData.imageThumbnails[index]);
              } else if (index === 0) {
                setImageUrl(image); // Fallback if thumbnails are missing
              }
            });
          }
  
          setUploadImages(imagesFromFirebase);
          setFetchedImageUrl(firebaseData.images || []);
          setFetchedImageThumbnail(firebaseData.imageThumbnails || []);
        } else {
          console.log("No such product!");
        }
      }).catch(error => {
        console.error("Error fetching product data:", error);
      });
    }
  }, [isEditing, location.state]);

  useEffect(() => {
    initialPathRef.current = location.pathname;
  }, [location]);

  // Conditionally run createBasicProductDoc only when not editing and user is present
  useEffect(() => {
    if (!isEditing && props.user) {
      createBasicProductDoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, props.user]); // Added dependencies 

  function createBasicProductDoc() {
    if (isEditing) {
      console.log("User is editing a product")
    } else {
      if (props.user) {
        setUserID(props.user.uid);
        // Fetch user's storeID
        getDoc(doc(db, 'users', props.user.uid)).then(docSnap => {
          if (docSnap.exists()) {
            setStoreID(docSnap.data().storeID)
          } else {
            console.log("The user does not have a store")
          }
        })

        const dbRef = doc(db, 'products', productID)

        setDoc(dbRef, {
          quantity: "",
          seller_price: "",
          fees_on_product: "",
          listed_price: "",
          productName: "",
          category: "",
          description: "",
          images: "",
          imageThumbnails: "",
          likes: "",
          productID: productID,
          userID: props.user.uid,
          shipping: "",
          user: props.user.displayName,
          email: props.user.email,
          store: "",
          store_description: "",
          storeID: "",
          store_logo: "",
          // incorporate when multiple options available
          // sellerDeliveryPreferance: "",
          productSold: false,
          productSent: false,
        })
        .then(() => {
          console.log("Basic product document created.")
        })
        .catch(error => {
          console.error("Error creating basic product document:", error);
        })

        // Update the document with store details
        getDoc(doc(db, 'users', props.user.uid)).then(docSnap => {
          if (docSnap.exists()) {
            updateDoc(dbRef, {
              store: docSnap.data().store,
              store_description: docSnap.data().store_description,
              storeID: docSnap.data().storeID,
              store_logo: docSnap.data().store_logo,
            }).then(() => {
              console.log("Store details updated in product document.")
            }).catch(error => {
              console.error("Error updating store details:", error);
            })
          } else {
            console.log("No such user document!");
          }
        })
      }
    }
  }

  const handlePriceChange = (e) => {
    const inputValue = e.target.value;
  
    // Allow empty input
    if (inputValue === '') {
      setPrice('');
      setListedPrice('');
      setCommission(0);
      setPriceError(''); // Clear any existing errors
      return;
    }
  
    const productPrice = parseFloat(inputValue);
  
    // Validate the price
    if (isNaN(productPrice)) {
      setPriceError('Please enter a valid number');
    } else if (productPrice < 9) {
      setPriceError('The minimum selling price is R10');
    } else {
      setPriceError(''); // Clear any existing errors
    }
  
    setPrice(inputValue);
  
    // Calculate listed price only if the price is valid
    if (!isNaN(productPrice) && productPrice >= 9) {
      const updatedListedPrice = Math.round(productPrice / 0.9); // Correct calculation for the inverse of 10% commission
      setListedPrice(updatedListedPrice);
      setCommission(updatedListedPrice - productPrice);
    } else {
      setListedPrice('');
      setCommission(0);
    }
  
    handleFieldChange();
  };
  
  const handleListedPriceChange = (e) => {
    const inputValue = e.target.value;
  
    // Allow empty input
    if (inputValue === '') {
      setListedPrice('');
      setPrice('');
      setCommission(0);
      setListedPriceError(''); // Clear any existing errors
      return;
    }
  
    const listedPriceValue = parseFloat(inputValue);
  
    // Validate the listed price
    if (isNaN(listedPriceValue)) {
      setListedPriceError('Please enter a valid number');
    } else if (listedPriceValue < 10) {
      setListedPriceError('The minimum selling price is R10');
    } else {
      setListedPriceError(''); // Clear any existing errors
    }
  
    setListedPrice(inputValue);
  
    // Calculate seller price only if the listed price is valid
    if (!isNaN(listedPriceValue) && listedPriceValue >= 10) {
      const productPrice = Math.round(listedPriceValue * 0.9); // 10% commission
      setPrice(productPrice);
      setCommission(listedPriceValue - productPrice);
    } else {
      setPrice('');
      setCommission(0);
    }
  
    handleFieldChange();
  };
  
  useEffect(() => {
    const handlePopState = () => {
      console.log('Popstate event detected'); // Debug line
      const newPath = window.location.pathname;
      if (initialPathRef.current !== newPath) {
        console.log('Path changed'); // Debug line
        checkForEmptyFields();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  useEffect(() => {
    if (!file) return

    const name = file.name + uuidv4()
    const storageRef = ref(storage, `product images/${name}`)
    const newUploadTask = uploadBytesResumable(storageRef, file)

    setUploadTask(newUploadTask);

    const uploadFile = () => {
      setShowUploadDiv(true)
      newUploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress)
            setPercentage(progress)
        },
        (error) => {console.log(error)},
        () => {
          getDownloadURL(newUploadTask.snapshot.ref).then((downloadURL) => {
            console.log(downloadURL)
            setData((prev) => ({...prev, img:downloadURL}))
          });
        }
      );
    }
    file && uploadFile()
  }, [file])

  const removeExistingResolution = (url) => {
    return url.replace(/(_1000x1000|_150x150)/g, '');
  };
  
  const resizeThumbnailImageUrl = (url) => {
    const cleanedUrl = removeExistingResolution(url);
    return resizeImageUrl(cleanedUrl, '_150x150');
  };
  
  const resizeImageUrl = (url, resolution) => {
    const queryParamIndex = url.lastIndexOf('?');
    return queryParamIndex === -1 ? url + resolution : url.substring(0, queryParamIndex) + resolution + url.substring(queryParamIndex);
  };

  const resizePrimaryImageUrl = (url) => {
    const cleanedUrl = removeExistingResolution(url);
    return resizeImageUrl(cleanedUrl, '_1000x1000');
  };

  const saveProduct = async (e) => {
    e.preventDefault();
  
    let productImg;
    if (uploadImages.length > 0) {
      productImg = uploadImages[0].preview;
    } else if (imageUrl) {
      productImg = imageUrl;
    }
  
    if (!productImg) {
      console.error('Product image is required.');
      return;
    }
  
    const primaryImages = uploadImages.map((img) => resizePrimaryImageUrl(img.preview));
    const primaryThumbnails = uploadImages.map((img) => resizeThumbnailImageUrl(img.preview));
  
    if (primaryImages.length === 0 && imageUrl) {
      primaryImages.push(resizePrimaryImageUrl(imageUrl));
      primaryThumbnails.push(resizeThumbnailImageUrl(imageUrl));
    }
  
    const dbRef = doc(db, 'products', productID);
    const storeRef = doc(db, 'stores', storeID);
  
    let attributes = {
      quantity: quantity,
      seller_price: price,
      fees_on_product: commission,
      listed_price: listedPrice,
      productName: title,
      category: category,
      description: description,
      images: primaryImages,
      imageThumbnails: primaryThumbnails,
      likes: 0,
      productID: productID,
      shipping: shippingOptions,
      date_added: serverTimestamp(), // Only set date_added when creating
      bundling: bundlingRule,
      status: 'published',
      tags,
      ethicalChecks,
      ethicalChecksText,
      productSold: false,
      productSent: false,
      userID: userID,
    };
  
    try {
      const storeSnapshot = await getDoc(storeRef);
  
      if (storeSnapshot.exists()) {
        const storeData = storeSnapshot.data();
  
        if (storeData.address) {
          attributes.address = storeData.address;
        }
  
        const product_prices = storeData.product_prices || [];
        let average_price = storeData.average_price || 0;
  
        product_prices.push(Number(price));
        const total = product_prices.reduce((acc, curr) => acc + curr, 0);
        average_price = total / product_prices.length;
  
        const lowest_price = Math.min(...product_prices);
        const highest_price = Math.max(...product_prices);
  
        if (isEditing) {
          // Update existing product
          await updateDoc(dbRef, {
            ...attributes,
            store: storeData.store,
            store_description: storeData.store_description,
            storeID: storeData.storeID,
            store_logo: storeData.store_logo,
          });
        } else {
          // Create new product
          await setDoc(dbRef, {
            ...attributes,
            store: storeData.store,
            store_description: storeData.store_description,
            storeID: storeData.storeID,
            store_logo: storeData.store_logo,
          }, { merge: true });
        }
  
        await updateDoc(storeRef, {
          product_prices,
          average_price,
          lowest_price,
          highest_price
        });
  
        console.log(isEditing ? 'Product updated!' : 'Product uploaded!');
        
        // START NEW CODE HERE
        // After updating the product, if isEditing is true, we update carts and send notifications.
        if (isEditing) {
          // Fetch updated product data
          const updatedProductSnap = await getDoc(dbRef);
          if (updatedProductSnap.exists()) {
            const editedProductData = updatedProductSnap.data();
            const usersInCart = editedProductData.usersInCart || [];

            for (const userID of usersInCart) {
              const cartRef = doc(db, 'cart', userID);
              const cartSnap = await getDoc(cartRef);

              if (cartSnap.exists()) {
                const cartData = cartSnap.data();
                let updatedCart = cartData.cart || [];

                const productIndex = updatedCart.findIndex(item => item.productID === productID);
                if (productIndex > -1) {
                  const cartProduct = updatedCart[productIndex];

                  // Remove courier-related fields
                  const {
                    selected_shippingName,
                    selected_shippingPrice,
                    selected_subgroup,
                    courierMethod,
                    ...restOfCartProduct
                  } = cartProduct;

                  // Merge updated product data with the existing cart product data (minus courier fields)
                  const updatedCartProduct = {
                    ...restOfCartProduct,
                    ...editedProductData
                  };

                  // Replace product in cart
                  updatedCart[productIndex] = updatedCartProduct;

                  // Update the cart document
                  await updateDoc(cartRef, { cart: updatedCart });

                  // Create a notification
                  const storeNotificationRef = doc(collection(db, 'notifications'));
                  await setDoc(storeNotificationRef, {
                    notificationType: 'productNotification',
                    message: `The product titled '${editedProductData.productName}' has been updated. The product information in your cart has been refreshed.`,
                    messageDate: new Date().toLocaleString(),
                    messageDateNumeric: serverTimestamp(),
                    messageID: storeNotificationRef.id,
                    storeName: editedProductData.store,
                    productName: editedProductData.productName,
                    storeID: editedProductData.storeID,
                    userID: userID, // This user had the product in their cart
                    readStatus: 'unread'
                  });
                  console.log("Notification made")
                }
              }
            }
          }
        }
        // END NEW CODE HERE

        props.setAlertMessage(isEditing ? "You have successfully updated your product" : "You have successfully uploaded your product");
        props.setAlertType("success");
        setShowGotIt(true)
      } else {
        console.error('Store document does not exist.');
        props.setAlertMessage("Store not found. Cannot upload product.");
        props.setAlertType("error");
      }
    } catch (error) {
      console.error('Error saving product:', error);
      props.setAlertMessage("There was an error saving your product. Please try again.");
      props.setAlertType("error");
    }
  };

  

  const checkForEmptyFields = async () => {
    // If the product was originally published and the user hasn't changed anything
    // since loading (showSaveDraft is false), just navigate back.
    if (originalStatus === 'published' && showSaveDraft === false) {
      navigate('/vendor');
      return;
    }

    console.log('Checking for empty fields');
    const dbRef = doc(db, 'products', productID);
  
    const primaryImages = uploadImages.map((img) => resizePrimaryImageUrl(img.preview));
    const primaryThumbnails = uploadImages.map((img) => resizeThumbnailImageUrl(img.preview));

    if (primaryImages.length === 0 && imageUrl) {
      primaryImages.push(resizePrimaryImageUrl(imageUrl));
      primaryThumbnails.push(resizeThumbnailImageUrl(imageUrl));
    }
  
    const defaultAttributes = {
      quantity: quantityRef.current ? quantityRef.current.value : '1',
      seller_price: priceRef.current ? price : '0',
      fees_on_product: commission || 0,
      listed_price: listedPrice || 0,
      productName: titleRef.current ? titleRef.current.value : 'No name set',
      category: categoryRef.current ? categoryRef.current.value : '',
      description: descriptionRef.current ? descriptionRef.current.value : '',
      images: primaryImages,
      imageThumbnails: primaryThumbnails,
      likes: 0,
      productID: productID,
      shipping: shippingOptions || 'none',
      bundling: bundlingRule || 'no',
      status: 'editing',
      tags,
      ethicalChecks: ethicalChecks || [],
      ethicalChecksText: ethicalChecksText || '',
      // incorporate when multiple options available
      // sellerDeliveryPreferance,
      productSold: false,
      productSent: false,
      lastEdited: serverTimestamp()
    };
  
    const hasNonEmptyFields = Boolean(
      (titleRef.current && titleRef.current.value !== '') ||
      (descriptionRef.current && descriptionRef.current.value !== '') ||
      (priceRef.current && Number(priceRef.current.value) !== 0) ||
      (quantityRef.current && Number(quantityRef.current.value) !== 0) ||
      imageUrl
    );
  
    if (hasNonEmptyFields) {
      await updateDoc(dbRef, defaultAttributes)
        .then(() => {
          console.log('The document has been saved for future editing purposes');
          props.setProductSavedMessageFunction(true);
        })
        .catch((error) => {
          console.error('Error saving document:', error);
        });
      
      navigate('/vendor')
    } else {
      await onAuthStateChanged(auth, (user) => {
        if (user) {
          deleteDoc(dbRef)
            .then(() => {
              console.log(`Empty document ${productID} deleted from database`);
            })
            .catch((error) => {
              console.error('Error deleting empty document:', error);
            });
        }
      });

      setShowGotIt(true)
    }
  };

  function showFeesPopup() {
    feesPopupRef.current.style.visibility = "visible"
    feesPopupBackgroundRef.current.style.visibility = "visible"
  }
 
  const addTags = useCallback((productTags) => {
    setTags(productTags)
    console.log(productTags);
  }, [productID]);


  const addEthicalChecks = useCallback((ethicalCheck) => {
    setEthicalChecks(ethicalCheck)
    console.log(ethicalCheck);
  }, [productID]);


  const addEthicalChecksText = useCallback((ethicalText) => {
    setEthicalChecksText(ethicalText)
    console.log(ethicalText);
  }, [productID]);
 
  // function backToEditing() {
  //   feesPopupRef.current.style.visibility = "hidden"
  //   feesPopupBackgroundRef.current.style.visibility = "hidden"
  // }

  useEffect(() => {
    checkFormValidity();
  }, [quantityRef, price, titleRef, category, descriptionRef, file, imageUrl, shippingOptions]);
  // add sellerDeliveryPreference as one of the dependencies in the useEffect above in future

  const checkFormValidity = () => {
    const isUploading = uploadImages.some(img => !img.completed);
  
    // Check for the existence of images when editing
    const hasImagesWhileEditing = isEditing && (imageUrl || uploadImages.length > 0);
  
    // Determine if there are any price errors
    const hasPriceError = priceError !== '' || listedPriceError !== '';

    // Check if shippingOptions is provided
    const hasShippingOptions = shippingOptions.length > 0;

    // Form is valid if:
    // - All required fields are filled
    // - No price errors exist
    // - shippingOptions is provided
    // - Either all images are uploaded or existing images are present when editing
    const isFormValid = 
      price !== '' && 
      listedPrice !== '' &&
      !hasPriceError &&
      title.trim() !== '' &&
      category.trim() !== '' &&
      description.trim() !== '' &&
      hasShippingOptions &&
      (!isUploading || hasImagesWhileEditing);  

    setIsFormValid(isFormValid);
  };
  

  const variants = {
    hidden: { opacity: 0, y: 20 }, // Start below and invisible
    visible: { opacity: 1, y: 0 }, // Fade in and move up
    exit: { opacity: 0, y: 20 },   // Fade out and move down
  }

  // incorporate this after logic for local collection and 
  // driver-pickup have been developed
  // const renderDeliveryMethods = () => {
  //   return (
  //     <div className="addProductDeliveryMethodsContainer">
  //       <p className="deliveryMethodsTitle">delivery methods</p>
  //       <div className="deliveryButtons">
  //         <button 
  //           type="button" 
  //           className={`deliveryButton ${sellerDeliveryPreferance === 'driverPickup' ? 'selected' : ''}`} 
  //           onClick={() => setSellerDeliveryPreferance('driverPickup')}
  //         >
  //           driver-pickup
  //         </button>
  //         <button 
  //           type="button" 
  //           className={`deliveryButton ${sellerDeliveryPreferance === 'localCollect' ? 'selected' : ''}`} 
  //           onClick={() => setSellerDeliveryPreferance('localCollect')}
  //         >
  //           local collection
  //         </button>
  //         <button 
  //           type="button" 
  //           className={`deliveryButton ${sellerDeliveryPreferance === 'courier' ? 'selected' : ''}`} 
  //           onClick={() => setSellerDeliveryPreferance('courier')}
  //         >
  //           courier
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className="addProductPage">
      {/* <style>
        {'body { background: linear-gradient(-10deg, #D75759, #EA5601 ); }' }
      </style> */}
      <canvas className="addProductGradientCanvas">
        <MarketGradient 
          page={"market"} 
          dominantColors={savedDominantColors} 
          isWideScreen={isWideScreen}
        />
      </canvas>
      <motion.div
        key="filmGrain"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <FilmGrain />
      </motion.div>
      <div className="addProductHeader">
        <Link to="/vendor">
          <div className="vendorBack">
            <img src={backArrow} className="vendorBackIcon" onClick={() => {checkForEmptyFields()}}/>
          </div>        
        </Link>
        <div className='addProductHeadingDiv'>          
          <h1 className='addProductHeading'>{isEditing ? 'edit product.' : 'add product.'}</h1>
        </div>
      </div>
      <form className='addProductForm' onSubmit={saveProduct}>
        {props.country && props.country !== 'South Africa' && (
          <div className="countryUploadNote">
            Note that product <strong>purchasing is not available in {props.country} yet, </strong> 
            but feel free to test product uploading.
          </div>
        )}
        <div className='necessaryInfo'>
          <div className="numberInput">
            <h1 className='quantityText'>
              quantity
              <input 
                type="number" 
                min="1" 
                placeholder='1' 
                id='quantity'
                value={quantity} 
                ref={quantityRef} 
                onChange={(e) => { setQuantity(e.target.value); handleFieldChange(); }}
                required 
              />
            </h1>
            <h1 className='randSymbol'>
              R
              <input 
                type="number" 
                min="10" 
                placeholder='10' 
                id='price'
                step="any" 
                ref={priceRef} 
                value={listedPrice}
                onChange={handleListedPriceChange} 
                required
              />
            </h1>
          </div>

          {listedPriceError && 
            <p className="priceErrorMessage">
              {listedPriceError}
            </p>
          }

          {priceError && <p className="priceErrorMessage">{priceError}</p>}

          <input 
            type="text" 
            placeholder=' product name' 
            id='title' 
            ref={titleRef} 
            value={title}
            onChange={(e) => { setTitle(e.target.value); handleFieldChange(); }}
            required
          />
          <div className="categoriesDiv">
            <CategoriesDiv 
              category={category} 
              setCategory={setCategory} 
              handleFieldChange={handleFieldChange} 
            />
          </div>

          <textarea 
            placeholder=' description' 
            id='description' 
            rows="6" 
            ref={descriptionRef} 
            value={description}
            onChange={(e) => { setDescription(e.target.value); handleFieldChange(); }}
            required 
          />
        </div>
        <AnimatePresence>
          {/* Conditionally render and animate pricing breakdown if price is greater than 0 */}
          {price > 0 && (
            <motion.div
              key="pricingBreakdown" // Key is necessary for AnimatePresence to track the component
              initial="hidden" // Start from hidden state
              animate="visible" // Animate to visible state
              exit="exit" // Exit animation
              variants={variants} // Apply the variants defined above
              transition={{ duration: 0.5, ease: "easeInOut" }} // Customize transition
              className="pricingBreakdown"
            >
              <p className="pricingBreakdownTitle">pricing breakdown</p>
              <div className="pricingContainer">
                <div className="sellerEarningsContainer">
                  seller earnings:
                  <h1 className='randSymbol'>
                    {/* In future make this symbol country and currency dependant */}
                    R
                    <input 
                      type="number" 
                      min="0" 
                      placeholder='0' 
                      id='price' 
                      value={price}
                      onChange={handlePriceChange} 
                    />
                  </h1>
                </div>
                <div className="listedPriceContainer">
                  listed price:
                  <h1 className='randSymbol'>
                    R
                    <input type="number" min="0" placeholder='0' id='price' value={listedPrice}
                          onChange={handleListedPriceChange} />
                  </h1>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <UploadComponent
          productID={productID}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          setThumbnailUrl={setThumbnailUrl}
          setImagesChanged={setImagesChanged}
          setUploadImages={setUploadImages}
          uploadImages={uploadImages}
          setPercentage={setPercentage}
          percentage={percentage}
          setShowProgressBar={setShowProgressBar}
          completedUploads={completedUploads}
          setCompletedUploads={setCompletedUploads}
          setProgressCompleted={setProgressCompleted}
          setFetchedImageUrl={setFetchedImageUrl}
          setFetchedImageThumbnail={setFetchedImageThumbnail}   
          handleFieldChange={handleFieldChange}  
          required={isImageRequired}
          initialImages={isEditing ? uploadImages : []}
          isEditing={isEditing}
          setTags={setTags}
          tags={tags}
          addTags={addTags}
          existingTags={tags}
        />

        {/* incorporate when more delivery methods introduced */}
        {/* {renderDeliveryMethods()} */}
        
        <ShippingOptions setShippingOptions={setShippingOptions} shippingOptions={shippingOptions}/>
        
        <div className="bundlingDiv">
          <div className="bundlingDivContainer">
            <img src={infoIcon} className="bundlingInfoIcon" onClick={() => {setShowBundlingInfo(!showBundlingInfo)}}/>
            <div className="bundlingDivHeader">bundling</div>
            {showBundlingInfo &&
              <div className="bundlingInfoDiv">
                <p className="bundlingInfoText">
                  This allows your customers to order <strong>multiple items </strong>
                  from your store and only pay <strong>one shipping fee.</strong>
                </p>
                <div className="bundlingInfoBtn" onClick={() => {setShowBundlingInfo(false)}}>got it</div>
              </div>
            }
          </div>
          <div className="bundlingDescription">
            does bundling apply to this product?
          </div>
          <div className="bundlingBtnContainer">
            <div className={`bundlingBtnYes ${bundlingRule === "yes" ? "selected" : ""}`} onClick={() => {setBundlingRule("yes")}}>yes</div>
            <div className={`bundlingBtnNo ${bundlingRule === "no" ? "selected" : ""}`} onClick={() => {setBundlingRule("no")}}>no</div>
            {/* if bundling rules not set, a popup appears */}
          </div>
        </div>

        <EthicalChecks
          addEthicalChecks={addEthicalChecks}
          addEthicalChecksText={addEthicalChecksText}
          ethicalChecks={ethicalChecks}
          ethicalChecksText={ethicalChecksText}
          isEditing={isEditing}
        />

        {/* {category === "Antiques & Collectables" && (<AntiquesCollectablesTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Art" && (<ArtTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Audio & Media" && (<AudioMediaTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Automotive & DIY" && (<AutomotiveDIYTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Beauty" && (<BeautyTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Books" && (<BooksTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Cellphones & Wearables" && (<CellphonesWearablesTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Crafts & Handmade Goods" && (<CraftsHandmadeGoodsTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Electronics" && (<ElectronicsTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Fashion" && (<FashionTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Gaming" && (<GamingTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Garden, Pool & Patio" && (<GardenPoolPatioTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Health, Fitness & Personal Care" && (<HealthFitnessPersonalCareTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Hobbies & Toys" && (<HobbiesToysTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Holistic & Esoteric" && (<HolisticEsotericTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Home & Decor" && (<HomeDecorTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Movies & Television" && (<MoviesTelevisionTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Music & Instruments" && (<MusicInstrumentsTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Office & Stationary" && (<OfficeStationaryTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Pets" && (<PetsTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)}
        {category === "Physical Sport & Training" && (<PhysicalSportTrainingTags submit={submit} addTags={addTags} tags={tags} isEditing={isEditing}/>)} */}


        <div className="feesPopupBackground" ref={feesPopupBackgroundRef}>
          {/* <div id="delete2" onClick={backToEditing}>
            <img src={deleteIcon} alt="back to edit product" id="deleteIcon" />
          </div> */}
          <div className="feesPopup" ref={feesPopupRef}>
            <div className="sellerPriceDiv">
              <p className="sellerPrice">Seller Earnings</p>
              <p>R{price}</p>
            </div>
            <div className="allFeesDiv">
              <p className="allFees">Payment Gateway fee & server costs</p>
              <p>R{commission}</p>
            </div>
            <hr className="feesPopupLine" border-radius="2" size="4" width="90%" color="#000000"/>
            <div className="listedPriceDiv">
              <p className="listedPrice">Listed Price</p>
              <p>R{listedPrice}</p>
            </div>
            {showGotIt &&
            <Link to="/vendor">
              <motion.button
                key="gotItButton" // Key is necessary for AnimatePresence to track the component
                initial="hidden" // Start from hidden state
                animate="visible" // Animate to visible state
                exit="exit" // Exit animation
                variants={variants} // Apply the variants defined above
                transition={{ duration: 0.5, ease: "easeInOut" }} // Customize transition
                className="gotItButton"
              >
                got it
              </motion.button>
            </Link>
            }
          </div>
        </div>
        <div>
          {showSaveDraft && <button type="button" className='saveDraftButton' onClick={checkForEmptyFields}>save draft</button>}
          <button disabled={!isFormValid} className='submitButton' onClick={showFeesPopup} type='submit'>submit</button>
        </div>
      </form>
      <Outlet />
    </div>
  )
}
 
export default AddProduct

