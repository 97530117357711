import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { db } from '../../firebase'; // Firebase database instance
import { doc, updateDoc, getDoc } from '@firebase/firestore';

const FrameBorderWidth = (props) => {
  const {
    selectedObjectData,
    updateFrames,
    data,
    selectedObjectID,
    storeID,
    borderStyle,
    selectedBorderColor,
    setSelectedObjectData,
  } = props;

  // State variable for border width input
  const [borderWidthInput, setBorderWidthInput] = useState('');

  // Initialize borderWidthInput when selectedObjectID changes
  useEffect(() => {
    if (selectedObjectData && selectedObjectData.frame_styling) {
      const styling = selectedObjectData.frame_styling;
      const borderString = styling.border;

      if (borderString && borderString !== 'none') {
        const borderParts = borderString.split(' ');
        const widthPart = borderParts[0];
        const widthValue = parseInt(widthPart, 10);
        if (!isNaN(widthValue)) {
          setBorderWidthInput(widthValue.toString());
        } else {
          setBorderWidthInput('');
        }
      } else {
        setBorderWidthInput('');
      }
    }
  }, [selectedObjectID]); // Only run when selectedObjectID changes

  // Debounced function to update border width in Firestore
  const debouncedUpdateBorderWidth = useCallback(
    debounce(async (borderWidthValue) => {
      if (!selectedObjectData) return;

      // Prepare the new border style string
      const currentBorderStyle = borderStyle && borderStyle !== '' ? borderStyle : 'solid';

      let newBorderStyle = '';
      if (borderWidthValue && currentBorderStyle !== 'none') {
        newBorderStyle = `${borderWidthValue}px ${currentBorderStyle} ${selectedBorderColor || 'black'}`;
      } else {
        newBorderStyle = 'none';
      }

      // Prepare updated frame styling
      const updatedFrameStyling = {
        ...selectedObjectData.frame_styling,
        border: newBorderStyle,
      };

      // Update props.data array
      const updatedObjectData = { ...selectedObjectData, frame_styling: updatedFrameStyling };
      const updatedData = data.map(obj =>
        obj.frameID === selectedObjectID ? updatedObjectData : obj
      );
      updateFrames(updatedData, updatedObjectData);
      setSelectedObjectData(updatedObjectData); // Update local state

      // Update Firestore for frames
      const framesRef = doc(db, 'frames', selectedObjectID);
      await updateDoc(framesRef, { frame_styling: updatedFrameStyling });

      // Update the frames array in the stores document
      const storesRef = doc(db, 'stores', storeID);
      const storeDoc = await getDoc(storesRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames || [];
        const frameIndex = framesArray.findIndex(frame => frame.frameID === selectedObjectID);

        if (frameIndex !== -1) {
          framesArray[frameIndex].frame_styling = updatedFrameStyling;
          await updateDoc(storesRef, { frames: framesArray });
        }
      }
    }, 300),
    [
      selectedObjectData,
      selectedObjectID,
      storeID,
      borderStyle,
      data,
      updateFrames,
      selectedBorderColor,
      setSelectedObjectData,
    ]
  );

  // Handler for input change
  const handleBorderWidthChange = (e) => {
    const value = e.target.value;
    setBorderWidthInput(value);

    // If input is empty, set border to 'none'
    if (value.trim() === '') {
      debouncedUpdateBorderWidth('');
    } else {
      const borderWidthValue = parseInt(value, 10);
      if (!isNaN(borderWidthValue)) {
        debouncedUpdateBorderWidth(borderWidthValue);
      }
    }
  };

  return (
    <div className="frameBorderWidth">
      <div className="frameBorderWidthText">border width</div>
      <div className="frameBorderWidthInputSection">
        <input
          type="text"
          className="frameBorderWidthInput"
          value={borderWidthInput}
          onChange={handleBorderWidthChange}
          placeholder="0"
        />
        <div className="frameBorderWidthPixelText">px</div>
      </div>
    </div>
  );
};

export default FrameBorderWidth;
