// TextPopupPortal.jsx
import React from 'react';
import ReactDOM from 'react-dom';

const TextPopupPortal = React.forwardRef(({ children }, ref) => {
  const portalRoot = document.getElementById('portal-root');
  
  if (!portalRoot) {
    console.error("Portal root element with id 'portal-root' not found in the DOM.");
    return null;
  }

  return ReactDOM.createPortal(
    React.cloneElement(children, { ref }), // Attach the forwarded ref to the child
    portalRoot
  );
});

export default TextPopupPortal;
