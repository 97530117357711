import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { 
  addDoc, collection, doc, updateDoc, setDoc, getDoc, getDocs, 
  query, where, onSnapshot, deleteDoc, serverTimestamp
} from '@firebase/firestore';
import { 
  ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, deleteObject 
} from 'firebase/storage';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, storage } from '../../firebase';
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";

import './Dashboard.css';
import './StoreUploadedProducts.css'

import backArrow from '../../icons/backArrow.png';
import backArrow2 from '../../icons/returnArrowIcon.png';
import plusIcon from '../../icons/plusIcon.png';
import editIcon from '../../icons/editIcon.png';
import LongPress from '../../components/LongPress';
import BundlingDiv from './BundlingDiv';
import blankImage from '../../images/blankImage.png';

import LogoUploadPopup from './LogoUploadPopup';
import Login from '../../login/Login';
import '../../market/MarketProductCard.css'
import ScoutCard from '../../scout/ScoutCard';
import ImageGallery from '../../scout/ImageGallery'

import StoreUploadedProducts from './StoreUploadedProducts'; 
import StoreProductStatus from './StoreProductStatus'; 

const Dashboard = (props) => {
  const navigate = useNavigate();
  
  const [data, setData] = useState([]);
  const [userID, setUserID] = useState(null);
  const [storeID, setStoreID] = useState(null);
  const [publishedProducts, setPublishedProducts] = useState([]);
  const [editingProducts, setEditingProducts] = useState([]);
  const [pendingProducts, setPendingProducts] = useState([]);
  const [sentProducts, setSentProducts] = useState([]);
  
  const [adminOrderData, setAdminOrderData] = useState([]); // NEW: Store admin order history data

  const [showPendingProducts, setShowPendingProducts] = useState(true);
  const [showSentProducts, setShowSentProducts] = useState(false);

  const [originalStoreName, setOriginalStoreName] = useState('');
  const [originalStoreDescription, setOriginalStoreDescription] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeDescription, setStoreDescription] = useState('');

  const [showInfo1, setShowInfo1] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [editingText, setEditingText] = useState(false);
  const [imageGrowthAnimation, setImageGrowthAnimation] = useState();
  const [colorArray, setColorArray] = useState([]);

  const [showPublishedProducts, setShowPublishedProducts] = useState(true);
  const [showEditingProducts, setShowEditingProducts] = useState(false);
  const [showLoginOverlay, setShowLoginOverlay] = useState(false);
  const [showTextDivMessage, setShowTextDivMessage] = useState(false);
  const [infoDivExpanded, setInfoDivExpanded] = useState(false);
  const [isLogoUploadPopupOpen, setIsLogoUploadPopupOpen] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null); 
  const [isScoutCardVisible, setIsScoutCardVisible] = useState(false);
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);

  const [showEthicalChecksPopup, setShowEthicalChecksPopup] = useState(false);
  const [ethicalChecks, setEthicalChecks] = useState();
  const [ethicalChecksText, setEthicalChecksText] = useState('');

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryThumbnails, setGalleryThumbnails] = useState([]);

  const [storeNameExists, setStoreNameExists] = useState(false);
  const [showStoreNameExistsPopup, setShowStoreNameExistsPopup] = useState(false);
  const [sellerDeliveryPreference, setSellerDeliveryPreference] = useState('');

  useEffect(() => {
    document.title = 'Dashboard | taizte™';
  }, []);

  // Fetch adminOrderHistory once storeID is known
  useEffect(() => {
    if (storeID) {
      const adminOrderHistoryRef = doc(db, "orderHistory", storeID);
      getDoc(adminOrderHistoryRef).then((snapshot) => {
        if (snapshot.exists()) {
          const orderData = snapshot.data().orderHistory || [];
          setAdminOrderData(orderData);
          console.log("Order History", orderData)
        }
      });
    }
  }, [storeID]);

  // Fetch delivery preferences
  useEffect(() => {
    if (storeID) {
      getDoc(doc(db, "stores", storeID)).then(docSnap => {
        if (docSnap.exists()) {
          setSellerDeliveryPreference(docSnap.data().sellerDeliveryPreference || 'both');
        }
      });
    }
  }, [storeID]);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch products once we have userID and storeID
  useEffect(() => {
    let unsubscribe;
    if (userID && storeID) {
      unsubscribe = retrieveUserProducts();
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userID, storeID]);

  const openEthicalChecksPopup = useCallback((eCheck, eCheckText) => {
    console.log(eCheck, eCheckText);
    setEthicalChecks(eCheck);
    setEthicalChecksText(eCheckText);
    setShowEthicalChecksPopup(true);
  });

  // Retrieve user products and merge adminOrderData if available
  const retrieveUserProducts = () => {
    if (!storeID) {
      console.warn("storeID is not set. Cannot retrieve products.");
      return;
    }
  
    const productQuery = query(collection(db, "products"), where("storeID", "==", storeID));
  
    const unsubscribe = onSnapshot(productQuery, (querySnapshot) => {
      let publishedArr = [];
      let editingArr = [];
      let pendingArr = [];
      let sentArr = [];
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        if (data.status === 'published' && data.productSold !== true) {
          publishedArr.push(data);
        } else if (data.status === 'editing') {
          editingArr.push(data);
        }

        if (data.productSold === true) {
          if (data.productSent === true) {
            sentArr.push(data);
          } else {
            pendingArr.push(data);
          }
        }
      });

      // Now merge adminOrderData here to avoid multiple sets
      let mergedPending = [...pendingArr];
      let mergedSent = [...sentArr];

      if (adminOrderData.length > 0) {
        mergedPending = mergedPending.map(prod => {
          const order = adminOrderData.find(order => 
            order.products.some(p => p.productID === prod.productID)
          );
          if (order) {
            const matchingProduct = order.products.find(p => p.productID === prod.productID);
            return {
              ...prod,
              ...matchingProduct,
              buyerInfo: order.buyerInfo
            };
          }
          return prod;
        });

        mergedSent = mergedSent.map(prod => {
          const order = adminOrderData.find(order => 
            order.products.some(p => p.productID === prod.productID)
          );
          if (order) {
            const matchingProduct = order.products.find(p => p.productID === prod.productID);
            return {
              ...prod,
              ...matchingProduct,
              buyerInfo: order.buyerInfo
            };
          }
          return prod;
        });
      }

      setPublishedProducts(publishedArr);
      setEditingProducts(editingArr);
      setPendingProducts(mergedPending);
      setSentProducts(mergedSent);
      setNoProducts(
        publishedArr.length === 0 &&
        editingArr.length === 0 &&
        mergedPending.length === 0 &&
        mergedSent.length === 0
      );
    }, (error) => {
      console.error("Error fetching products:", error);
    });
  
    return unsubscribe;
  };

  useEffect(() => {
    if (showTextDivMessage) {
      const timer = setTimeout(() => {
        setShowTextDivMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showTextDivMessage]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user);
        setUserID(user.uid);
        setShowLoginOverlay(false);
  
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const storeDoc = await getDoc(doc(db, "stores", userData.storeID))
            if (storeDoc.exists()) {
              const storeData = storeDoc.data();
              console.log("Store data:", storeData);
              console.log("User data:", userData);
              setData(storeData);
              setStoreName(storeData.store);
              setOriginalStoreName(storeData.store);
              setStoreDescription(storeData.store_description);
              setOriginalStoreDescription(storeData.store_description);
              setStoreID(storeData.storeID);
            }
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setShowLoginOverlay(true);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const onLongPress = () => {
    console.log('longpress is triggered');
    setShowInfo1(true);
  };

  const onClick = () => {
    console.log('click is triggered');
  };

  const editWebsiteText = () => {
    setEditingText(true);
  };

  useEffect(() => {
    const checkIfStoreNameExists = async () => {
      if (!storeName) {
        console.log("Store name is empty.");
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
        return;
      }
  
      const normalizedStoreName = storeName.trim().toLowerCase();
      const normalizedOriginalStoreName = originalStoreName.trim().toLowerCase();
  
      if (normalizedStoreName === normalizedOriginalStoreName) {
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
        return;
      }
  
      const storeQuery = query(
        collection(db, "stores"),
        where("store", "==", storeName.trim())
      );
  
      try {
        const snapshot = await getDocs(storeQuery);
        if (snapshot.size > 0) {
          const exists = snapshot.docs.some(doc => doc.data().storeID !== storeID);
          setStoreNameExists(exists);
          setShowStoreNameExistsPopup(exists);
        } else {
          setStoreNameExists(false);
          setShowStoreNameExistsPopup(false);
        }
      } catch (error) {
        console.error("Error checking store name existence:", error);
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
      }
    };
  
    checkIfStoreNameExists();
  }, [storeName, originalStoreName, storeID]);

  const saveTextEdits = async () => {
    const trimmedStoreName = storeName.trim();
  
    if (!trimmedStoreName) {
      props.setAlertMessage("Store name cannot be blank.");
      props.setAlertType("error");
      return;
    }
  
    if (storeNameExists) {
      props.setAlertMessage("Store name already exists. Please choose another name.");
      props.setAlertType("error");
      return;
    }

    setEditingText(false);
  
    const dbRef = doc(db, "users", userID);
    const storeRef = doc(db, "stores", storeID);
    
    setOriginalStoreName(trimmedStoreName);
    setOriginalStoreDescription(storeDescription.trim());

    try {
      await updateDoc(dbRef, {
        store: trimmedStoreName,
        store_description: storeDescription.trim(),
      });
  
      await updateDoc(storeRef, {
        store: trimmedStoreName,
        store_description: storeDescription.trim(),
      });
  
      const productDoc = query(collection(db, "products"), where("userID", "==", userID));
      onSnapshot(productDoc, (querySnapshot) => {
        querySnapshot.forEach((object) => {
          const productID = object.data().productID;
          updateDoc(doc(db, "products", productID), {
            store: trimmedStoreName,
            store_description: storeDescription.trim(),
          });
        });
      });
  
      const favQuerySnapshot = await getDocs(query(collection(db, "favourites")));
      favQuerySnapshot.forEach(async (object) => {
        const likedStores = object.data().liked_stores;
        const likedProducts = object.data().liked_products;
  
        if (!likedStores || !likedProducts) return;
  
        const updatedLikedStores = likedStores.map((likedStore) => {
          if (likedStore.storeID === storeID) {
            return {
              ...likedStore,
              store: trimmedStoreName,
              store_description: storeDescription.trim(),
            };
          } else {
            return likedStore;
          }
        });
  
        const updatedLikedProducts = likedProducts.map((likedProduct) => {
          if (likedProduct.storeID === storeID) {
            return {
              ...likedProduct,
              store: trimmedStoreName,
              store_description: storeDescription.trim(),
            };
          } else {
            return likedProduct;
          }
        });
    
        await updateDoc(doc(db, "favourites", object.id), {
          liked_products: updatedLikedProducts,
          liked_stores: updatedLikedStores,
        });
  
        console.log("All favourites documents edited");
      });
  
      props.setAlertMessage("Store Information has been changed.");
      props.setAlertType("success");
    } catch (error) {
      console.error("Error saving text edits:", error);
      props.setAlertMessage("An error occurred while saving changes.");
      props.setAlertType("error");
    }
  };

  const cancelTextEdit = async () => {
    setEditingText(false);
    setStoreName(originalStoreName);
    setStoreDescription(originalStoreDescription);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = LongPress(onLongPress, onClick, defaultOptions);

  const deleteProduct = async (userProduct, productName, storeName) => {
    const productID = userProduct.productID;
    console.log("product ID " + productID);
  
    const storeRef = doc(db, "stores", storeID);
    
    try {
      const storeSnap = await getDoc(storeRef);
      if (storeSnap.exists()) {
        let product_prices = storeSnap.data().product_prices || [];
        product_prices = product_prices.filter(price => price !== userProduct.seller_price);
  
        const total = product_prices.reduce((acc, price) => acc + Number(price), 0);
        const average_price = product_prices.length > 0 ? total / product_prices.length : 0;
        const lowest_price = product_prices.length > 0 ? Math.min(...product_prices.map(Number)) : 0;
        const highest_price = product_prices.length > 0 ? Math.max(...product_prices.map(Number)) : 0;
  
        await updateDoc(storeRef, {
          product_prices: product_prices,
          average_price: average_price,
          lowest_price: lowest_price,
          highest_price: highest_price,
        });
      }
  
      const favouritesSnapshot = await getDocs(collection(db, "favourites"));
      favouritesSnapshot.forEach(async (favDoc) => {
        const favData = favDoc.data();
        const likedProducts = Array.isArray(favData.liked_products) ? favData.liked_products : [];
        const favUserID = favData.user;
  
        if (likedProducts.some(product => product.productID === productID)) {
          const updatedLikedProducts = likedProducts.filter(product => product.productID !== productID);
          await updateDoc(favDoc.ref, { liked_products: updatedLikedProducts });
  
          const notificationRef = doc(collection(db, 'notifications'));
          await setDoc(notificationRef, {
            notificationType: 'productNotification',
            message: `The product titled '${productName}' has been deleted by the store ${storeName}`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: notificationRef.id,
            readStatus: 'unread',
            storeName: storeName,
            productName: productName,
            storeID: storeID,
            userID: favUserID
          });
        }
      });
  
      await deleteDoc(doc(db, "products", userProduct.productID));
      console.log("Product deleted!");
  
      if (userProduct.image) {
        const imageURL = userProduct.image;
        let fileName = imageURL.slice(89, imageURL.lastIndexOf('?')).replace(/ /g, "%20");
        fileName = decodeURIComponent(fileName);
  
        const deleteRef = ref(storage, `product images/${fileName}`);
        await deleteObject(deleteRef);
        console.log("Image successfully deleted!");
      }

      const cartSnapshot = await getDocs(collection(db, "cart"));
      cartSnapshot.forEach(async (cartDoc) => {
        const cartData = cartDoc.data();
        const cartItems = Array.isArray(cartData.cart) ? cartData.cart : [];
        const cartUserID = cartData.user;
  
        if (cartItems.some(item => item.productID === productID)) {
          const updatedCartItems = cartItems.filter(item => item.productID !== productID);
          await updateDoc(cartDoc.ref, { cart: updatedCartItems });
  
          const notificationRef = doc(collection(db, 'notifications'));
          await setDoc(notificationRef, {
            notificationType: 'cartNotification',
            message: `The product titled '${productName}' has been deleted by the store ${storeName} and has been removed from your cart`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: notificationRef.id,
            readStatus: 'unread',
            storeName: storeName,
            productName: productName,
            storeID: storeID,
            userID: cartUserID
          });
        }
      });
  
    } catch (error) {
      console.error("Error deleting product:", error);
      props.setAlertMessage("An error occurred while deleting the product.");
      props.setAlertType("error");
    }
  };

  const adjustTextAreaHeight = (event) => {
    event.target.style.height = 'auto';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };
  
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        handleLogout();
      }, 600000); // 10 minutes
    };

    const handleActivity = () => {
      resetTimer();
    };

    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    resetTimer();

    return () => {
      if (timer) clearTimeout(timer);
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User has been logged out due to inactivity.");
        setShowLoginOverlay(true);
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsScoutCardVisible(true);
    setImageGrowthAnimation(true);
  };

  const closeScoutCard = () => {
    setSelectedProduct(null);
    setIsScoutCardVisible(false);
  };

  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  const handleEditProduct = (product) => {
    navigate('../addproduct', { 
      state: { 
        isEditing: true, 
        productID: product.productID, 
        storeID: product.storeID 
      } 
    });
  };

  const openLogoUploadPopup = () => {
    setIsLogoUploadPopupOpen(true);
  };
  
  const closeLogoUploadPopup = () => {
    setIsLogoUploadPopupOpen(false);
  };
  
  const handleLogoUpdate = (newLogoURL) => {
    setData((prevData) => ({
      ...prevData,
      store_logo: newLogoURL,
    }));
  };  

  const openGallery = (images, thumbnails) => {
    setGalleryImages(images);
    setGalleryThumbnails(thumbnails);
    setIsGalleryOpen(true);
  };
  
  const closeGallery = () => setIsGalleryOpen(false);
  // Render Delivery Preference Buttons only once more options available
  // const renderDeliveryPreference = () => {
  //   return (
  //     <div className="deliveryPreferenceContainer">
  //       <p className="deliveryPreferenceTitle">delivery preference</p>
  //       <div className="deliveryButtons">
  //         <div
  //           type="button"
  //           className={`deliveryButton ${sellerDeliveryPreference === 'driverPickup' ? 'selectedPreference' : ''}`}
  //           onClick={() => updateDeliveryPreference('driverPickup')}
  //         >
  //           driver pickup
  //         </div>
  //         <div
  //           type="button"
  //           className={`deliveryButton ${sellerDeliveryPreference === 'localCollect' ? 'selectedPreference' : ''}`}
  //           onClick={() => updateDeliveryPreference('localCollect')}
  //         >
  //           local collection
  //         </div>
  //         <div
  //           type="button"
  //           className={`deliveryButton ${sellerDeliveryPreference === 'both' ? 'selectedPreference' : ''}`}
  //           onClick={() => updateDeliveryPreference('both')}
  //         >
  //           both
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };


  return (
    <div className="dashboardBody">
      <style>{'body { background-color: #C8B7D2; }'}</style>
      <div className="dashboardHeader">
        <div className="dashboardHeaderContainer1">
          <Link to="/vendor">
            <div className="dashboardBack">
              <img src={backArrow} className="dashboardBackIcon" alt="Back" />
            </div>
          </Link>
          <div className='dashboardHeadingDiv'>
            <h1 className='dashboardHeadingText'>dashboard</h1>
          </div>
        </div>
        {data.store_logo ? (
          <img
            src={data.store_logo}
            alt="Store Logo"
            className='dashboardLogo'
            onClick={openLogoUploadPopup}
            style={{ cursor: 'pointer' }}
            title="Click to change logo"
          />
        ) : (
          <div
            className='dashboardLogoPlaceholder'
            onClick={openLogoUploadPopup}
            style={{ cursor: 'pointer' }}
            title="Click to upload logo"
          >
            <img src={plusIcon} alt="Upload Logo" />
          </div>
        )}
      </div>

      <AnimatePresence>
        {isLogoUploadPopupOpen && (
          <LogoUploadPopup
            userID={userID}
            storeID={storeID}
            currentLogo={data.store_logo}
            onClose={closeLogoUploadPopup}
            onLogoUpdate={handleLogoUpdate}
            setAlertMessage={props.setAlertMessage}
            setAlertType={props.setAlertType}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showTextDivMessage &&
          <motion.div
            className="marketTextDivMessage"
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {infoDivExpanded ? 'Longpress the description box to collapse it.' : 'Longpress the description box to expand it.'}
          </motion.div>
        }
      </AnimatePresence>
      <div className="dashboardContent">
        <div className="storeInfo">
          <div className="storeInfoTextSection">
            {editingText ?
              <textarea 
                className="dashboardEditStoreName" 
                value={storeName} 
                onChange={(e) => setStoreName(e.target.value)}
              /> :
              <p className="dashboardStoreName">{storeName}</p>
            }
            {editingText ?
              <textarea
                rows="3"
                className="dashboardEditStoreDescription"
                value={storeDescription}
                onChange={(e) => setStoreDescription(e.target.value)}
                onInput={adjustTextAreaHeight}
              /> :
              <p className="dashboardStoreDescription">{storeDescription}</p>
            }
          </div>
          <AnimatePresence>
            {showStoreNameExistsPopup && editingText && (
              <motion.div
                className="dashboardStoreNameExistsDiv"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
              >
                Business name already exists, please pick another name.
              </motion.div>
            )}
          </AnimatePresence>
          {!editingText ?
            <img 
              className="storeEditInfo" 
              src={editIcon} 
              onClick={editWebsiteText} 
              alt="Edit Info"
            /> :
            <div className="editTextBtnDiv">
              <div 
                className="submitTextBtn" 
                onClick={saveTextEdits}
                disabled={storeNameExists}
              >
                submit
              </div>
              <div className="cancelTextBtn" onClick={cancelTextEdit}>cancel</div>
            </div>
          }
        </div>
        {props.country && props.country !== 'South Africa' && (
          <div className="countryUploadNote">
            Note that product <strong>purchasing is not available in {props.country} yet, </strong> 
            but feel free to test it.
          </div>
        )}

        {data.offeringType !== 'services' && (
          <>
            <StoreUploadedProducts
              storeName={storeName}
              publishedProducts={publishedProducts}
              editingProducts={editingProducts}
              showPublishedProducts={showPublishedProducts}
              showEditingProducts={showEditingProducts}
              setShowPublishedProducts={setShowPublishedProducts}
              setShowEditingProducts={setShowEditingProducts}
              handleProductClick={handleProductClick}
              handleEditProduct={handleEditProduct}
              deleteProduct={deleteProduct}
              longPressEvent={longPressEvent}
            />
            
            <StoreProductStatus
              storeName={storeName}
              pendingProducts={pendingProducts}
              sentProducts={sentProducts}
              showPendingProducts={showPendingProducts}
              showSentProducts={showSentProducts}
              setShowPendingProducts={setShowPendingProducts}
              setShowSentProducts={setShowSentProducts}
              handleProductClick={handleProductClick}
              handleEditProduct={handleEditProduct}
              deleteProduct={deleteProduct}
              longPressEvent={longPressEvent}
              adminOrderData={adminOrderData}
            />

            <Outlet />
          </>
        )}
        <BundlingDiv
          storeID={storeID}
          userProducts={publishedProducts}
        />
      </div>

      <AnimatePresence>
        {isGalleryOpen && (
          <ImageGallery 
            image={selectedProduct?.images} 
            imageThumbnail={selectedProduct?.imageThumbnails}
            closeGallery={closeGallery}
          />
        )}
      </AnimatePresence>

      {isScoutCardVisible && selectedProduct && (
        <div className="marketCardPopupDiv">
          <div className="marketCardPopupContent">
            <div className="marketBackArrow" onClick={closeScoutCard}>
              <div className="marketCardBlurredBackground">
                <img src={backArrow2} alt="Close" id="marketBackArrowIcon" />
              </div>
            </div>
            <ScoutCard 
              page={'dashboard'}
              imageGrowthAnimation={imageGrowthAnimation}
              productID={selectedProduct.productID}
              data={selectedProduct}
              image={selectedProduct.images} 
              imageThumbnail={selectedProduct.imageThumbnails}
              onDominantColor={handleDominantColor}
              listed_price={selectedProduct.listed_price}
              title={selectedProduct.productName}
              store={storeName}
              storeID={storeID}
              description={selectedProduct.description}
              category={selectedProduct.category}
              userID={userID}
              bundling={selectedProduct.bundling}
              isWideScreen={isWideScreen}
              openEthicalChecksPopup={openEthicalChecksPopup}
              showTextDivMessage={showTextDivMessage}
              setInfoDivExpanded={setInfoDivExpanded}
              setShowTextDivMessage={setShowTextDivMessage}
              openGallery={openGallery}
            />
          </div>
        </div>
      )}

      {showLoginOverlay &&
        <motion.div
          className="dashboardLogin"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0, duration: 0.5 }}
        >
          <div className="dashboardLoginDiv">
            <p className="dashboardLoginTextDiv">
              Session expired due to inactivity. Please <strong>login</strong> again to continue.
            </p>
            <Login show={showLoginOverlay} />
          </div>
        </motion.div>
      }
    </div>
  );
}

export default Dashboard;
