import React, { useRef, useState, useEffect } from 'react'
import { motion } from "framer-motion"

import './WebsiteInfo.css'
import closePopup from '../icons/deleteIcon.png'

const WebsiteInfo = (props) => {
  const { onClickOutside } = props;
  const websiteInfoRef = useRef()

  // State to track if the store logo image exists
  const [imageExists, setImageExists] = useState(false);

  // Existing useEffect to handle clicks outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (websiteInfoRef.current && !websiteInfoRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

    // useEffect to preload the store logo image and verify its existence
    useEffect(() => {
      if (props.storeLogo) {
        const img = new Image();
        img.src = props.storeLogo;
        img.onload = () => setImageExists(true);   // Image loaded successfully
        img.onerror = () => setImageExists(false); // Image failed to load
      } else {
        setImageExists(false); // No image URL provided
      }
    }, [props.storeLogo]);

  // Early return if the popup shouldn't be shown
  if(!props.show)
    return null;

  // Normalize socialMediaLinks to always be an array
  let socialMediaLinksArray = [];
  if (Array.isArray(props.socialMediaLinks)) {
    socialMediaLinksArray = props.socialMediaLinks;
  } else if (typeof props.socialMediaLinks === 'string') {
    socialMediaLinksArray = [props.socialMediaLinks];
  }

  return ( 
    <motion.div 
      className="websiteInfoDiv" 
      ref={websiteInfoRef}
      initial={{ y: '100vh' }} // Start below the screen
      animate={{ y: 0 }} // End at its natural position
      transition={{ 
        duration: 0.5, 
        ease: "easeInOut" 
      }}
    >
      {/* Close Popup Button */}
      <img 
        src={closePopup} 
        alt="Close" 
        className="websiteInfoClose" 
        onClick={props.closePopup}
      />

      {/* Conditionally render the store logo if it exists */}
      {imageExists && (
        <img 
          src={props.storeLogo} 
          alt={`${props.store} Logo`} 
          className="websiteStoreLogo" 
        />
      )}

      {/* Store Name */}
      <div className="websiteStoreName">{props.store}</div>

      {/* Store Description */}
      <div className="websiteDescription">{props.storeDescription}</div>
    
      {/* Social Media Links */}
      {socialMediaLinksArray.length > 0 && (
        <div className="websiteSocialMediaLinks" style={{ display: (socialMediaLinksArray.length > 0) ? 'none' : ''}}>
          {socialMediaLinksArray.map((link, index) => (
            <a 
              key={index} 
              href={link} 
              target="_blank" 
              rel="noopener noreferrer"
              className="websiteSocialMediaLinkDiv"
            >
              {link}
            </a>
          ))}
        </div>
      )}
    </motion.div>
  )
}

export default WebsiteInfo
