import React, { useContext, useEffect, useRef, useState } from 'react';
import { VendorFormContext } from '../context/VendorFormContext.jsx';

import { auth, db, storage } from "../firebase";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  arrayUnion, getDoc, getDocs, query, where, 
  onSnapshot, serverTimestamp, deleteDoc, runTransaction 
} from '@firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import deleteIcon from '../icons/deleteIcon.png';
import plusIcon from '../icons/plusIcon.png';

const VendorFormStep3 = ({ next, prev, setAlertMessage, setAlertType }) => {
  const { formData, updateFormData } = useContext(VendorFormContext);
  const uploadDivRef2 = useRef();
  const businessLogoDiv2 = useRef();

  const [file, setFile] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [preview, setPreview] = useState(formData.store_logo || ''); // Initialize from context
  const [fileName, setFileName] = useState(formData.fileName || '');
  const [progressCompleted, setProgressCompleted] = useState(false);
  const [data, setData] = useState({});
  const [percentage, setPercentage] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  const [error, setError] = useState(''); // State to handle errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  // Handle "Yes" option for social media integration
  useEffect(() => {
    if (formData.socialMediaIntegration !== 'Yes') {
      updateFormData({ socialMediaLinks: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.socialMediaIntegration]);

  useEffect(() => {
    if (formData.store_logo) {
      setProgressCompleted(true);
    }
  }, [formData.store_logo]);

  useEffect(() => {
    if (formData.fileName && !fileName) {
      setFileName(formData.fileName);
    }
  }, [formData.fileName, fileName]);

  // Handle image file upload
  useEffect(() => {
    if (!file) return;

    const processImage = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
          // Create a canvas to draw the image onto
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');

          // Fill the canvas with white background
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0);

          // Convert canvas to Blob
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            file.type,
            1 // Quality parameter (1 is highest quality)
          );
        };

        img.onerror = (error) => {
          reject(error);
        };

        // Read the file as a Data URL
        const reader = new FileReader();
        reader.onload = (e) => {
          img.src = e.target.result;
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    };

    const uploadFile2 = async () => {
      try {
        setIsUploading(true); // Indicate that uploading has started

        // Process the image
        const processedFile = await processImage(file);

        const name = file.name + uuidv4();
        setFileName(name);
        const storageRef = ref(storage, `store logo/${name}`);
        const uploadTask = uploadBytesResumable(storageRef, processedFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            setPercentage(progress);
          },
          (error) => {
            console.error('Upload error:', error);
            setIsUploading(false); // Indicate that uploading has failed
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setData((prev) => ({ ...prev, img: downloadURL }));

              const storagePath = `store logo/${name}`
              // Optionally, use only the original downloadURL for preview
              updateFormData({
                store_logo: downloadURL,
                nonResizedLogoURL: downloadURL,
                fileName: name,
              });
              console.log('Image URL:', downloadURL);

              // **Keep the preview as the uploaded image to prevent delay**
              setPreview(downloadURL); // Set preview to downloadURL
              setIsUploading(false); // Indicate that uploading has completed
              setFile(null);
              setError(''); // Clear any previous errors
            });
          }
        );
      } catch (error) {
        console.error('Error processing image:', error);
        setIsUploading(false);
        setError('Failed to upload image. Please try again.');
      }
    };

    uploadFile2();
  }, [file, updateFormData]);

  // Manage progress bar visibility
  useEffect(() => {
    if (percentage === 100) {
      setPercentage(null);
      setProgressCompleted(true);
    }
  }, [percentage]);

  // Handle delete uploaded logo
  const handleDelete2 = () => {
    if (!fileName) return;
  
    console.log('fileName:', fileName); // Add this line to inspect the value
  
    const suffix = "_1000x1000";
    const deleteRefResized = ref(storage, `store logo/${fileName}${suffix}`);
    const deleteRefOriginal = ref(storage, `store logo/${fileName}`);
  
    // Delete Resized Image
    deleteObject(deleteRefResized).catch((error) => {
      console.error("Error deleting resized logo:", error);
    });
  
    // Delete Original Image
    deleteObject(deleteRefOriginal)
      .then(() => {
        updateFormData({ store_logo: '', nonResizedLogoURL: '', fileName: '' });
        setFile("");
        setData({});
        setFileName('');
        setPreview('');
        setProgressCompleted(false);
        setError(''); // Clear any errors
        console.log("Original logo successfully deleted");
      })
      .catch((error) => {
        console.error("Error deleting original logo:", error);
        setError('Failed to delete the logo. Please try again.');
      });
  };

  const handleNext = (e) => {
    e.preventDefault();
    // Proceed without enforcing social media links
    next();
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    prev();
  };

  useEffect(() => {
    return () => {
      if (preview && preview.startsWith('blob:')) { // Revoke only blob URLs
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  // Validation Logic
  useEffect(() => {
    const validateForm = () => {
      const { store_logo, style } = formData;

      // Check if logo is present
      const isLogoPresent = store_logo && store_logo.trim() !== '';

      // Check if website aesthetic is selected
      const isStyleSelected = style && style.trim() !== '';

      // If either is missing, form is invalid
      return isLogoPresent && isStyleSelected;
    };

    setIsFormValid(validateForm());
  }, [formData]);

  return (
    <form onSubmit={handleNext}>
      <div className="businessLogoDiv2" ref={businessLogoDiv2}>
        {!formData.store_logo && (
          <label htmlFor="inputField2" className="chooseImageButton2"> 
            Upload Logo
            <img src={plusIcon} alt="Upload Icon" className="createStorePlusIcon2"/>
          </label>
        )}
        {!formData.store_logo && (
          <input
            type="file"
            id="inputField2"
            accept="image/*"
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              if (selectedFile) {
                if (selectedFile.size > 5 * 1024 * 1024) { // 5 MB limit
                  setError('File size exceeds 5 MB. Please select a smaller file.');
                  e.target.value = ''; // Reset the input
                  return;
                }
                setFile(selectedFile);
                setPreview(URL.createObjectURL(selectedFile));
                setError(''); // Clear any previous errors
              }
            }}
            style={{ display: "none" }}
            required={!formData.store_logo} // Keep required only if no logo exists
          />
        )}

        {preview || formData.store_logo ? (
          <div id='uploadDiv2' ref={uploadDivRef2}>
            <img
              src={preview || formData.store_logo}
              id="uploadedImage2"
              alt="Uploaded Logo"
            />
            {isUploading && (
              <div className='uploadingDiv'>
                <progress className="createStoreProgressBar2" value={percentage} max="100"/>
              </div>
            )}
            {!isUploading && formData.store_logo && (
              <div id="delete2" onClick={handleDelete2}>
                <img src={deleteIcon} alt="Delete Uploaded" id="deleteIcon" />
              </div>
            )}
          </div>
        ) : (
          <div className='createStoreNoProductImageDiv2'>No image has been selected yet</div>
        )}
        {error && <div className="createStoreErrorMessage">{error}</div>} {/* Display error message */}
      </div>
      <div className="websiteAestheticsDiv">
        {/* In future ask about colour scheme - have users select a few colours */}
        <div className="brandAestheticsDiv">
          <label htmlFor="brandAesthetics">
            What word describes your brand aesthetic:
          </label>
          <select
            name="style"
            value={formData.style}
            id="brandAesthetics"
            onChange={handleChange}
            required
          >
            <option value="">Select Style</option>
            <option value="Modern">Modern</option>
            <option value="Minimalist">Minimalist</option>
            <option value="Classic">Classic</option>
            <option value="Bold">Bold</option>
            <option value="Elegant">Elegant</option>
            <option value="Playful">Playful</option>
          </select>
        </div>

        <div className="socialMediaLinkDiv">
          <label htmlFor="socialMediaLinks">
            Enter your social media links (comma-separated):
          </label>
          <input
            type="text"
            name="socialMediaLinks"
            id="socialMediaLinks"
            value={formData.socialMediaLinks}
            onChange={handleChange}
            placeholder="e.g., https://instagram.com/yourprofile"
          />
        </div>
      </div>
      <div className="buttons">
        <button 
          type="button" 
          onClick={handlePrevClick}
          className="backButton"
        >
          back
        </button>
        <button 
          type="submit" 
          disabled={!isFormValid} // Disable button if form is invalid
          className={`nextButton ${!isFormValid ? 'disabled' : ''}`} // Optional: Add a class for styling disabled state
        >
          next
        </button>
      </div>
    </form>
  );
};

export default VendorFormStep3;
