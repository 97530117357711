// src/components/VendorFormApp.jsx

import React, { useState } from 'react';
import { VendorFormProvider } from '../context/VendorFormContext.jsx';
import VendorFormStep1 from './VendorFormStep1.jsx';
import VendorFormStep2 from './VendorFormStep2.jsx';
import VendorFormStep3 from './VendorFormStep3.jsx';
import VendorFormStep4 from './VendorFormStep4.jsx';
import VendorFormContent from './VendorFormContent.jsx'; // Import the new component
import './VendorFormStyles.css';

const VendorFormApp = (props) => {
  const [step, setStep] = useState(0); 

  const next = () => setStep((prev) => prev + 1);
  const prev = () => setStep((prev) => prev - 1);

  const renderStep = () => {
    switch (step) {
      case 0:
        return <VendorFormStep1 next={next} setAlertMessage={props.setAlertMessage} setAlertType={props.setAlertType}/>;
      case 1:
        return <VendorFormStep2 next={next} prev={prev} setAlertMessage={props.setAlertMessage} setAlertType={props.setAlertType}/>;
      case 2:
        return <VendorFormStep3 next={next} prev={prev} setAlertMessage={props.setAlertMessage} setAlertType={props.setAlertType}/>;
      case 3:
        return <VendorFormStep4 
                storeID={props.storeID}
                setStoreID={props.setStoreID} 
                userID={props.userID}
                setUserID={props.setUserID}
                prev={prev}
                setAlertMessage={props.setAlertMessage} 
                setAlertType={props.setAlertType} 
              />;
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <VendorFormProvider>
      <VendorFormContent step={step} next={next} prev={prev} renderStep={renderStep} country={props.country}/>
    </VendorFormProvider>
  );
};

export default VendorFormApp;
