// PortraitLock.jsx
import React, { useState, useEffect } from "react";
import './PortraitLock.css';

const PortraitLock = ({ children }) => {
  // Function to detect if the device is mobile
  const isMobileDevice = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);

  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  useEffect(() => {
    // Only run if the device is mobile
    if (isMobileDevice()) {
      const handleOrientationChange = () => {
        setIsLandscape(window.innerWidth > window.innerHeight);
      };

      window.addEventListener('resize', handleOrientationChange);
      window.addEventListener('orientationchange', handleOrientationChange); // For better compatibility

      // Initial check
      handleOrientationChange();

      return () => {
        window.removeEventListener('resize', handleOrientationChange);
        window.removeEventListener('orientationchange', handleOrientationChange);
      };
    } else {
      // If not mobile, set landscape to false to prevent the overlay from appearing
      setIsLandscape(false);
    }
  }, []);

  return (
    <div className={`portraitLockContainer ${isLandscape ? 'landscape' : ''}`}>
      {children}
      {isLandscape && (
        <div className="landscapeOverlay">
          <p>Please rotate your device for the best experience.</p>
        </div>
      )}
    </div>
  );
};

export default PortraitLock;
