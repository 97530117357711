import React, { useRef, useState, useEffect } from 'react'
import { Outlet, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { 
  addDoc, collection, doc, updateDoc, setDoc, orderBy,
  arrayUnion, getDoc, getDocs, query, where, increment,
  onSnapshot, serverTimestamp, deleteDoc, limit  
} from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from '../firebase'
import { Reorder, motion, AnimatePresence } from 'framer-motion';
import useFavicon from './useFavicon';

import WebsiteProductSection from './WebsiteProductSection'

import './Website.css'
import '../vendor/Vendor.css'
import WebsiteInfo from './WebsiteInfo'
import Stickers from '../vendor/website creation/Stickers';
import WebsiteStickers from './WebsiteStickers'
import Frames from '../vendor/website creation/Frames'
import DraggableFrames from '../vendor/website creation/DraggableFrames'
import heartIcon from '../icons/heartIcon.png'
import redHeartIcon from '../icons/redHeartIcon.png'
import infoIcon from '../icons/infoIcon.png'
import loadIcon from '../icons/scoutIcon.png'
import storeIcon from '../icons/cartIcon.png'
import backArrow from '../icons/returnArrowIcon.png'
import Login from '../login/Login'

const Website = (props) => {
  const [userID, setUserID] = useState(null)
  const [store, setStore] = useState('')
  const [storeID, setStoreID] = useState(null)
  const { websiteName } = useParams()
  const [animation, setAnimation] = useState(-60)
  const [fixedFrames, setFixedFrames] = useState([])
  const [draggableFrames, setDraggableFrames] = useState([]);
  const [websiteView, setWebsiteView] = useState(true)

  const [data, setData] = useState({})
  const [storeData, setStoreData] = useState({})
  const [showInfo, setShowInfo] = useState(false)
  const [websiteColor, setWebsiteColor] = useState('#FFFFFF')
  const [frames, setFrames] = useState([])
  const [stickers, setStickers] = useState([])
  const [newStickerStyling, setNewStickerStyling] = useState([])
  const [showEditingDiv, setShowEditingDiv] = useState(false)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [liked, setLiked] = useState({})
  const [showWebsiteStore, setShowWebsiteStore] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [newFrameStyling, setNewFrameStyling] = useState({})
  const [fromScout, setFromScout] = useState(false)
  const [storeNotFound, setStoreNotFound] = useState(false);
  const [isLoadingText, setIsLoadingText] = useState(true);
  const [transitionData, setTransitionData] = useState({
    colorArray: [],
    visibleCardIndex: 0,
    isWideScreen: false,
    showGradient: false,
    animationsActive: false,
  })

  const navigate = useNavigate();
  const setFavicon = useFavicon('/favicon.ico');
  const prevFavicon = useRef('/favicon.ico');

  // Authentication Listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid)
        setShowLogin(false)
      } else {
        setShowLogin(true)
      }
    })
    return () => unsubscribe()
  }, [])

  // Fetch Store Data
  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const currentLink = location.pathname;
        const customName = decodeURIComponent(currentLink.split("/")[1]);
        setStore(customName)
        console.log(`Fetching data for store: ${customName}`)
        document.title = `${customName}`

        // Query to get the store document
        const q = query(collection(db, "stores"), where("store", "==", customName), limit(1))
        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          const storeDoc = querySnapshot.docs[0]
          const storeData = storeDoc.data()
          const fetchedStoreID = storeData.storeID
          setStoreID(fetchedStoreID)

          // Set main store data
          setData(storeData)
          if (storeData.website_background) {
            setWebsiteColor(storeData.website_background)
          }

          // Set frames
          if (storeData.frames) {
            setFrames(storeData.frames)
            const draggable = storeData.frames.filter(frame => frame.isDragging === true)
            const fixed = storeData.frames.filter(frame => frame.isDragging === false)
            setDraggableFrames(draggable)
            setFixedFrames(fixed)
          } else {
            setShowEditingDiv(true)
          }

          // Set stickers
          if (storeData.stickers) {
            setStickers(storeData.stickers)
          }

          // Handle transition data if coming from ScoutCard
          if (location.state && location.state.fromScout) {
            setFromScout(true)
            setTransitionData({
              colorArray: location.state.colorArray || [],
              visibleCardIndex: location.state.visibleCardIndex || 0,
              isWideScreen: location.state.isWideScreen || false,
              showGradient: location.state.showGradient || false,
              animationsActive: location.state.animationsActive || false,
            });
            setWebsiteColor(location.state.websiteBackground || storeData.website_background || '#FFFFFF')
          }

          // Set favicon
          if (storeData.store_logo) {
            prevFavicon.current = '/favicon.ico';
            setFavicon(storeData.store_logo);
            console.log('Website favicon set:', storeData.store_logo);
          }

          setLoading(false) // Data fetched successfully
        } else {
          console.error("No matching store found");
          setStoreNotFound(true); // Set storeNotFound to true on error
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching store data:", error)
        setLoading(false)
      }
    }

    fetchStoreData()
  }, [location.pathname, location.state, setFavicon])

  // Listen to real-time updates for the store document
  useEffect(() => {
    if (!storeID) return

    const storeDocRef = doc(db, "stores", storeID)
    const unsubscribe = onSnapshot(storeDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const updatedData = docSnap.data()
        setData(updatedData)

        if (updatedData.website_background && updatedData.website_background !== websiteColor) {
          setWebsiteColor(updatedData.website_background)
        }

        // Update frames
        if (updatedData.frames) {
          setFrames(updatedData.frames)
          const draggable = updatedData.frames.filter(frame => frame.isDragging === true)
          const fixed = updatedData.frames.filter(frame => frame.isDragging === false)
          setDraggableFrames(draggable)
          setFixedFrames(fixed)
        } else {
          setFrames([])
          setFixedFrames([])
          setShowEditingDiv(true)
        }

        // Update stickers
        if (updatedData.stickers) {
          setStickers(updatedData.stickers)
        } else {
          setStickers([])
        }
      } else {
        console.log("Store document does not exist")
      }
    })

    return () => unsubscribe()
  }, [storeID, websiteColor])

  // Handle Favorites
  useEffect(() => {
    if (!userID || !storeID) return

    const fetchFavorites = async () => {
      try {
        const favDocRef = doc(db, 'favourites', userID)
        const favDocSnap = await getDoc(favDocRef)
        if (favDocSnap.exists()) {
          const likedStores = favDocSnap.data().liked_stores
          if (likedStores) {
            const isLiked = likedStores.some(store => store.storeID === storeID)
            setLiked(prevLiked => ({ ...prevLiked, [storeID]: isLiked }))
          }
        }
      } catch (error) {
        console.error("Error fetching favorites:", error)
      }
    }

    fetchFavorites()
  }, [userID, storeID])

  // Handle favicon reset on unmount
  useEffect(() => {
    return () => {
      setFavicon('/favicon.ico')
      props.setAppFavicon('/favicon.ico')
      console.log('Favicon reset to default on Website unmount')
    };
  }, [setFavicon, props])

  // Handle Back Navigation
  const handleBackNavigation = () => {
    setFavicon('/favicon.ico')
    sessionStorage.setItem('websiteColor', websiteColor);
    navigate(-1);
  }

  // Handle Heart Icon Click
  const onHeartIconClick = () => {
    if (liked[storeID]) {
      onUncheck()     
    } else {
      addToFavourites();
    }
  }

  const onUncheck = async () => {
    try {
      setLiked(prevLiked => ({ ...prevLiked, [storeID]: false }))
      
      const storeRef = doc(db, "stores", storeID)
      const storeSnap = await getDoc(storeRef)
      const likes = storeSnap.data().likes || 0

      if (likes > 0) {
        await updateDoc(storeRef, { likes: increment(-1) })
      } else {
        await updateDoc(storeRef, { likes: 0 })
      }

      const favDocRef = doc(db, 'favourites', userID)
      const favDocSnap = await getDoc(favDocRef)

      if (favDocSnap.exists()) {
        const likedStores = favDocSnap.data().liked_stores || []
        const updatedLikedStores = likedStores.filter(store => store.storeID !== storeID)
        await updateDoc(favDocRef, { liked_stores: updatedLikedStores })
        console.log("Store removed from favorites")
      }
    } catch (error) {
      console.error("Error unchecking favorite:", error)
    }
  }

  const addToFavourites = async () => {
    try {
      setLiked(prevLiked => ({ ...prevLiked, [storeID]: true }))
      
      const storeRef = doc(db, 'stores', storeID)
      const storeSnap = await getDoc(storeRef)
      const likes = storeSnap.data().likes || 0
      await updateDoc(storeRef, { likes: increment(1) })
      
      const favDocRef = doc(db, 'favourites', userID)
      const favDocSnap = await getDoc(favDocRef)
      const storeData = storeSnap.data()

      if (favDocSnap.exists()) {
        await updateDoc(favDocRef, {
          liked_stores: arrayUnion({
            storeID: storeID,
            ...storeData,
            date_liked: serverTimestamp()
          }),
          user: userID,
        })
        console.log("Store added to existing favorites")
      } else {
        await setDoc(favDocRef, {
          liked_stores: [{
            storeID: storeID,
            ...storeData,
            date_liked: serverTimestamp()
          }],
          user: userID,
        })
        console.log("Store added and favorites document created")
      }
    } catch (error) {
      console.error("Error adding to favorites:", error)
    }
  }

  const showStore = () => {
    setShowWebsiteStore(!showWebsiteStore)
  }

  // Function to close the product section
  const closeStore = () => {
    setShowWebsiteStore(false)
  }

  // Animation Variants
  const contentVariants = {
    hidden: { 
      y: 30,
      opacity: 0, 
      scale: 0.9 
    },
    visible: { 
      y: 0,
      opacity: 1, 
      scale: 1,
      transition: { duration: 1, delay: 0.5 }
    }
  };

  if (storeNotFound) {
    return (
      <div className="pageNotFound">
        <div className="pageNotFoundWarning">
          <div className="pageNotFoundHeader" >Page does not exist</div >
          <div className="pageNotFoundText" >The page you are looking for does not exist. Perhaps you might have typed the wrong link.</div>
        </div>
      </div>
    );
  }  

  return (
    <div className="userWebsiteBody">
      <style>{`body { background: ${websiteColor}; }`}</style>
      <motion.div animate={{ y: animation }} className="websiteOptionsContainer">
        <motion.div className="websiteOptions1">
          <div className="websiteBackArrow" onClick={handleBackNavigation}>
            <img src={backArrow} alt="" className="websiteBackArrowIcon" />
          </div>
          <div className="websiteStoreButton" >
            <img id={`websiteStoreIcon`} src={storeIcon} onClick={showStore}/>
          </div>
        </motion.div>
        <motion.div className="websiteOptions2">
          <div className="websiteHeartDiv" onClick={onHeartIconClick}>
            {liked[storeID] ? <img id={`websiteRedHeartIcon`} src={redHeartIcon} alt="Liked" /> 
              : <img id={`websiteHeartIcon`} src={heartIcon} alt="Like" />}
          </div>
          <img src={infoIcon} alt="Info" className="websiteInfoIcon" onClick={() => {setShowInfo(true)}}/>
          {/* <div className="swipedCardDiv"></div> if user swiped a card, show that card*/}
        </motion.div>
      </motion.div>
      <WebsiteInfo 
        onClickOutside={() => {setShowInfo(false)}} 
        closePopup={() => {setShowInfo(false)}}
        show={showInfo} 
        store={data.store} 
        storeDescription={data.store_description}
        storeLogo={data.store_logo}
        socialMediaLinks={data.socialMediaLinks}
      />
      {showEditingDiv && 
        <div className="websiteInProgress">
          The website is still being built
        </div>
      }

      <AnimatePresence>
        {loading && (
          <motion.div 
            className="websiteLoadingDiv"
            variants={{
              exit: { 
                y: '-100px',
                opacity: 0,
                scale: 0.5,
                transition: { duration: 0.5 }
              }
            }}
            exit="exit"
            initial={{ opacity: 1, scale: 1, y: 0 }}
          >
            <img 
              src={loadIcon} 
              onDragStart={(e) => e.preventDefault()}
              className="websiteLoadingIcon" 
              alt="loading icon" 
            />
          </motion.div>
        )}
      </AnimatePresence>

      <DndProvider backend={HTML5Backend}>
        <div
          axis="y" 
          values={frames}
          className="websiteFramesDiv"
          initial="hidden"
          animate="visible"
          variants={contentVariants}
          layout={!isLoadingText}
          transition={{ stiffness: 500, damping: 30 }}
          style={{
            pointerEvents: 'none',
          }}
        >
          <div className="framesContainer" 
            style={{ 
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0
            }}
          >
            {draggableFrames.map((frame, index) => {
            // Find the corresponding frame styling from newFrameStyling using frameID
            const frameStylingData = frame.frame_styling
            
            return (
              <DraggableFrames 
                key={`${frame.frameID}-${index}`}
                index={index}
                currentFrame={frame}
                frameStyle={frame.frame_style}
                initialX={frameStylingData.x}
                initialY={frameStylingData.y}
                zIndex={frame.zIndex}
                frameID={frame.frameID}
                storeID={storeID}
                openFramePopup={null}
                openStickerPopup={null}
                websiteColor={websiteColor}
                removeFrame={null}
                frames={frames}
                frameStyling={frameStylingData} // Pass the specific frame styling
                data={storeData}
                showLightenSticker={null}
                noLiftedStyle={null}
                liftedStyle={null}
                setLiftedStyle={null}
                onFramesLoaded={() => setIsLoadingText(false)}
                handleFrameSelected={null}
                closeFrameBar={null}
                setFrameSelected={null}
                vendorBarRef={null}
                selectedFrameStyle={null}
                draggableFrameSelected={null}
                setDraggableFrameSelected={null}
                updateFrames={null}
                selectedObject={null}
                moveFrame={null}
                setShowImageFrameBar={null}
                setShowZIndexPopup={null}
                showZIndexPopup={null}
                setShowFrameBar={null}
                selectedObjectID={null}
                isDragging={true}
                frameBarRef={null}
                imageFrameBarRef={null}
                handleDeleteFunctionality={null}
                frameBorderPopupRef={null}
                draggableFrameRef={null}
                frameTypesPopupRef={null}
                opacityPopupRef={null}
                doneBtnDivRef={null}
                elementBarRef={null}
                websiteView={websiteView}
              />
              
            );
          })}
          </div>

          {fixedFrames.map((frame, index) => {
            const frameStylingData = frame.frame_styling
            
            return (
              <Frames 
                key={`${frame.frameID}-${index}`}
                index={index}
                currentFrame={frame}
                moveFrame={null}
                updateDraggingFramePosition={null}
                frameStyle={frame.frame_style}
                zIndex={frame.zIndex}
                frameID={frame.frameID}
                storeID={storeID}
                openFramePopup={null}
                openStickerPopup={null}
                websiteColor={null}
                removeFrame={null}
                frames={frames}
                frameStyling={frameStylingData} // Pass the specific frame styling
                draggableFrameSelected={null}
                setDraggableFrameSelected={null}
                data={storeData}
                showLightenSticker={null}
                noLiftedStyle={null}
                liftedStyle={null}
                onFramesLoaded={() => setIsLoadingText(false)}
                handleFrameSelected={null}
                closeFrameBar={null}
                vendorBarRef={null}
                selectedFrameStyle={null}
                updateFrames={null}
                selectedObject={null}
                setShowImageFrameBar={null}
                setShowZIndexPopup={null}
                showZIndexPopup={null}
                setShowFrameBar={null}
                selectedObjectID={null}
                isDragging={false}
                frameBarRef={null}
                imageFrameBarRef={null}
                frameBorderPopupRef={null}
                handleDeleteFunctionality={null}
                setIsResizing={null}
                onResize={null}
                frameTypesPopupRef={null}
                setFrameWidth={null}
                draggableFrameRef={null}
                opacityPopupRef={null}
                doneBtnDivRef={null}
                elementBarRef={null}
                websiteView={websiteView}
                stretchFrame={frame.stretchFrame}
              />
            );
          })}

          {stickers.map((sticker, index) => {
            const stickerStylingData = newStickerStyling?.find(obj => obj.stickerID === sticker.stickerID)?.sticker_styling;
            
            if (!sticker.isVisible) {
              return null;
            }

            return (
              <motion.div
                key={sticker.stickerID}
                variants={contentVariants}
                initial="hidden"
                animate="visible"
                style={{
                  position: 'absolute',
                }}
              >
                <Stickers
                  data={sticker}
                  storeID={storeID}
                  stickerID={sticker.stickerID}
                  image={sticker.sticker}
                  xPosition={sticker.x}
                  yPosition={sticker.y}
                  zIndex={sticker.zIndex}
                  height={sticker.height}
                  width={sticker.width}
                  addDeleteStickerPopup={null}
                  removeDeleteStickerPopup={null}
                  draggedStickerInfo={null}
                  isVisible={sticker.isVisible}
                  showLightenSticker={false}
                  lightenSticker={null}
                  noLiftedStyle={true}
                  onStickerUpdate={null}
                  handleStickerSelected={null}
                  handleStickerUnselected={null}
                  vendorBarRef={null}
                  updateStickerData={null}
                  stickerStylingData={stickerStylingData}
                  websiteView={websiteView}
                />
               </motion.div>
              )
            })}
        </div>
      </DndProvider>

      {showWebsiteStore && (
        <WebsiteProductSection 
          storeID={storeID}
          close={closeStore}
          animation={animation}
        />
      )}

      {showLogin ?
        <div className="websiteLogin">
          <div className="websiteLoginDiv">
            <p className="websiteLoginTextDiv">Please do one of the following to browse this website</p>
            <Login show={showLogin} onClickOutside={() => {setShowLogin(false)}}/>
          </div>
        </div> : null
      }
    </div>
  )
}

export default Website
