// src/Layout.jsx

import { useRef, useState, useEffect } from 'react';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot 
} from '@firebase/firestore';
import { auth, db, storage } from './firebase';
import { motion } from "framer-motion";
import './Layout.css';

import homeIcon from './icons/homeIcon.png';
import scoutIcon from './icons/scoutIcon.png';
import marketIcon from './icons/marketIcon.png';
import vendorIcon from './icons/vendorIcon.png';
import cartIcon from './icons/cartIcon.png';
import popupIcon from './icons/popupIcon.png';

const Layout = (props) => {
  const navBar = useRef();
  const popup = useRef();
  const [showNavBar, setShowNavBar] = useState(true);
  const [showPopupBar, setShowPopupBar] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const [activeIcon, setActiveIcon] = useState(pathname.substring(1) || "home");
  const veryWideScreenBreakpoint = 1250;
  const [isVeryWideScreen, setIsVeryWideScreen] = useState(window.innerWidth >= veryWideScreenBreakpoint);
  const [isHovered, setIsHovered] = useState(false);
  const [isNavBarPages, setIsNavBarPages] = useState(true);
  const [navBarReady, setNavBarReady] = useState(false);
  const [showCart, setShowCart] = useState(false);
  let hideNavBarTimeout;

  const navigate = useNavigate(); // Moved to top for consistency

  const preventDrag = (event) => {
    event.preventDefault();
  };

  const startHideNavBarTimeout = () => {
    hideNavBarTimeout = setTimeout(() => {
      setShowNavBar(false);
      setShowPopupBar(true);
      props.navBarStateFunction(false);
    }, 10000);
  };

  const clearHideNavBarTimeout = () => {
    clearTimeout(hideNavBarTimeout);
  };

  // Function to decide NavBar visibility based on current path
  const decideNavBarVisibility = () => {
    const noNavBarPages = ['/addproduct', '/dashboard', '/wallet', '/create-store', '/create-store2'];
    const shouldShowNavBar = !noNavBarPages.includes(pathname);
    setIsNavBarPages(shouldShowNavBar);
    setShowNavBar(shouldShowNavBar);
    setShowPopupBar(!shouldShowNavBar);
    props.navBarStateFunction(shouldShowNavBar);

    clearHideNavBarTimeout();
    if (shouldShowNavBar) {
      startHideNavBarTimeout();
    }
  };

  // Effect to handle active icon and nav bar visibility
  useEffect(() => {
    const updateActiveIcon = () => {
      const path = pathname.substring(1);
      if (path === "") {
        setActiveIcon("home");
      } else if (["scout", "market", "vendor", "cart"].includes(path)) {
        setActiveIcon(path);
      } else {
        setActiveIcon(prevIcon => prevIcon);
      }
    };

    updateActiveIcon();
    decideNavBarVisibility();
    setNavBarReady(true);
  }, [pathname]);

  // Effect to handle window load
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const handleLoad = () => {
      setShowNavBar(true);
      props.navBarStateFunction(true);
      startHideNavBarTimeout();
      setNavBarReady(true);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      clearHideNavBarTimeout();
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsVeryWideScreen(window.innerWidth >= veryWideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Effect to handle NavBar visibility on path change
  useEffect(() => {
    decideNavBarVisibility();
    setNavBarReady(true);
  }, [pathname]);

  // ** Updated Effect to handle cart icon visibility using onSnapshot **
  useEffect(() => {
    if (props.userID && props.userID.trim() !== "") {
      const cartRef = doc(db, 'cart', props.userID);
      const unsubscribe = onSnapshot(cartRef, (docSnap) => {
        if (docSnap.exists()) {
          const cartData = docSnap.data().cart;
          if (Array.isArray(cartData) && cartData.length > 0) {
            setShowCart(true);
          } else if (pathname === "/cart") {
            setShowCart(true); // Ensure showCart is true on the cart page
          } else {
            setShowCart(false);
          }
        } else if (pathname === "/cart") {
          setShowCart(true); // Ensure showCart is true on the cart page even if cart doesn't exist
        } else {
          setShowCart(false);
        }
      }, (error) => {
        console.error("Error onSnapshot cart:", error);
        if (pathname === "/cart") {
          setShowCart(true);
        } else {
          setShowCart(false);
        }
      });

      return () => unsubscribe();
    } else {
      if (pathname === "/cart") {
        setShowCart(true); // Ensure showCart is true on the cart page when logged out
      } else {  
        setShowCart(false);
      }
    }
  }, [props.userID, pathname]);

  const handleMouseEnter = () => {
    clearHideNavBarTimeout();
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    startHideNavBarTimeout();
  };

  const removeNavBar = () => {
    setShowNavBar(false);
    setShowPopupBar(true);
    props.navBarStateFunction(false);
  };

  const handleShowNavBar = () => {
    setShowNavBar(true);
    setShowPopupBar(false);
    props.navBarStateFunction(true);
    startHideNavBarTimeout();
  };

  const navBarVariants = {
    open: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: '5px',
      width: "fit-content",
      height: isVeryWideScreen ? "215px" : "44px",
      scale: 1,
      transition: { duration: 0.5 }
    },
    closed: { scale: 0.5, transition: { duration: 0.5 } },
    contracted: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "50px",
      height: "30px",
      transition: { duration: 0.5 }
    }
  };

  // Function to set favicon
  const setFavicon = (href) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = href;
    document.head.appendChild(link);
  };

  // Function to handle icon click
  const handleIconClick = (route, iconName) => {
    setFavicon('/favicon.ico'); // Set default favicon
    setActiveIcon(iconName);
    navigate(route);
  };

  return (
    <>
      {isNavBarPages && (
        <div
          className={`navBarParent${!showNavBar && !showPopupBar ? ' navBarHidden' : ''}${isVeryWideScreen ? 'veryWide' : ''}${!navBarReady ? ' hiddenNavBar' : ''}`}
          style={
            isVeryWideScreen ? {
              top: `10px`
            } : {
              bottom: `calc(1.25 * var(--vh))`
            }
          }
        >
          <motion.div
            className={`navBarContainer${isVeryWideScreen ? 'veryWide' : ''}`}
            variants={navBarVariants}
            animate={showNavBar ? "open" : (showPopupBar ? "contracted" : "closed")}
            style={{ borderRadius: showNavBar ? "15px" : "10px" }}
          >
            {showNavBar ?
              <motion.ul
                onClick={removeNavBar}
                ref={navBar}
                className={`navBar${isVeryWideScreen ? 'veryWide' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                initial="closed"
              >
                <li id="navBarIcons"
                  onClick={() => handleIconClick("/", "home")}
                  className={activeIcon === "home" ? "active" : ""}
                >
                  <img src={homeIcon} alt="home icon" onDragStart={preventDrag} />
                </li>

                <li id="navBarIcons"
                  onClick={() => handleIconClick("/scout", "scout")}
                  className={activeIcon === "scout" ? "active" : ""}
                >
                  <img src={scoutIcon} alt="scout icon" onDragStart={preventDrag} />
                </li>

                {/* Uncomment if needed
                <li id="navBarIcons"
                  onClick={() => handleIconClick("/market", "market")}
                  className={activeIcon === "market" ? "active" : ""}
                >
                  <img src={marketIcon} alt="market icon" onDragStart={preventDrag} />
                </li> 
                */}

                <li id="navBarIcons"
                  onClick={() => handleIconClick("/vendor", "vendor")}
                  className={activeIcon === "vendor" ? "active" : ""}
                >
                  <img src={vendorIcon} alt="vendor icon" onDragStart={preventDrag} />
                </li>

                {showCart &&
                  <li id="navBarIcons"
                    onClick={() => handleIconClick("/cart", "cart")}
                    className={activeIcon === "cart" ? "active" : ""}
                  >
                    <img src={cartIcon} alt="cart icon" onDragStart={preventDrag} />
                  </li>
                }
              </motion.ul> : null
            }
            {showPopupBar ?
              <motion.button
                ref={popup}
                className={`popup${isVeryWideScreen ? 'veryWide' : ''}`}
                id={`popup${isVeryWideScreen ? 'veryWide' : ''}`}
                onClick={handleShowNavBar}
                initial="closed"
              >
                <img src={popupIcon} alt="return navigation bar" className="popupIcon" onDragStart={preventDrag} />
              </motion.button> : null
            }
          </motion.div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default Layout;
