import React, { useRef, useState, useCallback, useEffect } from 'react'
import { Outlet, Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion';
import './VendorOpeningPopup.css'

const VendorPopup = ({ showVendorPopup, setShowLoginPopup, setShowInfo3, vendorOpeningPopupBackgroundRef, vendorOpeningPopupDivRef, navBarState }) => {
  let [animationHeight, setAnimationHeight] = useState('510px');

  const updateHeight = () => {
    const width = window.innerWidth;
    if (width >= 900) {
      setAnimationHeight(navBarState ? '510px' : '490px');
    } else if (width >= 620) {
      setAnimationHeight(navBarState ? '515px' : '485px');
    } else if (width >= 450) {
      setAnimationHeight(navBarState ? '530px' : '510px');
    } else {
      setAnimationHeight(navBarState ? '530px' : '510px');
    }
  }

  useEffect(() => {
    updateHeight(); 
  }, [navBarState]);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [])

  return (
    <div className="vendorOpeningPopupBackground" ref={vendorOpeningPopupBackgroundRef}>
      <motion.div 
        animate={{ height: animationHeight }} 
        className="vendorOpeningPopupDiv" 
        ref={vendorOpeningPopupDivRef}
        initial={{ height: '510px' }}
      >
        <h1 className="vODHeader">open your store now!</h1>
        <div className="vODDescription">
          <div className="noStoreExistsDiv">
            <p className="noStoreExistsText1"><strong>This is your moment.</strong></p> 
            <p>
              Start that online side hustle you’ve been dreaming of, or take 
              your existing business to new heights.
            </p>
            <p>
              Discover everything <strong>taizte™</strong> has to offer 
              and start building your online store today!
            </p>
            <Link to="/create-store">
              <button className="createStoreBtn">
                create store
              </button>
            </Link>
          </div>
          <div className="storeExistsDiv">
            <p  className="storeExistsText"><strong>already have a store?</strong></p>
            <button className="vendorLoginBtn"
              onClick={() => {
                setShowLoginPopup(true)
                setShowInfo3(true)
              }}
            >
              login
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default VendorPopup
