import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const CategoriesDiv = ({ category, setCategory }) => {
  const categoryOptions = [
    { label: "Antiques & Collectables", value: "Antiques & Collectables" },
    { label: "Art", value: "Art" },
    { label: "Audio & Media", value: "Audio & Media" },
    { label: "Automotive & DIY", value: "Automotive & DIY" },
    { label: "Beauty", value: "Beauty" },
    { label: "Books", value: "Books" },
    { label: "Cellphones & Wearables", value: "Cellphones & Wearables" },
    { label: "Crafts & Handmade Goods", value: "Crafts & Handmade Goods" },
    { label: "Electronics", value: "Electronics" },
    { label: "Fashion", value: "Fashion" },
    { label: "Gaming", value: "Gaming" },
    { label: "Garden, Pool & Patio", value: "Garden, Pool & Patio" },
    { label: "Health, Fitness & Personal Care", value: "Health, Fitness & Personal Care" },
    { label: "Hobbies & Toys", value: "Hobbies & Toys" },
    { label: "Holistic & Esoteric", value: "Holistic & Esoteric" },
    { label: "Home & Decor", value: "Home & Decor" },
    { label: "Movies & Television", value: "Movies & Television" },
    { label: "Music & Instruments", value: "Music & Instruments" },
    { label: "Office & Stationary", value: "Office & Stationary" },
    { label: "Pets", value: "Pets" },
    { label: "Physical Sport & Training", value: "Physical Sport & Training" },
  ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      position: 'relative',
      backgroundColor: '#f5f5f5',
      height: '30px',
      borderRadius: '10px',
      width: '100%',
      fontFamily: 'Poppins',
      fontSize: '14px',
      zIndex: 1001,
      boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
      borderColor: 'none',
      border: 'none'
    }),
    menu: (base) => ({
      ...base,
      position: 'absolute',
      backgroundColor: '#f5f5f5',
      top: '20px',
      marginBottom: '-24px',
      borderRadius: '0px 0px 12px 12px',
      fontFamily: 'Poppins',
      fontSize: '12px',
      zIndex: 1000,
      paddingTop: '15px',    
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#FB890E' : (state.isFocused ? '#FFC55C' : base.backgroundColor),
      color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
      width: '98%',
      margin: '2px auto',
      boxSizing: 'border-box',
      overflow: 'hidden',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      textAlign: 'left',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#333',
    }),
  };

  return (
    <Select
      value={categoryOptions.find(option => option.value === category) || null}
      onChange={(selectedOption) => setCategory(selectedOption ? selectedOption.value : '')}
      options={categoryOptions}
      placeholder="choose category"
      styles={customStyles}
      isClearable
    />
  );
};

CategoriesDiv.propTypes = {
  category: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
};

export default CategoriesDiv;
