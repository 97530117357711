import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import styles from './frameStyles.json'; // Assuming you have this JSON file
import { db } from '../../firebase'; // Firebase database instance
import { doc, updateDoc, getDoc } from '@firebase/firestore';

import dropdownIcon from '../../icons/popupIcon.png';
import borderRadiusIcon from '../../icons/borderRadiusIcon.png';
import topLeftCornerIcon from '../../icons/topLeftCornerIcon.png';
import topRightCornerIcon from '../../icons/topRightCornerIcon.png';
import bottomLeftCornerIcon from '../../icons/bottomLeftCornerIcon.png';
import bottomRightCornerIcon from '../../icons/bottomRightCornerIcon.png';

const FrameBorderRadius = (props) => {
  const {
    selectedObjectData,
    updateFrames,
    data,
    selectedObjectID,
    storeID,
  } = props;

  // State variables for border radius inputs and values
  const [showCornerDropdownContent, setShowCornerDropdownContent] = useState(false);
  const [unifiedBorderRadiusInput, setUnifiedBorderRadiusInput] = useState('');
  const [topLeftRadiusInput, setTopLeftRadiusInput] = useState('');
  const [topRightRadiusInput, setTopRightRadiusInput] = useState('');
  const [bottomLeftRadiusInput, setBottomLeftRadiusInput] = useState('');
  const [bottomRightRadiusInput, setBottomRightRadiusInput] = useState('');

  const [unifiedBorderRadius, setUnifiedBorderRadius] = useState('');
  const [topLeftRadius, setTopLeftRadius] = useState('');
  const [topRightRadius, setTopRightRadius] = useState('');
  const [bottomLeftRadius, setBottomLeftRadius] = useState('');
  const [bottomRightRadius, setBottomRightRadius] = useState('');

  // Initialize or update border radii based on selectedObjectData
  useEffect(() => {
    if (selectedObjectData && selectedObjectData.frame_styling) {
      const styling = selectedObjectData.frame_styling;

      // Determine default border radius
      const frameStyleName = selectedObjectData.frame_style;
      const defaultStyle = styles[frameStyleName];
      const defaultBorderRadiusValue = defaultStyle?.borderRadius.slice(0, -2) || '0';

      // Initialize or retrieve border radii
      if (!styling.borderTopRightRadius) {
        setTopLeftRadius(defaultBorderRadiusValue);
        setTopRightRadius(defaultBorderRadiusValue);
        setBottomLeftRadius(defaultBorderRadiusValue);
        setBottomRightRadius(defaultBorderRadiusValue);
        setUnifiedBorderRadius(defaultBorderRadiusValue);

        // Initialize input states
        setUnifiedBorderRadiusInput(defaultBorderRadiusValue);
        setTopLeftRadiusInput(defaultBorderRadiusValue);
        setTopRightRadiusInput(defaultBorderRadiusValue);
        setBottomLeftRadiusInput(defaultBorderRadiusValue);
        setBottomRightRadiusInput(defaultBorderRadiusValue);
      } else {
        // Extract individual corner radii
        const parsedTopLeft = styling.borderTopLeftRadius?.slice(0, -2) || '0';
        const parsedTopRight = styling.borderTopRightRadius?.slice(0, -2) || '0';
        const parsedBottomLeft = styling.borderBottomLeftRadius?.slice(0, -2) || '0';
        const parsedBottomRight = styling.borderBottomRightRadius?.slice(0, -2) || '0';

        setTopLeftRadius(parsedTopLeft);
        setTopRightRadius(parsedTopRight);
        setBottomLeftRadius(parsedBottomLeft);
        setBottomRightRadius(parsedBottomRight);

        // Determine if unified or mixed
        if (
          styling.borderTopLeftRadius === styling.borderTopRightRadius &&
          styling.borderTopRightRadius === styling.borderBottomLeftRadius &&
          styling.borderBottomLeftRadius === styling.borderBottomRightRadius
        ) {
          const unifiedValue = styling.borderTopLeftRadius?.slice(0, -2) || '0';
          setUnifiedBorderRadius(unifiedValue);
          setUnifiedBorderRadiusInput(unifiedValue);
        } else {
          setUnifiedBorderRadius('mixed');
          setUnifiedBorderRadiusInput('mixed');
        }

        // Initialize input states
        setTopLeftRadiusInput(parsedTopLeft);
        setTopRightRadiusInput(parsedTopRight);
        setBottomLeftRadiusInput(parsedBottomLeft);
        setBottomRightRadiusInput(parsedBottomRight);
      }
    }
  }, [selectedObjectData, styles]);

  // Debounced function to update border radius in Firestore
  const debouncedUpdateBorderRadius = useCallback(
    debounce(async (updatedStyling) => {
      if (!selectedObjectData) return;

      try {
        // Update Firestore for frames
        const framesRef = doc(db, 'frames', selectedObjectID);
        await updateDoc(framesRef, { frame_styling: updatedStyling });

        // Update the frames array in the stores document
        const storesRef = doc(db, 'stores', storeID);
        const storeDocSnap = await getDoc(storesRef);
        if (storeDocSnap.exists()) {
          const storeData = storeDocSnap.data();
          const framesArray = storeData.frames || [];
          const frameIndex = framesArray.findIndex(frame => frame.frameID === selectedObjectID);
          if (frameIndex !== -1) {
            framesArray[frameIndex].frame_styling = updatedStyling;
            await updateDoc(storesRef, { frames: framesArray });
          }
        }
      } catch (error) {
        console.error("Failed to update Firestore:", error);
      }
    }, 300), // 300ms debounce delay
    [selectedObjectData, selectedObjectID, storeID]
  );

  // Handler for unified border radius input (onBlur)
  const handleUnifiedBorderRadiusBlur = (radiusValue) => {
    if (unifiedBorderRadiusInput === 'mixed') {
      return;
    }

    if (radiusValue !== '' && isNaN(radiusValue)) {
      return;
    }

    // If input is empty, default to '0'
    const finalRadius = radiusValue !== '' ? radiusValue : '0';

    // Prepare updated frame styling with individual corner radii and borderRadius shorthand
    const updatedFrameStyling = {
      ...selectedObjectData.frame_styling,
      borderRadius: `${finalRadius}px`, // Unified border radius
      borderTopLeftRadius: `${finalRadius}px`,
      borderTopRightRadius: `${finalRadius}px`,
      borderBottomLeftRadius: `${finalRadius}px`,
      borderBottomRightRadius: `${finalRadius}px`,
    };

    // Update main states
    setUnifiedBorderRadius(finalRadius);
    setTopLeftRadius(finalRadius);
    setTopRightRadius(finalRadius);
    setBottomLeftRadius(finalRadius);
    setBottomRightRadius(finalRadius);

    // Update the input to the final radius
    setUnifiedBorderRadiusInput(finalRadius);

    // Update props.data array
    const updatedObjectData = { ...selectedObjectData, frame_styling: updatedFrameStyling };
    const updatedData = data.map(obj =>
      obj.frameID === selectedObjectID ? updatedObjectData : obj
    );
    updateFrames(updatedData, updatedObjectData);

    // Invoke debounced Firestore update
    debouncedUpdateBorderRadius(updatedFrameStyling);
  };

  // Handler for individual border radius input (onBlur)
  const handleIndividualBorderRadiusBlur = (corner, radiusInput) => {
    let setRadius;

    switch (corner) {
      case 'topLeft':
        setRadius = setTopLeftRadius;
        break;
      case 'topRight':
        setRadius = setTopRightRadius;
        break;
      case 'bottomLeft':
        setRadius = setBottomLeftRadius;
        break;
      case 'bottomRight':
        setRadius = setBottomRightRadius;
        break;
      default:
        return;
    }

    // Validate input
    const radiusValue = radiusInput !== '' ? parseInt(radiusInput, 10) : 0;
    if (radiusInput !== '' && isNaN(radiusValue)) {
      return;
    }

    // Prepare updated frame styling with the specific corner radius
    const updatedFrameStyling = {
      ...selectedObjectData.frame_styling,
      [`border${capitalizeFirstLetter(corner)}Radius`]: `${radiusValue}px`,
    };

    // Update main state
    setRadius(radiusInput !== '' ? radiusInput : '0');

    // Determine if all corners have the same radius
    const finalTopLeft = corner === 'topLeft' ? radiusInput : topLeftRadiusInput;
    const finalTopRight = corner === 'topRight' ? radiusInput : topRightRadiusInput;
    const finalBottomLeft = corner === 'bottomLeft' ? radiusInput : bottomLeftRadiusInput;
    const finalBottomRight = corner === 'bottomRight' ? radiusInput : bottomRightRadiusInput;

    const finalTopLeftVal = finalTopLeft !== '' ? finalTopLeft : '0';
    const finalTopRightVal = finalTopRight !== '' ? finalTopRight : '0';
    const finalBottomLeftVal = finalBottomLeft !== '' ? finalBottomLeft : '0';
    const finalBottomRightVal = finalBottomRight !== '' ? finalBottomRight : '0';

    if (
      finalTopLeftVal === finalTopRightVal &&
      finalTopRightVal === finalBottomLeftVal &&
      finalBottomLeftVal === finalBottomRightVal
    ) {
      // All corners have the same radius, set borderRadius
      updatedFrameStyling.borderRadius = `${finalTopLeftVal}px`;
      setUnifiedBorderRadius(finalTopLeftVal);
      setUnifiedBorderRadiusInput(finalTopLeftVal);
    } else {
      // Corners have different radii, remove borderRadius
      const { borderRadius, ...restStyling } = updatedFrameStyling;
      delete updatedFrameStyling.borderRadius;
      setUnifiedBorderRadius('mixed');
      setUnifiedBorderRadiusInput('mixed');
    }

    // Update props.data array after possible borderRadius removal
    const updatedObjectData = { ...selectedObjectData, frame_styling: updatedFrameStyling };
    const updatedData = data.map(obj =>
      obj.frameID === selectedObjectID ? updatedObjectData : obj
    );
    updateFrames(updatedData, updatedObjectData);

    // Invoke debounced Firestore update
    debouncedUpdateBorderRadius(updatedFrameStyling);
  };

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // ... (rest of the component remains unchanged)

  return (
    <div className="imageFrameBorderRadius">
      <div className="imageFrameBorderRadiusFirstSection">
        <div className="imageFrameBorderRadiusText">border radius</div>
        <div className="imageFrameBorderRadiusInputSection">
          <input 
            type="number" 
            min="0"
            className="imageFrameBorderRadiusInput" 
            value={unifiedBorderRadiusInput}
            style={{ width: unifiedBorderRadiusInput === 'mixed' ? '60px' : '30px' }}
            onChange={(e) => {
              const value = e.target.value.trim();
              setUnifiedBorderRadiusInput(value);
              // Removed handleUnifiedBorderRadiusBlur from here
            }}                  
            onBlur={() => handleUnifiedBorderRadiusBlur(unifiedBorderRadiusInput)}
            placeholder={unifiedBorderRadius === 'mixed' ? 'Mixed' : '0'}
          />
          <div className="imageFrameBorderRadiusPixelText">px</div>
        </div>
      </div>
      <div className="imageFrameBorderRadiusCornerDropdown">
        <div className="imageFrameCornerSectionContainer">
          <div className="imageFrameCornerSection1">
            <img src={borderRadiusIcon} className="imageFrameCornerIcon" alt="Border Radius Icon" />
            <div className="imageFrameCornerSectionText">set individual corner radii</div>
          </div>
          <img 
            src={dropdownIcon} 
            className="imageFrameCornerSectionDropdownIcon" 
            alt="Dropdown Icon"
            onClick={() => {setShowCornerDropdownContent(!showCornerDropdownContent)}}
          />
        </div>
        {showCornerDropdownContent &&
          <div className="imageFrameCornerDropdownContainer">
            <div className="imageFrameCornerDropdownTopSection">
              {/* Top Left Corner */}
              <div className="topLeftCornerDiv">
                <img src={topLeftCornerIcon} alt="Top Left Corner" className="topLeftCornerIcon" />
                <input 
                  type="number" 
                  min="0"
                  className="topLeftCornerInput" 
                  value={topLeftRadiusInput}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    setTopLeftRadiusInput(value);
                    // Removed handleIndividualBorderRadiusChange from here
                  }}
                  onBlur={() => handleIndividualBorderRadiusBlur('topLeft', topLeftRadiusInput)}
                  placeholder="0"
                />
                <div className="cornerRadiusPixelText">px</div>
              </div>
              {/* Top Right Corner */}
              <div className="topRightCornerDiv">
                <img src={topRightCornerIcon} alt="Top Right Corner" className="topRightCornerIcon" />
                <input 
                  type="number" 
                  min="0"
                  className="topRightCornerInput" 
                  value={topRightRadiusInput}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    setTopRightRadiusInput(value);
                    // Removed handleIndividualBorderRadiusChange from here
                  }}
                  onBlur={() => handleIndividualBorderRadiusBlur('topRight', topRightRadiusInput)}
                  placeholder="0"
                />
                <div className="cornerRadiusPixelText">px</div>
              </div>
            </div>
            <div className="imageFrameCornerDropdownBottomSection">
              {/* Bottom Left Corner */}
              <div className="bottomLeftCornerDiv">
                <img src={bottomLeftCornerIcon} alt="Bottom Left Corner" className="bottomLeftCornerIcon" />
                <input 
                  type="number" 
                  min="0"
                  className="bottomLeftCornerInput" 
                  value={bottomLeftRadiusInput}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    setBottomLeftRadiusInput(value);
                    // Removed handleIndividualBorderRadiusChange from here
                  }}
                  onBlur={() => handleIndividualBorderRadiusBlur('bottomLeft', bottomLeftRadiusInput)}
                  placeholder="0"
                />
                <div className="cornerRadiusPixelText">px</div>
              </div>
              {/* Bottom Right Corner */}
              <div className="bottomRightCornerDiv">
                <img src={bottomRightCornerIcon} alt="Bottom Right Corner" className="bottomRightCornerIcon" />
                <input 
                  type="number" 
                  min="0"
                  className="bottomRightCornerInput" 
                  value={bottomRightRadiusInput}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    setBottomRightRadiusInput(value);
                    // Removed handleIndividualBorderRadiusChange from here
                  }}
                  onBlur={() => handleIndividualBorderRadiusBlur('bottomRight', bottomRightRadiusInput)}
                  placeholder="0"
                />
                <div className="cornerRadiusPixelText">px</div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default FrameBorderRadius;
