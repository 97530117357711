export const shippingInformation = [
    {
      name: 'Aramex',
      logo: 'aramexLogo',
      options: [
        {
          description: 'standard',
          dimensions: '35 x 45cm',
          price: 'R99.99',
          value: 'Aramex Standard (35 x 45cm)'
        }
        // Add more options here if necessary
      ]
    },
    {
      name: 'Pudo',
      logo: 'pudoLogo',
      subgroups: [
        {
          subgroup: 'Locker to Locker',
          options: [
            {
              description: 'Extra Small',
              dimensions: '60 x 17 x 8cm',
              price: 'R40.00',
              value: 'Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)'
            },
            {
              description: 'Small',
              dimensions: '60 x 41 x 8cm',
              price: 'R50.00',
              value: 'Locker-to-Locker Pudo Small (60 x 41 x 8cm)'
            },
            {
              description: 'Medium',
              dimensions: '60 x 41 x 19cm',
              price: 'R50.00',
              value: 'Locker-to-Locker Pudo Medium (60 x 41 x 19cm)'
            },
            {
              description: 'Large',
              dimensions: '60 x 41 x 41cm',
              price: 'R50.00',
              value: 'Locker-to-Locker Pudo Large (60 x 41 x 41cm)'
            },
            {
              description: 'Extra Large',
              dimensions: '60 x 41 x 69cm',
              price: 'R50.00',
              value: 'Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)'
            }
          ]
        },
        {
          subgroup:  "Door to Locker",
          options: [
            {
              description: "Extra Small",
              dimensions: "60 x 17 x 8cm",
              price: "R50.00",
              value: "Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)"
            },
            {
              description: "Small",
              dimensions: "60 x 41 x 8cm",
              price: "R60.00",
              value: "Door-to-Locker Pudo Small (60 x 41 x 8cm)"
            },
            {
              description: "Medium",
              dimensions: "60 x 41 x 19cm",
              price: "R100.00",
              value: "Door-to-Locker Pudo Medium (60 x 41 x 19cm)"
            },
            {
              description: "Large",
              dimensions: "60 x 41 x 41cm",
              price: "R150.00",
              value: "Door-to-Locker Pudo Large (60 x 41 x 41cm)"
            },
            {
              description: "Extra Large",
              dimensions: "60 x 41 x 69cm",
              price: "R200.00",
              value: "Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)"
            }
          ]
        },
        {
          subgroup:  "Locker to Door",
          options: [
            {
              description: "Extra Small",
              dimensions: "60 x 17 x 8cm",
              price: "R50.00",
              value: "Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)"
            },
            {
              description: "Small",
              dimensions: "60 x 41 x 8cm",
              price: "R60.00",
              value: "Locker-to-Door Pudo Small (60 x 41 x 8cm)"
            },
            {
              description: "Medium",
              dimensions: "60 x 41 x 19cm",
              price: "R100.00",
              value: "Locker-to-Door Pudo Medium (60 x 41 x 19cm)"
            },
            {
              description: "Large",
              dimensions: "60 x 41 x 41cm",
              price: "R150.00",
              value: "Locker-to-Door Pudo Large (60 x 41 x 41cm)"
            },
            {
              description: "Extra Large",
              dimensions: "60 x 41 x 69cm",
              price: "R200.00",
              value: "Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)"
            }
          ]
        }          
      ]
    },
    {
      name: 'Paxi',
      logo: 'paxiLogo',
      options: [
        {
          description: 'standard',
          dimensions: '45 x 37cm',
          price: 'R59.95',
          value: 'Paxi Standard (45 x 37cm)'
        },
        {
          description: 'large',
          dimensions: '64 x 51cm',
          price: 'R89.95',
          value: 'Paxi Large (64 x 51cm)'
        }
        // Add more options here if necessary
      ]
    }
    // Add more shipping methods here if necessary
  ];
  