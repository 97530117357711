import React, { useRef, useState, useCallback, useEffect } from 'react'
import { Outlet, Link } from "react-router-dom";
import { 
  addDoc, collection, doc, updateDoc, setDoc, getDoc, getDocs, 
  query, where, onSnapshot, deleteDoc, arrayContains, serverTimestamp
} from '@firebase/firestore'
import { 
  ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, deleteObject } 
from 'firebase/storage'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from '../../firebase'
import Select from "react-select";

import './Dashboard.css'
import editIcon from '../../icons/editIcon.png'
import dropdownIcon from '../../icons/popupIcon.png'
import aramexLogo from '../../images/aramexLogo.png'
import pudoLogo from '../../images/pudoLogo.png'
import paxiLogo from '../../images/paxiLogo.png'
import LongPress from '../../components/LongPress';

const BundlingDiv = ({storeID, userProducts}) => {
  const [bundledProducts, setBundledProducts] = useState([]);
  const [showBundledProducts, setShowBundledProducts] = useState(false);
  const [showBundlingSettings, setShowBundlingSettings] = useState(true);
  const [initialBundlingRules, setInitialBundlingRules] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [bundlingRules, setBundlingRules] = useState({
    "Aramex Standard (35 x 45cm)": 1,
    "Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)": 1,
    "Locker-to-Locker Pudo Small (60 x 41 x 8cm)": 1,
    "Locker-to-Locker Pudo Medium (60 x 41 x 19cm)": 1,
    "Locker-to-Locker Pudo Large (60 x 41 x 41cm)": 1,
    "Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)": 1,
    "Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)": 1,
    "Door-to-Locker Pudo Small (60 x 41 x 8cm)": 1,
    "Door-to-Locker Pudo Medium (60 x 41 x 19cm)": 1,
    "Door-to-Locker Pudo Large (60 x 41 x 41cm)": 1,
    "Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)": 1,
    "Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)": 1,
    "Locker-to-Door Pudo Small (60 x 41 x 8cm)": 1,
    "Locker-to-Door Pudo Medium (60 x 41 x 19cm)": 1,
    "Locker-to-Door Pudo Large (60 x 41 x 41cm)": 1,
    "Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)": 1,
    "Paxi Standard (45 x 37cm)": 1,
    "Paxi Large (64 x 51cm)": 1
  });

  useEffect(() => {
    if (userProducts) {
      const bundled = userProducts.filter(product => product.bundling === 'yes');
      setBundledProducts(bundled);
    }
  }, [userProducts]); 

  const [openDropdown, setOpenDropdown] = useState('');

  const toggleDropdown = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(''); // if the clicked dropdown is currently open, close it
    } else {
      setOpenDropdown(dropdown); // otherwise, open the clicked dropdown
    }
  };

  useEffect(() => {
    if (storeID) {
      const fetchBundlingRules = async () => {
        const dbRef = doc(db, "stores", storeID);
        await getDoc(dbRef).then(docSnap => {
          if (docSnap.exists()) {
            console.log(docSnap.data())
            setBundlingRules(docSnap.data().bundlingRules || {});
          } else {
            console.log("No such document!");
          }
        })
      };
    
      fetchBundlingRules();
    }
  }, [storeID]);
  
  const saveBundlingRules = async () => {
    const dbRef = doc(db, "stores", storeID);
  
    await updateDoc(dbRef, {
      bundlingRules,
    });

    setIsEditMode(false);
  };
  
  const cancelChanges = () => {
    setBundlingRules(initialBundlingRules);
    setIsEditMode(false);
  };

  return (
    <div className="bundlingSettingsDiv">
      <div className="bundlingSettingsHeader">
        bundling
        {showBundlingSettings && 
          (isEditMode ? 
            <div className="bundlingEditButtonContainer">
              <div className="bundlingEditSaveButton" onClick={saveBundlingRules}>Save</div>
              <div  className="bundlingEditCancelButton" onClick={cancelChanges}>Cancel</div>
            </div>
          :
            <img className="bundlingEditButton" src={editIcon} 
              onClick={() => {
                setIsEditMode(true);
                setInitialBundlingRules(bundlingRules);
              }}
            />
          )
        }
      </div>
      <div className="bundlingDivContent">
        <div className="bundlingOptionsButtonsDiv" 
          style={{ marginTop: isEditMode ? '90px' : '40px' }}
        >
        <div 
          className={`bundlingSettingsBtn${showBundlingSettings ? 'Active' : ''}`} 
          onClick={() => {
            setShowBundlingSettings(true)
            setShowBundledProducts(false)
          }}>
          settings
        </div>
        <div 
          className={`bundlingProductsBtn${showBundledProducts ? 'Active' : ''}`} 
          onClick={() => {
            setShowBundledProducts(true)
            setShowBundlingSettings(false)
          }}>
          products
        </div>
        </div>
        <div className="bundlingSettingsDescriptionDiv">
          <div className="bundlingDescriptionText">
            {showBundlingSettings && "How many of your products can roughly fit into each package’s dimensions?"}
            {showBundledProducts && "These are all your products with bundling enabled"}
          </div>
        </div>
        {showBundledProducts && 
        <div className="bundlingProductsDiv">
          {bundledProducts.map((object, index) => (
            <div className="bundledStoresProducts" >
              <div className="storesProductsSection1">
                <img src={object.image} className="storesProductImg"/>
                <div className="storesProductInfo">
                  <div className="storesProductName">{object.productName}</div>
                  <div className="storesProductPrice">R{object.listed_price}</div>
                </div>
              </div>
              <div className="storesProductsSection2">
                <div className="storesProductBundlingQuantity">{object.quantity}</div>
              </div>
            </div>
          ))}
        </div>
        }
        {showBundlingSettings && 
          <div className="bundlingSettingContainer">
            <div className="aramexBundling">
              <img className="aramexBundlingLogo" src={aramexLogo} alt="aramex" />
              <div className="aramexBundlingOptions">
                <label className="aramexOption1">
                  <p className="aramexBundling1Text">standard (35 x 45cm)</p>
                  <p className="aramexBundlingPrice">R99.99</p>
                </label>
                <input type="number" min="1" placeholder='1'
                  id="dashboardBundlingBox" 
                  value={bundlingRules["Aramex Standard (35 x 45cm)"]}
                  onChange={(e) => {
                    setIsEditMode(true)
                    setBundlingRules({
                    ...bundlingRules,
                    "Aramex Standard (35 x 45cm)": parseInt(e.target.value, 10)
                    })
                  }}
                />
              </div>
            </div>
            <div className="pudo">
              <img className="pudoBundlingLogo" src={pudoLogo} alt="pudo" />
              <div className="pudoBundlingOptionsDiv">
                <div className="pudoBundlingSelections" onClick={() => toggleDropdown('lockerToLocker')}>
                  <div className="pudoSelectionsHeader" >
                    Locker to Locker
                    <img className={openDropdown === 'lockerToLocker' ? "bundlingDropdownIcon2" : "bundlingDropdownIcon"} src={dropdownIcon} alt="dropdown icon" />
                  </div>
                  {openDropdown === 'lockerToLocker' && 
                    <div className="pudoBundlingOptionsLockerToLocker">
                      <div className="pudoOption">
                        <div className="pudoOption1Container">
                          <p className="pudoBundlingText">extra small</p>
                          <p className="pudoOption1Dimensions">(60 x 17 x 8cm)</p>
                          <p className="pudoBundlingPrice">R40.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                            ...bundlingRules,
                            "Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption2Container">
                          <p className="pudoBundlingText">small (60 x 41 x 8cm)</p>
                          <p className="pudoBundlingPrice">R50.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Locker Pudo Small (60 x 41 x 8cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Locker Pudo Small (60 x 41 x 8cm)": parseInt(e.target.value, 10)
                            })}
                          }
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption3Container">
                          <p className="pudoBundlingText">medium</p>
                          <p className="pudoOption3Dimensions">(60 x 41 x 19cm)</p>
                          <p className="pudoBundlingPrice">R50.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Locker Pudo Medium (60 x 41 x 19cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Locker Pudo Medium (60 x 41 x 19cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption4Container">
                          <p className="pudoBundlingText">large (60 x 41 x 41cm)</p>
                          <p className="pudoBundlingPrice">R50.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Locker Pudo Large (60 x 41 x 41cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Locker Pudo Large (60 x 41 x 41cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption5Container">
                          <p className="pudoBundlingText">extra large</p>
                          <p className="pudoOption5Dimensions">(60 x 41 x 69cm)</p>
                          <p className="pudoBundlingPrice">R50.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                    </div>
                  }
                </div>
                <div className="pudoBundlingSelections" onClick={() => toggleDropdown('doorToLocker')}>
                  <div className="pudoSelectionsHeader" >
                    Door to Locker
                    <img className={openDropdown === 'doorToLocker' ? "bundlingDropdownIcon2" : "bundlingDropdownIcon"} src={dropdownIcon} alt="dropdown icon" />                    
                  </div>
                  {openDropdown === 'doorToLocker' && 
                    <div className="pudoBundlingOptionsDoorToLocker">
                      <div className="pudoOption">
                        <div className="pudoOption1Container">
                          <p className="pudoBundlingText">extra small</p>
                          <p className="pudoOption1Dimensions">(60 x 17 x 8cm)</p>
                          <p className="pudoBundlingPrice">R50.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption2Container">
                          <p className="pudoBundlingText">small (60 x 41 x 8cm)</p>
                          <p className="pudoBundlingPrice">R60.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox"  
                          value={bundlingRules["Door-to-Locker Pudo Small (60 x 41 x 8cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Door-to-Locker Pudo Small (60 x 41 x 8cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption3Container">
                          <p className="pudoBundlingText">medium</p>
                          <p className="pudoOption3Dimensions">(60 x 41 x 19cm)</p>
                          <p className="pudoBundlingPrice">R100.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Door-to-Locker Pudo Medium (60 x 41 x 19cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Door-to-Locker Pudo Medium (60 x 41 x 19cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption4Container">
                          <p className="pudoBundlingText">large (60 x 41 x 41cm)</p>
                          <p className="pudoBundlingPrice">R150.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Door-to-Locker Pudo Large (60 x 41 x 41cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Door-to-Locker Pudo Large (60 x 41 x 41cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption5Container">
                          <p className="pudoBundlingText">extra large</p>
                          <p className="pudoOption5Dimensions">(60 x 41 x 69cm)</p>
                          <p className="pudoBundlingPrice">R200.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                    </div>
                  }
                </div>
                <div className="pudoBundlingSelections" onClick={() => toggleDropdown('lockerToDoor')}>
                  <div className="pudoSelectionsHeader" >
                    Locker to Door
                    <img className={openDropdown === 'lockerToDoor' ? "bundlingDropdownIcon2" : "bundlingDropdownIcon"} src={dropdownIcon} alt="dropdown icon" />
                  </div>
                  {openDropdown === 'lockerToDoor' && 
                    <div className="pudoBundlingOptionsLockerToDoor">
                      <div className="pudoOption">
                        <div className="pudoOption1Container">
                          <p className="pudoBundlingText">extra small</p>
                          <p className="pudoOption1Dimensions">(60 x 17 x 8cm)</p>
                          <p className="pudoBundlingPrice">R50.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption2Container">
                          <p className="pudoBundlingText">small (60 x 41 x 8cm)</p>
                          <p className="pudoBundlingPrice">R60.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox"  
                          value={bundlingRules["Locker-to-Door Pudo Small (60 x 41 x 8cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Door Pudo Small (60 x 41 x 8cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption3Container">
                          <p className="pudoBundlingText">medium</p>
                          <p className="pudoOption3Dimensions">(60 x 41 x 19cm)</p>
                          <p className="pudoBundlingPrice">R100.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Door Pudo Medium (60 x 41 x 19cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Door Pudo Medium (60 x 41 x 19cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption4Container">
                          <p className="pudoBundlingText">large (60 x 41 x 41cm)</p>
                          <p className="pudoBundlingPrice">R150.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Door Pudo Large (60 x 41 x 41cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Door Pudo Large (60 x 41 x 41cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                      <div className="pudoOption">
                        <div className="pudoOption5Container">
                          <p className="pudoBundlingText">extra large</p>
                          <p className="pudoOption5Dimensions">(60 x 41 x 69cm)</p>
                          <p className="pudoBundlingPrice">R200.00</p>
                        </div>
                        <input type="number" min="1" placeholder='1'
                          id="dashboardBundlingBox" 
                          value={bundlingRules["Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)"]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setIsEditMode(true)
                            setBundlingRules({
                              ...bundlingRules,
                              "Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)": parseInt(e.target.value, 10)
                            })
                          }}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="paxi">
              <img className="paxiBundlingLogo" src={paxiLogo} alt="paxi" />
              <div className="paxiBundlingOptions">
                <label className="paxiBundlingOption1">
                  <div className="paxiOption1Container">
                    <p className="paxiBundlingOption1Text">standard (45 x 37cm)</p>
                    <p className="paxiPrice">R59.95</p>
                  </div>
                  <input type="number" min="1" placeholder='1'
                    id="dashboardBundlingBox" 
                    value={bundlingRules["Paxi Standard (45 x 37cm)"]}
                    onChange={(e) => {
                      setIsEditMode(true)
                      setBundlingRules({
                        ...bundlingRules,
                        "Paxi Standard (45 x 37cm)": parseInt(e.target.value, 10)
                      })
                    }}
                  />
                </label>
                <label className="paxiBundlingOption2">
                  <div className="paxiOption2Container">
                    <p className="paxiBundlingOption2Text">large (64 x 51cm)</p>
                    <p className="paxiPrice">R89.95</p>
                  </div>
                  <input type="number" min="1" placeholder='1'
                    id="dashboardBundlingBox" 
                    value={bundlingRules["Paxi Large (64 x 51cm)"]}
                    onChange={(e) => {
                      setIsEditMode(true)
                      setBundlingRules({
                        ...bundlingRules,
                        "Paxi Large (64 x 51cm)": parseInt(e.target.value, 10)
                      })
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )

}

export default BundlingDiv
