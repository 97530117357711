import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './VintageHaze.css';

const VintageHaze = ({ dominantColors }) => {
  const [showHaze, setShowHaze] = useState(false);
  const [hazeStyle, setHazeStyle] = useState({});
  const [hazeDuration, setHazeDuration] = useState(0);
  const [blendMode, setBlendMode] = useState('');
  const [expandEffect, setExpandEffect] = useState(false); // New state for expansion

  const blendModes = ['overlay', 'screen', 'multiply', 'soft-light'];

  const invertColour = (hex) => {
    const rgb = parseInt(hex.slice(1), 16); 
    const r = (255 - ((rgb >> 16) & 0xff)) * 0.7; 
    const g = (255 - ((rgb >>  8) & 0xff)) * 0.7;
    const b = (255 - ((rgb >>  0) & 0xff)) * 0.7;
    return `rgb(${r}, ${g}, ${b})`;
  };

  const getPaletteType = () => {
    const averageLightness = dominantColors.reduce((sum, color) => {
      const rgb = parseInt(color.slice(1), 16); 
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >>  8) & 0xff;
      const b = (rgb >>  0) & 0xff;
      const lightness = (r + g + b) / 3;
      return sum + lightness;
    }, 0) / dominantColors.length;
    return averageLightness > 128 ? 'light' : 'dark';
  };

  const createHaze = () => {
    const palette = getPaletteType();
    const invertedColor = invertColour(dominantColors[Math.floor(Math.random() * dominantColors.length)]);
    const opacity = Math.random() * (0.95 - 0.5) + 0.5;
    const width = Math.random() * 40 + 10;
    const left = Math.random() * (100 - width);
    const duration = Math.random() * 1.5 + 0.5;
    const blendMode = blendModes[Math.floor(Math.random() * blendModes.length)];

    // Randomly decide if this haze should expand
    const shouldExpand = Math.random() > 0.5;

    setHazeStyle({
      background: `linear-gradient(${invertedColor}, transparent)`,
      opacity: opacity,
      width: `${width}%`,
      left: `${left}%`,
      filter: 'blur(15px)', 
      mixBlendMode: blendMode,
    });

    setHazeDuration(duration);
    setBlendMode(blendMode);
    setExpandEffect(shouldExpand);
    setShowHaze(true);

    if (duration > 1) {
      setTimeout(() => {
        setHazeStyle((prev) => ({
          ...prev,
          animation: `flicker 0.2s infinite`,
        }));
      }, duration * 500); 
    }

    setTimeout(() => {
      setShowHaze(false);
    }, duration * 1000);

    if (Math.random() > 0.5) {
      setTimeout(createHaze, 700);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      createHaze();
    }, Math.random() * (10000 - 3000) + 3000);

    return () => clearInterval(interval);
  }, [dominantColors]);

  return (
    showHaze && (
      <motion.div
        className="vintage-haze"
        style={hazeStyle}
        initial={{ opacity: 0, width: hazeStyle.width }}
        animate={{
          opacity: hazeStyle.opacity,
          width: expandEffect ? `${parseFloat(hazeStyle.width) + 10}%` : hazeStyle.width,
        }}
        transition={{ duration: hazeDuration }}
      />
    )
  );
};

export default VintageHaze;
