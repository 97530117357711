import { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const MarketGradient = (props) => {
  const gradientRef = useRef(null);

  useEffect(() => {
    if (!gradientRef.current && props.dominantColors && props.dominantColors.length === 5) {
      gradientRef.current = new GradientAnimation();
    }
  }, [props.dominantColors]);

  class GradientAnimation {
    constructor() {
      this.cnv = document.querySelector(`canvas`);
      this.ctx = this.cnv.getContext(`2d`);
    
      this.circlesNum = props.isWideScreen ? 8 : 15;
      this.minRadius = props.isWideScreen ? 800 : 600;
      this.maxRadius = props.isWideScreen ? 1000 : 700;

      do {
        this.speed = Math.random() * (props.page === 'scout') ? 0.003 : 0.001;
      } while (this.speed < 0.0003);
    
      (window.onresize = () => {
        this.setCanvasSize();
        this.createCircles();
      })();
      this.drawAnimation();
    }

    setCanvasSize() {
      this.w = this.cnv.width  = window.innerWidth * devicePixelRatio;
      this.h = this.cnv.height = window.innerHeight * devicePixelRatio;
      this.ctx.scale(devicePixelRatio, devicePixelRatio);
    }

    createCircles() {
      this.circles = [];
      for (let i = 0 ; i < this.circlesNum ; ++i) {
        this.circles.push(new Blob(this.w, this.h, this.minRadius, this.maxRadius, props.dominantColors));
      }
    }

    drawCircles() {
      this.circles.forEach(circle => circle.draw(this.ctx, this.speed));
    }

    clearCanvas() {
      this.ctx.clearRect(0, 0, this.w, this.h); 
    }

    drawAnimation() {
      this.clearCanvas();
      this.drawCircles();
      if (document.hidden) {
        setTimeout(() => this.drawAnimation(), 200); // slow down when tab isn't active
      } else {
        window.requestAnimationFrame(() => this.drawAnimation());
      }
    }
  }
  
  class Blob {
    constructor(w, h, minR, maxR, colors) {
      this.x = Math.random() * w;
      this.y = Math.random() * h;
      this.angle = Math.random() * Math.PI * 2;
      this.radius = Math.random() * (maxR - minR) + minR;
      this.firstColor = colors && colors[0] ? colors[0] : `hsla(${Math.random() * 360}, 100%, 50%, 1)`;
      this.secondColor = colors && colors[1] ? colors[1] : `hsla(${Math.random() * 360}, 100%, 50%, 0)`;
      this.thirdColor = colors && colors[2] ? colors[2] : `hsla(${Math.random() * 360}, 100%, 50%, 0)`;
      this.fourthColor = colors && colors[3] ? colors[3] : `hsla(${Math.random() * 360}, 100%, 50%, 0)`;
      this.fifthColor = colors && colors[4] ? colors[4] : `hsla(${Math.random() * 360}, 100%, 50%, 0)`;
      this.blobbiness = Math.random() * 0.5 + 0.1;
    }
  
    draw(ctx, speed) {
      this.angle += speed;
      const x = this.x + Math.cos(this.angle) * 200;
      const y = this.y + Math.sin(this.angle) * 200;
      const gradient = ctx.createRadialGradient(x, y, 0, x, y, this.radius);
      this.angle += speed;
      gradient.addColorStop(0, this.firstColor);
      gradient.addColorStop(0.25, this.secondColor); 
      gradient.addColorStop(0.5, this.thirdColor);
      gradient.addColorStop(0.75, this.fourthColor);
      gradient.addColorStop(1, this.fifthColor);
  
      ctx.globalCompositeOperation = `source-over`;
      ctx.globalAlpha = 0.8;
      ctx.fillStyle = gradient;

      const blobPoints = 200;
      ctx.beginPath();
      for (let i = 0; i < blobPoints; i++) {
        const angle = (Math.PI * 2) / blobPoints * i + this.angle;
        const r = this.radius + Math.sin(this.angle * 3) * this.radius * this.blobbiness;
        ctx.lineTo(x + Math.cos(angle) * r, y + Math.sin(angle) * r);
      }
      ctx.closePath();
      ctx.fill();
      ctx.globalAlpha = 1;
    }
  }

  return (
    <motion.canvas
      className="scoutBodyGradientCanvas"
      style={{ opacity: props.visible ? 1 : 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    />
  );
}

export default MarketGradient;
