import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { doc, updateDoc, arrayUnion, arrayRemove } from '@firebase/firestore';
import { storage, db } from '../../firebase';
import plusIcon from '../../icons/plusIcon.png';
import deleteIcon from '../../icons/deleteIcon.png';
import infoIcon from '../../icons/infoIcon.png';
import loadIcon from '../../icons/scoutIcon.png';
import { motion, AnimatePresence } from 'framer-motion';
import './ParcelUploadComponent.css';

const ParcelUploadComponent = ({ setUploadedImages, productID }) => {
  const [uploadImages, setUploadImages] = useState([]);
  const [error, setError] = useState('');
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    // Cleanup function to revoke object URLs when the component unmounts
    return () => {
      uploadImages.forEach((img) => {
        if (img.preview && !img.fromFirebase) {
          URL.revokeObjectURL(img.preview);
        }
      });
    };
  }, [uploadImages]);

  const toggleInfoVisibility = () => {
    setIsInfoVisible((prev) => !prev);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    e.target.value = ''; // Reset the input after processing
    if (!file) return;

    if (uploadImages.length >= 3) {
      setError('You can upload up to 3 images.');
      return;
    }

    // File size validation: 5 MB limit
    if (file.size > 5 * 1024 * 1024) {
      setError('File size exceeds 5 MB. Please select a smaller file.');
      return;
    }

    // Check if this file is already selected
    const fileName = file.name;
    const isAlreadyUploaded = uploadImages.some(
      (img) => img.file && img.file.name === fileName
    );
    if (isAlreadyUploaded) {
      setError('This image has already been selected.');
      return;
    }

    setError('');
    const uniqueName = fileName + uuidv4();
    const storageRef = ref(storage, `parcel images/${uniqueName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Add image to state with initial upload data
    const newImage = {
      file,
      preview: URL.createObjectURL(file),
      progress: 0,
      completed: false,
      fromFirebase: false,
    };
    setUploadImages((prev) => [...prev, newImage]);
    setIsUploading(true);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadImages((prev) =>
          prev.map((img) =>
            img.file === file ? { ...img, progress } : img
          )
        );
      },
      (error) => {
        console.error('Error uploading file: ', error);
        setError('Error uploading file. Please try again.');
        setIsUploading(false);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          // Update Firestore document for the product
          const dbRef = doc(db, 'products', productID);
          await updateDoc(dbRef, {
            parcelImages: arrayUnion(downloadURL),
          });
          console.log('Parcel image URL added to Firestore');

          // Update the image in state as completed and fromFirebase
          setUploadImages((prev) =>
            prev.map((img) =>
              img.file === file
                ? { ...img, preview: downloadURL, completed: true, fromFirebase: true }
                : img
            )
          );

          // Update parent with Firebase URLs (only completed images)
          const updatedCompletedImages = uploadImages
            .filter((img) => img.completed || img.file === file)
            .map((img) => (img.file === file ? downloadURL : img.preview));
          setUploadedImages(updatedCompletedImages);

          setError('');
        } catch (err) {
          console.error('Error during post-upload processing:', err);
          setError('Error processing uploaded image. Please try again.');
        } finally {
          setIsUploading(false);
        }
      }
    );
  };

  const handleDelete = async (index) => {
    const imageToDelete = uploadImages[index];
    if (!imageToDelete) return;

    // Remove the image from local state
    const newUploadImages = uploadImages.filter((_, i) => i !== index);
    setUploadImages(newUploadImages);

    // Update the parent's state with remaining completed images
    const completedImageURLs = newUploadImages
      .filter((img) => img.completed)
      .map((img) => img.preview);
    setUploadedImages(completedImageURLs);

    // If the image was completed and fromFirebase, remove from Firestore and Storage
    if (imageToDelete.completed && imageToDelete.fromFirebase) {
      try {
        const dbRef = doc(db, 'products', productID);
        await updateDoc(dbRef, {
          parcelImages: arrayRemove(imageToDelete.preview),
        });
        console.log(`${imageToDelete.preview} removed from Firestore document ${productID}`);

        // Extract fileName from the download URL
        let fileName = imageToDelete.preview
          .slice(imageToDelete.preview.lastIndexOf('%2F') + 3, imageToDelete.preview.lastIndexOf('?'))
          .replace(/%20/g, ' ');

        const deleteRefOriginal = ref(storage, `parcel images/${fileName}`);
        await deleteObject(deleteRefOriginal);
        console.log('Parcel image successfully deleted from Firebase');
      } catch (error) {
        console.error('Error deleting image from Firebase:', error);
        setError('Error deleting image from storage. Please try again.');
      }
    }
    setError('');
  };

  const maxImagesReached = uploadImages.length >= 3;

  return (
    <div className="parcelUploadContainer">
      <div className="parcelUploadHeader">
        Upload Parcel Images (Max 3)
        <img
          className="parcelUploadImgInfoIcon"
          src={infoIcon}
          alt="Info"
          onClick={toggleInfoVisibility}
          style={{ cursor: 'pointer', marginLeft: '8px' }}
        />
      </div>

      <AnimatePresence>
        {isInfoVisible && (
          <motion.div
            className="parcelUploadInfoDiv"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            Please upload an image of the parcel after it has been 
            wrapped, before it is sent. This helps increase the 
            buyer's trust in your store.
          </motion.div>
        )}
      </AnimatePresence>

      <div className="parcelImagesContainer">
        {uploadImages.map((img, index) => (
          <div key={index} className="parcelImageWrapper">
            <img src={img.preview} alt="Parcel" className="parcelImage" />
            {img.completed ? (
              <div id="parcelImgUploadDeleteBtn" onClick={() => handleDelete(index)}>
                <img
                  src={deleteIcon}
                  alt="Delete"
                  className="parcelDeleteIcon"
                />
              </div>
            ) : (
              <progress className="parcelProgressBar" value={img.progress || 0} max="100"></progress>
            )}
          </div>
        ))}
        {(!maxImagesReached && !isUploading) && (
          <label 
            htmlFor="parcelImageUpload" 
            className="parcelAddImageLabel"
            style={{ margin: uploadImages.length === 0 ? '0px' : '10px 0 0 15px' }}
          >
            <img src={plusIcon} alt="Add" className="parcelAddImageIcon" />
          </label>
        )}
        {isUploading && (
          <div className="imageUploadLoadingDiv">
            <img src={loadIcon} className="marketLoadingIcon" alt="loading icon" />
          </div>
        )}
      </div>
      <input
        type="file"
        id="parcelImageUpload"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageUpload}
      />
      {error && <div className="parcelUploadError">{error}</div>}
    </div>
  );
};

export default ParcelUploadComponent;
