import React, { useRef, useState, useCallback, useEffect } from 'react'
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot } 
from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from '../../firebase'
import ColorThief from 'colorthief';

import dropdownIcon from '../../icons/popupIcon.png' 
import './TextColourPopup.css'
import './ColourSquares.css'

const TextColourPopup = (props) => {
  const [show, setShow] = useState(false);
  const [dominantColor, setDominantColor] = useState()
  const [lightColor, setLightColor] = useState()

  const colors = [
    "#FFFFFF","#FF8A8A", "#FFD280", "#FDE890", "#C8E163", "#B2FFFF", "#AFAFED", "#D7A1F9", "#FFD1DC", "#FFE5B4",
    "#E8E8E8", "#E84B4F", "#E8A14D", "#FFFF00", "#77DD77", "#00FFFF", "#8A8AE5", "#C576F6", "#F39AA5", "#DA9E73",        
    "#D9D9D9", "#E32227", "#FFA500", "#FFD700", "#28FF00", "#89CFF0", "#6464DC", "#8B52FE", "#FE65C4", "#E8A14D",         
    "#A6A6A6", "#FF0000", "#F28500", "#F7C331", "#00D100", "#00D1D1", "#3F3FD4", "#A020F0", "#EF3A5D", "#923806",          
    "#545454", "#BF181D", "#D18700", "#D1D100", "#0EC75A", "#53B0AE", "#2A2ABB", "#880ED4", "#B00058", "#A07671",         
    "#2E2E2E", "#961316", "#A36A00", "#A3A300", "#00A86B", "#00A3A3", "#212196", "#6C0BA9", "#820041", "#523936",        
    "#000000", "#6D0E10", "#7B3F00", "#757500", "#048037", "#007575", "#191970", "#51087E", "#55002A", "#362624" 
  ];
  

  function contrastRatio(color1, color2) {
    const luminance = (rgb) => {
      const a = rgb.map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };
    
    const lum1 = luminance(color1);
    const lum2 = luminance(color2);
    const [light, dark] = lum1 > lum2 ? [lum1, lum2] : [lum2, lum1];
    return (light + 0.05) / (dark + 0.05);
  }
  
  function rgbToHsl(r, g, b) {
    r /= 255; g /= 255; b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    return [h, s, l];
  }
  
  function hslToRgb(h, s, l) {
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      }
      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  useEffect(() => {
    const img = new Image();
    const colorThief = new ColorThief();

    img.crossOrigin = "Anonymous"; // This line handles issues with CORS
    img.src = props.data.store_logo; 

    img.onload = () => {
      if (img.complete && img.naturalHeight !== 0) {
        try {
          const colorPalette = colorThief.getPalette(img, 14);
          const colorPaletteRGBStrings = colorPalette.map(color => `rgb(${color[0]}, ${color[1]}, ${color[2]})`)

          // Convert RGB colors to HSL and find the color with the highest lightness value
          let lightestColor = colorPalette[0];
          let maxLightness = rgbToHsl(...colorPalette[0])[2];

          for (let i = 1; i < colorPalette.length; i++) {
            const currentLightness = rgbToHsl(...colorPalette[i])[2];
            if (currentLightness > maxLightness) {
              maxLightness = currentLightness;
              lightestColor = colorPalette[i];
            }
          }
          // Check the contrast ratio with black color. If it's less than 4.5, increase the lightness
          let black = [0, 0, 0];
          while (contrastRatio(lightestColor, black) < 4.5) {
            let hsl = rgbToHsl(...lightestColor);
            hsl[2] += 0.05; // Increase lightness by 5%
            if (hsl[2] > 1) hsl[2] = 1; // Ensure lightness doesn't exceed 100%
            lightestColor = hslToRgb(...hsl);
          }

          // Set the lightColor and dominantColor
          setLightColor(`rgb(${lightestColor[0]}, ${lightestColor[1]}, ${lightestColor[2]})`);
          setDominantColor(colorPaletteRGBStrings);

        } catch (err) {
          console.error('Error getting color palette', err);
        }
      } else {
        console.log('Image not fully loaded');
      }
    };
  }, [props.data.store_logo]);

  const handleColourSelected = (color) => {
    props.onSelectColor(color)
    console.log(props)
    // props.setShowTextColorPopup(false)
    props.closePopup()
  }

  return (
    <div className="textColourDiv">
      <div className="brandColorsDiv" style={{ backgroundColor: lightColor}}>
        <div className="brandColorSectionHeader">
          <div className="bCSection1">
            <img src={props.data.store_logo} className="websiteLogoSquare" />
            <h1 className="brandColorsDivHeader">suggested colours</h1>
          </div>
          <div className="bCSection2" onClick={()=>setShow(!show)}>
              {show ? <img className="brandColorDropdownIcon2" src={dropdownIcon}  alt="dropdown icon" /> 
              : <img className="brandColorDropdownIcon" src={dropdownIcon}  alt="dropdown icon" />}
          </div>
        </div>
        {show && dominantColor && dominantColor.map((color, index) => (
        <div className="brandColorSection"
          key={index}
          style={{
            backgroundColor: color,
          }}
          onClick={() => {
            props.onSelectColor(color)
            props.closePopup()
          }}
        />
        ))}
      </div>
      <div className="textColourOptions">
        {colors.map((color, index) => (
          <div
            key={index} 
            className={`colour${index + 1}`}
            id="colourSquare"
            onClick={() => handleColourSelected(color)}
            style={{ backgroundColor: color, cursor: 'pointer' }}
          />
        ))}
      </div>
    </div>
  )
}

export default TextColourPopup