import React, { useState, useEffect } from 'react';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import { doc, updateDoc, getDoc } from '@firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary
import PropTypes from 'prop-types';
import './DeliveryOptionPopup.css';

const DeliveryOptionPopup = ({
  cart,
  userID,
  storeName,
  storeID,
  updateGlobalTotalShippingPrice,
  updateCartData,
  closePopup,
  setShowTotalContainer,
  setAlertMessage,
  setAlertType
}) => {
  const [deliveryMethod, setDeliveryMethod] = useState(null); // 'pickup', 'delivery', or null
  const [buyerAddress, setBuyerAddress] = useState('');
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // State to store seller details
  const [sellerAddress, setSellerAddress] = useState('');
  const [sellerDeliveryPreferance, setSellerDeliveryPreferance] = useState('');

  // Fetch seller address and delivery preference from Firestore
  const fetchStoreDetails = async () => {
    try {
      const storeRef = doc(db, 'stores', storeID);
      const storeSnap = await getDoc(storeRef);
      if (storeSnap.exists()) {
        const storeData = storeSnap.data();
        setSellerAddress(storeData.address || '');
        setSellerDeliveryPreferance(storeData.sellerDeliveryPreferance || 'both'); // Default to 'both' if not set
        // basically the delivery preferance is determined by each product, hence the field is not in stores, but products
        
        console.log(`Store Details for ${storeID}:`, storeData);
      } else {
        console.warn(`Store with ID ${storeID} does not exist.`);
      }
    } catch (error) {
      console.error(`Error fetching store details for storeID ${storeID}:`, error);
    }
  };

  useEffect(() => {
    fetchStoreDetails();
  }, [storeID]);

  const mapboxToken = ''; 
  // Use environment variable for security

  // Handle delivery method selection
  const handleDeliveryMethodChange = (method) => {
    setDeliveryMethod(method);
    if (method === 'pickup') {
      setDeliveryFee(0);
      updateTotalShipping(0);
      updateCartDeliveryOption('pickup', 0, null);
    } else if (method === 'delivery') {
      // Reset delivery fee and address when switching to delivery
      setDeliveryFee(0);
      setBuyerAddress('');
      updateTotalShipping(0);
      updateCartDeliveryOption('delivery', 0, null);
    }
  };

  // Handle buyer address selection from Mapbox autocomplete
  const handleBuyerAddressSelect = async (result, lat, lng, text) => {
    console.log('Buyer selected address:', result);
    console.log('Buyer coordinates:', lat, lng);

    const parsedLat = parseFloat(lat);
    const parsedLng = parseFloat(lng);

    if (isNaN(parsedLat) || isNaN(parsedLng)) {
      console.error('Invalid buyer coordinates received:', lat, lng);
      setAlertMessage('Invalid address selected. Please try again.');
      setAlertType('error')
      return;
    }

    setBuyerAddress(result);
    await calculateDistance(parsedLat, parsedLng);
  };

  // Calculate distance and delivery fee using Haversine formula
  const calculateDistance = async (buyerLat, buyerLng) => {
    if (!sellerAddress) {
      console.error('Seller address is not available.');
      setAlertMessage('Seller address is missing. Cannot calculate delivery fee.');
      setAlertType('error')
      return;
    }

    setIsLoading(true);

    try {
      const mbxGeocoding = require('@mapbox/mapbox-sdk/services/geocoding');
      const mbxClient = mbxGeocoding({ accessToken: mapboxToken });

      // Geocode seller address
      const sellerResponse = await mbxClient
        .forwardGeocode({
          query: sellerAddress,
          limit: 1,
        })
        .send();

      if (
        sellerResponse.body.features &&
        sellerResponse.body.features.length > 0
      ) {
        const sellerCoords = sellerResponse.body.features[0].geometry.coordinates;

        if (!sellerCoords || sellerCoords.length !== 2) {
          console.warn(
            `Invalid coordinates received for seller address: ${sellerAddress}.`
          );
          setDeliveryFee(0);
          updateTotalShipping(0);
          updateCartDeliveryOption('delivery', 0, buyerAddress);
          setIsLoading(false);
          return;
        }

        const sellerLng = parseFloat(sellerCoords[0]);
        const sellerLat = parseFloat(sellerCoords[1]);

        // Validate coordinates
        if (isNaN(sellerLat) || isNaN(sellerLng)) {
          console.warn('Non-numeric coordinates received for seller address.');
          setDeliveryFee(0);
          updateTotalShipping(0);
          updateCartDeliveryOption('delivery', 0, buyerAddress);
          setIsLoading(false);
          return;
        }

        // Calculate distance in kilometers using Haversine formula
        const distance = getDistanceFromLatLonInKm(
          sellerLat,
          sellerLng,
          buyerLat,
          buyerLng
        );

        if (isNaN(distance)) {
          console.warn('Distance calculation resulted in NaN.');
          setDeliveryFee(0);
          updateTotalShipping(0);
          updateCartDeliveryOption('delivery', 0, buyerAddress);
          setIsLoading(false);
          return;
        }

        const fee = parseFloat((distance * 8).toFixed(2)); // R8 per km
        setDeliveryFee(fee);
        updateTotalShipping(fee);
        updateCartDeliveryOption('delivery', fee, buyerAddress);

        console.log(`Calculated fee: R${fee} for distance: ${distance} km`);
      } else {
        console.warn(
          `No geocoding results found for seller address: ${sellerAddress}.`
        );
        setDeliveryFee(0);
        updateTotalShipping(0);
        updateCartDeliveryOption('delivery', 0, buyerAddress);
      }
    } catch (error) {
      console.error('Error during geocoding or distance calculation:', error);
      setDeliveryFee(0);
      updateTotalShipping(0);
      updateCartDeliveryOption('delivery', 0, buyerAddress);
    }

    setIsLoading(false);
  };

  // Haversine formula to calculate distance between two coordinates
  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    if (
      typeof lat1 !== 'number' ||
      typeof lon1 !== 'number' ||
      typeof lat2 !== 'number' ||
      typeof lon2 !== 'number'
    ) {
      console.error(
        'Invalid input types for distance calculation:',
        lat1,
        lon1,
        lat2,
        lon2
      );
      return NaN;
    }

    const R = 6371; // Radius of Earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  // Convert degrees to radians
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  // Update global shipping price
  const updateTotalShipping = (fee) => {
    // Assuming updateGlobalTotalShippingPrice expects fee in Rands
    updateGlobalTotalShippingPrice(storeID, storeName, fee);
    console.log(storeID, fee);
  };

  // Update cart delivery options in Firestore
  const updateCartDeliveryOption = async (method, fee, address) => {
    try {
      const cartRef = doc(db, 'cart', userID);
      const cartSnap = await getDoc(cartRef);
      if (cartSnap.exists()) {
        const cartData = cartSnap.data().cart;

        const updatedCartData = cartData.map((product) => {
          if (product.storeID === storeID) {
            return {
              ...product,
              deliveryMethod: method,
              deliveryFee: fee,
              buyerAddress: address,
            };
          }
          return product;
        });

        await updateDoc(cartRef, { cart: updatedCartData });
        updateCartData(updatedCartData);
        console.log('Cart delivery options updated successfully.');
      } else {
        console.error(
          `No such document in "cart" collection for userID: ${userID}`
        );
      }
    } catch (error) {
      console.error('Error updating cart delivery options:', error);
    }
  };

  // Determine button disabled state based on sellerDeliveryPreferance
  const isPickupDisabled = sellerDeliveryPreferance === 'driverPickup';
  const isDeliveryDisabled = sellerDeliveryPreferance === 'localCollect';

  // Debugging: Log the current delivery preference
  useEffect(() => {
    console.log(`Current sellerDeliveryPreferance: ${sellerDeliveryPreferance}`);
  }, [sellerDeliveryPreferance]);

  return (
    <div className="deliveryOptionPopup">
      <h2>{storeName} Delivery Options</h2>
      <div className="deliveryOptionsContainer">
        <p>Choose a delivery option:</p>
        <div className="delivery-buttons">
          <button
            className={`delivery-button ${
              deliveryMethod === 'pickup' ? 'selected' : ''
            }`}
            onClick={() => handleDeliveryMethodChange('pickup')}
            disabled={isPickupDisabled}
            aria-disabled={isPickupDisabled}
            title={
              isPickupDisabled
                ? 'Pick-up is not available for this store.'
                : 'Local Pick-up (No additional fee)'
            }
          >
            Local Pick-up (No additional fee)
          </button>
          <button
            className={`delivery-button ${
              deliveryMethod === 'delivery' ? 'selected' : ''
            }`}
            onClick={() => handleDeliveryMethodChange('delivery')}
            disabled={isDeliveryDisabled}
            aria-disabled={isDeliveryDisabled}
            title={
              isDeliveryDisabled
                ? 'Delivery is not available for this store.'
                : 'Delivery (R8 per km)'
            }
          >
            Delivery (R8 per km)
          </button>
        </div>
      </div>

      {/* Conditional Rendering Based on Delivery Method */}
      {deliveryMethod === 'delivery' && (
        <div className="address-input">
          <label>Enter your address:</label>
          <MapboxAutocomplete
            publicKey={mapboxToken}
            inputClass="form-control"
            onSuggestionSelect={handleBuyerAddressSelect}
            country="za"
            resetSearch={false}
          />
          {isLoading && <p>Calculating delivery fee...</p>}
          {!isLoading && deliveryFee > 0 && (
            <p>
              Delivery Fee: R{deliveryFee.toFixed(2)} (Based on distance)
            </p>
          )}
          {!isLoading && deliveryFee === 0 && buyerAddress && (
            <p>No delivery fee applicable.</p>
          )}
        </div>
      )}

      {deliveryMethod === 'pickup' && (
        <div className="seller-address">
          <h3>Seller Address:</h3>
          <p>{sellerAddress}</p>
        </div>
      )}

      <div className="totalStoreShippingDiv">
        <div className="totalStoreShippingText">Store shipping total: </div>
        <div className="totalStoreShippingValue">
          R{deliveryFee.toFixed(2)}
        </div>
      </div>
      <button
        className="closeShippingPopupButton"
        onClick={() => {
          closePopup();
          setShowTotalContainer(true);
        }}
        disabled={!deliveryMethod} // Disable if no delivery method is selected
      >
        Done
      </button>
    </div>
  );
};

// **Prop Type Validation (Optional but Recommended)**
DeliveryOptionPopup.propTypes = {
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      productID: PropTypes.string.isRequired,
      storeID: PropTypes.string.isRequired,
      // ... other product fields
    })
  ).isRequired,
  userID: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  storeID: PropTypes.string.isRequired,
  updateGlobalTotalShippingPrice: PropTypes.func.isRequired,
  updateCartData: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  setShowTotalContainer: PropTypes.func.isRequired,
};

export default DeliveryOptionPopup;


// import React, { useState, useEffect } from 'react';
// import MapboxAutocomplete from 'react-mapbox-autocomplete';
// import { doc, updateDoc, getDoc } from '@firebase/firestore';
// import { db } from '../firebase'; // Adjust the import path as necessary
// import PropTypes from 'prop-types';
// import './DeliveryOptionPopup.css';

// const DeliveryOptionPopup = ({
//   cart,
//   userID,
//   storeName,
//   storeID,
//   updateGlobalTotalShippingPrice,
//   updateCartData,
//   closePopup,
//   setShowTotalContainer,
// }) => {
//   const [deliveryMethod, setDeliveryMethod] = useState(null);
//   const [buyerAddress, setBuyerAddress] = useState('');
//   const [deliveryFee, setDeliveryFee] = useState(0);
//   const [isLoading, setIsLoading] = useState(false);

//   // State to store seller details
//   const [sellerAddress, setSellerAddress] = useState('');
//   const [sellerDeliveryPreferance, setSellerDeliveryPreferance] = useState('');

//   // Fetch seller address and delivery preference from Firestore
//   const fetchStoreDetails = async () => {
//     try {
//       const storeRef = doc(db, 'stores', storeID);
//       const storeSnap = await getDoc(storeRef);
//       if (storeSnap.exists()) {
//         const storeData = storeSnap.data();
//         setSellerAddress(storeData.address || '');
//         setSellerDeliveryPreferance(storeData.sellerDeliveryPreferance || 'both'); // Default to 'both' if not set
//         console.log(`Store Details for ${storeID}:`, storeData);
//       } else {
//         console.warn(`Store with ID ${storeID} does not exist.`);
//       }
//     } catch (error) {
//       console.error(`Error fetching store details for storeID ${storeID}:`, error);
//     }
//   };

//   useEffect(() => {
//     fetchStoreDetails();
//   }, [storeID]);

//   const mapboxToken = 'pk.eyJ1IjoibmlraGlscGVyYW0iLCJhIjoiY20yaDdnamkyMDl3NTJqczh3YXN6djF5cCJ9.sh-NHPmxWV9YRApdgVLsNQ'; // Replace with your actual Mapbox access token

//   // Handle delivery method selection
//   const handleDeliveryMethodChange = (method) => {
//     setDeliveryMethod(method);
//     if (method === 'pickup') {
//       setDeliveryFee(0);
//       updateTotalShipping(0);
//       updateCartDeliveryOption('pickup', 0, null);
//     } else if (method === 'delivery') {
//       // Reset delivery fee and address when switching to delivery
//       setDeliveryFee(0);
//       setBuyerAddress('');
//       updateTotalShipping(0);
//       updateCartDeliveryOption('delivery', 0, null);
//     }
//   };

//   // Handle buyer address selection from Mapbox autocomplete
//   const handleBuyerAddressSelect = async (result, lat, lng, text) => {
//     console.log('Buyer selected address:', result);
//     console.log('Buyer coordinates:', lat, lng);

//     const parsedLat = parseFloat(lat);
//     const parsedLng = parseFloat(lng);

//     if (isNaN(parsedLat) || isNaN(parsedLng)) {
//       console.error('Invalid buyer coordinates received:', lat, lng);
//       alert('Invalid address selected. Please try again.');
//       return;
//     }

//     setBuyerAddress(result);
//     await calculateDistance(parsedLat, parsedLng);
//   };

//   // Calculate distance and delivery fee using Haversine formula
//   const calculateDistance = async (buyerLat, buyerLng) => {
//     if (!sellerAddress) {
//       console.error('Seller address is not available.');
//       alert('Seller address is missing. Cannot calculate delivery fee.');
//       return;
//     }

//     setIsLoading(true);

//     try {
//       const mbxGeocoding = require('@mapbox/mapbox-sdk/services/geocoding');
//       const mbxClient = mbxGeocoding({ accessToken: mapboxToken });

//       // Geocode seller address
//       const sellerResponse = await mbxClient
//         .forwardGeocode({
//           query: sellerAddress,
//           limit: 1,
//         })
//         .send();

//       if (
//         sellerResponse.body.features &&
//         sellerResponse.body.features.length > 0
//       ) {
//         const sellerCoords = sellerResponse.body.features[0].geometry.coordinates;

//         if (!sellerCoords || sellerCoords.length !== 2) {
//           console.warn(
//             `Invalid coordinates received for seller address: ${sellerAddress}.`
//           );
//           setDeliveryFee(0);
//           updateTotalShipping(0);
//           updateCartDeliveryOption('delivery', 0, buyerAddress);
//           setIsLoading(false);
//           return;
//         }

//         const sellerLng = parseFloat(sellerCoords[0]);
//         const sellerLat = parseFloat(sellerCoords[1]);

//         // Validate coordinates
//         if (isNaN(sellerLat) || isNaN(sellerLng)) {
//           console.warn('Non-numeric coordinates received for seller address.');
//           setDeliveryFee(0);
//           updateTotalShipping(0);
//           updateCartDeliveryOption('delivery', 0, buyerAddress);
//           setIsLoading(false);
//           return;
//         }

//         // Calculate distance in kilometers using Haversine formula
//         const distance = getDistanceFromLatLonInKm(
//           sellerLat,
//           sellerLng,
//           buyerLat,
//           buyerLng
//         );

//         if (isNaN(distance)) {
//           console.warn('Distance calculation resulted in NaN.');
//           setDeliveryFee(0);
//           updateTotalShipping(0);
//           updateCartDeliveryOption('delivery', 0, buyerAddress);
//           setIsLoading(false);
//           return;
//         }

//         const fee = parseFloat((distance * 8).toFixed(2)); // R8 per km
//         setDeliveryFee(fee);
//         updateTotalShipping(fee);
//         updateCartDeliveryOption('delivery', fee, buyerAddress);

//         console.log(`Calculated fee: R${fee} for distance: ${distance} km`);
//       } else {
//         console.warn(
//           `No geocoding results found for seller address: ${sellerAddress}.`
//         );
//         setDeliveryFee(0);
//         updateTotalShipping(0);
//         updateCartDeliveryOption('delivery', 0, buyerAddress);
//       }
//     } catch (error) {
//       console.error('Error during geocoding or distance calculation:', error);
//       setDeliveryFee(0);
//       updateTotalShipping(0);
//       updateCartDeliveryOption('delivery', 0, buyerAddress);
//     }

//     setIsLoading(false);
//   };

//   // Haversine formula to calculate distance between two coordinates
//   const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
//     if (
//       typeof lat1 !== 'number' ||
//       typeof lon1 !== 'number' ||
//       typeof lat2 !== 'number' ||
//       typeof lon2 !== 'number'
//     ) {
//       console.error(
//         'Invalid input types for distance calculation:',
//         lat1,
//         lon1,
//         lat2,
//         lon2
//       );
//       return NaN;
//     }

//     const R = 6371; // Radius of Earth in km
//     const dLat = deg2rad(lat2 - lat1);
//     const dLon = deg2rad(lon2 - lon1);
//     const a =
//       Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//       Math.cos(deg2rad(lat1)) *
//         Math.cos(deg2rad(lat2)) *
//         Math.sin(dLon / 2) *
//         Math.sin(dLon / 2);
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     return R * c; // Distance in km
//   };

//   // Convert degrees to radians
//   const deg2rad = (deg) => {
//     return deg * (Math.PI / 180);
//   };

//   // Update global shipping price
//   const updateTotalShipping = (fee) => {
//     // Assuming updateGlobalTotalShippingPrice expects fee in Rands
//     updateGlobalTotalShippingPrice(storeID, storeName, fee);
//     console.log(storeID, fee);
//   };

//   // Update cart delivery options in Firestore
//   const updateCartDeliveryOption = async (method, fee, address) => {
//     try {
//       const cartRef = doc(db, 'cart', userID);
//       const cartSnap = await getDoc(cartRef);
//       if (cartSnap.exists()) {
//         const cartData = cartSnap.data().cart;

//         const updatedCartData = cartData.map((product) => {
//           if (product.storeID === storeID) {
//             return {
//               ...product,
//               deliveryMethod: method,
//               deliveryFee: fee,
//               buyerAddress: address,
//             };
//           }
//           return product;
//         });

//         await updateDoc(cartRef, { cart: updatedCartData });
//         updateCartData(updatedCartData);
//         console.log('Cart delivery options updated successfully.');
//       } else {
//         console.error(
//           `No such document in "cart" collection for userID: ${userID}`
//         );
//       }
//     } catch (error) {
//       console.error('Error updating cart delivery options:', error);
//     }
//   };

//   // Determine button disabled state based on sellerDeliveryPreferance
//   const isPickupDisabled = sellerDeliveryPreferance === 'driverPickup';
//   const isDeliveryDisabled = sellerDeliveryPreferance === 'localCollect';

//   return (
//     <div className="deliveryOptionPopup">
//       <h2>{storeName} Delivery Options</h2>
//       <div className="deliveryOptionsContainer">
//         <p>Choose a delivery option:</p>
//         <div className="delivery-buttons">
//           <button
//             className={`delivery-button ${
//               deliveryMethod === 'pickup' ? 'selected' : ''
//             }`}
//             onClick={() => handleDeliveryMethodChange('pickup')}
//             disabled={isPickupDisabled}
//           >
//             Local Pick-up (No additional fee)
//           </button>
//           <button
//             className={`delivery-button ${
//               deliveryMethod === 'delivery' ? 'selected' : ''
//             }`}
//             onClick={() => handleDeliveryMethodChange('delivery')}
//             disabled={isDeliveryDisabled}
//           >
//             Delivery (R8 per km)
//           </button>
//         </div>
//       </div>
//       {deliveryMethod === 'delivery' && (
//         <div className="address-input">
//           <label>Enter your address:</label>
//           <MapboxAutocomplete
//             publicKey={mapboxToken}
//             inputClass="form-control"
//             onSuggestionSelect={handleBuyerAddressSelect}
//             country="za"
//             resetSearch={false}
//           />
//           {isLoading && <p>Calculating delivery fee...</p>}
//           {!isLoading && deliveryFee > 0 && (
//             <p>
//               Delivery Fee: R{deliveryFee.toFixed(2)} (Based on distance)
//             </p>
//           )}
//           {!isLoading && deliveryFee === 0 && buyerAddress && (
//             <p>No delivery fee applicable.</p>
//           )}
//         </div>
//       )}
//       <div className="totalStoreShippingDiv">
//         <div className="totalStoreShippingText">Store shipping total: </div>
//         <div className="totalStoreShippingValue">
//           R{deliveryFee.toFixed(2)}
//         </div>
//       </div>
//       <button
//         className="closeShippingPopupButton"
//         onClick={() => {
//           closePopup();
//           setShowTotalContainer(true);
//         }}
//         disabled={!deliveryMethod} // Disable if no delivery method is selected
//       >
//         Done
//       </button>
//     </div>
//   );
// };

// // **Prop Type Validation (Optional but Recommended)**
// DeliveryOptionPopup.propTypes = {
//   cart: PropTypes.arrayOf(
//     PropTypes.shape({
//       productID: PropTypes.string.isRequired,
//       storeID: PropTypes.string.isRequired,
//       // ... other product fields
//     })
//   ).isRequired,
//   userID: PropTypes.string.isRequired,
//   storeName: PropTypes.string.isRequired,
//   storeID: PropTypes.string.isRequired,
//   updateGlobalTotalShippingPrice: PropTypes.func.isRequired,
//   updateCartData: PropTypes.func.isRequired,
//   closePopup: PropTypes.func.isRequired,
//   setShowTotalContainer: PropTypes.func.isRequired,
// };

// export default DeliveryOptionPopup;
