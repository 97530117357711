import React, { useState, useCallback, useEffect, useRef } from 'react';

import { 
  doc, updateDoc, getDoc
} from '@firebase/firestore';
import { db } from '../../firebase';
import debounce from 'lodash/debounce'; 

import './FrameBar.css';
import noBorderIcon from '../../icons/noBorderIcon.png';
import borderIcon from '../../icons/borderIcon.png';
import lineBorderIcon from '../../icons/lineBorderIcon.png';
import dashedBorderIcon from '../../icons/dashedBorderIcon.png';
import dottedBorderIcon from '../../icons/dottedBorderIcon.png';
import blankFrameIcon from '../../icons/blankFrameIcon.png';
import glassFrameIcon from '../../icons/glassFrameIcon.png';
import leftAlignIcon from '../../icons/leftAlignIcon.png';
import centreAlignIcon from '../../icons/centreAlignIcon.png';
import rightAlignIcon from '../../icons/rightAlignIcon.png';
import fontColourIcon from '../../icons/websitePageColourIcon.png'; 
import dropdownIcon from '../../icons/popupIcon.png';
import TextColourPopup from "./TextColourPopup";
import WebsiteBackgroundPopup from "./WebsiteBackgroundPopup"
import styles from './frameStyles.json';
import FrameBorderRadius from './FrameBorderRadius';
import FrameBorderWidth from './FrameBorderWidth'; 
import opacityIcon from '../../icons/opacityIcon.png';
import OpacityPopup from './OpacityPopup';

const FrameBar = (props) => {
  const storeID = props.storeID;
  const [showFrameTypes, setShowFrameTypes] = useState(false);
  const [frameTypeButtonSrc, setFrameTypeButtonSrc] = useState(blankFrameIcon);
  const [selectedFrameColor, setSelectedFrameColor] = useState(null);
  const [showFrameColourPopup, setShowFrameColourPopup] = useState(false);
  const [showBorderColourPopup, setShowBorderColourPopup] = useState(false);
  const [selectedBorderColor, setSelectedBorderColor] = useState('black');
  const [selectedObjectData, setSelectedObjectData] = useState(null);
  const [frameDesign, setFrameDesign] = useState();
  const [showFrameBorderPopup, setShowFrameBorderPopup] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('left');
  let [fontPosition, setFontPosition] = useState('left');
  const [manualUpdate, setManualUpdate] = useState(false);
  const [previewBorderColor, setPreviewBorderColor] = useState(null)
  const [previewFrameColor, setPreviewFrameColor] = useState(null)
  const [showCommonColors, setShowCommonColors] = useState(false); // New state for collapsible div
  const [colorPickerOpen, setColorPickerOpen] = useState(false)

  const [borderStyle, setBorderStyle] = useState('solid'); // Set default to 'solid'
  const [borderManualUpdate, setBorderManualUpdate] = useState(false);
  const [showOpacityPopup, setShowOpacityPopup] = useState(false);
  const [opacity, setOpacity] = useState(100); // Numeric value for UI
  const [opacityInput, setOpacityInput] = useState('100'); // String value for input

  // Fetch the selected object's data
  useEffect(() => {
    const matchedObject = props.data.find(obj => obj.frameID === props.selectedObjectID);
    if (matchedObject) {
      setSelectedObjectData(matchedObject);
      setFrameDesign(matchedObject.frame_design);
      if ((matchedObject.frame_design) === 'color') {
        setShowFrameColourPopup(true)
      }
      setSelectedFrameColor(matchedObject.frame_styling?.backgroundColor || null);  
      // Initialize opacity
      const storedOpacity = matchedObject.frame_styling?.opacity;
      let initialOpacity = 100; // Default value
      if (typeof storedOpacity === 'string' && storedOpacity.endsWith('%')) {
        const parsed = parseInt(storedOpacity.slice(0, -1), 10);
        initialOpacity = isNaN(parsed) ? 100 : parsed;
      } else if (typeof storedOpacity === 'number') {
        initialOpacity = storedOpacity;
      }
      setOpacity(initialOpacity);
      setOpacityInput(initialOpacity.toString());
    }
  }, [props.data, props.selectedObjectID]);

  useEffect(() => {
    if (manualUpdate) {
      return;
    }

    if (selectedObjectData) { 
      if (selectedObjectData.frame_styling) {
        const fontPosition = selectedObjectData.frame_styling.textAlign;
        if (fontPosition && fontPosition != null) {
          if (fontPosition !== selectedBtn) {
            setSelectedBtn(fontPosition);
          } 
        } else {
          setSelectedBtn('left');
        }
      } else {
        setSelectedBtn('left');
      }
    } else if (manualUpdate) {
      setManualUpdate(false); // Reset manualUpdate after acknowledging it
    }
  }, [selectedObjectData, selectedBtn, manualUpdate]);

  useEffect(() => {
    if (borderManualUpdate) {
      // Reset the manual update flag
      setBorderManualUpdate(false);
      return;
    }

    if (selectedObjectData?.frame_styling?.border) {
      const borderStyles = ['none', 'solid', 'dashed', 'dotted'];
      const borderParts = selectedObjectData.frame_styling.border.split(' ');

      // Search for a known border style in the border string
      const style = borderParts.find(part => borderStyles.includes(part)) || 'solid';
      setBorderStyle(style);
    } else {
      setBorderStyle('solid'); // Set default to 'solid' if not found
    }
  }, [selectedObjectData, borderManualUpdate]);

  useEffect(() => {
    if (frameDesign != null) {
      if (frameDesign === 'glass') {
        setFrameTypeButtonSrc(glassFrameIcon);
      } else if (frameDesign === 'color') {
        setFrameTypeButtonSrc(fontColourIcon);
        setShowFrameColourPopup(true)
      } else {
        setFrameTypeButtonSrc(blankFrameIcon);
      }
    }
  }, [frameDesign]);

  const handleFrameTypeClick = (src) => {
    setFrameTypeButtonSrc(src);
    if (selectedFrameColor === null) {
      const defaultColor = '#FFFFFF'; // Your default color
      setSelectedFrameColor(defaultColor);
    }
  };

  const handleFrameSelectedColor = useCallback(async (color) => {
    if (color) {
      setSelectedFrameColor(color);
    
      // Find and update the selected object
      const updatedObject = props.data.find(obj => obj.frameID === props.selectedObjectID);
      if (updatedObject) {
        // Update frame_styling with the new backgroundColor
        let updatedFrameStyling = {
          ...updatedObject.frame_styling,
          backgroundColor: color
        };
    
        // Remove the specific border style if it exists
        if (updatedFrameStyling.border === '3px solid rgba(255, 255, 255, 0.2)') {
          updatedFrameStyling = {
            ...updatedFrameStyling,
            border: '' // or 'none' or any other default value you want
          };
        }
    
        // Create a new updated object with the updated frame_styling
        const updatedObjectWithColor = {
          ...updatedObject,
          frame_styling: updatedFrameStyling
        };
    
        // Update the selectedObjectData state
        setSelectedObjectData(updatedObjectWithColor);
    
        // Update the props.data array
        const updatedData = props.data.map(obj => 
          obj.frameID === props.selectedObjectID ? updatedObjectWithColor : obj
        );
    
        // Call the setFrames function with the updated data
        props.updateFrames(updatedData, updatedObjectWithColor);
    
        // Firestore updates for frames
        const framesRef = doc(db, 'frames', props.selectedObjectID);
        try {
          await updateDoc(framesRef, {
            frame_styling: updatedFrameStyling,
          });
        } catch (error) {
          console.error("Error updating color in Firebase:", error);
        }

        // Update the stores document
        const storesRef = doc(db, 'stores', storeID);
        await updateDoc(storesRef, {
          frames: updatedData,
        });
      }
    }
  }, [props.data, props.selectedObjectID, props.updateFrames, storeID]);  
      
  const handleBorderSelectedColor = useCallback(async (color) => {
    if (color) {
      setSelectedBorderColor(color);
  
      // Find and update the selected object
      const updatedObject = props.data.find(obj => obj.frameID === props.selectedObjectID);
      if (updatedObject) {
        // Extract current borderWidth from frame_styling.border
        let borderWidth = '3'; // Default value
        if (updatedObject.frame_styling && updatedObject.frame_styling.border) {
          const borderParts = updatedObject.frame_styling.border.split(' ');
          if (borderParts.length > 0) {
            const widthPart = borderParts[0];
            const widthValue = parseInt(widthPart, 10);
            if (!isNaN(widthValue)) {
              borderWidth = widthValue.toString();
            }
          }
        }

        // Construct the new border string
        const newBorder = borderStyle !== 'none' ? `${borderWidth}px ${borderStyle} ${color}` : 'none';

        // Update frame_styling with the new border
        const updatedFrameStyling = {
          ...updatedObject.frame_styling,
          border: newBorder,
        };

        // Create a new updated object with the updated frame_styling
        const updatedObjectWithColor = {
          ...updatedObject,
          frame_styling: updatedFrameStyling,
        };

        // Update the selectedObjectData state
        setSelectedObjectData(updatedObjectWithColor);

        // Update the props.data array
        const updatedData = props.data.map(obj => 
          obj.frameID === props.selectedObjectID ? updatedObjectWithColor : obj
        );

        // Call the setFrames function with the updated data
        props.updateFrames(updatedData, updatedObjectWithColor);

        // Firestore updates for frames
        const framesRef = doc(db, 'frames', props.selectedObjectID);
        try {
          await updateDoc(framesRef, {
            frame_styling: updatedFrameStyling,
          });
        } catch (error) {
          console.error("Error updating color in Firebase:", error);
        }

        // Update the stores document
        const storesRef = doc(db, 'stores', storeID);
        await updateDoc(storesRef, {
          frames: updatedData,
        });
      }
    }
  }, [props.data, props.selectedObjectID, props.updateFrames, borderStyle, storeID]); 

  const setFrameStyle = async (type, selectedObjectID) => {
    props.setSelectedFrameStyle(type);
    props.setFrameDesign(type);

    if (type === 'color') {
      setShowFrameColourPopup(true)
    }
  
    // Define new frame style based on the type
    let newFrameStyle = {};
    switch (type) {
      case 'glass':
        newFrameStyle = {
          border: '3px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.259)',
          backgroundColor: 'rgba(208, 215, 215, 0.5)',
          backdropFilter: 'blur(12px)'
        };
        break;
      case 'none':
        newFrameStyle = {
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          backdropFilter: 'none'
        };
        break;
      case 'color':
        newFrameStyle = {
          backgroundColor: selectedFrameColor,
        };
        break;
      default:
        throw new Error("Invalid frame style type");
    }
  
    // Update local state
    const updatedObjectWithNewStyle = {
      ...props.data.find(obj => obj.frameID === selectedObjectID),
      frame_design: type,
      frame_styling: {
        ...props.data.find(obj => obj.frameID === selectedObjectID).frame_styling,
        ...newFrameStyle,
      },
    };
  
    // Update the props.data array
    const updatedData = props.data.map(obj => 
      obj.frameID === selectedObjectID ? updatedObjectWithNewStyle : obj
    );

    setSelectedObjectData(updatedObjectWithNewStyle);
    props.updateFrames(updatedData, updatedObjectWithNewStyle);
  
    // Firestore updates for frames
    const framesRef = doc(db, 'frames', selectedObjectID);
    await updateDoc(framesRef, {
      frame_design: type,
      frame_styling: updatedObjectWithNewStyle.frame_styling,
    });
  
    // Update the frames array in the stores document
    const storesRef = doc(db, 'stores', storeID);
    const storeDoc = await getDoc(storesRef);
    if (storeDoc.exists()) {
      const storeData = storeDoc.data();
      const framesArray = storeData.frames || [];
      const frameIndex = framesArray.findIndex(frame => frame.frameID === selectedObjectID);
  
      if (frameIndex !== -1) {
        framesArray[frameIndex] = updatedObjectWithNewStyle;
  
        await updateDoc(storesRef, {
          frames: framesArray,
        });
      }
    }
  };

  const handleBorderStyle = async (borderStyleValue) => {
    setBorderStyle(borderStyleValue);

    // Extract current borderWidth from selectedObjectData.frame_styling.border
    let borderWidth = '3'; // Default value
    if (selectedObjectData && selectedObjectData.frame_styling && selectedObjectData.frame_styling.border) {
      const borderParts = selectedObjectData.frame_styling.border.split(' ');
      if (borderParts.length > 0) {
        const widthPart = borderParts[0];
        const widthValue = parseInt(widthPart, 10);
        if (!isNaN(widthValue)) {
          borderWidth = widthValue.toString();
        }
      }
    }

    // Build new border style
    const newBorderStyle = borderStyleValue === 'none' ? 'none' : `${borderWidth}px ${borderStyleValue} ${selectedBorderColor || 'black'}`;

    // Indicate a manual update has occurred
    setBorderManualUpdate(true);

    // Update Firestore and state
    if (selectedObjectData) {
      const updatedFrameStyling = {
        ...selectedObjectData.frame_styling,
        border: newBorderStyle,
      };

      const updatedObjectData = {
        ...selectedObjectData,
        frame_styling: updatedFrameStyling,
      };

      // Update props.data array
      const updatedData = props.data.map(obj =>
        obj.frameID === props.selectedObjectID ? updatedObjectData : obj
      );

      // Set the updated object data in the state
      setSelectedObjectData(updatedObjectData);

      // Propagate the changes to the parent component
      props.updateFrames(updatedData, updatedObjectData);

      // Firestore updates
      const framesRef = doc(db, 'frames', props.selectedObjectID);
      await updateDoc(framesRef, {
        frame_styling: updatedFrameStyling,
      });

      // Update the frames array in the stores document
      const storesRef = doc(db, 'stores', storeID);
      const storeDoc = await getDoc(storesRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames || [];

        // Find and update the frame in the store's frames array
        const frameIndex = framesArray.findIndex(frame => frame.frameID === props.selectedObjectID);
        if (frameIndex !== -1) {
          framesArray[frameIndex] = updatedObjectData;
          await updateDoc(storesRef, {
            frames: framesArray,
          });
        }
      }
    }
  };

  const textPosition = useCallback(async (position) => {
    setFontPosition(position);

    if (selectedObjectData) {
      // Ensure frame_styling is an object, then update it with the new textAlign
      const updatedFrameStyling = {
        ...(selectedObjectData.frame_styling || {}),
        textAlign: position,
      };

      // Update the local state
      const updatedObjectData = { 
        ...selectedObjectData, 
        frame_styling: updatedFrameStyling
      };
      setSelectedObjectData(updatedObjectData);

      // Update props.data array
      const updatedData = props.data.map(obj =>
        obj.frameID === props.selectedObjectID ? updatedObjectData : obj
      );
      props.updateFrames(updatedData, updatedObjectData);

      // Firestore updates
      const framesRef = doc(db, 'frames', props.selectedObjectID);
      await updateDoc(framesRef, {
        frame_styling: updatedFrameStyling,
      });

      // Update the stores document
      const storesRef = doc(db, 'stores', storeID);
      const storeDoc = await getDoc(storesRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames;

        // Update the frame in the store's frames array
        const frameIndex = framesArray.findIndex(frame => frame.frameID === props.selectedObjectID);
        if (frameIndex !== -1) {
          framesArray[frameIndex] = {
            ...framesArray[frameIndex],
            frame_styling: updatedFrameStyling,
          };

          await updateDoc(storesRef, {
            frames: framesArray,
          });
        }
      }
    }
  }, [selectedObjectData, props.data, props.selectedObjectID, props.updateFrames, storeID]);

  const handleAlignBtnClick = useCallback((btn) => {
    textPosition(btn);
    setManualUpdate(true);
    setSelectedBtn(btn);
  }, [textPosition]);

  // New: Debounced function to update opacity in Firestore
  const debouncedUpdateOpacity = useCallback(
    debounce(async (updatedOpacity) => {
      if (!selectedObjectData) return;

      try {
        // Update Firestore for frames with opacity as a percentage string
        const framesRef = doc(db, 'frames', props.selectedObjectID);
        await updateDoc(framesRef, { 
          frame_styling: { 
            ...selectedObjectData.frame_styling, 
            opacity: `${updatedOpacity}%` 
          } 
        });

        // Update the frames array in the stores document
        const storesRef = doc(db, 'stores', storeID);
        const storeDocSnap = await getDoc(storesRef);
        if (storeDocSnap.exists()) {
          const storeData = storeDocSnap.data();
          const framesArray = storeData.frames || [];
          const frameIndex = framesArray.findIndex(frame => frame.frameID === props.selectedObjectID);
          if (frameIndex !== -1) {
            framesArray[frameIndex].frame_styling.opacity = `${updatedOpacity}%`;
            await updateDoc(storesRef, { frames: framesArray });
          }
        }
      } catch (error) {
        console.error("Failed to update opacity in Firestore:", error);
      }
    }, 300), // 300ms debounce delay
    [selectedObjectData, props.selectedObjectID, storeID]
  );

  // Handler for opacity slider change
  const handleOpacitySliderChange = (newOpacity) => {
    if (isNaN(newOpacity)) return;

    setOpacity(newOpacity);
    setOpacityInput(newOpacity.toString());

    // Update frame styling
    const updatedFrameStyling = {
      ...selectedObjectData.frame_styling,
      opacity: `${newOpacity}%`, // Save as percentage string
    };

    const updatedObjectData = { ...selectedObjectData, frame_styling: updatedFrameStyling };
    const updatedData = props.data.map(obj =>
      obj.frameID === props.selectedObjectID ? updatedObjectData : obj
    );
    setSelectedObjectData(updatedObjectData);
    props.updateFrames(updatedData, updatedObjectData);

    // Invoke debounced Firestore update
    debouncedUpdateOpacity(newOpacity);
  };

  // Handler for opacity input change
  const handleOpacityInputChange = (newOpacity) => {
    if (newOpacity === '') {
      setOpacityInput('');
      return;
    }

    const numericValue = parseInt(newOpacity, 10);
    if (isNaN(numericValue)) {
      return;
    }

    if (numericValue < 0 || numericValue > 100) {
      return;
    }

    setOpacity(numericValue);
    setOpacityInput(numericValue.toString());

    // Update frame styling
    const updatedFrameStyling = {
      ...selectedObjectData.frame_styling,
      opacity: `${numericValue}%`, // Save as percentage string
    };

    const updatedObjectData = { ...selectedObjectData, frame_styling: updatedFrameStyling };
    const updatedData = props.data.map(obj =>
      obj.frameID === props.selectedObjectID ? updatedObjectData : obj
    );
    setSelectedObjectData(updatedObjectData);
    props.updateFrames(updatedData, updatedObjectData);

    // Invoke debounced Firestore update
    debouncedUpdateOpacity(numericValue);
  };

  const getSelectedBorderColor = useCallback(async(color) => {
    setSelectedBorderColor(color)
    // frameTextAreaRef.current.style.color = `${color}`
  })

  const handleSelectedFrameColor = useCallback((color) => {
    if (color && color.hex) {
      setPreviewFrameColor(color.hex); // Update previewed color for real-time preview
      handleFrameSelectedColor(color.hex)
    } else if (typeof color === 'string') {
      setPreviewFrameColor(color); // Directly set previewed color if a string is provided
      handleFrameSelectedColor(color)
    }
  }, []);

  const handleConfirmFrameColor = useCallback(async () => {
    if (previewFrameColor) { // Only proceed if a new color has been selected
      try {
        setSelectedFrameColor(previewFrameColor)
        handleFrameSelectedColor(previewFrameColor)
      } catch (error) {
        console.error('Error updating text:', error);
      }
    }
    setPreviewFrameColor(null); // Reset previewed color regardless
    setShowFrameColourPopup(false); // Close the color picker popup
  }, [previewFrameColor, props.storeID]);

  const handleCancelFrameColor = useCallback(() => {
    setPreviewFrameColor(null); // Revert to original color
    setShowFrameColourPopup(false); // Close the popup
  }, []);

  const handleSelectedBorderColor = useCallback((color) => {
    if (color && color.hex) {
      setPreviewBorderColor(color.hex); // Update previewed color for real-time preview
      handleBorderSelectedColor(color.hex)
    } else if (typeof color === 'string') {
      setPreviewBorderColor(color); // Directly set previewed color if a string is provided
      handleBorderSelectedColor(color)
    }
  }, []);

  const handleConfirmBorderColor = useCallback(async () => {
    if (previewBorderColor) { // Only proceed if a new color has been selected
      try {
        setSelectedBorderColor(previewBorderColor)
        getSelectedBorderColor(previewBorderColor)
        handleBorderSelectedColor(previewBorderColor)
      } catch (error) {
        console.error('Error updating text:', error);
      }
    }
    setPreviewBorderColor(null); // Reset previewed color regardless
    setShowBorderColourPopup(false); // Close the color picker popup
  }, [previewBorderColor, props.storeID]);
  
  const handleCancelBorderColor = useCallback(() => {
    setPreviewBorderColor(null); // Revert to original color
    setShowBorderColourPopup(false); // Close the popup
  }, []);

  return (
    <div>
      <div className="frameBar">
        <div className="frameZIndexBtn" 
          onClick={() => {
            props.setShowZIndexPopup(!(props.showZIndexPopup));
          }}
        >
          frame dynamics
        </div>
        <div className="frameTypesDiv">
          <img src={frameTypeButtonSrc} className="frameTypeButton" 
          onClick={() => {
            setShowFrameTypes(!showFrameTypes);
          }}/>
        </div>
        <img 
          className="frameBorderBtn" 
          src={borderIcon} 
          onClick={() => {
            setShowFrameBorderPopup(!showFrameBorderPopup);
          }}>
        </img>
        <img
          className="frameOpacityBtn"
          src={opacityIcon}
          alt="Opacity Icon"
          onClick={() => {
            setShowOpacityPopup(!showOpacityPopup);
            setShowFrameBorderPopup(false); // Close border popup if open
          }}
        />
        
        
        <div className="textAlignDiv">
          <img src={leftAlignIcon} className={selectedBtn === 'left' ? 'leftAlignBtn selectedBtn' : 'leftAlignBtn'}  
          onClick={() => {
            handleAlignBtnClick('left');
          }}
          />
          <img src={centreAlignIcon} className={selectedBtn === 'center' ? 'centreAlignBtn selectedBtn' : 'centreAlignBtn'} 
          onClick={() => {
            handleAlignBtnClick('center');
          }}
          />
          <img src={rightAlignIcon} className={selectedBtn === 'right' ? 'rightAlignBtn selectedBtn' : 'rightAlignBtn'}  
          onClick={() => {
            handleAlignBtnClick('right');
          }}
          />
        </div>
      </div>
      {showFrameTypes &&
        <div className="frameTypesPopup" ref={props.frameTypesPopupRef}>
          <div className="frameTypeSection">
            <div className="frameTypeSectionHeader">frame type</div>
            <div className="frameTypeSectionOptions">
              <img className="blankFrameBtn" title="blank border frame" src={blankFrameIcon} 
              onClick={() => {
                setFrameStyle('none', props.selectedObjectID);
                handleFrameTypeClick(blankFrameIcon);
              }}/>
              <img className="glassFrameBtn" title="glassmorphism frame" src={glassFrameIcon} 
              onClick={() => {
                setFrameStyle('glass', props.selectedObjectID);
                handleFrameTypeClick(glassFrameIcon);
              }}/>
              <img className="colourFrameBtn" src={fontColourIcon} title="colour frame" 
              onClick={() => {
                setFrameStyle('color', props.selectedObjectID);
                setShowFrameColourPopup(true);
                handleFrameTypeClick(fontColourIcon);
                console.log("Frame colour button has been clicked")
              }}/>
            </div>
          </div>
          {showFrameColourPopup &&
            <div className="frameTypeColour">
              <WebsiteBackgroundPopup 
                parentComponent={'frameBar'}
                useCase={'frameColorChange'}
                storeID={props.storeID}
                closePopup={() => {setShowFrameColourPopup(false)}}
                show={showFrameColourPopup} 
                onClickOutside={null} 
                onSelectColor={handleSelectedFrameColor}
                objectColor={previewFrameColor || selectedFrameColor}
                data={props.storeData}
                pickerRef={props.pickerRef}
                onDone={handleConfirmFrameColor} 
                onCancel={handleCancelFrameColor}
                onSizeChange={null}
                showCommonColors={showCommonColors}
                setShowCommonColors={setShowCommonColors}
                setColorPickerOpen={setColorPickerOpen}
              />
            </div> 
          }
        </div>
      }
      {showFrameBorderPopup && 
        <div className="frameBorderPopupDiv" ref={props.frameBorderPopupRef}>
          <div className="frameBorderStyleDiv">
            <div className="frameBorderStyleText">border style</div>
            <div className="frameBorderStyleIconDiv">
              <img 
                src={noBorderIcon} 
                className={`noBorderBtn${borderStyle === 'none' ? 'Active' : ''}`}
                onClick={() => {handleBorderStyle('none');}}
              />
              <img 
                src={lineBorderIcon} 
                className={`lineBorderBtn${borderStyle === 'solid' ? 'Active' : ''}`} 
                onClick={() => {handleBorderStyle('solid');}}
              />
              <img 
                src={dashedBorderIcon} 
                className={`dashedBorderBtn${borderStyle === 'dashed' ? 'Active' : ''}`}
                onClick={() => {handleBorderStyle('dashed');}}
              />
              <img 
                src={dottedBorderIcon} 
                className={`dottedBorderBtn${borderStyle === 'dotted' ? 'Active' : ''}`}
                onClick={() => {handleBorderStyle('dotted');}}
              />
            </div>
          </div>
          {/* Use the new FrameBorderWidth component */}
          <FrameBorderWidth
            selectedObjectData={selectedObjectData}
            updateFrames={props.updateFrames}
            data={props.data}
            selectedObjectID={props.selectedObjectID}
            storeID={storeID}
            borderStyle={borderStyle}
            selectedBorderColor={selectedBorderColor}
            setSelectedObjectData={setSelectedObjectData}
          />
          <FrameBorderRadius
            selectedObjectData={selectedObjectData}
            updateFrames={props.updateFrames}
            data={props.data}
            selectedObjectID={props.selectedObjectID}
            storeID={storeID}
          />
          <div className="frameBorderColor">
            <div className="frameBorderColorText" >
              border colour
              <img 
                src={dropdownIcon} 
                className="borderColourSectionDropdownIcon" 
                onClick={() => {setShowBorderColourPopup(!showBorderColourPopup);}}
              />
            </div>
            {showBorderColourPopup &&
              <WebsiteBackgroundPopup 
                parentComponent={'frameBar'}
                useCase={'borderColorChange'}
                storeID={props.storeID}
                closePopup={() => {setShowBorderColourPopup(false)}}
                show={showBorderColourPopup} 
                onClickOutside={null} 
                onSelectColor={handleSelectedBorderColor}
                objectColor={previewBorderColor || selectedBorderColor}
                data={props.storeData}
                pickerRef={props.pickerRef}
                onDone={handleConfirmBorderColor} 
                onCancel={handleCancelBorderColor}
                onSizeChange={null}
                showCommonColors={showCommonColors}
                setShowCommonColors={setShowCommonColors}
                setColorPickerOpen={setColorPickerOpen}
              />
            }
          </div>
        </div>
      }
      {showOpacityPopup &&
        <OpacityPopup
          opacity={opacity}
          opacityInput={opacityInput}
          onSliderChange={handleOpacitySliderChange}
          onInputChange={handleOpacityInputChange}
          closePopup={() => setShowOpacityPopup(false)}
          opacityPopupRef={props.opacityPopupRef}
        />
      }
    </div>
  );
};

export default FrameBar;
