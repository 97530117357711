// PaymentPopup.jsx

import { useState, useEffect, useRef } from "react";
import { 
  getDoc, 
  doc, 
  setDoc, 
  updateDoc, 
  runTransaction, 
  arrayUnion, 
  getDocs, 
  query, 
  collection, 
  serverTimestamp 
} from "@firebase/firestore"; // Added getDocs, query, collection, serverTimestamp
import { db } from "../firebase";
import { motion } from "framer-motion";

import './PaymentPopup.css';

const PaymentPopup = ({
  onClickOutside,
  setShowPaymentPopup,
  userID,
  totalAmount,
  setAlertMessage,
  setAlertType,
  stores, // Ensure stores prop is being passed
  buyerInfo, // **Receive buyerInfo as a prop**
}) => {
  const paymentPopupRef = useRef();

  const [deliveryFee, setDeliveryFee] = useState(0);
  const [buyerAddress, setBuyerAddress] = useState("");
  const [yocoSDK, setYocoSDK] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Handle clicking outside the payment popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        paymentPopupRef.current &&
        !paymentPopupRef.current.contains(event.target)
      ) {
        onClickOutside?.();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside]);

  // Fetch buyerAddress and deliveryFee if needed
  useEffect(() => {
    const fetchAdditionalData = async () => {
      if (userID) {
        const cartRef = doc(db, "cart", userID);
        const cartSnap = await getDoc(cartRef);
        if (cartSnap.exists()) {
          const cartData = cartSnap.data();
          setDeliveryFee(cartData.deliveryFee || 0);
          setBuyerAddress(cartData.buyerAddress || "");
        }
      }
    };

    fetchAdditionalData();
  }, [userID]);

  // Fetch YOCO Public Key from backend
  useEffect(() => {
    const fetchPublicKey = async () => {
      try {
        const response = await fetch(`https://getyocopublickey-a6vcfarowq-uc.a.run.app/get-yoco-public-key`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch YOCO public key");
        }

        const data = await response.json();
        setPublicKey(data.publicKey);
      } catch (error) {
        console.error("Error fetching YOCO public key:", error.message);
        setAlertMessage("Failed to initialize payment gateway.");
        setAlertType("error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublicKey();
  }, [setAlertMessage, setAlertType]);

  // Load the YOCO SDK once the public key is fetched
  useEffect(() => {
    if (publicKey) {
      if (window.YocoSDK) {
        const yoco = new window.YocoSDK({
          publicKey: publicKey,
        });
        setYocoSDK(yoco);
      } else {
        const script = document.createElement("script");
        script.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
        script.async = true;
        script.onload = () => {
          const yoco = new window.YocoSDK({
            publicKey: publicKey,
          });
          setYocoSDK(yoco);
        };
        script.onerror = () => {
          setAlertMessage("Failed to load Yoco SDK");
          setAlertType("error");
        };
        document.body.appendChild(script);
      }
    }
  }, [publicKey, setAlertMessage, setAlertType]);

  console.log(stores)
  // Handle the payment process
  const handlePayment = (totalAmount) => {
    if (!yocoSDK) {
      setAlertMessage("Payment gateway is not ready. Please try again later.");
      setAlertType("error");
      return;
    }

    if (totalAmount === 0) {
      setAlertMessage("Total amount cannot be R0. Please check your cart.");
      setAlertType("error");
      return;
    }

    // Convert totalAmount from Rands to Cents
    const amountInCents = totalAmount * 100;

    yocoSDK.showPopup({
      amountInCents: amountInCents,
      currency: "ZAR",
      name: "taizte",
      description: "Purchase Products",
      callback: function (result) {
        if (result.error) {
          setAlertMessage("Error: " + result.error.message);
          setAlertType("error");
        } else {
          // Send the token to your backend for processing
          processPayment(result.id, totalAmount, stores, buyerInfo); // **Pass buyerInfo**
        }
      },
    });
  };

  // Process payment by sending token to the backend
  const processPayment = async (token, amount, stores, buyerInfo) => { // **Include buyerInfo**
    try {
      const response = await fetch(
        `https://processyocopayment-a6vcfarowq-uc.a.run.app/process-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            amount: amount * 100, // Convert to cents
            currency: "ZAR",
            description: "Purchase Products",
            metadata: {
              userID,
              stores, // Send store-specific data
              deliveryFee,
              buyerAddress,
              buyerInfo, // **Include buyerInfo in metadata**
            },
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setAlertMessage("Payment successful!");
        setAlertType("success");

        // **Begin Firestore Updates**

        // Use Firestore transactions for atomicity
        const transactionPromises = stores.map(async (store) => {
          const storeID = store.storeID;
          const storeName = store.storeName;
          const products = store.products;
          console.log(products)

          // References to Firestore documents
          const orderHistoryRef = doc(db, "orderHistory", storeID);
          const adminOrderHistoryRef = doc(db, "adminOrderHistory", storeID);

          // Calculate saleTotal for lifetimeSales
          const saleTotal = products.reduce((acc, product) => acc + (product.seller_price * (product.quantity || 1)), 0);

          // Calculate platform fees and other aggregates
          const platformFees = products.reduce((acc, product) => acc + (product.listed_price - product.seller_price) * (product.quantity || 1), 0);
          const paymentGatewayValue = saleTotal * 0.0295; // 3.5% of saleTotal
          const netProfit = platformFees - paymentGatewayValue;

          // Start a transaction for each store
          await runTransaction(db, async (transaction) => {
            // **Step 1: Read all necessary documents first**

            const orderHistoryDoc = await transaction.get(orderHistoryRef);
            const adminOrderHistoryDoc = await transaction.get(adminOrderHistoryRef);

            // **Step 2: Perform write operations based on the reads**

            // **Update orderHistory**
            if (!orderHistoryDoc.exists()) {
              // If the document doesn't exist, create it
              transaction.set(orderHistoryRef, {
                orderHistory: arrayUnion({
                  saleDate: new Date(),
                  products: products, // Append entire product objects
                  buyerInfo: buyerInfo, // **Add buyerInfo here**
                }),
                storeID: storeID,
                lifetimeSales: saleTotal, // Initialize lifetimeSales
                productsSoldNum: products.reduce((acc, product) => acc + (product.quantity || 1), 0),
                // Add other useful fields if necessary
              });
            } else {
              // If the document exists, update it
              const currentData = orderHistoryDoc.data();

              transaction.update(orderHistoryRef, {
                orderHistory: arrayUnion({
                  saleDate: new Date(),
                  products: products, // Append entire product objects
                  buyerInfo: buyerInfo, // **Add buyerInfo here**
                }),
                lifetimeSales: currentData.lifetimeSales + saleTotal, // Update lifetimeSales
                storeID: storeID,
                productsSoldNum: currentData.productsSoldNum + products.reduce((acc, product) => acc + (product.quantity || 1), 0),
                // Update other aggregate fields if necessary
              });
            }

            // **Update adminOrderHistory**
            if (!adminOrderHistoryDoc.exists()) {
              // If the document doesn't exist, create it
              transaction.set(adminOrderHistoryRef, {
                adminOrderHistory: arrayUnion({
                  saleDate: new Date(),
                  products: products,
                  buyerInfo: buyerInfo, // **Add buyerInfo here**
                }),
                lifetimePlatformFees: platformFees,
                paymentGatewayValue: paymentGatewayValue,
                netProfit: netProfit,
                storeID: storeID,
                lifetimeSales: saleTotal, // Initialize lifetimeSales in adminOrderHistory
                // Add other useful fields if necessary
              });
            } else {
              // If the document exists, update it
              const currentAdminData = adminOrderHistoryDoc.data();

              transaction.update(adminOrderHistoryRef, {
                adminOrderHistory: arrayUnion({
                  saleDate: new Date(),
                  products: products,
                  buyerInfo: buyerInfo, // **Add buyerInfo here**
                }),
                storeID: storeID,
                lifetimePlatformFees: currentAdminData.lifetimePlatformFees + platformFees,
                paymentGatewayValue: currentAdminData.paymentGatewayValue + paymentGatewayValue,
                netProfit: currentAdminData.netProfit + netProfit,
                lifetimeSales: (currentAdminData.lifetimeSales || 0) + saleTotal, // Update lifetimeSales in adminOrderHistory
                // Update other aggregate fields if necessary
              });
            }
          });

          // **Update Products Collection with buyerInfo**

          const updateProductPromises = products.map(async (product) => {
            const productRef = doc(db, "products", product.productID);

            await updateDoc(productRef, {
              productSold: true,
              productSent: false,
              shippingStatus: "pending",
              buyerInfo: buyerInfo, // **Add buyerInfo here**
            }, { merge: true }); // Use merge to avoid overwriting existing data
          });

          await Promise.all(updateProductPromises);
        });

        // Wait for all transactions to complete
        await Promise.all(transactionPromises);

        // **End Firestore Updates**

        // **Begin: Remove Purchased Products from Favourites and Cart, and Create Notifications**

        // 1. Collect all purchased products information
        const purchasedProducts = stores.flatMap(store => store.products.map(product => ({
          productID: product.productID,
          productName: product.productName, // Assuming each product has a 'productName' field
          storeID: store.storeID,
          storeName: store.storeName,
          userID: userID, // Assuming notifications are for the buyer
        })));

        const purchasedProductIDs = purchasedProducts.map(p => p.productID);

        // 2. Remove purchased products from Favourites
        const favQuerySnapshot = await getDocs(collection(db, "favourites"));
        const removeFromFavouritesPromises = favQuerySnapshot.docs.map(async (favDoc) => {
          const likedProducts = favDoc.data().liked_products;
          if (!likedProducts) return;

          const updatedLikedProducts = likedProducts.filter(likedProduct => !purchasedProductIDs.includes(likedProduct.productID));

          // Update only if there are changes
          if (updatedLikedProducts.length !== likedProducts.length) {
            await updateDoc(doc(db, "favourites", favDoc.id), {
              liked_products: updatedLikedProducts
            });
          }
        });

        await Promise.all(removeFromFavouritesPromises);
        console.log("Purchased products removed from favourites.");

        // 3. Remove purchased products from Cart
        const cartRef = doc(db, "cart", userID);
        const cartSnap = await getDoc(cartRef);
        if (cartSnap.exists()) {
          const cartData = cartSnap.data();
          const updatedCartProducts = cartData.cart.filter(cartProduct => !purchasedProductIDs.includes(cartProduct.productID));

          // Update only if there are changes
          if (updatedCartProducts.length !== cartData.cart.length) {
            await updateDoc(cartRef, {
              cart: updatedCartProducts, 
              cart_total: 0
            });
            console.log("Purchased products removed from cart.");
            // refresh cart here, by invoking function
            setShowPaymentPopup(false)
          }
        }

        // 4. Create Notifications
        const createNotificationPromises = purchasedProducts.map(async (product) => {
          // Notification for the buyer
          const buyerNotificationRef = doc(collection(db, 'notifications'));
          await setDoc(buyerNotificationRef, {
            notificationType: 'purchaseConfirmation',
            message: `You have successfully purchased '${product.productName}' from ${product.storeName}.`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: buyerNotificationRef.id,
            readStatus: 'unread',
            storeName: product.storeName,
            productName: product.productName,
            storeID: product.storeID,
            userID: userID // Recipient is the buyer
          });

          // Notification for the store owner
          // Assuming you have a way to get the store owner's userID, e.g., from the store document
          // For this example, let's assume each store has an 'ownerID' field
          const storeRef = doc(db, "stores", product.storeID);
          const storeSnap = await getDoc(storeRef);
          if (storeSnap.exists()) {
            const storeData = storeSnap.data();

            const storeNotificationRef = doc(collection(db, 'notifications'));
            await setDoc(storeNotificationRef, {
              notificationType: 'productNotification',
              message: `The product titled '${product.productName}' has been purchased.`,
              messageDate: new Date().toLocaleString(),
              messageDateNumeric: serverTimestamp(),
              messageID: storeNotificationRef.id,
              readStatus: 'unread',
              storeName: product.storeName,
              productName: product.productName,
              storeID: product.storeID,
              userID: storeData.ownerID // Recipient is the store owner
            });
          }
        });

        await Promise.all(createNotificationPromises);
        console.log("Notifications created.");

        // **End: Remove Purchased Products from Favourites and Cart, and Create Notifications**

        // **Create Payment Documents for Each Store**

        const paymentPromises = stores.map(async (store) => {
          const paymentRef = doc(db, "payments", store.storeID);
          await setDoc(paymentRef, {
            userID: userID,
            storeID: store.storeID,
            storeName: store.storeName,
            products: store.products, // Assuming it contains necessary fields
            totalAmount: store.products.reduce((sum, product) => sum + (product.listed_price * (product.quantity || 1)), 0),
            shippingStatus: "pending",
            productSent: false,
            paymentDate: new Date(),
            buyerInfo: buyerInfo, // **Add buyerInfo here for redundancy**
            // Add any other relevant fields here
          }, { merge: true }); // Use merge to avoid overwriting existing data
        });

        await Promise.all(paymentPromises);

        // **Clear the Cart After Successful Payment**

        await setDoc(doc(db, "cart", userID), {
          cart: [],
          deliveryFee: 0,
          buyerAddress: "",
        });

        // **Close the Payment Popup**

        onClickOutside(); // Close the payment popup
      } else {
        setAlertMessage("Payment failed: " + data.message);
        setAlertType("error");
        console.log(data.message)
      }
    } catch (error) {
      setAlertMessage("Payment processing error: " + error.message);
      setAlertType("error");
      console.log(error.message)
    }
  };

  // ... [rest of the component remains unchanged] ...

  if (isLoading) {
    return (
      <div className="paymentPopupContainer">
        <motion.div
          initial={{ y: "100vh" }}
          animate={{ y: 0 }}
          transition={{ stiffness: 100 }}
          ref={paymentPopupRef}
          className="paymentPopupDiv"
        >
          <div className="paymentPopupText">Loading Payment Gateway...</div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="paymentPopupContainer">
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ stiffness: 100 }}
        ref={paymentPopupRef}
        className="paymentPopupDiv"
      >
        <div className="paymentPopupText">Payment</div>
        <div className="paymentDetails">
          <p>Total Amount: R{totalAmount.toFixed(2)}</p>
          <button
            className="payNowButton"
            onClick={() => handlePayment(totalAmount)}
          >
            Pay Now
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default PaymentPopup;
