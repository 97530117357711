import { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  getDoc,
} from '@firebase/firestore';
import { db } from '../firebase';

import './CartStoreContainer.css';
import Select from 'react-select';
import bundlingIcon from '../icons/bundlingIcon.png';
import pudoLogo from '../images/pudoLogo.png';
import paxiLogo from '../images/paxiLogo.png';
import aramexLogo from '../images/aramexLogo.png';
import { shippingInformation } from './ShippingInformation';

const ShippingOptionsPopup = (props) => {
  // State Variables
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [bundlingOptions, setBundlingOptions] = useState([]);
  const [selectedShippingOptions, setSelectedShippingOptions] = useState({});
  const [selectedSubgroup, setSelectedSubgroup] = useState({});
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [totalShippingPrice, setTotalShippingPrice] = useState(0);
  const [showBundlingOptions, setShowBundlingOptions] = useState(false);
  const [originalCheckedOptions, setOriginalCheckedOptions] = useState({});
  const [showPudoOptions, setShowPudoOptions] = useState({});
  const [clickedCheckbox, setClickedCheckbox] = useState(false);
  const [recentCartData, setRecentCartData] = useState(null);
  const [checkedOptions, setCheckedOptions] = useState({});
  const [bundledProducts, setBundledProducts] = useState({});
  const [shippingMethodAllowBundlingState, setShippingMethodAllowBundlingState] = useState({});

  // Props Destructuring
  const { cart, userID, storeName, storeID, bundlingRules, updateCartData, updateGlobalTotalShippingPrice, closePopup, setShowTotalContainer } = props;

  // Helper Functions
  const getShippingInfo = (method) => {
    let logo = "";
    let updatedText = "";

    if (method.includes('Pudo')) {
      logo = pudoLogo;
      updatedText = method.replace('Pudo', '').trim();
    }
    else if (method.includes('Paxi')) {
      logo = paxiLogo;
      updatedText = method.replace('Paxi', '').trim();
    }
    else if (method.includes('Aramex')) {
      logo = aramexLogo;
      updatedText = method.replace('Aramex', '').trim();
    }

    return { logo, updatedText };
  };

  const getPriceForOptionText = (optionText) => {
    console.log("getPriceForOptionText called with:", optionText);
    for (const method of shippingInformation) {
      if (method.options) {
        for (const option of method.options) {
          if (option.value === optionText) {
            console.log("Found price:", option.price);
            return parseFloat(option.price.slice(1)); // remove the 'R' prefix and convert to float
          }
        }
      }

      if (method.subgroups) {
        for (const subgroup of method.subgroups) {
          if (subgroup.options) {
            for (const option of subgroup.options) {
              if (option.value === optionText) {
                console.log("Found price in subgroup:", option.price);
                return parseFloat(option.price.slice(1)); // remove the 'R' prefix and convert to float
              }
            }
          }
        }
      }
    }
    console.log("No price found for:", optionText);
    return null;
  };

  const getShippingDetails = (shippingOptionText, storeName) => {
    let price = getPriceForOptionText(shippingOptionText);

    if (shippingOptionText.includes('Pudo')) {
      let subgroup = null;
      if (shippingOptionText.includes('Locker-to-Locker')) {
        subgroup = 'Locker-to-Locker';
      } else if (shippingOptionText.includes('Door-to-Locker')) {
        subgroup = 'Door-to-Locker';
      } else if (shippingOptionText.includes('Locker-to-Door')) {
        subgroup = 'Locker-to-Door';
      }
      let updatedText = shippingOptionText.replace('Pudo', '').replace(subgroup, '').trim();
      return { method: 'Pudo', subgroup, updatedText, logo: pudoLogo, price, value: shippingOptionText, store: storeName };
    }
    else if (shippingOptionText.includes('Paxi')) {
      let updatedText = shippingOptionText.replace('Paxi', '').trim();
      return { method: 'Paxi', updatedText, logo: paxiLogo, price, value: shippingOptionText, store: storeName };
    }
    else if (shippingOptionText.includes('Aramex')) {
      let updatedText = shippingOptionText.replace('Aramex', '').trim();
      return { method: 'Aramex', updatedText, logo: aramexLogo, price, value: shippingOptionText, store: storeName };
    }
    else {
      let updatedText = shippingOptionText;
      return { method: null, updatedText, logo: null, price: null, value: null, store: null };
    }
  };

  const calculateTotalShipping = (shippingMethodCounts, bundlingRules) => {
    let totalShipping = 0;
  
    Object.entries(shippingMethodCounts).forEach(([method, count]) => {
      const allowsBundling = shippingMethodAllowBundlingState[method]; 
      const bundlingRule = allowsBundling ? (bundlingRules[method] || 1) : 1;
      const shippingPrice = getPriceForOptionText(method);
  
      // If bundling is not allowed, bundlingRule effectively becomes 1
      // meaning every product pays full price individually
      if (bundlingRule > 1 && count >= bundlingRule) {
        const numberOfBundles = Math.floor(count / bundlingRule);
        const remaining = count % bundlingRule;
        totalShipping += (numberOfBundles + remaining) * parseFloat(shippingPrice || 0);
      } else {
        totalShipping += count * parseFloat(shippingPrice || 0);
      }
    });
  
    return totalShipping;
  };
  

  const determineBundles = (cartData, method, storeName) => {
    const bundlingRule = bundlingRules[method] || 1;
    if (bundlingRule <= 1) return {};

    const storeProducts = cartData.filter(product => product.store === storeName && product.selected_shippingName === method);
    let bundles = {};
    let bundleIndex = 1;

    for (let i = 0; i < storeProducts.length; i += bundlingRule) {
      const bundle = storeProducts.slice(i, i + bundlingRule);
      bundle.forEach(product => {
        bundles[product.productID] = `Bundle ${bundleIndex}`;
      });
      bundleIndex++;
    }

    return bundles;
  };

  // Shipping Calculation Function
  const shippingCalculation = () => {
    if (userID) {
      getDoc(doc(db, "cart", userID)).then(docSnap => {
        if (docSnap.exists()) {
          const cartData = docSnap.data().cart;
          setRecentCartData(cartData);

          const shippingMethodCounts = {};
          let isPudoMethodPresent = false;
          const newShowPudoOptions = {};
          const newOriginalCheckedOptions = {};

          // Count shipping methods and handle Pudo options
          cartData.forEach(product => {
            const shippingMethod = product.selected_shippingName;
            if (shippingMethod) {
              shippingMethodCounts[shippingMethod] = (shippingMethodCounts[shippingMethod] || 0) + 1;

              // Handle Pudo options
              if (shippingMethod.includes("Pudo")) {
                isPudoMethodPresent = true;
                newShowPudoOptions[product.productID] = true;
                setSelectedSubgroup(prevSubgroups => ({
                  ...prevSubgroups,
                  [product.productID]: product.selected_subgroup,
                }));
              } else {
                newShowPudoOptions[product.productID] = false;
              }

              newOriginalCheckedOptions[product.productID] = shippingMethod;
            }
          });

          // Calculate total shipping
          const totalShipping = calculateTotalShipping(shippingMethodCounts, bundlingRules);

          // Update component state
          setShowPudoOptions(newShowPudoOptions);
          setTotalShippingPrice(totalShipping);
          updateGlobalTotalShippingPrice(storeID, storeName, totalShipping);
          setIsDropdownOpen(isPudoMethodPresent);
          setMenuIsOpen(isPudoMethodPresent);
          setCheckedOptions(newOriginalCheckedOptions);
          setOriginalCheckedOptions(newOriginalCheckedOptions);
        }
      });
    }
  };

  // Function to update shipping calculation after checkbox changes
  const updateShippingCalculation = (updatedCartData) => {
    const shippingMethodCounts = {};

    // Count shipping methods
    updatedCartData.forEach(product => {
      const shippingMethod = product.selected_shippingName;
      if (shippingMethod) {
        shippingMethodCounts[shippingMethod] = (shippingMethodCounts[shippingMethod] || 0) + 1;
      }
    });

    // Calculate total shipping
    const totalShipping = calculateTotalShipping(shippingMethodCounts, bundlingRules);

    // Update component state
    setTotalShippingPrice(totalShipping);
    updateGlobalTotalShippingPrice(storeID, storeName, totalShipping);
  };

  // Handle Bundling Click
  const handleBundleClick = async (method, shippingPrice, currentStore, shippingGroup) => {
    console.log(shippingGroup);
    console.log("Method: ", method, "with price: ", shippingPrice, " for store: ", currentStore);
    console.log("Bundling rules for each shipping method for this specific store: ", bundlingRules);
    console.log("Available bundling options: ", bundlingOptions);

    // Check if the method exists in the bundling rules and bundling is allowed
    if (!bundlingRules[method] || bundlingRules[method] <= 1) {
      console.log(`Bundling not allowed for method ${method} with bundling rule ${bundlingRules[method]}`);
      return;
    }

    const bundleOptions = bundlingOptions[method];
    if (!bundleOptions) {
      console.log(`No bundle options found for method ${method}`);
      return;
    }

    // Filter products for the current store and shipping method
    const storeProducts = cart[currentStore].filter(product => product.selected_shippingName === method);
    const selectedProductIDs = storeProducts.map(product => product.productID);

    if (selectedProductIDs.length === 0) {
      console.log(`No selected products found for method ${method} in store ${currentStore}`);
      return;
    }

    console.log("Maximum amount of products that can be bundled for clicked bundling option: ", bundlingRules[method]);

    // Calculate number of bundles based on bundling rule
    const bundlingRule = bundlingRules[method];
    const numberOfBundles = Math.floor(selectedProductIDs.length / bundlingRule);
    const remaining = selectedProductIDs.length % bundlingRule;
    console.log("Number of bundles: " + numberOfBundles + ", Remaining: " + remaining);

    const numericShippingPrice = parseFloat(bundleOptions.shippingPrice.replace('R', ''));
    const totalBundlePrice = numberOfBundles * numericShippingPrice + remaining * numericShippingPrice;
    console.log("Total bundle price: ", totalBundlePrice);

    // Split selected products into bundles based on bundling rule
    let bundledGroups = [];
    for (let i = 0; i < selectedProductIDs.length; i += bundlingRule) {
      bundledGroups.push(selectedProductIDs.slice(i, i + bundlingRule));
    }

    // Update the shipping options and bundled products for each bundle
    let updatedShippingOptions = { ...selectedShippingOptions };
    let updatedBundledProducts = { ...bundledProducts };

    bundledGroups.forEach((bundle, bundleIndex) => {
      bundle.forEach(productID => {
        updatedShippingOptions[productID] = {
          option: `${method} Bundle ${bundleIndex + 1}`,
          price: numericShippingPrice.toFixed(2),
          bundledMethod: method,
          bundledWith: bundle,
          selected_shippingName: method
        };
      });

      // Track bundled groups per store
      if (!updatedBundledProducts[currentStore]) {
        updatedBundledProducts[currentStore] = {};
      }
      updatedBundledProducts[currentStore][`Bundle ${bundleIndex + 1}`] = {
        products: bundle,
        method: method,
        price: numericShippingPrice.toString()
      };
    });

    setSelectedShippingOptions(updatedShippingOptions);
    setBundledProducts(updatedBundledProducts);

    // Calculate total shipping for bundled and non-bundled products
    const shippingForBundled = numberOfBundles * numericShippingPrice + remaining * numericShippingPrice;
    let shippingForNonBundled = 0;

    cart[currentStore].forEach(product => {
      if (!selectedProductIDs.includes(product.productID)) {
        shippingForNonBundled += parseFloat(product.selected_shippingPrice || 0);
      }
    });

    const newTotalShippingPrice = shippingForBundled + shippingForNonBundled;

    // Update Firebase cart data
    const cartRef = doc(db, 'cart', userID);
    const cartSnap = await getDoc(cartRef);
    const cartData = cartSnap.exists() ? cartSnap.data().cart : [];

    const bundles = determineBundles(cartData, method, currentStore);
    let bundleGroups = {};

    selectedProductIDs.forEach(id => {
      const group = bundles[id];
      if (!bundleGroups[group]) {
        bundleGroups[group] = { products: [], method: method, price: numericShippingPrice };
      }
      bundleGroups[group].products.push(id);
    });

    // Update the bundledProducts state for the current store
    setBundledProducts(prev => ({
      ...prev,
      [currentStore]: bundleGroups
    }));

    cartData.forEach(product => {
      if (selectedProductIDs.includes(product.productID)) {
        const subgroup = selectedSubgroup[product.productID] || "";
        const selectedOption = subgroup
          ? `${method}--${subgroup}--Bundle ${bundlingRule}`
          : `${method}--Bundle ${bundlingRule}`;
        let formattedPrice = numericShippingPrice.toFixed(2);

        updatedShippingOptions[product.productID] = {
          option: selectedOption,
          price: formattedPrice,
          bundledMethod: method,
          bundledWith: bundles[product.productID],
          selected_shippingName: method
        };
      }
    });

    // Update the total shipping price
    setTotalShippingPrice(newTotalShippingPrice);
    updateGlobalTotalShippingPrice(storeID, storeName, newTotalShippingPrice);

    // Update Firebase cart data
    const updatedCartData = cartData.map(product => {
      if (selectedProductIDs.includes(product.productID)) {
        return {
          ...product,
          selected_shippingName: method,
          selected_shippingPrice: `R${numericShippingPrice.toFixed(2)}`,
          selected_subgroup: shippingGroup === "Pudo" ? method.split(' ')[0] : null,
          courierMethod: getGeneralMethod(method),
          ...updatedShippingOptions[product.productID]
        };
      } else {
        return product;
      }
    });

    console.log("Updated cart: ", updatedCartData);
    await updateDoc(cartRef, { cart: updatedCartData });
    updateCartData(updatedCartData);
    setShowBundlingOptions(false);
  };

  const courierMethods = {
    'Aramex': 'Aramex',
    'Pudo': 'Pudo',
    'Paxi': 'Paxi',
    // Add more methods here as needed
  };

  // retries the courier methods
  const getGeneralMethod = (optionValue) => {
    for (const [key, method] of Object.entries(courierMethods)) {
      if (optionValue.includes(key)) {
        return method;
      }
    }
    return 'Other';
  };

  const getBundleShippingPrice = (method) => {
    let minPrice = null;
    for (const shipInfo of shippingInformation) {
      if (shipInfo.name === method) {
        if (shipInfo.options) {
          shipInfo.options.forEach(option => {
            const price = parseFloat(option.price.slice(1));
            if (minPrice === null || price < minPrice) {
              minPrice = price;
            }
          });
        }
        if (shipInfo.subgroups) {
          shipInfo.subgroups.forEach(subgroup => {
            subgroup.options.forEach(option => {
              const price = parseFloat(option.price.slice(1));
              if (minPrice === null || price < minPrice) {
                minPrice = price;
              }
            });
          });
        }
      }
    }
    return minPrice !== null ? minPrice : 0;
  };
  

  // Handle Checkbox Changes
  const handleCheckboxChange = async (event, item, method, option) => {
    console.log("Item: ", item);
    event.preventDefault();
    const checkboxState = event.target.checked;
    console.log(`Checkbox state before setClickedCheckbox: ${checkboxState}`);

    setClickedCheckbox({ productID: item.productID, value: option.value });
    console.log("Clicked Checkbox set with:", { productID: item.productID, value: option.value });
    console.log("checkedOptions before setCheckedOptions:", checkedOptions);

    const newCheckedOptions = {
      ...checkedOptions,
      [item.productID]: checkboxState ? option.value : null
    };
    setCheckedOptions(newCheckedOptions);
    console.log("newCheckedOptions after setCheckedOptions:", newCheckedOptions);

    const cartRef = doc(db, 'cart', userID);
    const cartSnap = await getDoc(cartRef);
    console.log("cartSnap exists:", cartSnap.exists());

    if (cartSnap.exists()) {
      const cartData = cartSnap.data().cart;
      const productID = item.productID;
      const subgroup = selectedSubgroup[productID] || "";
      
      // Retrieve the price using getPriceForOptionText
      const optionPrice = getPriceForOptionText(option.value);
      if (optionPrice === null) {
        console.error(`Price not found for shipping option: ${option.value}`);
        return;
      }

      let selectedOption = subgroup
        ? `${method}--${subgroup}--${option.text}`
        : `${method}--${option.text}`;

      let updatedOptions = { ...selectedShippingOptions };
      console.log("updatedOptions initial state:", updatedOptions);

      if (checkboxState) {
        updatedOptions[item.productID] = { 
          option: selectedOption, 
          price: optionPrice, 
          selected_shippingName: method 
        };
        console.log("updatedOptions after checkboxState true:", updatedOptions);

        if (selectedShippingOptions[item.productID] && selectedShippingOptions[item.productID].selected_shippingName !== method) {
          console.log("Selected shipping method has changed, updating bundle info...");
          const bundledProductIDs = selectedShippingOptions[item.productID].bundledWith || [];

          bundledProductIDs.forEach(bundleProductID => {
            if (selectedShippingOptions[bundleProductID]) {
              selectedShippingOptions[bundleProductID].bundledWith = [];
            }
          });
          selectedShippingOptions[item.productID].bundledWith = [];

          bundledProductIDs.forEach(bundleProductID => {
            if (updatedOptions[bundleProductID]) {
              delete updatedOptions[bundleProductID].bundledWith;
              updatedOptions[bundleProductID].price = calculateIndividualShipping(bundleProductID, updatedOptions[bundleProductID].selected_shippingName);
            }
          });

          updatedOptions[item.productID].price = calculateIndividualShipping(item.productID, method);
          console.log("updatedOptions after bundle processing:", updatedOptions);
        }
      } else {
        delete updatedOptions[item.productID];
        console.log("updatedOptions after checkboxState false:", updatedOptions);

        if (selectedShippingOptions[item.productID]?.bundledWith) {
          selectedShippingOptions[item.productID].bundledWith.forEach(bundleProductID => {
            if (updatedOptions[bundleProductID]) {
              delete updatedOptions[bundleProductID].bundledWith;
            }
          });
        }
      }

      // Update shipping type if method is Pudo
      let shippingType = null;
      if (method === "Pudo" && option) {
        shippingType = option.value.split(' ')[0];
        console.log(`Pudo shippingType: ${shippingType}`);
      }

      const updatedCartData = cartData.map(product => {
        if (product.productID === productID) {
          return {
            ...product,
            selected_shippingName: checkboxState ? option.value : null,
            selected_shippingPrice: checkboxState ? optionPrice : null,
            selected_subgroup: method === "Pudo" ? shippingType : null, // for pudo
            courierMethod: checkboxState ? getGeneralMethod(option.value) : null,
          };
        }
        return product;
      });

      console.log("Updated data: ", updatedCartData);

      // Recalculate shipping based on the updated cart data
      updateShippingCalculation(updatedCartData);
      updateCartData(updatedCartData);
      console.log("updatedCartData:", updatedCartData);
      await updateDoc(cartRef, { cart: updatedCartData });
    }
  };

  // Calculate Individual Shipping
  const calculateIndividualShipping = (productID, shippingMethod) => {
    // Combine all products into a single array
    let allProducts = [];
    for (let store in cart) {
      allProducts = [...allProducts, ...cart[store]];
    }

    // Find the product in the allProducts array
    const product = allProducts.find(p => p.productID === productID);
    if (!product) {
      console.error(`Product with ID ${productID} not found`);
      return 0; // Default to 0 if the product is not found
    }

    // Determine the individual shipping cost for this product
    let individualShippingCost = 0;
    if (product.shippingInfo && product.shippingInfo.options) {
      const shippingOption = product.shippingInfo.options.find(option => option.value === shippingMethod);
      if (shippingOption && shippingOption.price) {
        individualShippingCost = parseFloat(shippingOption.price);
      }
    }

    return individualShippingCost;
  };

  // Initial Shipping Calculation on Component Mount
  useEffect(() => {
    shippingCalculation();
  }, [userID]);

  // Update bundling options based on cart and bundling rules
  useEffect(() => {
    console.log("Current cart data: ", cart);
    let allProducts = [];
  
    // Combine all products into a single array
    for (let store in cart) {
      allProducts = [...allProducts, ...cart[store]];
      console.log("All Products: ", allProducts);
    }
  
    if (allProducts.length === 0) {
      console.log("No products found");
      return;
    }
  
    let shippingMethodCounts = {};
    let shippingMethodAllowBundling = {}; // track if bundling is allowed per method
  
    for (let product of allProducts) {
      for (let method of product.shipping) {
        // Check if this product allows bundling
        const productAllowsBundling = product.bundling === 'yes';
  
        if (!shippingMethodCounts[method]) {
          shippingMethodCounts[method] = 0;
          // Initially assume bundling is allowed unless we find a product that says otherwise
          shippingMethodAllowBundling[method] = true;
        }
  
        shippingMethodCounts[method]++;
  
        // If any product for this method does not allow bundling, flag it
        if (!productAllowsBundling) {
          shippingMethodAllowBundling[method] = false;
        }

        setShippingMethodAllowBundlingState(shippingMethodAllowBundling)
      }
    }
  
    // Filter methods to only include those where bundling is allowed and meets bundling rules
    let commonShippingMethods = Object.keys(shippingMethodCounts).filter(method => {
      const count = shippingMethodCounts[method];
      const bundlingRule = bundlingRules[method];
      // Only include if bundling is allowed and the number of products meets the bundling rule
      return bundlingRule > 1 && count >= bundlingRule && shippingMethodAllowBundling[method];
    });
  
    let commonShippingMethodCounts = {};
  
    for (let method of commonShippingMethods) {
      // Get logo and updated text using the helper function
      const { logo, updatedText } = getShippingInfo(method);
  
      // Initialize with count, total price, logo, updatedText, and empty arrays
      commonShippingMethodCounts[method] = { 
        count: shippingMethodCounts[method], 
        totalPrice: 0.0, 
        logo, 
        updatedText, 
        bundledProducts: [],
        shippingPrice: 0 
      };
  
      // Loop through all products and calculate total price
      for (let product of allProducts) {
        if (product.shipping.includes(method)) {
          let price = getPriceForOptionText(product.selected_shippingName);
          if (price !== null) {
            commonShippingMethodCounts[method].totalPrice += price;
          } else {
            console.warn(`Price not found for shipping method: ${product.selected_shippingName}`);
          }
  
          if (!commonShippingMethodCounts[method].bundledProducts.includes(product.productID)) {
            commonShippingMethodCounts[method].bundledProducts.push(product.productID);
          }
        }
      }
  
      // Determine the base shipping price from shippingInformation
      for (let shipInfo of shippingInformation) {
        if (shipInfo.options) {
          for (let option of shipInfo.options) {
            if (option.value === method) {
              commonShippingMethodCounts[method].shippingPrice = option.price;
              break;
            }
          }
        }
        if (shipInfo.subgroups) {
          for (let subgroup of shipInfo.subgroups) {
            if (subgroup.options) {
              for (let option of subgroup.options) {
                if (option.value === method) {
                  commonShippingMethodCounts[method].shippingPrice = option.price;
                  break;
                }
              }
            }
          }
        }
      }
    }
  
    console.log("Bundling Info: ", commonShippingMethodCounts);
    setBundlingOptions(commonShippingMethodCounts);
  }, [cart, bundlingRules]);
  

  // React hook to update checkboxes based on bundlingOptions
  useEffect(() => {
    let updatedShippingOptions = { ...selectedShippingOptions };
    
    // Logic to update checkboxes based on bundlingOptions
    for (const [method, options] of Object.entries(bundlingOptions)) {
      // Currently no specific logic; implement if needed
    }

    setSelectedShippingOptions(updatedShippingOptions);
  }, [bundlingOptions]);

  return (
    <div className="shippingOptionsContainer">
      <div className="shippingOptionsDiv">
        {/* Bundling Button */}
        <div className="bundlingButtonContainer">
          {Object.keys(bundlingOptions).length > 0 && (
            <div className="bundlingOptionsButton" onClick={() => { setShowBundlingOptions(!showBundlingOptions) }}>
              <p className="bundlingOptionsButtonText">Apply Bundling</p>
              <img src={bundlingIcon} className="bundlingOptionsButtonIcon" alt="Bundling Icon" />
            </div>
          )}
        </div>

        {/* Bundling Options Popup */}
        {showBundlingOptions &&
          <div className="bundledOptionsDiv">
            <div className="bundlingOptionsDivText">Choose a shipping method to bundle products:</div>
            {Object.entries(bundlingOptions).map(([method, data]) => {
              const { logo, updatedText } = getShippingInfo(method);

              let shippingGroup;
              if (method.includes("Pudo")) {
                shippingGroup = "Pudo";
              } else if (method.includes("Aramex")) {
                shippingGroup = "Aramex";
              } else if (method.includes("Paxi")) {
                shippingGroup = "Paxi";
              } else {
                shippingGroup = "Other"; // Default or other cases
              }

              return (
                <div key={method} className="bundledOptionComponentDiv" id={method}
                  onClick={() => {
                    handleBundleClick(method, data.shippingPrice, storeName, shippingGroup)
                  }}
                >
                  <div className="bundlingDivTextAndLogo">
                    <img src={logo} alt={`${method} logo`} className="bundledOptionComponentLogo" />
                    <div className="bundledOptionComponentText">
                      <div className="bundledOptionComponentMethod">{updatedText}</div>
                      <p className="bundledOptionComponentPrice">{data.shippingPrice}</p>
                    </div>
                  </div>
                  <p className="bundledOptionComponentCount">{data.count}</p>
                </div>
              )
            })}
          </div>
        }

        {/* Shipping Options for Each Product */}
        {cart[storeName].map((item, index) => {
          if (!Array.isArray(item.shipping)) {                   
            console.error("Expected item.shipping to be an array, received: ", item.shipping);
            // handle the error or skip the iteration
            return null; 
          }

          const groupedOptions = item.shipping.reduce((groups, option) => {
            const { method, subgroup, updatedText, logo, price, value, store } = getShippingDetails(option, storeName);
            const groupKey = method;
            const optionKey = subgroup || updatedText;

            if (!groups[groupKey]) { groups[groupKey] = { logo, method, options: {} } }
            if (!groups[groupKey].options[optionKey]) { groups[groupKey].options[optionKey] = [] }
            groups[groupKey].options[optionKey].push({ text: updatedText, price, value, store });
            return groups;
          }, {});

          return (
            <div key={index} className="productShippingOptions" id={item.productID}>
              <img src={item.imageThumbnails[0]} className="productShippingOptionImg" id={item.productID}/>
              <div className="productShippingOptionBlock">
                {Object.entries(groupedOptions).map(([method, { logo, options }], i) => {
                  const selectOptions = Object.keys(options).map(subgroup => ({ value: subgroup, label: subgroup }));  

                  return (
                    <div key={i} className={`shippingMethod ${isDropdownOpen ? 'dropdown-open' : ''}`} >
                      {method === 'Pudo' ? (
                        <>
                          <Select
                            onMenuOpen={() => setIsDropdownOpen(true)}
                            onMenuClose={() => setIsDropdownOpen(false)}
                            className="shippingSubgroupSelect"
                            options={selectOptions}
                            onChange={(option) => {
                              setSelectedSubgroup({
                                ...selectedSubgroup,
                                [item.productID]: option.value
                              });
                            }}
                            styles={{
                              container: (base) => ({
                                paddingBottom: '-30px',
                              }),
                              control: (base) => ({
                                ...base,
                                position: 'relative',
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                width: '100%',
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                zIndex: 1001,
                                fontWeight: 'bold',
                                boxShadow: 'none',
                                borderColor: 'none',
                                border: 'none',
                              }),
                              menu: (base) => ({
                                ...base,
                                position: 'relative',
                                top: '-20px',
                                marginBottom: '-20px',
                                borderRadius: '0px 0px 12px 12px',
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                zIndex: 1000,
                                paddingTop: '10px',
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused ? '#FFC55C' : base.backgroundColor,
                                color: state.isHovered ? '#FFD68A' : base.color,
                                width: '98%',
                                margin: 'auto',
                                boxSizing: 'border-box',
                                overflow: 'hidden',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'left',
                                textAlign: 'left',
                              }),
                              indicatorSeparator: () => ({ display: 'none' }),
                            }}
                          />
                          {(showPudoOptions[item.productID] || selectedSubgroup[item.productID]) && Array.isArray(options[selectedSubgroup[item.productID]]) &&
                            <div className="PudoOptionsContainer">
                              {options[selectedSubgroup[item.productID]].map((option, j) => (
                                <div key={j} className="shippingOptionTextContainer">
                                  <div className="shippingOptionComponentDiv" data-value={option.value} id={option.value}>
                                    <div className="shippingOptionTextAndLogo">
                                      {logo && <img className="cartShippingOptionLogo" src={logo} alt={`${method} logo`} />}
                                      <div className="shippingOptionText">
                                        <p className="shippingOptionName">{option.text}</p>
                                        <p className="shippingOptionPrice">R{option.price}</p>
                                      </div>
                                    </div>
                                    <input
                                      type="checkbox"
                                      className="shippingMethodCheckbox"
                                      checked={checkedOptions[item.productID] === option.value}
                                      value={option.value} // Corrected
                                      onChange={(e) => {
                                        handleCheckboxChange(e, item, method, option);
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          }
                        </>
                      ) : (
                        <div className="shippingOptionTextContainer">
                          {Object.values(options).flat().map((option, j) => (
                            <div key={j} className="shippingOptionComponentDiv" data-value={option.value} id={option.value}>
                              <div className="shippingOptionTextAndLogo">
                                {logo && <img className="cartShippingOptionLogo" src={logo} alt={`${method} logo`} />}
                                <div className="shippingOptionText">
                                  <p className="shippingOptionName">{option.text}</p>
                                  <p className="shippingOptionPrice">R{option.price}</p>
                                </div>
                              </div>
                              <input
                                type="checkbox"
                                className="shippingMethodCheckbox"
                                checked={checkedOptions[item.productID] === option.value}
                                value={option.value} // Corrected
                                onChange={(e) => {
                                  handleCheckboxChange(e, item, method, option);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          );
        })}

        {/* Total Shipping Price */}
        <div className="totalStoreShippingDiv">
          <div className="totalStoreShippingText">Store shipping total: </div>
          <div className="totalStoreShippingValue">R{totalShippingPrice.toFixed(2)}</div>
        </div>

        {/* Close Button */}
        <div className="closeShippingPopupButton" 
          onClick={() => { 
            closePopup(); 
            setShowTotalContainer(true);
          }}>
          Done
        </div>
      </div>
    </div>
  );
};

export default ShippingOptionsPopup;
