import React, {useEffect, useRef, useState} from 'react'
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot, 
  deleteDoc, limit, orderBy } 
from '@firebase/firestore'
import {set, ref} from "firebase/database"
import { db } from '../firebase'
import { Pressable, StyleSheet, Text, View } from 'react-native';

import './MarketStoreLogo.css'

const MarketStoreLogo = (props) => {
  const marketStoreLogoRef = useRef()
 
  const handleClick = () => {
    props.onLogoClick(props.logo, props.category, props.description, props.store, props.storeID, props.key);
  };

  return (
    <div className="marketStoreInfoContainer">
      <img src={props.logo} className="marketStoreLogo" ref={marketStoreLogoRef}
      alt="store logo" 
      onClick={handleClick}
      />
  </div>
  )
}

export default MarketStoreLogo