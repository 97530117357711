import { useState, useEffect } from 'react';

const useFavicon = (defaultFavicon) => {
  const [favicon, setFavicon] = useState(defaultFavicon);

  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = favicon;
    document.head.appendChild(link);

    console.log('Favicon changed to:', favicon);

    return () => {
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    };
  }, [favicon]);

  return setFavicon;
};

export default useFavicon;