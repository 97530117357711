import React from 'react';
import './RoundedSlider.css';

const RoundedSlider = ({ value, onChange, min = 0, max = 100 }) => {
  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    onChange(newValue);
  }

  return (
    <div className="rounded-slider-container">
      <input
        type="range"
        className="rounded-slider"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
      />
    </div>
  );
};

export default RoundedSlider;
