import React, { useEffect, useState, useRef } from "react";
import { ref, getDownloadURL, getStorage, listAll } from 'firebase/storage'
import { auth, db, storage } from '../../firebase'
import { collection, doc, updateDoc, setDoc, getDoc, 
query, getDocs, onSnapshot, deleteDoc, limit, orderBy, 
serverTimestamp, startAfter } from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";

import Stickers from "./Stickers";
import './StickerPopup.css'
import infoIcon from '../../icons/infoIcon.png'
import loadIcon from '../../icons/scoutIcon.png'
import returnArrowIcon from '../../icons/returnArrowIcon.png'
import { setUserId } from "firebase/analytics";

const StickerPopup = (props) => {
  const [url, setUrl] = useState([])
  const [data, setData] = useState([])
  const stickerSectionRef = useRef()
  const stickerPopupRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("stickerUrls", JSON.stringify(url));
    return () => {
      localStorage.removeItem("stickerUrls");
    };
  }, [url]);
  
  useEffect(() => {
    const savedUrls = JSON.parse(localStorage.getItem("stickerUrls"));
    if (savedUrls) {
      setUrl(savedUrls);
    }
  }, []);

  function randomArrayShuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  // useEffect(() => {
  //   const getStickers = () => {
  //     const stickerRef = ref(storage, `stickers/`)

  //     // creates a doc once new stickers have been added in storage
  //     // export to admin dashboard in future
  //     listAll(stickerRef)
  //     .then((result) => {
  //       result.items.forEach((stickerObject) => {
  //         const stickerDocID = (stickerObject._location.path_).slice(9)
  //         const stickerDocNumber = stickerDocID.slice(0, -4)
  //         if (stickerDocNumber >= 610) {
  //           const stickerPopupSticker = doc(db, 'sticker popup stickers', stickerDocID)
  //           getDownloadURL(stickerObject).then((items) => {
  //             // code below creates a new doc for every sticker in storage
  //             getDoc(stickerPopupSticker)
  //               setDoc(stickerPopupSticker, {
  //                 stickerDocID: stickerDocID,
  //                 tags: [],
  //                 stickerURL: items,
  //               })
                
  //           }) 
  //           console.log("doc created")         
  //         }
          
  //       })
  //     })
  //   }
  //   getStickers()
  // }, [])

  // gets sticker data from database
  const loadMore = async () => {
    setIsLoadMoreLoading(true);
    if (data) {
      try {
        const stickerDoc = query(
          collection(db, "sticker popup stickers"),
          orderBy("stickerDocID", "asc"),
          startAfter(data[data.length - 1].stickerDocID),
          limit(12)
        );
  
        const querySnapshot = await getDocs(stickerDoc);
        const stickerArr = [];
        const downloadPromises = querySnapshot.docs.map(async (doc) => {
          const stickerName = doc.data().stickerDocID;
          await getDownloadURL(ref(storage, `stickers/${stickerName}`))
            .then((url) => {
              stickerArr.push(url);
            })
            .catch(() => {});
        });
  
        await Promise.all(downloadPromises);
        setUrl([...url, ...stickerArr]);
  
        const stickerInfo = [];
        getDocs(stickerDoc).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            stickerInfo.push({ id: doc.id, ...doc.data() });
          });
          setData(stickerInfo);
        });
      } catch (err) {
        console.log(err);
      }
    }
    setIsLoadMoreLoading(false);
  };
  
  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => resolve(url);
      image.onerror = () => reject(url);
    });
  };

  useEffect(() => {
    const fetchStickerData = async () => {
      setIsLoading(true);
      let stickerArr = [];
      try {
        const stickerPopupDocRef = query(
          collection(db, "sticker popup stickers"),
          orderBy("stickerDocID", "asc"),
          limit(15)
        );
        const querySnapshot = await getDocs(stickerPopupDocRef);
        const stickerInfo = [];
    
        const downloadPromises = querySnapshot.docs.map(async (doc) => {
          const stickerName = doc.data().stickerDocID;
          const url = await getDownloadURL(ref(storage, `stickers/${stickerName}`)).catch(() => {});
          if (url) {
            stickerArr.push(url);
            stickerInfo.push({ id: doc.id, ...doc.data() });
          }
        });
    
        Promise.all(downloadPromises).then(() => {
          const imagePromises = stickerArr.map(loadImage);
          Promise.all(imagePromises).then(() => {
            setData(stickerInfo);
            setUrl(stickerArr);
            setIsLoading(false);
          });
        });
    
      } catch (err) {
        console.log(err);
      }
    };
    
    fetchStickerData();
  }, []);

  // Need to change names for the spinner, as it is currently using the market pages styling
  return (
    <div className="stickerPopup" ref={stickerPopupRef}>
      <div 
        className="stickerPopupBackArrow" 
        onClick={() => {props.closeStickerPopup()}}
      >
        <img src={returnArrowIcon} className="stickerPopupBackArrowIcon" />
      </div>
      <h1 className="stickerSelectorHeader">stickers.</h1>
      <img src={infoIcon} className="stickerInfoIcon"/>
      <div className="stickerTags">
        <div className="StickerTag3D">3D</div>
        <div className="vintageStickerTag">vintage</div>
        <div className="cartoonStickerTag">cartoon</div>
        <div className="redStickerTag">red</div>
        <div className="orangeStickerTag">orange</div>
        <div className="yellowStickerTag">yellow</div>
        <div className="greenStickerTag">green</div>
        <div className="blueStickerTag">blue</div>
        <div className="purpleStickerTag">purple</div>
        <div className="pinkStickerTag">pink</div>
        <div className="beigeStickerTag">beige</div>
        <div className="whiteStickerTag">white</div>
        <div className="brownStickerTag">brown</div>
        <div className="blackStickerTag">black</div>
      </div>
      {isLoading ?(
        <div className="marketLoadingDiv">
          <img src={loadIcon} className="marketLoadingIcon" alt="loading icon" />
        </div>) : (
        <div className="stickerSection" ref={stickerSectionRef}>
          {url.map((url, index) => (
            <img className="stickerPopupSticker" src={url} key={index} 
              onClick={() => {
                props.addSticker(url, props.storeID, props.userID, props.stickerID, "sticker")
                props.closeStickerPopup()
                props.closePopup()
              }}
            />
          ))}
          <div className="stickerLoadMoreDiv">
            {isLoadMoreLoading ? (
              <div className="marketLoadingDiv">
                <img src={loadIcon} className="marketLoadingIcon" alt="loading icon" />
              </div>
            ) : (
              <div className="stickerLoadMoreBtn" onClick={loadMore}>
                load more
              </div>
            )}
          </div>
        </div>)
      }
    </div>
  )
}

export default StickerPopup