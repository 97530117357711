import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { auth, db, storage } from '../../firebase'
import { collection, doc, updateDoc, setDoc, arrayUnion,
getDoc, onSnapshot, deleteDoc, serverTimestamp } 
from '@firebase/firestore'
import Select from 'react-select';
import './AddProduct.css'

import aramexLogo from '../../images/aramexLogo.png'
import pudoLogo from '../../images/pudoLogo.png'
import paxiLogo from '../../images/paxiLogo.png'

const ShippingOptions = (props) => {
  const [selectedPudoOption, setSelectedPudoOption] = useState(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shippingOptions, setShippingOptions] = useState([])

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  const [checkbox5, setCheckbox5] = useState(false);
  const [checkbox6, setCheckbox6] = useState(false);
  const [checkbox7, setCheckbox7] = useState(false);
  const [checkbox8, setCheckbox8] = useState(false);
  const [checkbox9, setCheckbox9] = useState(false);
  const [checkbox10, setCheckbox10] = useState(false);
  const [checkbox11, setCheckbox11] = useState(false);
  const [checkbox12, setCheckbox12] = useState(false);
  const [checkbox13, setCheckbox13] = useState(false);
  const [checkbox14, setCheckbox14] = useState(false);
  const [checkbox15, setCheckbox15] = useState(false);
  const [checkbox16, setCheckbox16] = useState(false);
  const [checkbox17, setCheckbox17] = useState(false);
  const [checkbox18, setCheckbox18] = useState(false);

  const selectOptions = [
    { label: "Locker to Locker", value: "Locker to Locker" },
    { label: "Door to Locker", value: "Door to Locker" },
    { label: "Locker to Door", value: "Locker to Door" }
  ];  

  const aramexOptions = [
    { checkboxName: "checkbox1", value: "Aramex Standard (35 x 45cm)" }
  ];
  
  const pudoOptions = [
    { checkboxName: "checkbox2", value: "Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)" },
    { checkboxName: "checkbox3", value: "Locker-to-Locker Pudo Small (60 x 41 x 8cm)" },
    { checkboxName: "checkbox4", value: "Locker-to-Locker Pudo Medium (60 x 41 x 19cm)" },
    { checkboxName: "checkbox5", value: "Locker-to-Locker Pudo Large (60 x 41 x 41cm)" },
    { checkboxName: "checkbox6", value: "Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)" },
    
    { checkboxName: "checkbox7", value: "Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)" },
    { checkboxName: "checkbox8", value: "Door-to-Locker Pudo Small (60 x 41 x 8cm)" },
    { checkboxName: "checkbox9", value: "Door-to-Locker Pudo Medium (60 x 41 x 19cm)" },
    { checkboxName: "checkbox10", value: "Door-to-Locker Pudo Large (60 x 41 x 41cm)" },
    { checkboxName: "checkbox11", value: "Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)" },
    
    { checkboxName: "checkbox12", value: "Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)" },
    { checkboxName: "checkbox13", value: "Locker-to-Door Pudo Small (60 x 41 x 8cm)" },
    { checkboxName: "checkbox14", value: "Locker-to-Door Pudo Medium (60 x 41 x 19cm)" },
    { checkboxName: "checkbox15", value: "Locker-to-Door Pudo Large (60 x 41 x 41cm)" },
    { checkboxName: "checkbox16", value: "Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)" }
  ];
  
  const paxiOptions = [
    { checkboxName: "checkbox17", value: "Paxi Standard (45 x 37cm)" },
    { checkboxName: "checkbox18", value: "Paxi Large (64 x 51cm)" }
  ];
  
  useEffect(() => {
    const allOptions = [...aramexOptions, ...pudoOptions, ...paxiOptions];
    allOptions.forEach(option => {
      const checked = props.shippingOptions.includes(option.value);
  
      switch (option.checkboxName) {
        case 'checkbox1':
          setCheckbox1(checked);
          break;
        case 'checkbox2':
          setCheckbox2(checked);
          break;
        case 'checkbox3':
          setCheckbox3(checked);
          break;
        case 'checkbox4':
          setCheckbox4(checked);
          break;
        case 'checkbox5':
          setCheckbox5(checked);
          break;
        case 'checkbox6':
          setCheckbox6(checked);
          break;
        case 'checkbox7':
          setCheckbox7(checked);
          break;
        case 'checkbox8':
          setCheckbox8(checked);
          break;
        case 'checkbox9':
          setCheckbox9(checked);
          break;
        case 'checkbox10':
          setCheckbox10(checked);
          break;
        case 'checkbox11':
          setCheckbox11(checked);
          break;
        case 'checkbox12':
          setCheckbox12(checked);
          break;
        case 'checkbox13':
          setCheckbox13(checked);
          break;
        case 'checkbox14':
          setCheckbox14(checked);
          break;
        case 'checkbox15':
          setCheckbox15(checked);
          break;
        case 'checkbox16':
          setCheckbox16(checked);
          break;
        case 'checkbox17':
          setCheckbox17(checked);
          break;
        case 'checkbox18':
          setCheckbox18(checked);
          break;
        default:
          break;
      }
    });
  }, [props.shippingOptions]);  

  const handleCheckboxChange = (event) => {
    const { name, checked, value } = event.target;

    if (name === 'checkbox1') {
      setCheckbox1(checked);
    } else if (name === 'checkbox2') {
      setCheckbox2(checked);
    } else if (name === 'checkbox3') {
      setCheckbox3(checked)
    } else if (name === 'checkbox4') {
      setCheckbox4(checked)
    } else if (name === 'checkbox5') {
      setCheckbox5(checked)
    } else if (name === 'checkbox6') {
      setCheckbox6(checked)
    } else if (name === 'checkbox7') {
      setCheckbox7(checked)
    } else if (name === 'checkbox8') {
      setCheckbox8(checked)
    } else if (name === 'checkbox9') {
      setCheckbox9(checked)
    } else if (name === 'checkbox10') {
      setCheckbox10(checked)
    } else if (name === 'checkbox11') {
      setCheckbox11(checked)
    } else if (name === 'checkbox12') {
      setCheckbox12(checked)
    } else if (name === 'checkbox13') {
      setCheckbox13(checked)
    } else if (name === 'checkbox14') {
      setCheckbox14(checked)
    } else if (name === 'checkbox15') {
      setCheckbox15(checked)
    } else if (name === 'checkbox16') {
      setCheckbox16(checked)
    } else if (name === 'checkbox17') {
      setCheckbox17(checked)
    } else if (name === 'checkbox18') {
      setCheckbox18(checked)
    }

    if (checked) {
      setShippingOptions([...shippingOptions, value]);
      props.setShippingOptions([...props.shippingOptions, value])
    } else {
      setShippingOptions(shippingOptions.filter((option) => option !== value));
      props.setShippingOptions(props.shippingOptions.filter((option) => option !== value))
    }
  };

  return (
    <div className="shippingOptions">
      <h1 className='courierOptionsHeader'>courier options</h1>
      <div className="aramex">
        <img className="aramexLogo" src={aramexLogo} alt="aramex" />
        <div className="aramexOptions">
          <label className="aramexOption1">
            <p className="aramexOption1Text">standard (35 x 45cm)</p>
            <p className="aramexPrice">R99.99</p>
          </label>
          <input className="aramexcheckBox" name="checkbox1"
            type="checkbox" 
            checked={checkbox1}
            onChange={handleCheckboxChange}
            id="aramexcheckBox" 
            value="Aramex Standard (35 x 45cm)"/>
        </div>
      </div>
      <div className="pudo">
        <img className="pudoLogo" src={pudoLogo} alt="pudo" />
        <div className="pudoOptionsDiv">
          <Select
            onMenuOpen={() => setIsDropdownOpen(true)}
            onMenuClose={() => setIsDropdownOpen(false)}
            className="shippingSubgroupSelect"
            options={selectOptions}
            onChange={(option) => {setSelectedPudoOption(option);}}
            styles={{
              container: (base) => ({
                paddingBottom: '-30px',
              }),
              control: (base) => ({
                ...base,
                position: 'relative',
                backgroundColor: 'rgb(241, 240, 240)',
                borderRadius: '10px',
                width: '100%',
                fontFamily: 'Poppins',
                fontSize: '12px',
                zIndex: 1001,
                fontWeight: 'bold',
                boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
                borderColor: 'none',
                border: 'none',
              }),
              menu: (base) => ({
                ...base,
                position: 'relative',
                backgroundColor: 'rgb(241, 240, 240)',
                top: '-22px',
                marginBottom: '-24px',
                borderRadius: '0px 0px 12px 12px',
                fontFamily: 'Poppins',
                fontSize: '12px',
                zIndex: 1000,
                paddingTop: '15px',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#FB890E' : (state.isFocused ? '#FFC55C' : base.backgroundColor),
                color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
                width: '98%',
                margin: '2px auto',
                boxSizing: 'border-box',
                overflow: 'hidden',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                textAlign: 'left',
              }),
              indicatorSeparator: () => ({ display: 'none' }),
            }}
          />
          {selectedPudoOption && selectedPudoOption.value === "Locker to Locker" &&
            <div className="pudoOptionsLockerToLocker">
              <div className="pudoOption1">
                <div className="pudoOption1Container">
                  <p className="pudoOption1Text">extra small</p>
                  <p className="pudoOption1Dimensions">(60 x 17 x 8cm)</p>
                  <p className="pudoPrice">R40.00</p>
                </div>
                <input className="pudocheckBox1" name="checkbox2"
                  type="checkbox"
                  checked={checkbox2}
                  onChange={handleCheckboxChange}
                  id="pudocheckBox1" 
                  value="Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)"
                />
              </div>
                <div className="pudoOption2">
                  <div className="pudoOption2Container">
                    <p className="pudoOption2Text">small (60 x 41 x 8cm)</p>
                    <p className="pudoPrice">R50.00</p>
                  </div>
                  <input className="pudocheckBox2" name="checkbox3"
                    type="checkbox"
                    checked={checkbox3}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox2" 
                    value="Locker-to-Locker Pudo Small (60 x 41 x 8cm)"
                  />
                </div>
                <div className="pudoOption3">
                  <div className="pudoOption3Container">
                    <p className="pudoOption3Text">medium</p>
                    <p className="pudoOption3Dimensions">(60 x 41 x 19cm)</p>
                    <p className="pudoPrice">R50.00</p>
                  </div>
                  <input className="pudocheckBox3" name="checkbox4"
                    type="checkbox"
                    checked={checkbox4}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox3" 
                    value="Locker-to-Locker Pudo Medium (60 x 41 x 19cm)"
                  />
                </div>
                <div className="pudoOption4">
                  <div className="pudoOption4Container">
                    <p className="pudoOption4Text">large (60 x 41 x 41cm)</p>
                    <p className="pudoPrice">R50.00</p>
                  </div>
                  <input className="pudocheckBox4" name="checkbox5"
                    type="checkbox"
                    checked={checkbox5}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox4" 
                    value="Locker-to-Locker Pudo Large (60 x 41 x 41cm)"
                  />
                </div>
                <div className="pudoOption5">
                  <div className="pudoOption5Container">
                    <p className="pudoOption5Text">extra large</p>
                    <p className="pudoOption5Dimensions">(60 x 41 x 69cm)</p>
                    <p className="pudoPrice">R50.00</p>
                  </div>
                  <input className="pudocheckBox5" name="checkbox6"
                    type="checkbox"
                    checked={checkbox6}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox5" 
                    value="Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)"
                  />
                </div>
            </div>
          }
          {selectedPudoOption && selectedPudoOption.value === "Door to Locker" &&
            <div className="pudoOptionsDoorToLocker">
                <div className="pudoOption1">
                  <div className="pudoOption1Container">
                    <p className="pudoOption1Text">extra small</p>
                    <p className="pudoOption1Dimensions">(60 x 17 x 8cm)</p>
                    <p className="pudoPrice">R50.00</p>
                  </div>
                  <input className="pudocheckBox1" name="checkbox7"
                    type="checkbox"
                    checked={checkbox7}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox1" 
                    value="Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)"
                  />
                </div>
                <div className="pudoOption2">
                  <div className="pudoOption2Container">
                    <p className="pudoOption2Text">small (60 x 41 x 8cm)</p>
                    <p className="pudoPrice">R60.00</p>
                  </div>
                  <input className="pudocheckBox2" name="checkbox8"
                    type="checkbox"
                    checked={checkbox8}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox2" 
                    value="Door-to-Locker Pudo Small (60 x 41 x 8cm)"
                  />
                </div>
                <div className="pudoOption3">
                  <div className="pudoOption3Container">
                    <p className="pudoOption3Text">medium</p>
                    <p className="pudoOption3Dimensions">(60 x 41 x 19cm)</p>
                    <p className="pudoPrice">R100.00</p>
                  </div>
                  <input className="pudocheckBox3" name="checkbox9"
                    type="checkbox"
                    checked={checkbox9}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox3" 
                    value="Door-to-Locker Pudo Medium (60 x 41 x 19cm)"
                  />
                </div>
                <div className="pudoOption4">
                  <div className="pudoOption4Container">
                    <p className="pudoOption4Text">large (60 x 41 x 41cm)</p>
                    <p className="pudoPrice">R150.00</p>
                  </div>
                  <input className="pudocheckBox4" name="checkbox10"
                    type="checkbox"
                    checked={checkbox10}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox4" 
                    value="Door-to-Locker Pudo Large (60 x 41 x 41cm)"
                  />
                </div>
                <div className="pudoOption5">
                  <div className="pudoOption5Container">
                    <p className="pudoOption5Text">extra large</p>
                    <p className="pudoOption5Dimensions">(60 x 41 x 69cm)</p>
                    <p className="pudoPrice">R200.00</p>
                  </div>
                  <input className="pudocheckBox5" name="checkbox11"
                    type="checkbox"
                    checked={checkbox11}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox5" 
                    value="Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)"
                  />
                </div>
            </div>
          }
          {selectedPudoOption && selectedPudoOption.value === "Locker to Door" &&
            <div className="pudoOptionsLockerToDoor">
                <div className="pudoOption1">
                  <div className="pudoOption1Container">
                    <p className="pudoOption1Text">extra small</p>
                    <p className="pudoOption1Dimensions">(60 x 17 x 8cm)</p>
                    <p className="pudoPrice">R50.00</p>
                  </div>
                  <input className="pudocheckBox1" name="checkbox12"
                    type="checkbox"
                    checked={checkbox12}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox1" 
                    value="Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)"
                  />
                </div>
                <div className="pudoOption2">
                  <div className="pudoOption2Container">
                    <p className="pudoOption2Text">small (60 x 41 x 8cm)</p>
                    <p className="pudoPrice">R60.00</p>
                  </div>
                  <input className="pudocheckBox2" name="checkbox13"
                    type="checkbox"
                    checked={checkbox13}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox2" 
                    value="Locker-to-Door Pudo Small (60 x 41 x 8cm)"
                  />
                </div>
                <div className="pudoOption3">
                  <div className="pudoOption3Container">
                    <p className="pudoOption3Text">medium</p>
                    <p className="pudoOption3Dimensions">(60 x 41 x 19cm)</p>
                    <p className="pudoPrice">R100.00</p>
                  </div>
                  <input className="pudocheckBox3" name="checkbox14"
                    type="checkbox"
                    checked={checkbox14}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox3" 
                    value="Locker-to-Door Pudo Medium (60 x 41 x 19cm)"
                  />
                </div>
                <div className="pudoOption4">
                  <div className="pudoOption4Container">
                    <p className="pudoOption4Text">large (60 x 41 x 41cm)</p>
                    <p className="pudoPrice">R150.00</p>
                  </div>
                  <input className="pudocheckBox4" name="checkbox15"
                    type="checkbox"
                    checked={checkbox15}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox4" 
                    value="Locker-to-Door Pudo Large (60 x 41 x 41cm)"
                  />
                </div>
                <div className="pudoOption5">
                  <div className="pudoOption5Container">
                    <p className="pudoOption5Text">extra large</p>
                    <p className="pudoOption5Dimensions">(60 x 41 x 69cm)</p>
                    <p className="pudoPrice">R200.00</p>
                  </div>
                  <input className="pudocheckBox5" name="checkbox16"
                    type="checkbox"
                    checked={checkbox16}
                    onChange={handleCheckboxChange}
                    id="pudocheckBox5" 
                    value="Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)"
                  />
                </div>
            </div>
          }
        </div>
      </div>
      <div className="paxi">
        <img className="paxiLogo" src={paxiLogo} alt="paxi" />
        <div className="paxiOptions">
          <label className="paxiOption1">
            <div className="paxiOption1Container">
              <p className="paxiOption1Text">standard (45 x 37cm)</p>
              <p className="paxiPrice">R59.95</p>
            </div>
            <input className="paxicheckBox1" name="checkbox17"
            type="checkbox" 
            checked={checkbox17}
            onChange={handleCheckboxChange}
            id="paxicheckBox1" 
            value="Paxi Standard (45 x 37cm)"
            />
          </label>
          <label className="paxiOption2">
            <div className="paxiOption2Container">
              <p className="paxiOption2Text">large (64 x 51cm)</p>
              <p className="paxiPrice">R89.95</p>
            </div>
            <input className="paxicheckBox2" name="checkbox18"
            type="checkbox"
            checked={checkbox18}
            onChange={handleCheckboxChange}
            id="paxicheckBox2" 
            value="Paxi Large (64 x 51cm)"
            />
          </label>
        </div>
      </div>
    </div>
  );
}

export default ShippingOptions