// VendorFormStep1.jsx

import React, { useRef, useEffect, useContext, useState, useMemo } from 'react';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { VendorFormContext } from '../context/VendorFormContext.jsx';
import {
  onAuthStateChanged,
  getAuth
} from "firebase/auth";

import { doc, updateDoc, setDoc, getDoc } from '@firebase/firestore';
import { auth, db, storage } from "../firebase";
import { 
  addDoc, collection, query, where, getDocs 
} from '@firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import { motion, AnimatePresence } from 'framer-motion'; // Import Framer Motion components
import infoIcon from '../icons/infoIcon.png'; // Import an icon from react-icons (you can choose any icon library)

import './StorelessUser.css';

const VendorFormStep1 = ({ next, setAlertMessage, setAlertType }) => {
  const { formData, updateFormData } = useContext(VendorFormContext);
  const navigate = useNavigate();
  const addressContainerRef = useRef(null);
  const businessInfoDiv2 = useRef();

  const [storeNameExistsDiv, setStoreNameExistsDiv] = useState(false);
  const [offeringType, setOfferingType] = useState(formData.offeringType || ''); // 'services' or 'products'
  const [userEmail, setUserEmail] = useState('');

  // State to control the visibility of the services message and icon
  const [messageVisible, setMessageVisible] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  // New State Variables for Address Autocomplete
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);

  const addressInputRef = useRef(null); // Ref for the address input field

  // Debounce Timer to prevent excessive API calls
  const debounceTimer = useRef(null);

  // Firebase Auth Instance
  const authInstance = getAuth();

  // Define Cloud Function URLs (Ensure these are correctly set up)
  const MAPBOX_SUGGESTIONS_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-suggestions";
  const MAPBOX_REVERSE_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-from-coordinates";

  useEffect(() => {
    if (formData.offeringType === 'services') {
      triggerMessage();
    } else {
      setMessageVisible(false);
      setShowIcon(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.offeringType]);

  // Function to trigger the message animation
  const triggerMessage = () => {
    setMessageVisible(true);
    setShowIcon(false);

    // Hide the message after 10 seconds
    const timer = setTimeout(() => {
      setMessageVisible(false);
      setShowIcon(true);
    }, 10000); // 10,000 milliseconds = 10 seconds

    // Cleanup the timer when component unmounts or offeringType changes
    return () => clearTimeout(timer);
  };

  // Get the current user's email
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      }
    });
    return () => unsubscribe();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });

    // If the address field is being updated, fetch suggestions
    if (name === 'address') {
      fetchAddressSuggestions(value);
    }
  };

  // Function to fetch address suggestions via Cloud Function
  const fetchAddressSuggestions = (query) => {
    // Clear the previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    if (query.length < 3) {
      // If query is too short, clear suggestions
      setAddressSuggestions([]);
      setIsSuggestionsVisible(false);
      return;
    }

    // Set a new timer for debouncing
    debounceTimer.current = setTimeout(() => {
      const user = authInstance.currentUser;
      if (!user) {
        // Optionally, handle unauthenticated state
        setAddressSuggestions([]);
        setIsSuggestionsVisible(false);
        return;
      }

      // Retrieve ID Token
      user.getIdToken()
        .then((idToken) => {
          fetch(MAPBOX_SUGGESTIONS_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${idToken}`, // Include ID Token
            },
            body: JSON.stringify({ query }),
          })
            .then(response => {
              if (!response.ok) {
                throw new Error(`Server responded with status ${response.status}`);
              }
              return response.json();
            })
            .then(data => {
              console.log("Received data from suggestions API:", data);
              if (data.suggestions) {
                setAddressSuggestions(data.suggestions);
                setIsSuggestionsVisible(true);
              } else {
                setAddressSuggestions([]);
                setIsSuggestionsVisible(false);
              }
            })
            .catch(error => {
              console.error("Error fetching address suggestions:", error);
              setAddressSuggestions([]);
              setIsSuggestionsVisible(false);
            });
        })
        .catch(error => {
          console.error("Error retrieving ID token:", error);
          setAddressSuggestions([]);
          setIsSuggestionsVisible(false);
        });
    }, 500); // 500ms debounce
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    // Format the selected suggestion
    const formattedPlaceName = suggestion.place_name.replace(/,\s*South Africa$/, '');

    // Update the address in form data
    updateFormData({ address: formattedPlaceName });

    // Hide suggestions after selection
    setAddressSuggestions([]);
    setIsSuggestionsVisible(false);

    // Optionally, blur the input to remove focus
    if (addressContainerRef.current) {
      const input = addressContainerRef.current.querySelector('input');
      if (input) {
        input.blur();
      }
    }
  };

  // Handle click outside the suggestions dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addressContainerRef.current && !addressContainerRef.current.contains(event.target)) {
        setIsSuggestionsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle offering type selection
  const handleOfferingType = (type) => {
    setOfferingType(type);
    updateFormData({ offeringType: type, industry: '' });
  };

  // Handle Next button
  const handleNext = (e) => {
    e.preventDefault();
    next();
  };

  // Check if store name exists whenever formData.store changes
  useEffect(() => {
    const checkIfStoreNameExists = async () => {
      if (!formData.store) {
        setStoreNameExistsDiv(false);
        return;
      }

      const storeQueryInstance = query(
        collection(db, "stores"),
        where("store", "==", formData.store)
      );
      try {
        const snapshot = await getDocs(storeQueryInstance);
        if (snapshot.size > 0) {
          setStoreNameExistsDiv(true);
        } else {
          setStoreNameExistsDiv(false);
        }
      } catch (error) {
        console.error("Error checking store name existence:", error);
      }
    };

    checkIfStoreNameExists();
  }, [formData.store]);

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("Authenticated User:", user);

        const dbRef = doc(db, 'users', user.uid);

        // Generate a unique store ID
        const storeID = uuidv4();

        // Prepare the base data to be saved in the 'stores' collection
        const storeInfo = {
          email: user.email,
          user: user.displayName,
          userID: user.uid,
          phone: formData.phone,
          address: formData.address,
          store: formData.store,
          store_description: formData.store_description,
          storeID: storeID, // Use the generated storeID
          likes: 0,
          offeringType: formData.offeringType,
          createdBy: user.uid,
          createdAt: new Date(),
          services: formData.services
        };

        try {
          // Update the user document with storeInfo
          await updateDoc(dbRef, storeInfo);
        } catch (error) {
          console.error("Error updating user document:", error);
          setAlertMessage("Failed to update user information. Please try again.");
          setAlertType('error')
          return;
        }

        console.log("User info added to database:", storeInfo);

        // Create store document within the 'stores' collection
        const storeDbRef = doc(db, 'stores', storeID);

        try {
          await setDoc(storeDbRef, storeInfo);
        } catch (error) {
          console.error("Error creating store document:", error);
          setAlertMessage("Failed to create store. Please try again.");
          setAlertType('error')
          return;
        }

        console.log("Store has been successfully created in the database.");
        setAlertMessage("You have successfully created your store");
        setAlertType('success')
        navigate("/vendor");
      } else {
        console.error("No authenticated user found.");
        setAlertMessage("You must be logged in to create a store.");
        setAlertType('error')
      }
    });
  };

  // Validation: Check if all required fields are filled
  const isFormValid = offeringType &&
                    formData.store &&
                    formData.store_description &&
                    formData.phone &&
                    formData.address &&
                    !storeNameExistsDiv &&
                    (
                      (offeringType === 'services' && formData.services) ||
                      (offeringType === 'products' && formData.industry)
                    );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (offeringType === 'services') {
      handleCreateAccount(e);
    } else if (offeringType === 'products') {
      handleNext(e);
    }
  }

  // Animation variants for the services message
  const messageVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: -100, opacity: 0 },
  };

  // Animation variants for the form sections
  const formVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 50, opacity: 0 },
  };

  return (
    <form onSubmit={handleFormSubmit} className="vendorFormStep1">
      {/* AnimatePresence handles the mounting and unmounting animations for the services message */}
      <AnimatePresence>
        {offeringType === 'services' && messageVisible && (
          <motion.div
            className="servicesMessage"
            variants={messageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 1 }} // 1 second for fade in/out
          >
            We're <strong>not allowing services just yet,</strong> but please <strong>fill in the details</strong> below so we can <strong>contact you when we launch it.</strong>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="offeringTypeQuestion">
        <p>What are you selling?</p>
        <div className="offeringTypeBtnDiv">
          <div
            className={`offeringTypeButton ${offeringType === 'services' ? 'selected' : ''}`}
            onClick={() => handleOfferingType('services')}
          >
            services
          </div>
          <div
            className={`offeringTypeButton ${offeringType === 'products' ? 'selected' : ''}`}
            onClick={() => handleOfferingType('products')}
          >
            products
          </div>
        </div>
      </div>

      {/* Conditionally render the icon when message is not visible and offeringType is 'services' */}
      {offeringType === 'services' && showIcon && (
        <div className="storelessUserBottomDiv" onClick={triggerMessage}>
          <img  className="storelessUserBottomIcon" src={infoIcon} alt="Info Icon" /> {/* You can choose any icon you prefer */}
        </div>
      )}

      {/* AnimatePresence handles the mounting and unmounting animations for the form sections */}
      <AnimatePresence>
        {offeringType && (
          <motion.div
            className="formSections"
            variants={formVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <div className="createStore2Form">

              <div className="businessInfoDiv2" ref={businessInfoDiv2}>
                <input
                  type="text"
                  placeholder='Business Name'
                  id='businessName2'
                  name='store'
                  value={formData.store}
                  onChange={handleChange}
                  required
                />
                {storeNameExistsDiv && (
                  <div className="storeNameExistsDiv2">
                    Business name already exists, please pick another name.
                  </div>
                )}
                {/* Conditionally render the select based on offeringType */}
                {offeringType === 'products' && (
                  <select
                    name="industry"
                    id="industries2"
                    value={formData.industry}
                    onChange={handleChange}
                    required={offeringType === 'products'}
                  >
                    <option value="">Select Industry</option>
                    <option value="Antiques & Collectables">Antiques & Collectables</option>
                    <option value="Art">Art</option>
                    <option value="Audio & Media">Audio & Media</option>
                    <option value="Automotive & DIY">Automotive & DIY</option>
                    <option value="Beauty">Beauty</option>
                    <option value="Books">Books</option>
                    <option value="Cellphones & Wearables">Cellphones & Wearables</option>
                    <option value="Crafts & Handmade Goods">Crafts & Handmade Goods</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Fashion">Fashion</option>
                    <option value="Gaming">Gaming</option>
                    <option value="Garden, Pool & Patio">Garden, Pool & Patio</option>
                    <option value="Health, Fitness & Personal Care">Health, Fitness & Personal Care</option>
                    <option value="Hobbies & Toys">Hobbies & Toys</option>
                    <option value="Holistic & Esoteric">Holistic & Esoteric</option>
                    <option value="Home & Decor">Home & Decor</option>
                    <option value="Movies & Television">Movies & Television</option>
                    <option value="Music & Instruments">Music & Instruments</option>
                    <option value="Office & Stationary">Office & Stationary</option>
                    <option value="Pets">Pets</option>
                    <option value="Physical Sport & Training">Physical Sport & Training</option>            
                    <option value="Other">Other</option>
                  </select>
                )}
                
                <textarea
                  placeholder='Business Description'
                  name='store_description'
                  rows="6"
                  id='businessDescription2'
                  value={formData.store_description}
                  onChange={handleChange}
                  required
                />
                {offeringType === 'services' && (
                  <div className="servicesField">
                    <label htmlFor="servicesOffered">What services do you offer?</label>
                    <input
                      type="text"
                      id="servicesOffered"
                      name="services"
                      value={formData.services || ''}
                      onChange={handleChange}
                      placeholder="e.g., Hair-dressing, Nailtech, Consulting..."
                      required={offeringType === 'services'}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="businessInfoDiv3">
              {/* Conditionally render the email input when services are selected */}
              {offeringType === 'services' && (
                <input
                  type="email"
                  name="email"
                  value={userEmail}
                  id='vendorFormEmail'
                  placeholder='Email'
                  disabled
                />
              )}
             <input
              type="tel"
              name="phone"
              value={formData.phone || ''}
              onChange={handleChange}
              id="phoneNumber"
              placeholder="0XX XXX XXXX"
              maxLength="10"
              pattern="^0\d{9}$"
              title="Enter a 10-digit phone number starting with 0."
              inputMode="numeric"
              required
            />

              {/* Address input with autocomplete */}
              <div className='addressAutocompleteDiv' ref={addressContainerRef}>                
                <input
                  type="text"
                  name="address"
                  id='address'
                  value={formData.address || ''}
                  placeholder='Physical Address'
                  onChange={handleChange}
                  required
                  autoComplete="off" // Disable browser's default autocomplete
                />
                {/* Suggestions */}
                {isSuggestionsVisible && addressSuggestions.length > 0 && (
                  <ul className="suggestionsList">
                    {addressSuggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestionItem"
                      > 
                        {suggestion.place_name.replace(/,\s*South Africa$/, '')}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="storelessUserBtnDiv">
        {offeringType === 'products' && (
          <button
            type="button"
            className="storelessUserNextBtn"
            onClick={handleNext}
            disabled={!isFormValid}
          >
            next
          </button>
        )}
        {offeringType === 'services' && (
          <button
            type="submit"
            className="storelessUserSubmitBtn"
            disabled={!isFormValid}
          >
            submit
          </button>
        )}      
      </div>
    </form>
  );
};

export default VendorFormStep1;
